import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderPage, Table, Button } from '../../components/elements/elements';

import { getChangeRequests, handleRequest } from '../../actions/usersActions';
import {
  changePaymentDelivery,
  fetchPaymentDelivery
} from '../../actions/services';

const COLUMNS = ['Зона доставки', 'Стоимость (₽)', 'Состояние (Вкл./Выкл.)'];

class PaymentDeliveryPage extends Component {
  componentDidMount() {
    this.props.fetchPaymentDelivery();
  }

  onHandleRequest = (id, enabled) => {
    return () => {
      this.props.changePaymentDelivery(id, enabled);
    };
  };

  renderButton = ({ enabled, id }) => {
    return (
      <Button
        onClick={this.onHandleRequest(id, !enabled)}
        value={enabled ? 'Отключить' : 'Включить'}
      />
    );
  };

  renderTableData = () => {
    const { deliveryPayments } = this.props;

    return deliveryPayments.map((pDelivery, i) => (
      <tr key={i}>
        <td>{pDelivery.name}</td>
        <td>{pDelivery?.price || 'Не известно'}</td>
        <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {this.renderButton(pDelivery)}
        </td>
      </tr>
    ));
  };

  render() {
    return (
      <div className="change-requests">
        <HeaderPage little title={`Платная доставка`} />

        <Table
          data={this.renderTableData()}
          style={{ marginTop: 50 }}
          title={COLUMNS}
        />
      </div>
    );
  }
}

const mapState = state => ({
  deliveryPayments: state.services.deliveryPayments
});

const mapDispatch = {
  getChangeRequests,
  handleRequest,
  fetchPaymentDelivery,
  changePaymentDelivery
};

export const PaymentDelivery = connect(
  mapState,
  mapDispatch
)(PaymentDeliveryPage);
