import {
  FETCH_SETTINGS,
  FETCH_APP_BANNER,
  UPDATE_SETTINGS,
  CLEAR_SETTINGS,
  FETCH_COURIER_SETTINGS,
  UPDATE_COURIER_SETTINGS,
  CLEAR_COURIER_SETTINGS
} from '../actions/settingsActions';

const INITIAL_STATE = {
  settings: [],
  courierSettings: {},
  banners: {
    appBanner: '',
    horizontalTabletBanner: '',
    tabletBanner: ''
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COURIER_SETTINGS:
      return { ...state, courierSettings: action.payload };

    case UPDATE_COURIER_SETTINGS:
      return { ...state, courierSettings: action.payload };

    case CLEAR_COURIER_SETTINGS:
      return { ...state, courierSettings: action.payload };

    case FETCH_SETTINGS:
      return { ...state, settings: action.payload };

    case UPDATE_SETTINGS:
      return { ...state, settings: action.payload };

    case CLEAR_SETTINGS:
      return { ...state, settings: action.payload };

    case FETCH_APP_BANNER:
      const { data } = action.payload;
      const banners = data
        ? {
            appBanner: data.appBanner || '',
            horizontalTabletBanner: data.horizontalTabletBanner || '',
            tabletBanner: data.tabletBanner || ''
          }
        : INITIAL_STATE.banners;

      return {
        ...state,
        banners
      };

    default:
      return state;
  }
}
