import request from '../lib/request';

export const GET_PAYMENTS = 'GET_PAYMENTS';

export const getPayments = query => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: GET_PAYMENTS,
      response
    });
  };

  await request('/payment_methods', {
    query,
    success,
    headers: {
      'Content-type': 'application/json',
      Authorization: getState().login.token
    }
  });
};
