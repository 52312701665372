import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  HeaderPage,
  Table,
  Cross,
  Input
} from '../../elements/elements';
import {
  fetchPromocodes,
  updatePromocodes,
  createPromocode,
  updatePromocode
} from '../../../actions/promocodeActions';
import { fetchRestaurants } from '../../../actions/restaurantActions';

import moment from 'moment';
import PaymentMethods from '../../PaymentMethods';
import Select from '../../Select';

moment.locale('ru');

const defaultPromocode = {
  delivery: false,
  selfdelivery: false,
  bonusAllowed: false,
  minimalSum: '',
  promocode: '',
  products: [],
  sale: '',
  disabledRestaurants: [],
  type: 'flatsale' // flatsale | percent | custom | product
  // custom: 'customType', // when type === custom
};

const days = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота'
];

const defaultWorkTime = '2019-07-29T09:00:00.078Z';

const defaultState = {
  array: [],
  removeArray: [],
  activeRestaurants: [],
  promocode: defaultPromocode
};

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.updatePromocodes();
    this.props.fetchPromocodes();
  };

  componentDidMount() {
    this.props.fetchRestaurants();
  }

  componentWillReceiveProps(newState) {
    const { restaurants } = this.props;
    if (!this.props.show && newState.show) {
      if (newState.promocode) {
        const workTimeWithMoment = {};

        for (const key in newState.promocode.workTime) {
          if (newState.promocode.workTime.hasOwnProperty(key)) {
            const value = newState.promocode.workTime[key];

            workTimeWithMoment[key] = [
              {
                from: moment(value[0] ? value[0].from : defaultWorkTime).format(
                  'HH:mm'
                ),
                to: moment(value[0] ? value[0].to : defaultWorkTime).format(
                  'HH:mm'
                )
              }
            ];
          }
        }

        const newPromocodes = { ...newState.promocode };
        newPromocodes.workTime = workTimeWithMoment;

        const activeRestaurants = newState.promocode.disabledRestaurants.map(
          el => {
            const restaurant = restaurants.find(elem => elem._id === el);
            const item = {
              value: restaurant._id,
              text: restaurant.name
            };
            return item;
          }
        );

        this.setState({
          ...defaultState,
          activeRestaurants,
          promocode: newPromocodes
        });
      } else {
        this.setState(defaultState);
      }
    }
  }

  getPromocodeTypes = () => [
    {
      text: 'Фиксированный',
      value: 'flatsale'
    },
    {
      text: 'Проценты',
      value: 'percent'
    },
    {
      text: 'Продукт в подарок',
      value: 'product'
    },
    {
      text: 'Уникальный',
      disabled: true,
      value: 'custom'
    }
  ];

  getRestaurants = () => {
    const { restaurants } = this.props;

    const array =
      restaurants && restaurants.length
        ? restaurants.map(el => ({ text: el.name, value: el._id }))
        : [];

    return array;
  };

  changeInput = event => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'sale' || name === 'minimalSum') {
      value = event.target.value.match(/[0-9]/g) || '';
      if (value && value.length) value = value.join('');
    } else if (
      [
        'bonusAllowed',
        'canUseWithOtherCoupons',
        'canUseWithPromocodes',
        'delivery',
        'selfdelivery'
      ].includes(name)
    ) {
      value = event.target.checked;
    }

    if (this.state.promocode[name] !== value) {
      this.setState(prevState => ({
        promocode: {
          ...prevState.promocode,
          [name]: value
        }
      }));
    }
  };

  onCreate = () => {
    const { promocode } = this.state;
    this.props.createPromocode(promocode, this.refreshPage);
  };

  onUpdate = () => {
    const { removeArray, promocode } = this.state;
    const id = promocode._id;
    const workTime = {};

    const data = { ...promocode };

    for (const key in data.workTime) {
      if (data.workTime.hasOwnProperty(key)) {
        const value = data.workTime[key][0];

        const time = {
          from: value.from.split(':'),
          to: value.to.split(':')
        };
        workTime[key] = [
          {
            from: moment()
              .hours(Number(time.from[0]))
              .minutes(Number(time.from[1]))
              .toISOString(),
            to: moment()
              .hours(Number(time.to[0]))
              .minutes(Number(time.to[1]))
              .toISOString()
          }
        ];
      }
    }
    data.workTime = workTime;

    if (removeArray && removeArray.length) {
      for (let i = 0; i < removeArray.length; i++) {
        for (let j = 0; j < data.products.length; j++) {
          if (data.products[j]._id === removeArray[i]._id) {
            data.products.splice(j, 1);
            break;
          }
        }
      }
    }

    this.props.updatePromocode(id, data, this.refreshPage);
  };

  onDeleteProduct = (key, id) => {
    const { promocode, removeArray } = this.state;

    if (promocode.products) {
      const array = [...promocode.products];
      array.splice(key, 1);

      removeArray.push(id);

      this.setState(prevState => ({
        promocode: {
          ...prevState.promocode,
          products: array
        }
      }));
    }
  };

  onChangeTimeInput = (event, key, type) => {
    const { value } = event.target;

    if (type === 'from') {
      this.setState({
        promocode: {
          ...this.state.promocode,
          workTime: {
            ...this.state.promocode.workTime,
            [key]: [{ ...this.state.promocode.workTime[key][0], from: value }]
          }
        }
      });
    }
    if (type === 'to') {
      this.setState({
        promocode: {
          ...this.state.promocode,
          workTime: {
            ...this.state.promocode.workTime,
            [key]: [{ ...this.state.promocode.workTime[key][0], to: value }]
          }
        }
      });
    }
  };

  renderTimeInputs = () => {
    const { workTime } = this.state.promocode;

    const result = [];

    if (workTime) {
      for (const key in workTime) {
        if (workTime.hasOwnProperty(key)) {
          const index = Number(key);
          result.push(
            <FormGroup
              onlyLabel
              placeholder={days[index]}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'from')}
                style={{ marginRight: '5px' }}
                type='time'
                value={workTime[key][0] ? workTime[key][0].from : ''}
              />

              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'to')}
                type='time'
                value={workTime[key][0] ? workTime[key][0].to : ''}
              />
            </FormGroup>
          );
        }
      }
    }
    return result;
  };

  onChangeSelect = type => value => {
    const newState = {
      ...this.state,
      promocode: { ...this.state.promocode }
    };

    if (type === 'type') {
      newState.promocode[type] = value;
      if (value === 'product') {
        newState.promocode.minimalSum = '';
        newState.promocode.sale = '';
      }
    }

    if (type === 'activeRestaurants') {
      newState[type] = value;
      newState.promocode.disabledRestaurants = value.map(el => el.value);
    }

    this.setState(newState);
  };

  onChangePaymentMethods = paymentMethods =>
    this.setState({
      promocode: {
        ...this.state.promocode,
        paymentMethods
      }
    });

  getActiveSelectValue = () => {
    const { type } = this.state.promocode;

    if (type) {
      const data = this.getPromocodeTypes().find(a => a.value === type);
      if (data) return data;
    }

    return this.getPromocodeTypes()[0];
  };

  renderWorkDays = () => {
    return 'workdays';
  };

  renderProducts = () => {
    const { promocode } = this.state;
    if (!this.isPropsPromocode()) return null;

    if (promocode.products && promocode.products.length) {
      const data = promocode.products.map((a, key) => {
        const dough = a.additionalInfo && a.additionalInfo.dough;
        const size = a.additionalInfo && a.additionalInfo.size;

        return (
          <tr key={key}>
            <td>{a.name}</td>
            <td>{dough || '-'}</td>
            <td>{size || '-'}</td>
            <td>{a._id || '-'}</td>
            <td>
              <Cross
                noModalCross
                onClick={() => this.onDeleteProduct(key, a._id)}
              />
            </td>
          </tr>
        );
      });

      return (
        <>
          <HeaderPage
            center
            little
            style={{ justifyContent: 'center' }}
            title='Продукты промокода'
          />
          <Table
            data={data}
            key='promocodesTable'
            title={['Продукт', 'Начинка', 'Размер', 'ID', '']}
          />
        </>
      );
    }

    return (
      <HeaderPage
        little
        notFound
        title='Промокод не имеет продуктов...'
      />
    );
  };

  isCustomPromocode = () => {
    const { promocode } = this.state;
    return promocode.type === 'custom';
  };

  isProductPromocode = () => {
    const { promocode } = this.state;
    return promocode.type === 'product';
  };

  isPropsPromocode = () => {
    return Boolean(this.props.promocode);
  };

  render() {
    const { promocode, activeRestaurants } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle
          title={
            this.isPropsPromocode()
              ? `«${promocode.promocode}»`
              : 'Добавить промокод'
          }
        />
        <Form
          direction='column'
          onSubmit={this.isPropsPromocode() ? this.onUpdate : this.onCreate}
          value={
            this.props.promocode ? 'Сохранить изменения' : 'Добавить промокод'
          }
        >
          <Select
            disabled={this.isCustomPromocode()}
            items={this.getPromocodeTypes()}
            onChange={this.onChangeSelect('type')}
            text={this.getActiveSelectValue().text}
            value={this.getActiveSelectValue().value}
          />

          {!this.isProductPromocode() && (
            <FormGroup
              id='sale'
              name='sale'
              onChange={this.changeInput}
              placeholder='Cкидка'
              value={promocode.sale}
            />
          )}

          <FormGroup
            id='promocode'
            name='promocode'
            onChange={this.changeInput}
            placeholder='Промокод'
            required
            value={promocode.promocode}
          />

          {!this.isProductPromocode() && (
            <>
              <FormGroup
                id='minimalSum'
                name='minimalSum'
                onChange={this.changeInput}
                placeholder='Минимальная сумма'
                value={promocode.minimalSum}
              />

              <FormGroup
                checked={promocode.bonusAllowed}
                id='bonusAllowed'
                name='bonusAllowed'
                onChange={this.changeInput}
                placeholder='Использование бонусов'
                type='checkbox'
              />
              <FormGroup
                checked={promocode.canUseWithOtherCoupons}
                id='canUseWithOtherCoupons'
                name='canUseWithOtherCoupons'
                onChange={this.changeInput}
                placeholder='Используется с другими купонами'
                type='checkbox'
              />

              <FormGroup
                checked={promocode.canUseWithPromocodes}
                id='canUseWithPromocodes'
                name='canUseWithPromocodes'
                onChange={this.changeInput}
                placeholder='Используется с другими акциями'
                type='checkbox'
              />
            </>
          )}

          <FormGroup
            checked={promocode.delivery}
            id='delivery'
            name='delivery'
            onChange={this.changeInput}
            placeholder='Доставка'
            type='checkbox'
          />

          <FormGroup
            checked={promocode.selfdelivery}
            id='selfdelivery'
            name='selfdelivery'
            onChange={this.changeInput}
            placeholder='Самовывоз'
            type='checkbox'
          />

          {this.renderProducts()}

          <HeaderPage
            little
            title='Рестораны в которых промокод недоступен'
          />
          <Select
            activeItems={activeRestaurants}
            default='Рестораны в которых промокод недоступен'
            items={this.getRestaurants()}
            onChange={this.onChangeSelect('activeRestaurants')}
          />

          <HeaderPage
            little
            title='Методы оплаты'
          />
          <PaymentMethods
            activeMethods={promocode.paymentMethods}
            onChange={this.onChangePaymentMethods}
          />

          <div>
            <HeaderPage
              little
              title='Время действия промокода'
            />
            {this.renderTimeInputs()}
          </div>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    promocode: state.modal.modalContent,
    restaurants: state.restaurants.restaurants
  };
}

const mapDispatchToProps = {
  fetchRestaurants,
  fetchPromocodes,
  updatePromocodes,
  updatePromocode,
  createPromocode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalComponent);
