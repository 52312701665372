import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  addCoupon
} from '../../../actions/usersActions';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import { getCouponName } from '../../elements/functions';
import Select from '../../Select';
import moment from 'moment';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCoupon: '',
      countCoupon: '',
      expireDate: '',
      termless: false,
      greatThursday: false,
      sale: ''
    };
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.clearUsers();
    this.props.fetchUsers(this.props.userData.user, 'all');
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  changeSale = event => {
    let { value } = event.target;
    if (parseInt(value, 10) > 99) value = '99';
    if (parseInt(value, 10) < 1) value = '1';

    this.setState({ sale: value });
  };

  onSelect = value => {
    const greatThursday = value === 'greatThursday';
    const sale = greatThursday ? this.state.sale : '';

    this.setState({
      selectCoupon: value,
      greatThursday,
      sale
    });
  };

  getItems = () => {
    const { couponTypes } = this.props;
    let array = [];
    if (couponTypes)
      array = couponTypes.map(a => ({ value: a, text: getCouponName(a) }));
    return array;
  };

  onSubmit = () => {
    const {
      selectCoupon,
      countCoupon,
      expireDate,
      termless,
      sale
    } = this.state;
    if (selectCoupon === '') return alert('Выберите тип купона');

    const coupons = {
      [selectCoupon]: {
        quantity: Number(countCoupon),
        expireDate: moment.utc(expireDate).toISOString()
      }
    };

    if (selectCoupon === 'greatThursday') {
      coupons[selectCoupon].sale = sale;
    }

    const data = {
      _id: this.props.userData.data._id,
      login: this.props.userData.user,
      coupons
    };
    if (termless) data.coupons[selectCoupon].expireDate = null;
    else if (expireDate === '') delete data.coupons[selectCoupon].expireDate;

    this.props.addCoupon(data, this.refreshPage);
  };

  changeCheckbox = e => {
    const { name, checked } = e.target;

    this.setState({ [name]: checked, expireDate: '' });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Добавить купон'
        >
          <ModalTitle title='Добавить купон' />
          <Select
            default='Выберите тип купона'
            items={this.getItems()}
            label='Купон:'
            maxHeight={222}
            onChange={this.onSelect}
          />
          {this.state.greatThursday && (
            <FormGroup
              id='sale'
              maxlength='4'
              name='sale'
              onChange={this.changeSale}
              placeholder='Скидка'
              value={this.state.sale}
            />
          )}
          <FormGroup
            id='countCoupon'
            maxlength='2'
            name='countCoupon'
            onChange={this.changeInput}
            placeholder='Количество'
            required
            value={this.state.countCoupon}
          />
          <FormGroup
            disabled={this.state.termless}
            id='expireDate'
            maxlength='2'
            name='expireDate'
            onChange={this.changeInput}
            placeholder='Действителен до'
            type='date'
            value={this.state.expireDate}
          >
            <FormGroup
              checked={this.state.termless}
              id='termless'
              name='termless'
              onChange={this.changeCheckbox}
              placeholder='Бессрочный'
              type='checkbox'
            />
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.modal.modalContent,
    couponTypes: state.users.couponTypes
  };
}

export default connect(mapStateToProps, {
  getCouponName,
  fetchUsers,
  clearUsers,
  addCoupon
})(ModalComponent);
