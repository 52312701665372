import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  toggleCampaignModal,
  toggleVacanciesModal,
  toggleProductModal,
  toggleGroupModal,
  toggleAddProductsGroupModal,
  toggleViewCouponModal,
  toggleHistoryCoupons,
  toggleHistoryOrders,
  toggleHistoryBonus,
  toggleAddCoupon,
  toggleEditBonus,
  toggleDeliveryZones,
  toggleCouriersModal,
  togglePromocodeModal,
  togglePromocodeProductModal,
  toggleOrderModal,
  toggleProductCampaignModal,
  toggleEditOrders,
  toggleChangeLoginModal,
  toggleChangeBirthdayModal,
  toggleAddCouponsProductModal,
  toggleCouponsProductModal,
  toggleCouponModal,
  toggleRuleBonusesSystemModal,
  toggleCampaignIndexModal,
  toggleAddOrderModal
} from '../../actions/modalActions';

import CampaignModal from './campaigns/campaignModal';
import CampaignIndexModal from './campaigns/campaignIndexModal';
import VacanciesModal from './vacancies/vacanciesModal';
import ProductModal from './product/productModal';
import GroupModal from './group/groupModal';

import AddCouponModal from './user/addCouponModal';
import EditBonusModal from './user/editBonusModal';
import HistoryBonusModal from './user/historyBonusModal';
import HistoryCouponsModal from './user/historyCouponsModal';
import HistoryOrdersModal from './user/historyOrdersModal';
import ViewCouponsModal from './user/viewCouponsModal';
import EditOrderModal from './user/editOrdersModal';
import ChangeLoginModal from './user/changeLoginModal';

import RuleBonusesSystemModal from './couriers/ruleBonusesSystemModal';
import CouriersModal from './couriers/couriersModal';

import PromocodeModal from './promocode/promocodeModal';
import PromocodeProductModal from './promocode/promocodeProductModal';
import OrderModal from './orders/ordersModal';
import AddOrderModal from './orders/addOrderModal';
import AddCouponProductModal from './coupons/addCouponProductModal';
import CouponProductModal from './coupons/couponProductModal';
import CouponModal from './coupons/couponModal';
import AddProductsGroupModal from './group/addProductsGroupModal';

import UpdateDeliveryZone from './restourant/updateDeliveryZones';
import ProductCampaignModal from './product/productCampaignModal';

import ChangeBirthDayModal from './user/changeBirthdayModal';

class Modal extends Component {
  render() {
    return (
      <div id='modal'>
        <div className='wrapper'>
          <CouriersModal
            show={this.props.couriersModal}
            toggleModal={this.props.toggleCouriersModal}
          />
          <CampaignModal
            show={this.props.campaignModal}
            toggleModal={this.props.toggleCampaignModal}
          />
          <VacanciesModal
            show={this.props.vacanciesModal}
            toggleModal={this.props.toggleVacanciesModal}
          />
          <ProductModal
            show={this.props.productModal}
            toggleModal={this.props.toggleProductModal}
          />
          <GroupModal
            show={this.props.groupModal}
            toggleModal={this.props.toggleGroupModal}
          />

          <RuleBonusesSystemModal
            show={this.props.ruleBonusesModal}
            toggleModal={this.props.toggleRuleBonusesSystemModal}
          />

          <AddCouponModal
            show={this.props.addCouponModal}
            toggleModal={this.props.toggleAddCoupon}
          />
          <EditBonusModal
            show={this.props.editBonusModal}
            toggleModal={this.props.toggleEditBonus}
          />
          <HistoryBonusModal
            show={this.props.historyBonusModal}
            toggleModal={this.props.toggleHistoryBonus}
          />
          <HistoryOrdersModal
            show={this.props.historyOrdersModal}
            toggleModal={this.props.toggleHistoryOrders}
          />
          <HistoryCouponsModal
            show={this.props.historyCouponsModal}
            toggleModal={this.props.toggleHistoryCoupons}
          />
          <ViewCouponsModal
            show={this.props.viewCouponsModal}
            toggleModal={this.props.toggleViewCouponModal}
          />
          <EditOrderModal
            show={this.props.editOrdersModal}
            toggleModal={this.props.toggleEditOrders}
          />
          <ChangeLoginModal
            show={this.props.changeLoginModal}
            toggleModal={this.props.toggleChangeLoginModal}
          />
          <ChangeBirthDayModal
            show={this.props.changeBirthdayModal}
            toggleModal={this.props.toggleChangeBirthdayModal}
          />

          <OrderModal
            show={this.props.ordersModal}
            toggleModal={this.props.toggleOrderModal}
          />
          <UpdateDeliveryZone
            show={this.props.deliveryZones}
            toggleModal={this.props.toggleDeliveryZones}
          />

          <ProductCampaignModal
            show={this.props.productCampaignModal}
            toggleModal={this.props.toggleProductCampaignModal}
          />
          <AddProductsGroupModal
            show={this.props.addProductsGroupModal}
            toggleModal={this.props.toggleAddProductsGroupModal}
          />

          <AddCouponProductModal
            show={this.props.addCouponProductModal}
            toggleModal={this.props.toggleAddCouponsProductModal}
          />
          <CouponProductModal
            show={this.props.couponProductModal}
            toggleModal={this.props.toggleCouponsProductModal}
          />
          <CouponModal
            show={this.props.couponModal}
            toggleModal={this.props.toggleCouponModal}
          />

          <CampaignIndexModal
            show={this.props.campaignIndexModal}
            toggleModal={this.props.toggleCampaignIndexModal}
          />
          <PromocodeModal
            show={this.props.promocodeModal}
            toggleModal={this.props.togglePromocodeModal}
          />
          <PromocodeProductModal
            show={this.props.promocodeProductModal}
            toggleModal={this.props.togglePromocodeProductModal}
          />
          <AddOrderModal
            show={this.props.addOrderModal}
            toggleModal={this.props.toggleAddOrderModal}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaignModal: state.modal.campaignModal,
    vacanciesModal: state.modal.vacanciesModal,
    productModal: state.modal.productModal,
    groupModal: state.modal.groupModal,

    addCouponModal: state.modal.addCouponModal,
    editBonusModal: state.modal.editBonusModal,
    historyBonusModal: state.modal.historyBonusModal,
    historyOrdersModal: state.modal.historyOrdersModal,
    historyCouponsModal: state.modal.historyCouponsModal,
    viewCouponsModal: state.modal.viewCouponsModal,

    changeLoginModal: state.modal.changeLoginModal,
    changeBirthdayModal: state.modal.changeBirthdayModal,

    couriersModal: state.modal.couriersModal,
    promocodeModal: state.modal.promocodeModal,
    promocodeProductModal: state.modal.promocodeProductModal,
    ordersModal: state.modal.ordersModal,
    editOrdersModal: state.modal.editOrdersModal,
    deliveryZones: state.modal.deliveryZones,

    productCampaignModal: state.modal.productCampaignModal,

    addCouponProductModal: state.modal.addCouponProductModal,
    couponProductModal: state.modal.couponProductModal,
    couponModal: state.modal.couponModal,
    addProductsGroupModal: state.modal.addProductsGroupModal,
    ruleBonusesModal: state.modal.ruleBonusesModal,
    campaignIndexModal: state.modal.campaignIndexModal,
    addOrderModal: state.modal.addOrderModal
  };
}

export default connect(mapStateToProps, {
  toggleCampaignModal,
  toggleVacanciesModal,
  toggleProductModal,
  toggleGroupModal,

  toggleEditBonus,
  toggleViewCouponModal,
  toggleAddProductsGroupModal,
  toggleHistoryCoupons,
  toggleHistoryOrders,
  toggleHistoryBonus,
  toggleAddCoupon,
  toggleCouponModal,

  toggleAddCouponsProductModal,
  toggleCouponsProductModal,
  toggleChangeLoginModal,
  toggleProductCampaignModal,
  toggleDeliveryZones,

  toggleCouriersModal,
  togglePromocodeModal,
  toggleCampaignIndexModal,
  togglePromocodeProductModal,
  toggleOrderModal,
  toggleEditOrders,
  toggleChangeBirthdayModal,
  toggleRuleBonusesSystemModal,
  toggleAddOrderModal
})(Modal);
