import React, { Component } from 'react';

class Spinner extends Component {
  getClass = () => {
    let className = 'col-sm-12';

    if (this.props.relative) className += ' relative';
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  };

  render() {
    return (
      <div
        className={this.getClass()}
        id='loader'
      >
        <p className='loader-element' />
        {this.props.children ? (
          <div className='loader-children'>{this.props.children}</div>
        ) : (
          <div className='loader-children'>
            <p>Идет загрузка...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Spinner;
