import {FETCH_RECALLS, CLEAR_RECALLS, FETCH_RECALL, UNPUBLIC_RECALL, DELETE_RECALL, PUBLIC_RECALL, ANSWER_RECALL, CLEAR_RECALL} from '../actions/recallsActions';

const INITIAL_STATE = {
  recalls: '',
  recall: ''
};

export default function( state = INITIAL_STATE, action )
{
  
  switch( action.type ) {
    case FETCH_RECALLS:
    return {...state, recalls: action.payload};

    case FETCH_RECALL:
    return {...state, recall: action.payload};

    case DELETE_RECALL:
    return {...state, recall: action.payload};

    case ANSWER_RECALL:
    return state;

    case PUBLIC_RECALL:
    return state;

    case UNPUBLIC_RECALL:
    return state;

    case CLEAR_RECALLS:
    return {...state, recalls: action.payload};

    case CLEAR_RECALL:
    return {...state, recall: action.payload};

    default:
      return state;
  }
}