import { API } from './index';

export const TOGGLE_CAMPAIGN_MODAL = 'TOGGLE_CAMPAIGN_MODAL';
export const TOGGLE_CAMPAIGN_INDEX_MODAL = 'TOGGLE_CAMPAIGN_INDEX_MODAL';
export const TOGGLE_VACANCIES_MODAL = 'TOGGLE_VACANCIES_MODAL';
export const TOGGLE_PRODUCT_MODAL = 'TOGGLE_PRODUCT_MODAL';
export const TOGGLE_GROUP_MODAL = 'TOGGLE_GROUP_MODAL';
export const TOGGLE_COUPON_MODAL = 'TOGGLE_COUPON_MODAL';

export const TOGGLE_VIEW_COUPON_MODAL = 'TOGGLE_VIEW_COUPON_MODAL';
export const TOGGLE_ADD_COUPON_MODAL = 'TOGGLE_ADD_COUPON_MODAL';
export const TOGGLE_EDIT_BONUS_MODAL = 'TOGGLE_EDIT_BONUS_MODAL';
export const TOGGLE_HISTORY_BONUS_MODAL = 'TOGGLE_HISTORY_BONUS_MODAL';
export const TOGGLE_HISTORY_COUPONS_MODAL = 'TOGGLE_HISTORY_COUPONS_MODAL';
export const TOGGLE_HISTORY_ORDERS_MODAL = 'TOGGLE_HISTORY_ORDERS_MODAL';

export const TOGGLE_COURIERS_MODAL = 'TOGGLE_COURIERS_MODAL';
export const TOGGLE_PROMOCODE_MODAL = 'TOGGLE_PROMOCODE_MODAL';
export const TOGGLE_PROMOCODE_PRODUCT_MODAL = 'TOGGLE_PROMOCODE_PRODUCT_MODAL';

export const TOGGLE_EDIT_ORDERS = 'TOGGLE_EDIT_ORDERS';
export const TOGGLE_ORDERS_MODAL = 'TOGGLE_ORDERS_MODAL';
export const TOGGLE_CHANGE_BIRTHDAY_MODAL = 'TOGGLE_CHANGE_BIRTHDAY_MODAL';
export const TOGGLE_CHANGE_LOGIN_PRODUCT = 'TOGGLE_CHANGE_LOGIN_PRODUCT';

export const TOGGLE_ADD_COUPONS_PRODUCT = 'TOGGLE_ADD_COUPONS_PRODUCT';
export const TOGGLE_COUPONS_PRODUCT = 'TOGGLE_COUPONS_PRODUCT';
export const TOGGLE_ADD_PRODUCTS_GROUP = 'TOGGLE_ADD_PRODUCTS_GROUP';
export const TOGGLE_DELIVERY_ZONES_MODAL = 'TOGGLE_DELIVERY_ZONES_MODAL';
export const TOGGLE_PRODUCT_CAMPAIGN_MODAL = 'TOGGLE_PRODUCT_CAMPAIGN_MODAL';
export const TOGGLE_RULE_BONUSES_MODAL = 'TOGGLE_RULE_BONUSES_MODAL';
export const TOGGLE_ADD_ORDER_MODAL = 'TOGGLE_ADD_ORDER_MODAL';

export const toggleCampaignModal = (data, callback) => async (
  dispatch,
  getState
) => {
  if (!data)
    return dispatch({
      type: TOGGLE_CAMPAIGN_MODAL,
      payload: false
    });

  const response = await fetch(`${API.url}/campaigns/${data._id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: TOGGLE_CAMPAIGN_MODAL,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const toggleCampaignIndexModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_CAMPAIGN_INDEX_MODAL,
    payload: data
  });
};

export const toggleVacanciesModal = (data, callback) => async (
  dispatch,
  getState
) => {
  if (!data)
    return dispatch({
      type: TOGGLE_VACANCIES_MODAL,
      payload: false
    });

  const response = await fetch(`${API.url}/vacancies/${data._id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: TOGGLE_VACANCIES_MODAL,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const toggleProductModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_PRODUCT_MODAL,
    payload: data || false
  });
};

export const toggleRuleBonusesSystemModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_RULE_BONUSES_MODAL,
    payload: data || false
  });
};

export const toggleChangeBirthdayModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_CHANGE_BIRTHDAY_MODAL,
    payload: data || false
  });
};

export const toggleProductCampaignModal = (product, callback) => async (
  dispatch,
  getState
) => {
  if (!product)
    return dispatch({
      type: TOGGLE_PRODUCT_CAMPAIGN_MODAL,
      payload: false
    });

  const response = await fetch(`${API.url}/menu/products/${product._id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) return callback(json);
      return dispatch({
        type: TOGGLE_PRODUCT_CAMPAIGN_MODAL,
        payload: json
      });

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const toggleDeliveryZones = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_DELIVERY_ZONES_MODAL,
    payload: data || false
  });
};

export const toggleGroupModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_GROUP_MODAL,
    payload: data || false
  });
};

export const toggleAddProductsGroupModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_ADD_PRODUCTS_GROUP,
    payload: data || false
  });
};

export const togglePromocodeModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_PROMOCODE_MODAL,
    payload: data || false
  });
};

export const togglePromocodeProductModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_PROMOCODE_PRODUCT_MODAL,
    payload: data || false
  });
};

export const toggleCouriersModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_COURIERS_MODAL,
    payload: data || false
  });
};

export const toggleViewCouponModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_VIEW_COUPON_MODAL,
    payload: data || false
  });
};

export const toggleHistoryCoupons = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_HISTORY_COUPONS_MODAL,
    payload: data || false
  });
};

export const toggleHistoryOrders = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_HISTORY_ORDERS_MODAL,
    payload: data || false
  });
};

export const toggleHistoryBonus = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_HISTORY_BONUS_MODAL,
    payload: data || false
  });
};

export const toggleAddCoupon = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_ADD_COUPON_MODAL,
    payload: data || false
  });
};

export const toggleEditBonus = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_EDIT_BONUS_MODAL,
    payload: data || false
  });
};

export const toggleOrderModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_ORDERS_MODAL,
    payload: data || false
  });
};

export const toggleEditOrders = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_EDIT_ORDERS,
    payload: data || false
  });
};

export const toggleAddCouponsProductModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_ADD_COUPONS_PRODUCT,
    payload: data || false
  });
};

export const toggleCouponsProductModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_COUPONS_PRODUCT,
    payload: data || false
  });
};

export const toggleChangeLoginModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_CHANGE_LOGIN_PRODUCT,
    payload: data || false
  });
};

export const toggleCouponModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_COUPON_MODAL,
    payload: data || false
  });
};

export const toggleAddOrderModal = (data, callback) => async (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_ADD_ORDER_MODAL,
    payload: data || false
  });
};
