export const days = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота'
];

export const COURIERS_LIMIT = 25;

export const DEFAULT_TIME = '00:00';
