import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import {
  createPush,
  deletePush,
  getPushes,
  updatePush
} from '../actions/pushes';
import {
  Button,
  FormGroup,
  HeaderPage,
  SvgButton,
  Table
} from '../components/elements/elements';
import Modal from '../components/isolatedModal';
import Select from '../components/Select';

class PushesPage extends Component {
  getInitialCreatedPushData = () => ({
    text: '',
    day: 0,
    time: '',
    pushType: 'campaigns'
  });

  state = {
    isModalOpen: false,

    createdPushData: this.getInitialCreatedPushData()
  };

  componentDidMount() {
    this.props.getPushes();
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  openEditModal = data => () =>
    this.setState({ createdPushData: data, isModalOpen: true });

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      createdPushData: this.getInitialCreatedPushData()
    });
  };

  createCallback = (response, status) => {
    if (status === 200) {
      this.setState({ isModalOpen: false });
    } else {
      alert(response.message);
    }
  };

  createPush = () => {
    const { createdPushData } = this.state;

    const body = {
      ...createdPushData,
      time: moment(createdPushData.time).toISOString()
    };

    if (createdPushData._id)
      this.props.updatePush(body, createdPushData._id, this.createCallback);
    else this.props.createPush(body, this.createCallback);
  };

  deletePush = ({ _id }) => () => this.props.deletePush(_id);

  getTableTitles = () => [
    'Текст',
    'День отправки',
    'Время отправки',
    'Дата последней отправки',
    'Тип',
    ''
  ];

  getWeekDay = index => ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'][index];

  getPushTypeItems = () => [
    { text: 'Акция', value: 'campaigns' },
    { text: 'Купоны', value: 'coupons' },
    { text: 'Профиль', value: 'profile' },
    { text: 'Бонусные баллы', value: 'bonuses' },
    { text: 'Личные данные', value: 'personal' },
    { text: 'История заказов', value: 'history' },
    { text: 'Сканировать чек', value: 'scan' },
    { text: 'Без перехода', value: '' }
  ];

  getPushType = type => {
    const match = this.getPushTypeItems().find(t => t.value === type);

    if (match) return match.text;
    return '';
  };

  onChangeModal = name => value =>
    this.setState({
      createdPushData: { ...this.state.createdPushData, [name]: value }
    });

  renderTableData = () =>
    this.props.pushes.map(push => (
      <tr key={push._id}>
        <td>{push.text}</td>
        <td>{this.getWeekDay(push.day)}</td>
        <td>{moment(push.time).format('hh:mm')}</td>
        <td>{moment(push.lastSent).format('DD/MM/YYYY')}</td>
        <td>{this.getPushType(push.pushType)}</td>
        <td>
          <div
            style={{ display: 'flex', float: 'right', alignItems: 'center' }}
          >
            <SvgButton
              image='edit'
              onClick={this.openEditModal(push)}
            />
            <SvgButton
              image='delete'
              onClick={this.deletePush(push)}
              style={{ marginLeft: '20px' }}
            />
          </div>
        </td>
      </tr>
    ));

  render() {
    const { isModalOpen } = this.state;
    const { text, day, pushType, time } = this.state.createdPushData;

    return (
      <div className='pushes'>
        <Modal
          onHide={this.closeModal}
          show={isModalOpen}
        >
          <div className='pushes-modal'>
            <HeaderPage
              little
              title='Пуш'
            />

            <FormGroup
              labelHidden
              onChange={e => this.onChangeModal('text')(e.target.value)}
              placeholder='Текст'
              rows='10'
              type='textarea'
              value={text}
            />

            <Select
              items={this.getPushTypeItems()}
              onChange={this.onChangeModal('pushType')}
              value={pushType}
            />

            <Select
              items={[
                {
                  text: 'вс',
                  value: 0
                },
                {
                  text: 'пн',
                  value: 1
                },
                {
                  text: 'вт',
                  value: 2
                },
                {
                  text: 'ср',
                  value: 3
                },
                {
                  text: 'чт',
                  value: 4
                },
                {
                  text: 'пт',
                  value: 5
                },
                {
                  text: 'сб',
                  value: 6
                }
              ]}
              onChange={this.onChangeModal('day')}
              value={day}
            />

            <DatePicker
              dateFormat='HH:mm'
              onChange={this.onChangeModal('time')}
              selected={time || new Date()}
              showTimeSelect
              showTimeSelectOnly
              timeFormat='HH:mm'
              timeIntervals={60}
            />

            <Button
              onClick={this.createPush}
              style={{ marginTop: '20px', width: '100%' }}
              value='Сохранить'
            />
          </div>
        </Modal>

        <HeaderPage
          button='Добавить пуш'
          little
          onClick={this.openModal}
          title='Пуши'
        />

        <Table
          data={this.renderTableData()}
          style={{ marginTop: 50 }}
          title={this.getTableTitles()}
        />
      </div>
    );
  }
}

const mapState = ({ pushes }) => ({
  pushes: pushes.list
});

const mapDispatch = {
  getPushes,
  createPush,
  updatePush,
  deletePush
};

export default connect(
  mapState,
  mapDispatch
)(PushesPage);
