import moment from 'moment';
import React from 'react';
import { fetchRestaurants } from '../actions/restaurantActions';
import DatePicker from '../components/DatePicker';
import { connect } from 'react-redux';
import { getPayments } from '../actions/paymentsActions';
import {
  Button,
  HeaderPage,
  Table,
  FormGroup
} from '../components/elements/elements';

import '../css/paymentsReportPage.scss';
import Pagination from '../components/Pagination';
import Select from '../components/Select';

const LIMIT = 50;

const checkWrongPaymentMethod = report => {
  if (
    ['Apple pay', 'Google pay', 'Картой онлайн'].includes(
      report.basePaymentMethod
    )
  )
    return false;

  return report.paymentMethod !== report.basePaymentMethod;
};

const checkWrongSum = report => {
  if (
    report.paymentMethod === 'Наличные' &&
    report.basePaymentMethod === 'Наличные'
  ) {
    return false;
  }

  return report.sum !== report.baseSum;
};

const checkWrongRestaurantName = report => {
  return report.restaurantName !== report.baseRestaurantName;
};

const checkWrongState = report => {
  return report.baseState === 'Отменена';
};

const checkWrongReport = (type, report) => {
  if (type === 'restaurantName') {
    return checkWrongRestaurantName(report);
  }

  if (type === 'sum') {
    return checkWrongSum(report);
  }

  if (type === 'paymentMethod') {
    return checkWrongPaymentMethod(report);
  }

  if (type === 'state') {
    return checkWrongState(report);
  }
};

const Td = props => {
  const { type, report, value } = props;

  const isWrong = React.useMemo(() => checkWrongReport(type, report), [
    type,
    report
  ]);

  return <td className={isWrong ? 'td-red' : ''}>{value}</td>;
};

class PaymentsReportPage extends React.Component {
  state = {
    page: 1,
    from: null,
    to: null,
    entity: '',
    state: '',
    sorting: '',
    restaurant: '',
    restaurants: [],
    entityTypes: [],
    orderNumber: '',
    isHiddenRightReports: true
  };

  componentDidMount() {
    this.props.getPayments(this.parseStateToQuery());
    this.props.fetchRestaurants();
  }

  componentDidUpdate() {
    if (!this.state.restaurants.length && this.props.restaurants.length) {
      this.mapRestaurantsToState();
    }
  }

  mapRestaurantsToState = () => {
    const restaurants = [];
    const entityTypes = [];

    this.props.restaurants.forEach(item => {
      restaurants.push(item.name);

      if (!entityTypes.includes(item.entityName)) {
        entityTypes.push(item.entityName);
      }
    });

    this.setState({
      restaurants,
      entityTypes
    });
  };

  parseStateToQuery = () => {
    const query = {
      limit: LIMIT
    };
    if (this.state.from) {
      query.from = moment(this.state.from).format('YYYY-MM-DD');
    }
    if (this.state.to) {
      query.to = moment(this.state.to).format('YYYY-MM-DD');
    }
    if (this.state.entity) {
      query.entity_name = this.state.entity;
    }
    if (this.state.state) {
      query.state = this.state.state;
    }
    if (this.state.restaurant) {
      query.restaurant_name = this.state.restaurant;
    }
    if (this.state.sorting) {
      query.sorting = this.state.sorting;
    }
    if (this.state.orderNumber) {
      query.order_number = this.state.orderNumber;
    }
    return query;
  };

  onSearch = () => {
    this.setState({ page: 1 }, () =>
      this.props.getPayments(this.parseStateToQuery())
    );
  };

  onSelect = name => value => {
    this.setState({ [name]: value });
  };

  onChangeDate = name => date => {
    this.setState({ [name]: date });
  };

  onChangePage = ({ selected }) => {
    this.setState({ page: selected + 1 });
    this.props.getPayments({
      page: selected + 1,
      ...this.parseStateToQuery()
    });
  };

  changeOrderNumber = e => {
    this.setState({ orderNumber: e.target.value });
  };

  changeCheckbox = event => {
    const name = event.target.name;
    const checked = event.target.checked;

    this.setState({
      [name]: checked
    });
  };

  checkRenderReportCondition(report) {
    const { isHiddenRightReports } = this.state;

    if (!isHiddenRightReports) {
      return true;
    }

    return checkWrongPaymentMethod(report);
  }

  getTableData = () => {
    const arr = [];
    this.props.payments.forEach(item => {
      if (this.checkRenderReportCondition(item)) {
        arr.push(
          <React.Fragment key={item._id}>
            <tr>
              <td rowSpan={2}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
              <td>{item.baseEntityName}</td>
              <Td
                report={item}
                type='restaurantName'
                value={item.baseRestaurantName}
              />
              <td>{item.baseNumber}</td>
              <Td
                report={item}
                type='sum'
                value={item.baseSum}
              />
              <Td
                report={item}
                type='state'
                value={item.baseState}
              />
              <Td
                report={item}
                type='paymentMethod'
                value={item.basePaymentMethod}
              />
              <td rowSpan={2}>{item.returnSum}</td>
            </tr>
            <tr>
              <td>{item.entityName}</td>
              <Td
                report={item}
                type='restaurantName'
                value={item.restaurantName}
              />
              <td>{item.number}</td>
              <Td
                report={item}
                type='sum'
                value={item.sum}
              />
              <td>{item.state}</td>
              <Td
                report={item}
                type='paymentMethod'
                value={item.paymentMethod}
              />
            </tr>
          </React.Fragment>
        );
      }
    });

    return arr;
  };

  render() {
    return (
      <div className='payments-report'>
        <HeaderPage title='Отчет по онлайн оплатам' />
        <div className='date-pickers-wrapper row'>
          <div className='date-picker-container'>
            <span>Период от</span>
            <DatePicker
              className='form-control'
              dateFormat='dd/MM/yyyy'
              onChange={this.onChangeDate('from')}
              placeholderText='от'
              selected={this.state.from}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <div className='date-picker-container'>
            <span>до</span>
            <DatePicker
              className='form-control'
              dateFormat='dd/MM/yyyy'
              onChange={this.onChangeDate('to')}
              placeholderText='до'
              selected={this.state.to}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <Select
            default='Статус'
            items={[
              {
                text: 'Все',
                value: ''
              },
              ...['Закрыт', 'В пути'].map(item => ({
                text: item,
                value: item
              }))
            ]}
            onChange={this.onSelect('state')}
            style={{ width: 200 }}
            value={this.state.state}
          />
          <Select
            default='Сортировка'
            items={[
              {
                text: 'По убыванию',
                value: ''
              },
              {
                text: 'По возрастанию',
                value: 'sorting_up'
              }
            ]}
            onChange={this.onSelect('sorting')}
            style={{ width: 200, marginLeft: 0 }}
            value={this.state.sorting}
          />
        </div>
        <div className='row'>
          <Select
            default='Юр лицо'
            items={[
              {
                text: 'Все',
                value: ''
              },
              ...this.state.entityTypes.map(item => ({
                text: item,
                value: item
              }))
            ]}
            onChange={this.onSelect('entity')}
            value={this.state.entity}
          />
          <Select
            default='Выберите ресторан'
            items={[
              {
                text: 'Все',
                value: ''
              },
              ...this.state.restaurants.map(item => ({
                text: item,
                value: item
              }))
            ]}
            onChange={this.onSelect('restaurant')}
            value={this.state.restaurant}
          />
          <FormGroup
            name='search'
            onChange={this.changeOrderNumber}
            placeholder='Номер заказа'
            type='number'
            value={this.state.orderNumber}
          />
        </div>
        <div className='submit row'>
          <FormGroup
            checked={this.state.isHiddenRightReports}
            id='isHiddenRightReports'
            name='isHiddenRightReports'
            onChange={this.changeCheckbox}
            placeholder='Скрыть правильные'
            type='checkbox'
          />
          <Button
            little
            onClick={this.onSearch}
            value='Поиск'
          />
        </div>
        <div className='table-container'>
          <Table
            data={this.getTableData()}
            title={[
              'Дата создания',
              'Юр лицо',
              'Ресторан',
              '№ заказа (в айке, на сайте)',
              'Сумма оплаты',
              'Статус заказа (айка, сбер)',
              'Тип оплаты',
              'Сумма возврата по сберу'
            ]}
          />
        </div>
        <Pagination
          count={this.props.count}
          limit={LIMIT}
          onChange={this.onChangePage}
          page={this.state.page}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ payments, restaurants }) => ({
  payments: payments.items,
  count: payments.pagination.count,
  restaurants: restaurants.restaurants
});

const mapDispatchToProps = {
  getPayments,
  fetchRestaurants
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsReportPage);
