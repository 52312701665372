import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, FormGroup, Modal, ModalTitle } from '../../elements/elements';
import {
  fetchCampaing,
  updateCampaing,
  createCampaing,
  fetchCampaigns,
  clearCampaigns,
  changeIndex
} from '../../../actions/campaignsActions';
import { uploadImage } from '../../../actions';

import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: [],
      isDate: false,
      isPromocode: false,
      loadBanner: false,
      loadPreview: false
    };
  }

  refresh = () => {
    this.props.toggleModal();
    this.props.clearCampaigns();
    this.props.fetchCampaigns();
    this.setState({ campaign: [] });
  };

  componentWillReceiveProps(newState) {
    if (this.props.show !== newState.show) {
      if (newState.campaign) {
        this.setState({
          campaign: newState.campaign,
          isDate: Boolean(newState.campaign.expireDate),
          isPromocode: !newState.campaign.promocode,
          loadBanner: false,
          loadPreview: false
        });
      }
    }
  }

  uploadImage = event => {
    event.persist();

    this.setState({ [event.target.dataset.loader]: true });
    this.props
      .uploadImage('image', event.target.files[0])
      .then(picture => {
        this.setState(prevState => ({
          [event.target.dataset.loader]: false,
          campaign: {
            ...prevState.campaign,
            [event.target.name]: picture.file.url
          }
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  updateIndexCampaign = () => {
    const data = { ...this.state.campaign };
    if (this.state.isPromocode) data.promocode = null;
    if (!this.state.isDate) data.expireDate = null;

    if (!this.state.campaign.banner) data.banner = null;
    if (!this.state.campaign.preview) data.preview = null;

    this.props.updateCampaing(this.state.campaign._id, data, this.refresh);
    this.props.toggleModal();
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        [name]: value
      }
    }));
  };

  onChange(currentDate) {
    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        expireDate: currentDate.toISOString()
      }
    }));
  }

  onSubmit = () => {
    const { orderIndex } = this.state.campaign;
    const id = this.props.campaign && this.props.campaign._id;

    if (id)
      this.props.changeIndex(id, parseInt(orderIndex, 10) - 1, this.refresh);
    else alert('front-end error!');
  };

  render() {
    const { campaign } = this.state;
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle title='Приоритет акций' />
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Сохранить изменения'
        >
          <FormGroup
            id='orderIndex'
            maxlength='2'
            name='orderIndex'
            onChange={this.changeInput}
            placeholder='Приоритет'
            type='number'
            value={campaign.orderIndex}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { campaign: state.modal.modalContent };
}

export default connect(
  mapStateToProps,
  {
    fetchCampaigns,
    fetchCampaing,
    updateCampaing,
    uploadImage,
    createCampaing,
    clearCampaigns,
    changeIndex
  }
)(ModalComponent);
