import { API } from './index';
import { logOut } from './loginActions';
import { getQueryParametres } from './functions';

export const NEW_RESTAURANT = 'NEW_RESTAURANT';
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';
export const FETCH_RESTAURANT = 'FETCH_RESTAURANT';
export const UPDATE_RESTAURANT = 'UPDATE_RESTAURANT';
export const DELETE_RESTAURANT = 'DELETE_RESTAURANT';

export const ADD_MANAGER = 'ADD_MANAGER';
export const CHANGE_MANAGER = 'CHANGE_MANAGER';
export const FETCH_MANAGER = 'FETCH_MANAGER';
export const DELETE_MANAGER = 'DELETE_MANAGER';

export const FETCH_ZONE = 'FETCH_ZONE';
export const CLEAR_ZONE = 'CLEAR_ZONE';
export const CLEAR_MANAGER = 'CLEAR_MANAGER';
export const CLEAR_RESTOURAN = 'CLEAR_RESTOURAN';

export const CLEAR_RESTOURANTS = 'CLEAR_RESTOURANTS';

export const fetchZone = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/restaurants`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_ZONE,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateDeliveryZones = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/upload_zones`, {
    method: 'post',
    headers: {
      Authorization: getState().login.token
    },
    body: data
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Файл успешно загружен на сервер');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      const error = await response.json();
      alert(error.error);
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createRestaurant = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/restaurants`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert('Ресторан успешно создан!');
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchRestaurants = (callback, data) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/restaurants?${getQueryParametres(data || {})} `,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_RESTAURANTS,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchRestaurant = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/restaurants/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_RESTAURANT,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export function clearRestourant() {
  return {
    type: CLEAR_RESTOURAN,
    payload: null
  };
}

export function clearZone() {
  return {
    type: CLEAR_ZONE,
    payload: []
  };
}

export function clearManager() {
  return {
    type: CLEAR_MANAGER,
    payload: []
  };
}

export const fetchManager = id => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/restaurants/${id}/managers`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_MANAGER,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteManager = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/managers/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_MANAGER,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const changePasswordManager = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/managers/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: CHANGE_MANAGER,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const clearRestaurants = () => async dispatch => {
  dispatch({
    type: CLEAR_RESTOURANTS,
    payload: []
  });
};

export const deleteRestaurant = (id, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/restaurants/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert('Ресторан успешно удален!');
      if (callback) await callback({ status, json });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateRestaurant = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/restaurants/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert('Ресторан успешно обновлен!');
      if (callback) await callback({ status, json });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addManager = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/managers/register`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert('Менеджер успешно добавлен!');
      if (callback) await callback({ status, json });
      break;

    case 409:
      alert('Такой логин уже занят');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const editRightsManagers = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/managers/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert('Права успешно обновлены');
      if (callback) await callback({ status, json });
      break;

    case 409:
      alert('Такой логин уже занят');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
