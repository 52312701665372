import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_BLACK_LIST = 'ADD_BLACK_LIST';
export const REMOVE_BLACK_LIST = 'REMOVE_BLACK_LIST';
export const CLEAR_USERS = 'CLEAR_USERS';
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const DELETE_COUPON = 'DELETE_COUPON';
export const PASSWORD = 'PASSWORD';
export const START_PAGE = 'START_PAGE';
export const CHANGE_DATA = 'CHANGE_DATA';
export const CURRENT_USER = 'CURRENT_USER';
export const FETCH_COUPONS_TYPES = 'FETCH_COUPONS_TYPES';
export const GET_CHANGE_REQUESTS = 'GET_CHANGE_REQUESTS';

const limits = {
  historyLimit: null,
  orderLimit: null,
  couponLimit: null
};

export const clearUsers = callback => async dispatch => {
  dispatch({
    type: CLEAR_USERS,
    payload: []
  });
  if (callback) await callback();
};

export const clearAllUsers = callback => async dispatch => {
  dispatch({
    type: CLEAR_ALL_USERS,
    payload: []
  });
  if (callback) await callback();
};

export const startPage = callback => async dispatch => {
  dispatch({
    type: START_PAGE,
    payload: ''
  });
  if (callback) await callback();
};

export const editCurrentUser = (data, callback) => async dispatch => {
  dispatch({
    type: CURRENT_USER,
    payload: data
  });
  if (callback) await callback();
};

export const fetchCouponTypes = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/manager_coupons`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;
  const json = await response.json();
  if (status === 200) {
    dispatch({
      type: FETCH_COUPONS_TYPES,
      payload: json.couponTypes
    });
    if (callback) await callback();
  } else {
    switch (status) {
      case 500:
        alert('Ошибка сервера');
        break;
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};

export const updateUsers = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_change_balance`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const changeBirthDay = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/users/manager_update_birthday/${id}`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify({
        birthday: data
      })
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
export const addAddres = (id, data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/${id}/addresses`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ addresses: [data] })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const editAddres = (id, data, addressId, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/users/${id}/addresses/${addressId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteAddres = (id, addressId, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/users/${id}/addresses/${addressId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const changeData = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/manager_edit_login/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены! ');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 409:
      alert('Такой пользователь существует');
      break;
    case 410:
      alert('Такой пользователь в черном списке');
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteCoupon = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_coupons/${data.id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchUsers = (
  user,
  history_limit,
  order_limit,
  callback
) => async (dispatch, getState) => {
  if (history_limit !== 'all') {
    limits.historyLimit = history_limit;
    limits.orderLimit = order_limit;
  }
  let url;

  if (history_limit !== 'all') {
    url = `/users/manager_bonus_info?login=${user.toLowerCase()}&history_limit=${history_limit}&history_skip=${0}&order_limit=${order_limit}&order_skip=${0}`;
  } else if (history_limit === 'all') {
    url = `/users/manager_bonus_info?login=${user.toLowerCase()}&history_limit=${
      limits.historyLimit
    }&history_skip=${0}&order_limit=${limits.orderLimit}&order_skip=${0}]`;
  }

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (!callback) {
        dispatch({
          type: FETCH_USERS,
          payload: json
        });
      }

      if (callback)
        await callback(
          user,
          json.bonusHistory.count,
          json.orderHistory.count,
          callback
        );
      break;
    case 401:
      dispatch(logOut());
      break;
    case 400:
      dispatch({
        type: FETCH_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 404:
      dispatch({
        type: FETCH_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchAllUsers = (
  user,
  history_limit,
  order_limit,
  callback
) => async (dispatch, getState) => {
  if (history_limit !== 'all') {
    limits.historyLimit = history_limit;
    limits.orderLimit = order_limit;
  }
  let url;
  if (history_limit !== 'all') {
    url = `/users/manager_bonus_info?login=${user.toLowerCase()}&history_limit=${history_limit}&history_skip=${0}&order_limit=${order_limit}&order_skip=${0}&v=2`;
  } else if (history_limit === 'all') {
    url = `/users/manager_bonus_info?login=${user.toLowerCase()}&history_limit=${
      limits.historyLimit
    }&history_skip=${0}&order_limit=${limits.orderLimit}&order_skip=${0}]&v=2`;
  }

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;
  switch (status) {
    case 200:
      const json = await response.json();
      if (!callback) {
        dispatch({
          type: FETCH_ALL_USERS,
          payload: json
        });
      }

      if (callback)
        await callback(
          user,
          json.bonusHistory.count,
          json.orderHistory.count,
          callback
        );
      break;
    case 401:
      dispatch(logOut());
      break;
    case 400:
      dispatch({
        type: FETCH_ALL_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 404:
      dispatch({
        type: FETCH_ALL_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addBlackList = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_black_list/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Пользователь добавлен в черный список');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteBlackList = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_black_list/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Пользователь убран из черного списка');
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const defaultPassword = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(
    `${API.url}/users/manager_reset_password/${id}`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Пароль пользователя обновлен. Новый пароль - 123456');
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const resetClientPassword = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/${id}/reset_password`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addCoupon = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_coupons`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Купоны добавлены');
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const editQuantityOrders = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/manager_orders_total/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Количество заказов изменено');
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const getUserHistory = (id, type, parametres, callback) => async (
  dispatch,
  getState
) => {
  const skip = (parametres.page - 1) * parametres.count;
  const limit = parametres.count;
  const slices = parametres.slices || false;
  const { couponType } = parametres;

  const response = await fetch(
    `${API.url}/users/${id}/history/${type}?limit=${limit}&skip=${skip}${
      slices ? '&slicesOnly=true' : ''
    }${couponType ? `&couponType=${couponType}` : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;
  const result = await response.json();

  switch (status) {
    case 200:
      if (typeof callback === 'function') callback(result);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateUser = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
export const userConfirm = (body, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_confirm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Данные обновлены');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const getChangeRequests = type => async (dispatch, getState) => {
  const request = await fetch(`${API.url}/users/change_requests/${type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await request.status;
  const response = await request.json();

  switch (status) {
    case 200:
      dispatch({ type: GET_CHANGE_REQUESTS, response });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const handleRequest = (id, type, isReject) => async (
  dispatch,
  getState
) => {
  const request = await fetch(
    `${API.url}/users/change_requests/${id}/${isReject ? 'reject' : 'accept'}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await request.status;

  switch (status) {
    case 200:
      dispatch(getChangeRequests(type));
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
