import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchQRResponse } from '../actions/qrActions';
import { HeaderPage, Button } from '../components/elements/elements';
import Select from '../components/Select';
import { fetchRestaurants } from '../actions/restaurantActions';
import { API } from '../actions/index';

const ALL_RESTAURANTS = 'Все рестораны';

class QRStatisticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pushText: '',
      pushType: 'campaigns',
      restaurant: ALL_RESTAURANTS,
      period: 'week',
      begin: '',
      end: ''
    };
  }

  async componentDidMount() {
    await Promise.all([this.props.fetchRestaurants()]);
  }

  getRestaurantsStatus = () => {
    const { restaurants } = this.props;
    return [
      {
        value: ALL_RESTAURANTS,
        text: ALL_RESTAURANTS
      },
      ...restaurants.map(r => ({ value: r.iikoId, text: r.name }))
    ];
  };

  onChangeRestaurantsStatusSelect = (value, name) => {
    this.setState({ restaurant: value });
  };

  handleChange = event => {
    this.setState({ pushText: event.target.value });
  };

  onChangeInput = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleChangeSelect = value => {
    this.setState({ pushType: value });
  };

  renderPeriodInputs = () => {
    const { period } = this.state;

    if (period === 'custom') {
      return (
        <>
          <input
            className='input-element'
            name='begin'
            onChange={this.onChangeInput}
            placeholder='от'
            style={{ width: '30%' }}
            type='date'
            value={this.state.begin}
          />
          <input
            className='input-element'
            name='end'
            onChange={this.onChangeInput}
            placeholder='до'
            style={{ margin: '0 20px', width: '30%' }}
            type='date'
            value={this.state.end}
          />
        </>
      );
    }
  };

  onChangePeriodSelect = value => {
    this.setState({ period: value }, () => {
      if (value !== 'custom') {
        this.setState({ begin: '', end: '' }, this.fetchGraphic);
      }
    });
  };

  getItems = () => {
    const items = [
      { value: 'week', text: 'Неделя' },
      { value: 'month', text: 'Месяц' },
      { value: 'year', text: 'Год' },
      { value: 'custom', text: 'Выбрать период' }
    ];

    return items;
  };

  getFile = ({ begin, end, period, restaurant }) => {
    let query = '';
    if (period === 'custom' && begin && end) {
      query = `begin=${begin}&end=${end}&period=${period}`;
    } else {
      query = `period=${period}`;
    }
    const rest = restaurant === ALL_RESTAURANTS ? 'all' : restaurant;
    const url = `${API.url}/qrreview/response/${rest}/qrreview.xlsx?${query}`;
    this.props.fetchQRResponse(url);
  };

  render() {
    const { begin, end, period, restaurant } = this.state;
    return [
      <>
        <HeaderPage title='Статистика по QR кодам'>
          <div>
            <Select
              items={this.getRestaurantsStatus()}
              onChange={this.onChangeRestaurantsStatusSelect}
              style={{ paddingRight: '20px' }}
            />
          </div>

          <div>
            <Select
              items={this.getItems()}
              onChange={this.onChangePeriodSelect}
            />
          </div>
        </HeaderPage>
        <div>{this.renderPeriodInputs()}</div>
        <div style={{ marginTop: '20px' }}>
          <Button
            onClick={() =>
              this.getFile({
                begin,
                end,
                period,
                restaurant
              })
            }
            style={{ width: '200px', height: '100px' }}
            value='Скачать'
          />
        </div>
      </>
    ];
  }
}

function mapStateToProps(state) {
  return {
    restaurants: state.restaurants.restaurants
  };
}

export default connect(mapStateToProps, {
  fetchRestaurants,
  fetchQRResponse
})(QRStatisticPage);
