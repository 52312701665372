import { GET_PUSHES } from '../actions/pushes';

const STATE = {
  list: []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_PUSHES:
      return { ...state, list: action.response.data };

    default:
      return state;
  }
}
