import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';

import {
  fetchDeletedUsers,
  recoverDeletedUser,
  clearDeletedUsers
} from '../actions/deletedUsersActions';

import {
  Form,
  FormGroup,
  HeaderPage,
  Button
} from '../components/elements/elements';

import moment from 'moment';
import CustomAlert from '../components/customAlert';

class DeletedPointsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: {
        lastName: '',
        firstName: '',
        middleName: ''
      },
      user: '',
      show: false,
      body: document.getElementsByTagName('body')[0],
      showStatusModal: false,
      showCoupon: false,
      showAllCoupon: false,
      showHistoryModal: false,
      showOrdersModal: false,
      showEditAddressModal: false,
      showAddressModal: false,
      showChangeDataModal: false,
      limitBonus: null,
      limitOrders: null,
      update: true,
      gender: ''
    };
  }

  componentDidMount() {
    this.props.clearDeletedUsers();
    this.setState({ update: false });
  }

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.clearDeletedUsers();
    this.props.fetchDeletedUsers(this.state.user);
  };

  onOpenAlert = () => {
    this.setState({ alertOpen: true });
  };

  onHideAlert = () => {
    this.setState({ alertOpen: false });
  };

  onHideAlertSuccess = () => {
    this.props.clearDeletedUsers();
    this.setState({ alertOpen: false });
  };

  render() {
    const { currentUser } = this.props;

    const { update } = this.state;
    const userInfo = this.props.user.deletedUser;

    if (update) return <Loader />;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomAlert show={this.state.alertOpen && userInfo}>
            <div className='title'>Вы хотите восстановить аккаунт?</div>
            <div>
              <Button
                onClick={() => this.onHideAlert()}
                style={{ marginRight: '10px' }}
                value='Отмена'
              />
              <Button
                onClick={() =>
                  this.props.recoverDeletedUser(
                    userInfo._id,
                    this.onHideAlertSuccess
                  )
                }
                value='Подтвердить'
              />
            </div>
          </CustomAlert>
        </div>
        <HeaderPage title='Удалённые пользователи' />
        <Form
          direction='row'
          onSubmit={this.handleSubmit}
          style={{ marginBottom: 60 }}
          value='Найти'
        >
          <FormGroup
            id='searchUser'
            name='user'
            onChange={this.handleChange}
            placeholder='Email или телефон'
            required
            value={this.state.user}
          />
        </Form>

        {!userInfo ? null : userInfo && userInfo !== {} ? (
          userInfo === 'Не найдено' ? (
            <HeaderPage
              little
              notFound
              title={`Пользователь ${currentUser} не найден...`}
            />
          ) : (
            <div>
              <div>Найден аккаунт:</div>
              <div>{`ФИО: ${userInfo.firstName} ${userInfo.lastName} ${userInfo.middleName}`}</div>
              <div>{`Email: ${userInfo.email}`}</div>
              <div>{`Телефон: ${userInfo.phone}`}</div>
              <div>{`Дата рождения ${moment(userInfo.birthDay).format(
                'DD.MM.YYYY'
              )}`}</div>
              <Button
                id={userInfo._id}
                onClick={this.onOpenAlert}
                value='Восстановить пользователя'
              />
            </div>
          )
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.deletedUser
  };
}

export default connect(mapStateToProps, {
  clearDeletedUsers,
  recoverDeletedUser,
  fetchDeletedUsers
})(DeletedPointsPage);
