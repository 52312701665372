import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';

import { logOut } from '../../actions/loginActions';

import profile from '../../images/login.png';
import users from '../../images/sidebar/cogwheel.svg';

class Sidebar extends Component {
  getName = () => {
    const { userType } = this.props;

    if (userType === 'manager') return 'Менеджер';
    else if (userType === 'admin') return 'Администратор';
    return 'Иван Иванов';
  };

  renderReadCallCentre = () => (
    <>
      <div className='section-link'>
        <Link to='/searchOrders'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Поиск заказа</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Клиенты</div>
        <Link to='/users'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Пользователи</p>
        </Link>
      </div>
    </>
  );

  renderCallCentre = () => (
    <>
      <div className='section-link'>
        <Link to='/searchOrders'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Поиск заказа</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Курьеры</div>
        <Link to='/'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Курьеры</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Клиенты</div>
        <Link to='/users'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Пользователи</p>
        </Link>
        <Link to='/recalls'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Отзывы</p>
        </Link>
      </div>
    </>
  );

  renderRegionalManager = () => (
    <>
      <div className='section-link'>
        <div className='section-link-title'>Курьеры</div>
        <Link to='/'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Курьеры</p>
        </Link>
        {/* <Link to='/couriers-requests'> */}
        {/*  <ReactSVG */}
        {/*    className='svg-image' */}
        {/*    src={users} */}
        {/*  /> */}
        {/*  <p>Заявки</p> */}
        {/* </Link> */}
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Клиенты</div>
        <Link to='/recalls'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Отзывы</p>
        </Link>
      </div>
    </>
  );

  renderMenu = () => (
    <>
      <div className='section-link'>
        <div className='section-link-title'>Delivery Club</div>
        <Link to='/delivery-club'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Каталог</p>
        </Link>
        <Link to='/delivery-club-categories'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Категории</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Статистика</div>
        <Link to='/statistic'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Статистика</p>
        </Link>
        <Link to='/qrstatistic'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Статистика по qr кодам</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Курьеры</div>
        <Link to='/'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Курьеры</p>
        </Link>
        <Link to='/couriers-settings'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Настройки</p>
        </Link>
        <Link to='/couriers-bonuses'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Бонусная система</p>
        </Link>
        <Link to='/couriers-requests'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Заявки</p>
        </Link>
        <Link to='/couriers-report'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Отчёт</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Каталог</div>
        <Link to='/categories'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Категории</p>
        </Link>
        <Link to='/products'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Продукты</p>
        </Link>
        <Link to='/groups'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Группы</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Заказы</div>
        <Link to='/orders'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Текущие заказы</p>
        </Link>
        <Link to='/searchOrders'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Поиск заказа</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Клиенты</div>
        <Link to='/users'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Пользователи</p>
        </Link>
        <Link to='/deletedUsers'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Удалённые пользователи</p>
        </Link>
        <Link to='/recalls'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Отзывы</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Заявки на изменение данных</div>
        <Link to='/change_requests/email'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Email</p>
        </Link>
        <Link to='/change_requests/phone'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Phone</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Акции</div>
        <Link to='/campaigns'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Акции</p>
        </Link>
        <Link to='/promocodes'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Промокоды</p>
        </Link>
        <Link to='/coupons'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Купоны</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Уведомления</div>
        <Link to='/pushes'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Регулярные пуши</p>
        </Link>
        <Link to='/emails'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Уведомления об акции</p>
        </Link>
        <Link to='/push'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Push-уведомления</p>
        </Link>
        <Link to='/custom-emails'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Email-уведомления</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Вакансии</div>
        <Link to='/vacancies'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Вакансии</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Рестораны</div>
        <Link to='/restaurants'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Рестораны</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Услуги</div>
        <Link to='/service-payment-delivery'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Платная доставка</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Настройки</div>
        <Link to='/letters'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Письма</p>
        </Link>
        <Link to='/setting'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Настройки</p>
        </Link>
        <Link to='/seo'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>SEO</p>
        </Link>
        <Link to='/advise-settings'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Очень рекомендуем</p>
        </Link>
        <Link to='/optional-modifiers'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>К этому блюду подойдет</p>
        </Link>
      </div>
      <div className='section-link'>
        <div className='section-link-title'>Отчеты</div>
        <Link to='/payments-report'>
          <ReactSVG
            className='svg-image'
            src={users}
          />
          <p>Отчет по онлайн оплатам</p>
        </Link>
      </div>
    </>
  );

  renderAccountant = () => {
    return (
      <div>
        <div className='section-link'>
          <div className='section-link-title'>Курьеры</div>
          <Link to='/couriers-report'>
            <ReactSVG
              className='svg-image'
              src={users}
            />
            <p>Отчёт по курьерам</p>
          </Link>
        </div>
        <div className='section-link'>
          <div className='section-link-title'>Отчеты</div>
          <Link to='/payments-report'>
            <ReactSVG
              className='svg-image'
              src={users}
            />
            <p>Отчет по онлайн оплатам</p>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const { userType } = this.props;

    let content = this.renderMenu();

    if (userType === 'readCallCentre') content = this.renderReadCallCentre();

    if (userType === 'callCentre') content = this.renderCallCentre();

    if (userType === 'regionalManager') content = this.renderRegionalManager();

    if (userType === 'accountant') content = this.renderAccountant();

    return (
      <div
        className={this.props.open ? 'active' : ''}
        id='sidebar'
      >
        {userType !== 'accountant' && userType !== 'readCallCentre' ? (
          <Link
            className='profile-link'
            to='/statistics'
          >
            <div className='profile'>
              <img
                alt='profile'
                className='profile-image'
                src={profile}
              />
              <div className='profile-name'>{this.getName()}</div>
            </div>
          </Link>
        ) : (
          <div className='profile'>
            <img
              alt='profile'
              className='profile-image'
              src={profile}
            />
            <div className='profile-name'>{this.getName()}</div>
          </div>
        )}
        {content}
        <div className='section-link'>
          <div className='section-link-title'>Выход</div>
          <a
            href='/'
            onClick={e => {
              e.preventDefault();
              this.props.logOut();
            }}
          >
            <ReactSVG
              className='svg-image'
              src={users}
            />
            <p>Выход</p>
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.login.token,
    userInfo: state.login.userInfo,
    userType: state.login.type
  };
}

export default connect(mapStateToProps, { logOut })(Sidebar);
