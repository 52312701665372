import { API } from './index';
import { logOut } from './loginActions';

export const PUSH = 'PUSH';
export const CAMPAIGN = 'CAMPAIGN';
export const FETCH_PUSH_CAMPAIGNS = 'FETCH_PUSH_CAMPAIGNS';

export const makePush = data => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/manager_push`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Пуш отправлен!');
      const json = await response.json();
      dispatch({
        type: FETCH_PUSH_CAMPAIGNS,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCampaigns = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns`, {
    method: 'get'
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_PUSH_CAMPAIGNS,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const sendCustomEmail = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/manager_email`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Сообщение на почту успешно отправлено');
      if (callback && typeof callback === 'function') callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const makeEmail = id => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns/notify/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({})
  });

  const status = await response.status;

  switch (status) {
    case 200:
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
