import React, { useEffect } from 'react';
import Select from 'react-select';
import { API } from '../../actions/index';

function SearchBar(props) {
  const [options, setOptions] = React.useState([]);

  function handleChange(e) {
    props.setProduct(e.value);
  }

  useEffect(() => {
    async function FetchData() {
      fetch(`${API.url}/menu/products/search`, {
        mode: 'cors'
      })
        .then(res => res.json())
        .then(result => {
          setOptions(
            result.map(res => {
              return {
                label: `${res.name +
                  (res.additionalInfo
                    ? ` ${res.additionalInfo.dough} ${res.additionalInfo.size}`
                    : '')} ${res.price}`,
                value: res._id
              };
            })
          );
        });
    }
    FetchData();
  }, []);

  const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black',
      padding: 5
    })
  };

  return (
    <Select
      getOptionLabel={option => {
        return option.label;
      }}
      getOptionValue={option => {
        return option.value;
      }}
      onChange={handleChange}
      options={options}
      placeholder='Добавьте продукт'
      styles={customStyles}
    />
  );
}

export default SearchBar;
