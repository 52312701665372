import { API } from './index';

export const LOGOUT = 'LOG_OUT';
export const USER_INFO = 'USER_INFO';
export const USER_SIGNIN = 'USER_SIGNIN';

export const logOut = callback => async dispatch => {
  dispatch({ type: LOGOUT });
  if (typeof callback === 'function') await callback();
};

export const userInfo = (token, type, callback) => async dispatch => {
  const response = await fetch(`${API.url}/users/info`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();

      const data = {
        token,
        info: json
      };

      if (type) data.type = type;
      dispatch({
        type: USER_INFO,
        payload: data
      });

      if (callback && typeof callback === 'function') callback();
      break;

    case 401:
      dispatch(logOut());
      break;

    default:
      alert('Ошибка при получении данных');
  }
};

export const signin = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/users/signin`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();

      if (
        [
          'regionalManager',
          'manager',
          'admin',
          'callCentre',
          'readCallCentre',
          'accountant'
        ].includes(json.userType)
      ) {
        dispatch(userInfo(json.token, json.userType));
        dispatch({
          type: USER_SIGNIN,
          payload: { token: json.token }
        });
      } else
        alert(
          'Данный аккаунт не предназначен для работы с административным сайтом'
        );

      if (callback) await callback({ status, json });
      break;

    case 401:
      alert('Неверный логин или пароль');
      break;

    case 403:
      alert('Неверный логин или пароль');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
  }
};
