import React from 'react';
import SelectItem from '../SelectItem';

import './Select.css';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDropList: false,
      activeItems: this.props.activeItems || null,
      text: this.props.value || null,
      value: this.props.text || null
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onPressedKey.bind(this));
    window.addEventListener('click', this.onClickWindow.bind(this));
    if (this.props.value) {
      const match = this.props.items.find(
        item => String(item.value) === String(this.props.value)
      );
      const text = match && String(match.text);

      this.setState({
        value: this.props.value,
        text: this.props.text || text || this.props.value
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value) {
      const match = newProps.items.find(
        item => String(item.value) === String(newProps.value)
      );
      const text = match && String(match.text);

      this.setState({
        value: newProps.value,
        text: newProps.text || text || newProps.value
      });
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.onPressedKey.bind(this));
  };

  onClickWindow(event) {
    const { viewDropList } = this.state;
    const { target } = event;

    if (
      target.classList.contains('select-element-wrapper') ||
      target.classList.contains('select-element') ||
      target.classList.contains('select-element-item') ||
      target.classList.contains('select-arrow') ||
      target.classList.contains('select-label')
    )
      return false;

    if (viewDropList) this.setState({ viewDropList: false });
  }

  onPressedKey(event) {
    const { viewDropList } = this.state;
    if (viewDropList && event.key === 'Escape')
      this.setState({ viewDropList: false });
  }

  changeSelect = event => {
    event.persist();

    const { value: newValue } = event.target.dataset;
    const { innerText: newText } = event.target;
    const isSameText = newText === this.state.text;

    if (this.state.activeItems) {
      const activeItems = [...this.state.activeItems];
      const ind = activeItems.findIndex(el => el.value === newValue);

      if (ind !== -1) {
        activeItems.splice(ind, 1);
      } else {
        activeItems.push({
          value: newValue,
          text: newText
        });
      }

      this.props.onChange(activeItems);
      this.setState({ activeItems });
    } else if (!isSameText || this.props.withClear) {
      if (this.props.onChange) {
        let condition = true;

        if (this.props.isConfirm) {
          condition = window.confirm(this.props.confirm(newValue));
        }

        if (condition) {
          const newItem = {
            value: isSameText ? null : newValue,
            text: isSameText ? null : newText
          };

          this.props.onChange(newItem.value, newItem.text);
          this.setState({
            ...newItem
          });
        }

        this.setState({ viewDropList: false });
      } else alert('На селекте отсутствует onChange');
    } else {
      this.setState({ viewDropList: false });
    }
  };

  toggleDropList = event => {
    if (event.target.dataset.parent)
      this.setState({ viewDropList: !this.state.viewDropList });
  };

  renderText = () => {
    const { activeItems } = this.state;
    if (activeItems && activeItems.length) {
      let text = '';
      activeItems.forEach((el, i) => {
        text += `${el.text}`;
        if (i < activeItems.length - 1) text += ', ';
      });
      return text;
    }
    if (this.state.text) return this.state.text;

    if (!this.state.text) {
      if (this.props.default) return this.props.default;
      else if (this.props.items && this.props.items.length)
        return this.props.items[0].text;
    }

    return 'default';
  };

  renderValue = () => {
    if (!this.state.value && this.props.items && this.props.items.length) {
      return this.props.items[0].value;
    }

    return this.state.value || 'default';
  };

  getClassName = () => {
    let className = 'select-element-wrapper';
    if (this.props.disabled) className += ' disabled';
    if (this.props.direction) className += ` direction-${this.props.direction}`;

    return className;
  };

  renderItems = () => {
    const { activeItems } = this.state;
    return (
      <div
        className='select-droplist'
        style={{ maxHeight: this.props.maxHeight || 'inherit' }}
      >
        {this.props.default && (
          <SelectItem
            default
            key='default'
            multiple={Boolean(activeItems)}
            onClick={this.changeSelect}
            text={this.props.default}
            value='default'
          />
        )}
        {this.props.items.map((item, key) => {
          const selected =
            activeItems && activeItems.find(el => el.value === item.value);
          return (
            <SelectItem
              disabled={item.disabled}
              key={key}
              multiple={Boolean(activeItems)}
              onClick={this.changeSelect}
              selected={selected}
              text={item.text}
              value={item.value}
            />
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div
        className={this.getClassName()}
        style={this.props.style && this.props.style}
      >
        {this.props.label && <label>{this.props.label}</label>}
        <div
          className={
            this.props.className
              ? `select-element ${this.props.className}`
              : 'select-element'
          }
          data-parent
          onClick={this.toggleDropList}
          value={this.renderValue()}
        >
          <div
            className='select-label'
            data-parent
          >
            {this.renderText()}
          </div>
          <div
            className='select-arrow'
            onClick={this.toggleDropList}
          />
          {this.props.items && this.state.viewDropList && this.renderItems()}
        </div>
      </div>
    );
  }
}

export default Select;
