import {FETCH_PROMOCODES, CLEAR_PROMOCODES, UPDATE_PROMOCODES} from '../actions/promocodeActions';

const INITIAL_STATE = {
    promocodes: 'Обновление',
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_PROMOCODES:
            return {...state, promocodes: action.payload};

        case CLEAR_PROMOCODES:
            return {...state, promocodes: action.payload};

        case UPDATE_PROMOCODES:
            return {...state, promocodes: action.payload};
        
        default:
            return state;
    }
}