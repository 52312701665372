import React from 'react';
import { Cross, HeaderPage, ModalTitle, Table } from '../elements/elements';

import './styles.scss';

const CouponProductsList = props => {
  const { products, onDelete } = props;

  if (!products || !products.length) {
    return <HeaderPage
      little
      notFound
      title='Купон не имеет продуктов...'
    />;
  }

  return (
    <div className='coupon-products-list'>
      <ModalTitle
        key='couponTitle'
        title='Продукты купона'
      />
      <Table
        data={products.map((a, key) => (
          <tr key={key}>
            <td>{a.name}</td>
            <td>
              {a.additionalInfo && a.additionalInfo.dough
                ? a.additionalInfo.dough
                : '-'}
            </td>
            <td>
              {a.additionalInfo && a.additionalInfo.size
                ? a.additionalInfo.size
                : '-'}
            </td>
            <td>{a._id}</td>
            <td>
              <Cross
                noModalCross
                onClick={() => onDelete(key, a._id)}
              />
            </td>
          </tr>
        ))}
        title={['Продукт', 'Начинка', 'Размер', 'ID', '']}
      />
    </div>
  );
};

export default CouponProductsList;
