import React, { Component } from 'react';
import ReactPagination from 'react-paginate';

import { withRouter } from 'react-router-dom';

import { getQueryString, pushHistory } from '../elements/functions';

import './style.scss';

class Pagination extends Component {
  pushHistory = pushHistory.bind(this);

  changePage = ({ selected }) => {
    const page = selected + 1;
    this.pushHistory({ page });
  };

  render() {
    const { count, limit, onChange, page } = this.props;

    if (!count || count <= limit) return null;

    const queryPage = getQueryString('page');
    const currentPage = queryPage ? queryPage - 1 : 0;

    return (
      <ReactPagination
        activeClassName='active'
        containerClassName='pagination'
        disableInitialCallback
        forcePage={page ? page - 1 : currentPage}
        marginPagesDisplayed={1}
        nextClassName='next'
        nextLabel='Далее'
        onPageChange={onChange || this.changePage}
        pageCount={Math.ceil(count / limit)}
        pageRangeDisplayed={4}
        previousClassName='previous'
        previousLabel='Назад'
      />
    );
  }
}

export default withRouter(Pagination);
