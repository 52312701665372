import React, { Component } from 'react';
import { connect } from 'react-redux';
import CouponProductsList from '../../CouponProductsList';

import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  ImageBlock
} from '../../elements/elements';

import {
  fetchSettings,
  createSettings,
  updateSettings,
  deleteCouponProducts
} from '../../../actions/settingsActions';

import { uploadImage, server } from '../../../actions';

const defaultState = {
  coupon: {
    name: '',
    description: '',
    specialTag: '',
    couponType: '',
    videoLink: '',
    image: '',
    products: [],
    minAmount: 0,
    sale: 0,
    overdueDelivery: 0,
    overdueGoldenMile: 0,
    countSumBeforeCoupon: false,
    canUseWithOtherCoupons: false,
    canUseWithPromocodes: false,
    monthlyCoupon: false
  },
  file: null,
  loader: false,
  removeArray: []
};

const imageUrl = `${server}/public/images/`;
class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.setState({ coupon: this.props.coupon || defaultState.coupon });
    }
    if (prevProps.show && !this.props.show) {
      this.setState({ ...defaultState });
    }
  }

  changeInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    const coupon = { ...this.state.coupon };
    coupon[name] = value;
    if (name === 'minAmount') coupon.countSumBeforeCoupon = true;

    this.setState({ coupon });
  };

  changeCheckbox = e => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState(prevState => ({
      coupon: {
        ...prevState.coupon,
        [name]: checked
      }
    }));
  };

  onSubmit = () => {
    if (this.props.coupon) {
      const { coupon, removeArray } = this.state;
      const body = {
        [coupon.couponType]: coupon
      };

      if (removeArray.length) {
        this.props.deleteCouponProducts(
          { products: removeArray },
          coupon.couponType
        );
      }

      this.props.updateSettings(body, () => {
        this.props.fetchSettings();
        this.props.toggleModal();
      });
    } else {
      const body = {
        ...this.state.coupon
      };
      this.props.createSettings(JSON.stringify(body), this.props.toggleModal);
    }
  };

  uploadImage = event => {
    event.persist();

    this.setState({ loader: true });

    this.props
      .uploadImage('image', event.target.files[0], 'all')
      .then(file => {
        this.setState(prevState => ({
          coupon: {
            ...prevState.coupon,
            image: file.filename
          },
          file,
          loader: false
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  deleteImage = () => {
    this.setState(prevState => ({
      coupon: {
        ...prevState.coupon,
        image: ''
      },
      file: null
    }));
  };

  getImage = () => {
    const { coupon, file } = this.state;
    const image = coupon.image || '';
    if (file) return file.url;
    if (image.includes(server)) return image;
    return `${imageUrl}${image}`;
  };

  onDeleteProduct = (key, id) => {
    const { coupon } = this.state;

    if (coupon.products) {
      const array = [...coupon.products];
      array.splice(key, 1);

      this.setState(prevState => ({
        coupon: {
          ...prevState.coupon,
          products: array
        },
        removeArray: [...prevState.removeArray, id]
      }));
    }
  };

  render() {
    const { coupon, loader, file } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle
          title={this.props.coupon ? `«${coupon.name}»` : 'Добавить купон'}
        />
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value={this.props.coupon ? 'Сохранить изменения' : 'Создать купон'}
        >
          <ImageBlock
            front
            image={this.getImage()}
            loader={loader}
            name='image'
            onDelete={this.deleteImage}
            onLoad={this.uploadImage}
            placeholder='Превью купона'
            withoutServer={!file}
          />

          <FormGroup
            id='name'
            name='name'
            onChange={this.changeInput}
            placeholder='Название купона'
            required
            value={coupon.name}
          />

          <FormGroup
            id='description'
            name='description'
            onChange={this.changeInput}
            placeholder='Описание'
            required
            type='textarea'
            value={coupon.description}
          />

          <FormGroup
            id='couponType'
            name='couponType'
            onChange={this.changeInput}
            placeholder='Тип купона'
            required
            value={coupon.couponType}
          />

          <FormGroup
            id='specialTag'
            name='specialTag'
            onChange={this.changeInput}
            placeholder='Тег'
            value={coupon.specialTag}
          />

          <FormGroup
            id='minAmount'
            name='minAmount'
            onChange={this.changeInput}
            placeholder='Минимальная сумма'
            type='number'
            value={coupon.minAmount}
          />

          {coupon.couponType === 'lateDelivery' && (
            <React.Fragment>
              <FormGroup
                id='overdueDelivery'
                name='overdueDelivery'
                onChange={this.changeInput}
                placeholder='Время опоздания заказа'
                type='number'
                value={coupon.overdueDelivery}
              />
              <FormGroup
                id='overdueGoldenMile'
                name='overdueGoldenMile'
                onChange={this.changeInput}
                placeholder='Время опоздания заказа ЗМ'
                type='number'
                value={coupon.overdueGoldenMile}
              />
            </React.Fragment>
          )}

          <FormGroup
            id='videoLink'
            name='videoLink'
            onChange={this.changeInput}
            placeholder='Ссылка на видео'
            value={coupon.videoLink}
          />

          <FormGroup
            checked={coupon.canUseWithOtherCoupons}
            id='canUseWithOtherCoupons'
            name='canUseWithOtherCoupons'
            onChange={this.changeCheckbox}
            placeholder='Используется с другими купонами'
            type='checkbox'
            value=''
          />

          <FormGroup
            checked={coupon.canUseWithPromocodes}
            id='canUseWithPromocodes'
            name='canUseWithPromocodes'
            onChange={this.changeCheckbox}
            placeholder='Используется с другими акциями'
            type='checkbox'
            value=''
          />

          <FormGroup
            checked={coupon.countSumBeforeCoupon}
            id='countSumBeforeCoupon'
            name='countSumBeforeCoupon'
            onChange={this.changeCheckbox}
            placeholder='Учитывать сумму перед использованием'
            type='checkbox'
            value=''
          />

          <FormGroup
            checked={coupon.monthlyCoupon}
            id='monthlyCoupon'
            name='monthlyCoupon'
            onChange={this.changeCheckbox}
            placeholder='Месячный купон'
            type='checkbox'
            value=''
          />

          <CouponProductsList
            onDelete={this.onDeleteProduct}
            products={coupon.products}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { coupon: state.modal.modalContent };
}

const mapDispatchToProps = {
  fetchSettings,
  createSettings,
  updateSettings,
  uploadImage,
  deleteCouponProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
