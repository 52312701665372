import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '../components/Select';
import { makeEmail, fetchCampaigns } from '../actions/pushActions';
import { HeaderPage, Form } from '../components/elements/elements';

class PushPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignID: ''
    };
  }

  componentDidMount() {
    this.props.fetchCampaigns();
  }

  handleChange = value => {
    this.setState({ campaignID: value });
  };

  actionNotify = () => {
    if (this.state.campaignID) this.props.makeEmail(this.state.campaignID);
    else alert('Выберите акцию из списка');
  };

  getItems = () => {
    const array = [];
    const { campaigns } = this.props;

    if (typeof campaigns === 'object') {
      campaigns.map(campaign =>
        array.push({
          value: campaign._id,
          text: campaign.title
        })
      );
    }

    return array;
  };

  render() {
    if (this.props.campaigns) {
      return [
        <HeaderPage
          key='1'
          title='Уведомления об акции'
        />,
        <Form
          direction='row'
          key='2'
          onSubmit={this.actionNotify}
          style={{ marginBottom: 60 }}
          value='Отправить'
        >
          <Select
            default='Выберите акцию'
            items={this.getItems()}
            label='Выберите акцию:'
            onChange={this.handleChange}
          />
        </Form>
      ];
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.push.campaigns
  };
}

export default connect(
  mapStateToProps,
  { makeEmail, fetchCampaigns }
)(PushPage);
