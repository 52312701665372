import {FETCH_COUPONS, CLEAR_COUPONS} from '../actions/couponActions';

const INITIAL_STATE = {
    coupons: [],
    count: false,
    data: false
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_COUPONS:
            return {...state, data: action.payload, coupons: action.payload.coupons, count: action.payload.count};
        
        case CLEAR_COUPONS:
            return {...state, data: false, coupons: action.payload, count: false};
        
        default:
            return state;
    }
}