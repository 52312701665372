import React from 'react';
import { connect } from 'react-redux';
import {
  updateRestaurant,
  fetchRestaurant,
  clearRestourant,
  fetchZone,
  clearZone
} from '../../actions/restaurantActions';
import { Modal, Form, Button } from 'react-bootstrap';
import { script } from '../../updateScript';

// import { YMaps, Map, Placemark, GeoObject, Polygon } from 'react-yandex-maps';

let stateMonitor = '';
let myPolygon = [];
let myPlacemark = {};
let myMapTest = {};
let currentRestourantZone;

class PolygoneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    script('//api-maps.yandex.ru/2.1/?lang=ru_RU');
  }

  refresh = () => {
    this.props.clearZone();
    this.props.clearRestourant();
    this.props.fetchRestaurant(this.props.restourantData._id);
    this.props.fetchZone();
  };

  updateCoords = () => {
    const data = this.props.restourantData;

    if (myPolygon.length > 0) {
      myPolygon[currentRestourantZone].zone.editor.stopEditing();
      data.deliveryZone = myPolygon[
        currentRestourantZone
      ].zone.geometry.getCoordinates();
    } else {
      myPolygon.editor.stopEditing();
      data.deliveryZone = myPolygon.geometry.getCoordinates();
    }

    this.props.updateRestaurant(
      this.props.restourantData._id,
      data,
      this.refresh
    );
    alert('Новые координаты сохранены');
    this.props.toggleModal();
  };

  // refreshPolygone = () => {

  //     if(myPolygon.length>0) {
  //         // myMapTest.geoObjects.remove(myPolygon);
  //     } else {
  //         myMapTest.geoObjects.remove(myPolygon);
  //     }

  //     myPolygon = new window.ymaps.Polygon([], {}, {
  //         editorDrawingCursor: "crosshair",
  //         editorMaxPoints: 100,
  //         fillColor: '#00FF0050',
  //         strokeColor: '#0000FF',
  //         strokeWidth: 2
  //     });

  //     myMapTest.geoObjects.add(myPolygon);
  //     myPolygon.editor.startDrawing();
  // }

  getMapRef = node => {
    this.newMap = node;

    window.ymaps.ready(() => {
      if (this.newMap) {
        myMapTest = new window.ymaps.Map(
          this.newMap,
          {
            center: [59.939095, 30.315868],
            zoom: 10,
            controls: ['fullscreenControl', 'zoomControl']
          },
          {
            searchControlProvider: 'yandex#search'
          }
        );

        if (this.props.loc) {
          myPlacemark = new window.ymaps.Placemark(
            [this.props.loc.lat, this.props.loc.lon],
            {},
            {
              preset: 'twirl#yellowStretchyIcon',
              balloonCloseButton: false,
              hideIconOnBalloonOpen: false
            }
          );
        }

        if (this.props.coords) {
          this.props.coords.map(function(a, index) {
            const newZone = new window.ymaps.Polygon(
              a.deliveryZone,
              {},
              {
                editorDrawingCursor: 'crosshair',
                editorMaxPoints: 100,
                fillColor: a.color,
                strokeColor: '#0000FF',
                name: a.name,
                strokeWidth: 2
              }
            );

            return myPolygon.push({ name: a.name, zone: newZone });
          });
        } else {
          myPolygon = new window.ymaps.Polygon(
            [],
            {},
            {
              editorDrawingCursor: 'crosshair',
              editorMaxPoints: 100,
              fillColor: '#00FF0050',
              strokeColor: '#0000FF',
              strokeWidth: 2
            }
          );
        }

        // (myPolygon);

        if (myPolygon.length > 0) {
          for (let i = 0; i < myPolygon.length; i++) {
            if (myPolygon[i].name === this.props.restourantData.name) {
              currentRestourantZone = i;
            } else {
              myMapTest.geoObjects.add(myPolygon[i].zone);
            }
          }
        }

        if (myPolygon.length > 0) {
          myMapTest.geoObjects.add(myPolygon[currentRestourantZone].zone);
          stateMonitor = new window.ymaps.Monitor(
            myPolygon[currentRestourantZone].zone.editor.state
          );
          stateMonitor.add('drawing', function(newValue) {
            myPolygon[currentRestourantZone].zone.options.set(
              'strokeColor',
              newValue ? '#FF0000' : '#0000FF'
            );
          });

          myPolygon[currentRestourantZone].zone.editor.startDrawing();
        }

        myMapTest.geoObjects.add(myPlacemark);
      }
    });
  };

  render() {
    return (
      <div>
        <Modal
          onHide={this.props.toggleModal}
          show={this.props.show}
        >
          <Modal.Body>
            <Form
              id='newCoords'
              onSubmit={this.handleSubmit}
            >
              <div
                height='400px'
                id='map'
                ref={r => this.getMapRef(r)}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button className="left" bsStyle="warning" type="submit" onClick={this.refreshPolygone}>Стереть текущую зону</Button> */}

            <Button
              bsStyle='success'
              onClick={this.updateCoords}
              type='submit'
            >
              Сохранить координаты
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.props.onPressClose}
            >
              Отменить
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    zone: state.restaurants.zone
  };
}

export default connect(mapStateToProps, {
  updateRestaurant,
  clearRestourant,
  fetchRestaurant,
  fetchZone,
  clearZone
})(PolygoneModal);
