import React, { Component } from 'react';
import { connect } from 'react-redux';
import CouponProductsList from '../../CouponProductsList';
import { Form, Modal, HeaderPage } from '../../elements/elements';
import {
  clearSettings,
  fetchSettings,
  deleteCouponProducts
} from '../../../actions/settingsActions';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: {},
      array: [],
      removeArray: []
    };
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.clearSettings();
    this.props.fetchSettings();
  };

  componentWillReceiveProps(newState) {
    if (this.props.show !== newState.show) {
      this.setState({ coupon: { ...newState.coupon.data }, removeArray: [] });
    }
  }

  onDelete = (key, id) => {
    const { coupon, removeArray } = this.state;
    if (coupon.products) {
      const array = [...coupon.products];
      array.splice(key, 1);

      removeArray.push(id);

      this.setState(prevState => ({
        coupon: {
          ...prevState.coupon,
          products: array
        }
      }));
    }
  };

  onUpdate = () => {
    const { removeArray } = this.state;
    const { coupon } = this.props;

    const data = { products: [...removeArray] };

    this.props.deleteCouponProducts(data, coupon.type, this.refreshPage);
  };

  render() {
    const { coupon } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        {coupon.products && coupon.products.length > 0 ? (
          [
            <Form
              direction='column'
              key='couponForm'
              onSubmit={this.onUpdate}
              value='Сохранить изменения'
            >
              <CouponProductsList
                onDelete={this.onDelete}
                products={coupon.products}
              />
            </Form>
          ]
        ) : (
          <HeaderPage
            little
            notFound
            title='Купон не имеет продуктов...'
          />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupon: state.modal.modalContent
  };
}

export default connect(
  mapStateToProps,
  { clearSettings, fetchSettings, deleteCouponProducts }
)(ModalComponent);
