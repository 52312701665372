import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import { fetchCategories } from '../actions/categoryActions';
import { HeaderPage, Table, SvgButton } from '../components/elements/elements';
import CategoryModal from '../components/category/categoryModal';

class CategoryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      category: {}
    };
  }

  title = [
    'Название',
    'Наличие тегов',
    'Отображать в меню',
    'Приоритет',
    'ID',
    ''
  ];

  componentDidMount() {
    this.props.fetchCategories();
  }

  toggleModal = () => {
    this.setState({ show: !this.state.show });
  };

  handleCategory = category => {
    this.toggleModal();
    this.setState({ category });
  };

  render() {
    if (this.props.categories.length === 0) {
      return <Loader />;
    } 
      return (
        <div>
          {this.state.category._id ? (
            <CategoryModal
              category={this.state.category}
              show={this.state.show}
              toggleModal={this.toggleModal}
            />
          ) : (
            ''
          )}

          <HeaderPage title='Категории' />
          <Table
            data={
              this.props.categories &&
              this.props.categories.map((category, key) => (
                <tr key={key}>
                  <td>{category.name}</td>
                  <td>
                    {category.tags && category.tags.length > 0 ? 'Да' : 'Нет'}
                  </td>
                  <td>{category.isIncludedInMenu ? 'Да' : 'Нет'}</td>
                  <td>{category.order ? category.order : '-'}</td>
                  <td>{category._id}</td>
                  <td>
                    <SvgButton
                      image='edit'
                      onClick={() => this.handleCategory(category)}
                    />
                  </td>
                </tr>
              ))
            }
            title={this.title}
          />

          {/* <CategoryList categories={this.props.categories} show={this.state.showModal} toggleModal={this.toggleModal}/> */}
        </div>
      );
    
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories.categories
  };
}

export default connect(mapStateToProps, { fetchCategories })(CategoryIndex);
