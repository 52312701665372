import React from 'react';
import { connect } from 'react-redux';
// import Loader from '../../../components/interface/loader';
import { Modal, ModalTitle, Table } from '../../elements/elements';

// import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

moment.locale('ru');

class ModalComponent extends React.Component {
  onCancel = () => {
    this.props.toggleModal();
  };

  title = ['Товар', 'Параметры', 'Модификаторы', 'Топинги', 'Продукт', 'Сумма'];

  getFullSum = product => {
    let cost = product.price;

    if (product.optionalModifiers && product.optionalModifiers.length > 0) {
      for (let z = 0; z < product.optionalModifiers.length; z++) {
        for (
          let t = 0;
          t < product.optionalModifiers[z].modifiers.length;
          t++
        ) {
          if (product.optionalModifiers[z].modifiers[t].amount > 0) {
            cost +=
              product.optionalModifiers[z].modifiers[t].amount *
              product.optionalModifiers[z].modifiers[t].price;
          }
        }
      }
    }

    return cost;
  };

  getOptionalModifiers = modifiers => {
    const array = [];
    let haveModifier = false;

    for (let z = 0; z < modifiers.length; z++) {
      for (let t = 0; t < modifiers[z].modifiers.length; t++) {
        if (modifiers[z].modifiers[t].amount > 0) {
          array.push(
            <div
              key={z + t}
            >{`${modifiers[z].modifiers[t].name} ${modifiers[z].modifiers[t].amount} x ${modifiers[z].modifiers[t].price} р.`}</div>
          );
          haveModifier = true;
        }
      }
    }

    if (haveModifier) return <div>{array}</div>;
    return <div>-</div>;
  };

  getModifiers = modifiers => {
    const array = [];
    let haveModifier = false;

    for (let z = 0; z < modifiers.length; z++) {
      for (let t = 0; t < modifiers[z].modifiers.length; t++) {
        if (modifiers[z].modifiers[t].amount > 0) {
          array.push(
            <div
              key={z + t}
            >{`${modifiers[z].modifiers[t].name} ${modifiers[z].modifiers[t].amount} x ${modifiers[z].modifiers[t].price} р.`}</div>
          );
          haveModifier = true;
        }
      }
    }

    if (haveModifier) return <div>{array}</div>;
    return <div>-</div>;
  };

  render() {
    const { order } = this.props;
    return (
      <div>
        <Modal
          show={this.props.show}
          toggleModal={this.props.toggleModal}
        >
          <ModalTitle title='Подробности заказа' />
          <Table
            data={
              order.items &&
              order.items.map((product, key) => (
                <tr key={key}>
                  <td>{`${product.amount} x ${product.name}`}</td>
                  <td>
                    {product.additionalInfo
                      ? [
                          product.additionalInfo.size,
                          <br key={key} />,
                          product.additionalInfo.dough
                        ]
                      : '-'}
                  </td>
                  <td>
                    {product.modifiers.length > 0
                      ? this.getModifiers(product.modifiers)
                      : '-'}
                  </td>
                  <td>
                    {product.optionalModifiers.length > 0
                      ? this.getOptionalModifiers(product.optionalModifiers)
                      : '-'}
                  </td>
                  <td>
                    {product.price ? `${product.price} р.` : 'Нет информации'}
                  </td>
                  <td>
                    {product.price
                      ? `${this.getFullSum(product)} р.`
                      : 'Нет информации'}
                  </td>
                </tr>
              ))
            }
            title={this.title}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {})(ModalComponent);
