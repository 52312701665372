import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  fetchPromocodes,
  clearPromocodes,
  deletePromocode,
  updatePromocode
} from '../actions/promocodeActions';
import {
  HeaderPage,
  Table,
  SvgButton,
  Cross,
  FormGroup
} from '../components/elements/elements';
import {
  togglePromocodeModal,
  togglePromocodeProductModal
} from '../actions/modalActions';

class PromocodeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  title = [
    'Код',
    'Минимум продуктов',
    'Минимальная сумма',
    'Использование бонусов',
    'Тип скидки',
    'Скидка',
    'Одноразовый промокод',
    ''
  ];

  componentDidMount() {
    this.props.fetchPromocodes();
  }

  componentWillUnmount() {
    this.props.clearPromocodes();
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  refreshPage = () => {
    this.props.clearPromocodes();
    this.props.fetchPromocodes();
  };

  getPromocodeType = type => {
    switch (type) {
      case 'custom':
        return 'Уникальный';
      case 'percent':
        return 'Проценты';
      case 'flatsale':
        return 'Фиксированный';
      default:
        return type;
    }
  };

  onDelete(promocode) {
    return () => {
      const condition = window.confirm(
        `Вы точно хотите удалить промокод ${promocode.promocode}?`
      );

      if (condition) {
        this.props.deletePromocode(promocode._id, this.refreshPage);
      }
    };
  }

  onCheck = promocode => {
    return e => {
      this.props.updatePromocode(
        promocode._id,
        {
          ...promocode,
          oneTimeUsage: e.target.checked
        },
        this.refreshPage
      );
    };
  };

  renderOneTimeUsageCheckbox = item => {
    return (
      <FormGroup
        checked={item.oneTimeUsage}
        id='oneTimeUsage'
        name='oneTimeUsage'
        onChange={this.onCheck(item)}
        type='checkbox'
      />
    );
  };

  render() {
    const { promocodes } = this.props;
    if (!this.props.promocodes) return null;

    return (
      <div>
        <HeaderPage
          button='Добавить промокод'
          onClick={this.props.togglePromocodeModal}
          title='Промокоды'
        />

        {typeof promocodes === 'object' ? (
          promocodes.length === 0 ? (
            <HeaderPage
              little
              style={{ opacity: 0.5, justifyContent: 'center' }}
              title='Промокоды не найдены...'
            />
          ) : (
            <Table
              data={promocodes.map(a => (
                <tr key={a._id}>
                  <td>{a.promocode}</td>
                  <td>{a.minimalAmount || '-'}</td>
                  <td>{a.minimalSum || '-'}</td>
                  <td>{a.bonusAllowed ? 'Да' : 'Нет'}</td>
                  <td>{this.getPromocodeType(a.type)}</td>
                  <td>{a.sale || '-'}</td>
                  <td>{this.renderOneTimeUsageCheckbox(a)}</td>
                  <td>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <SvgButton
                        image='edit'
                        onClick={() => this.props.togglePromocodeModal(a)}
                        style={{ marginRight: 20 }}
                      />
                      <SvgButton
                        image='add'
                        onClick={() =>
                          this.props.togglePromocodeProductModal(a)
                        }
                        style={{ marginRight: 20 }}
                      />
                      <Cross
                        noModalCross
                        onClick={this.onDelete(a)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              title={this.title}
            />
          )
        ) : (
          promocodes === 'Обновление' && <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    promocodes: state.promocodes.promocodes
  };
}

export default connect(mapStateToProps, {
  fetchPromocodes,
  togglePromocodeModal,
  togglePromocodeProductModal,
  clearPromocodes,
  deletePromocode,
  updatePromocode
})(PromocodeIndex);
