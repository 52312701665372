import React, { Component } from 'react';
import { connect } from 'react-redux';

import { signin } from '../actions/loginActions';
import { Input, Button } from '../components/elements/elements';
import image from '../images/telepizza.png';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: ''
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSubmit = event => {
    event.preventDefault();

    const data = {
      loginString: this.state.login,
      password: this.state.password
    };

    this.props.signin(data, ({ json }) => {
      if (json.userType === 'accountant') {
        this.props.history.push('/payments-report');
      }
      if (json.userType === 'readCallCentre') {
        this.props.history.push('/searchOrders');
      }
    });
  };

  render() {
    return (
      <div id='login-page'>
        <img
          alt='Авторизация'
          src={image}
        />
        <form onSubmit={this.onSubmit}>
          <Input
            maxlength='20'
            minlength='4'
            name='login'
            onChange={this.onChange}
            placeholder='Ваш логин'
            required
            type='text'
            value={this.state.login}
          />
          <Input
            maxlength='40'
            minlength='6'
            name='password'
            onChange={this.onChange}
            placeholder='Ваш пароль'
            required
            type='password'
            value={this.state.password}
          />
          <Button value='Авторизоваться' />
        </form>
        <p className='volga-volga'>
          developed by <a href='http://vvdev.ru/'>Volga-volga</a>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.login.token
  };
}

export default connect(mapStateToProps, { signin })(Signin);
