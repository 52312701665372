import React from 'react';
import Loader from '../../components/interface/loader';
import { Modal, Button, Form, Table } from 'react-bootstrap';

// eslint-disable-next-line
import DatePicker from 'react-datepicker';
// eslint-disable-next-line
import moment from 'moment';
// eslint-disable-next-line
import 'react-datepicker/dist/react-datepicker.css';
import { FormGroup } from '../elements/elements';
// eslint-disable-next-line
moment.locale('ru');

class OrderModal extends React.Component {
  onCancel = () => {
    this.props.toggleModal();
  };

  getFullSum = product => {
    let cost = product.price;

    if (product.optionalModifiers && product.optionalModifiers.length > 0) {
      for (let i = 0; i < product.optionalModifiers.length; i++) {
        cost +=
          product.optionalModifiers[i].amount *
          product.optionalModifiers[i].price;
      }
    }

    return cost;
  };

  getInfoDate = (state, history) => {
    if (history) {
      for (let i = 0; i < history.length; i++) {
        if (
          history[i].state.toLowerCase().trim() === state.toLowerCase().trim()
        )
          return (
            history[i].date &&
            moment(history[i].date).format('DD.MM.YYYY HH:mm')
          );
      }
    }

    return '-';
  };

  render() {
    return (
      <div>
        <Modal
          dialogClassName='order-info-modal'
          onHide={this.onCancel}
          show={this.props.show}
        >
          <Modal.Body>
            {!this.props.order ? (
              <div className='spinner-wrapper'>
                <Loader />
              </div>
            ) : (
              <Form
                id='campaignForm'
                onSubmit={this.handleSubmit}
              >
                <h3
                  className='text-center page-header'
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  Подробности заказа
                </h3>

                <FormGroup
                  disabled
                  placeholder='Сумма без учета скидок'
                  value={this.props.order.originalSum}
                />
                <FormGroup
                  disabled
                  placeholder='Время приема заказа'
                  value={
                    this.props.order.createdAt
                      ? moment(this.props.order.createdAt).format(
                          'DD.MM.YYYY HH:mm'
                        )
                      : '-'
                  }
                />

                <FormGroup
                  disabled
                  items={this.props.order.couponResult}
                  placeholder='Списанные купоны'
                  type='multipleInputs'
                  value={
                    this.props.order.coupon ? this.props.order.coupon : '-'
                  }
                />

                <Table>
                  <thead>
                    <tr>
                      <th>Товар</th>
                      <th>Параметры</th>
                      <th>Модификаторы</th>
                      <th>Топинги</th>
                      <th>Продукт</th>
                      <th>Сумма</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.order.items.length > 0
                      ? this.props.order.items.map((product, key) => (
                          <tr key={key}>
                            <td>{`${product.amount} x ${product.name}`}</td>
                            <td>
                              {product.additionalInfo
                                ? [
                                    product.additionalInfo.size,
                                    <br key={key} />,
                                    product.additionalInfo.dough
                                  ]
                                : 'Нет'}
                            </td>
                            <td>
                              {product.modifiers.length > 0
                                ? product.modifiers.map((modifier, key) => (
                                    <div key={key}>
                                      {`${modifier.name} x ${modifier.amount}`}
                                    </div>
                                  ))
                                : 'Нет'}
                            </td>
                            <td>
                              {product.optionalModifiers.length > 0
                                ? product.optionalModifiers.map(
                                    (modifier, key) => (
                                      <div key={key}>
                                        {`${modifier.name} ${
                                          modifier.amount
                                        } x ${modifier.price} р.`}
                                      </div>
                                    )
                                  )
                                : 'Нет'}
                            </td>
                            <td>
                              {product.price
                                ? `${product.price} р.`
                                : 'Нет информации'}
                            </td>
                            <td>
                              {product.price
                                ? this.getFullSum(product)
                                : 'Нет информации'}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='danger'
              onClick={this.onCancel}
            >
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default OrderModal;
