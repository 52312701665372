import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from '../components/Select';
import Loader from '../components/interface/loader';
import { HeaderPage, Table } from '../components/elements/elements';
import {
  fetchProducts,
  catalogUpdate,
  fetchCategories,
  fetchMenu,
  clearCatalog,
  fetchRecommended,
  saveRecommended
} from '../actions/productActions';

class OptionalModifiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      categoryName: localStorage.getItem('CatalogCategoryName')
        ? JSON.parse(localStorage.getItem('CatalogCategoryName'))
        : false,
      categoryID: localStorage.getItem('CatalogCategoryID')
        ? JSON.parse(localStorage.getItem('CatalogCategoryID'))
        : false,

      recommended: []
    };
  }

  componentDidMount() {
    if (this.state.categoryID) {
      this.setState({ update: true });
      this.props.fetchMenu(this.state.categoryID, () => {
        this.setState({ update: false });
      });
    }
    this.props.fetchCategories();
    this.props.fetchRecommended();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.recommended.length &&
      this.props.recommended.length &&
      !prevProps.recommended.length
    ) {
      this.setState({ recommended: this.props.recommended });
    }
  }

  componentWillUnmount() {
    this.props.clearCatalog();
  }

  getTitles = () => {
    return ['Название', 'Описание', 'ID', 'Цена', 'Размер'];
  };

  onChangeSelect = (value, name) => {
    this.setState({ update: true });

    this.props.fetchMenu(value, () => {
      this.setState({ categoryID: value, categoryName: name, update: false });
      localStorage.setItem('CatalogCategoryID', JSON.stringify(value));
      localStorage.setItem('CatalogCategoryName', JSON.stringify(name));
    });
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  renderTableRow = (items) =>
    items.map(product => (
      <tr key={product._id}>
        <td>
          <Link
            style={{ textDecoration: 'underline', color: 'blue' }}
            to={`/optional-modifiers/${product._id}`}
          >
            {product.name}
          </Link>
        </td>
        <td>{product.description}</td>
        <td>{product._id}</td>
        <td>{product.price}</td>
        <td>{product.additionalInfo ? product.additionalInfo.size : '-'}</td>
      </tr>
    ));

  renderCategoriesSelect = () => {
    const { update, categoryName } = this.state;
    const { categories } = this.props;

    return (
      !update &&
      categories && (
        <Select
          default='Выберите категорию'
          items={this.getItems()}
          onChange={this.onChangeSelect}
          style={{ marginBottom: 50 }}
          value={categoryName}
        />
      )
    );
  };

  renderProductsTable = () => {
    const { products } = this.props;

    if (!products) return null;

    if (Array.isArray(products)) {
      if (!products.length) {
        return (
          <>
            <HeaderPage
              little
              style={{ opacity: 0.5, justifyContent: 'center' }}
              title={`В «${this.state.categoryName}» товаров нет...`}
            />
          </>
        );
      }
    }

    return (
      <Table
        data={this.renderTableRow(products)}
        title={this.getTitles()}
      />
    );
  };

  renderProductList = () => {
    return (
      <>
        <HeaderPage
          little
          title='Выберите продукт'
        />
        {this.renderCategoriesSelect()}
        {this.renderProductsTable()}
      </>
    );
  };

  render() {
    const { update } = this.state;

    if (update) return <Loader />;

    return (
      <div>
        <HeaderPage
          title='К этому блюду подойдет'
        />
        {this.renderProductList()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.products.categories,
    products: state.products.products,
    recommended: state.products.recommended
  };
}
export default connect(mapStateToProps, {
  clearCatalog,
  fetchProducts,
  catalogUpdate,
  fetchCategories,
  fetchMenu,
  fetchRecommended,
  saveRecommended
})(OptionalModifiers);
