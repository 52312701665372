import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderPage, Table, Button } from '../components/elements/elements';

import { getChangeRequests, handleRequest } from '../actions/usersActions';

class ChangeRequestsPage extends Component {
  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    const isSearch = this.props.location.search !== prevProps.location.search;
    const isChangeType =
      this.props.match.params.type !== prevProps.match.params.type;

    if (isSearch || isChangeType) {
      this.getPageData();
    }
  }

  get requestType() {
    return this.props.match.params.type;
  }

  onHandleRequest = (id, isReject) => {
    return () => {
      this.props.handleRequest(id, this.requestType, isReject);
    };
  };

  getDisplayedRequestType = () => {
    switch (this.requestType) {
      case 'email':
        return 'Email';
      case 'phone':
        return 'Телефона';
      default:
        return '???';
    }
  };

  getPageData = () => {
    this.props.getChangeRequests(this.requestType);
  };

  getTableTitles = () => [
    'Пользователь',
    'Email',
    'Телефон',
    'Новые данные',
    ''
  ];

  renderButtons = ({ _id }) => {
    return (
      <>
        <Button
          little
          onClick={this.onHandleRequest(_id)}
          value='Принять'
        />
        <Button
          little
          onClick={this.onHandleRequest(_id, true)}
          style={{ marginLeft: '20px' }}
          value='Отклонить'
        />
      </>
    );
  };

  renderTableData = () => {
    const { requests } = this.props;
    return requests.requests.map((request, i) => (
      <tr key={i}>
        <td>{`${request.user.firstName || ''} ${request.user.lastName ||
          ''}`}</td>
        <td>{request.user.email}</td>
        <td>{request.user.phone}</td>
        <td>{request[this.requestType]}</td>
        <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {this.renderButtons(request)}
        </td>
      </tr>
    ));
  };

  render() {
    const titleType = this.getDisplayedRequestType();

    return (
      <div className='change-requests'>
        <HeaderPage
          little
          title={`Заявки на изменение ${titleType}`}
        />

        <Table
          data={this.renderTableData()}
          style={{ marginTop: 50 }}
          title={this.getTableTitles()}
        />
      </div>
    );
  }
}

const mapState = state => ({
  requests: state.users.changeRequests
});

const mapDispatch = {
  getChangeRequests,
  handleRequest
};

export default connect(mapState, mapDispatch)(ChangeRequestsPage);
