import React from 'react';
import { connect } from 'react-redux';

import {
  updateCatalog,
  fetchMenu,
  addCampaignProduct,
  removeCampaignProduct
} from '../../../actions/productActions';
import { refreshGroups, fetchGroups } from '../../../actions/groupsActions';
import { Modal, ModalTitle, Form, FormGroup } from '../../elements/elements';

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignsArray: [],
      product: {}
    };
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      if (newState.product.campaigns && newState.product.campaigns.length) {
        this.setState({
          product: newState.product,
          campaignsArray: newState.product.campaigns.map(a => a._id)
        });
      } else {
        this.setState({
          product: newState.product,
          campaignsArray: []
        });
      }
    }
  }

  checkFilter = campaign => {
    const { campaignsArray } = this.state;
    if (campaignsArray && campaignsArray.length) {
      for (let i = 0; i < campaignsArray.length; i++) {
        if (campaign._id === campaignsArray[i]) return true;
      }
    }
    return false;
  };

  onChangeCheckbox = id => {
    const { campaignsArray } = this.state;
    const array = [...campaignsArray];
    let confirm = true;

    if (array && array.length) {
      for (let i = 0; i < array.length; i++) {
        if (id === array[i]) {
          array.splice(i, 1);
          confirm = false;
          break;
        }
      }
    }

    if (confirm) array.push(id);

    this.setState({ campaignsArray: array });
  };

  renderCheckboxCampaign = () => {
    const { campaigns } = this.props;
    const array = [];

    if (campaigns && campaigns.length) {
      campaigns.map((campaign, key) =>
        array.push(
          <FormGroup
            checked={this.checkFilter(campaign)}
            className='checkboxCampaigns'
            id={`campaignCheckbox${key}`}
            key={key}
            name={campaign.title}
            onChange={() => this.onChangeCheckbox(campaign._id)}
            placeholder={campaign.title}
            type='checkbox'
          />
        )
      );

      return (
        <div
          className='product-modal-tags'
          style={{ marginBottom: 30 }}
        >
          {array}
        </div>
      );
    }

    return null;
  };

  refreshPage = (id, update) => {
    this.props.updateCatalog();
    this.props.fetchMenu(id);
    if (!update) this.props.toggleModal();
  };

  onUpdate = () => {
    const { product, campaignsArray } = this.state;
    const productCampaigns = product.campaigns.map(a => a._id);
    const addArray = [];
    const deleteArray = [];

    for (let i = 0; i < campaignsArray.length; i++) {
      if (productCampaigns.indexOf(campaignsArray[i]) === -1) {
        addArray.push(campaignsArray[i]);
      }
    }

    for (let i = 0; i < productCampaigns.length; i++) {
      if (campaignsArray.indexOf(productCampaigns[i]) === -1) {
        deleteArray.push(productCampaigns[i]);
      }
    }

    const addData = { campaigns: [...addArray] };
    const removeData = { campaigns: [...deleteArray] };

    if (addArray && addArray.length)
      this.props.addCampaignProduct(product.id, addData, () =>
        this.refreshPage(
          JSON.parse(localStorage.getItem('CatalogCategoryID')),
          true
        )
      );
    if (deleteArray && deleteArray.length)
      this.props.removeCampaignProduct(product.id, removeData, () =>
        this.refreshPage(
          JSON.parse(localStorage.getItem('CatalogCategoryID')),
          true
        )
      );
    this.props.toggleModal();
  };

  render() {
    const { product } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onUpdate}
          value='Сохранить изменения'
        >
          <ModalTitle
            title={
              product.name ? `Акции «${product.name}»` : 'Незвестный продукт'
            }
          />
          {this.renderCheckboxCampaign()}
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaigns.productCampaigns,
    product: state.modal.modalContent,
    tags: state.products.tags
  };
}

export default connect(
  mapStateToProps,
  {
    removeCampaignProduct,
    refreshGroups,
    fetchGroups,
    addCampaignProduct,
    updateCatalog,
    fetchMenu
  }
)(ModalComponent);
