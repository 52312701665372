import { FETCH_VACANCIES, CLEAR_VACANCIES } from '../actions/vacanciesActions';

const INITIAL_STATE = {
  vacancies: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_VACANCIES:
      return { ...state, vacancies: action.payload };

    case FETCH_VACANCIES:
      return { ...state, vacancies: action.payload };

    default:
      return state;
  }
}
