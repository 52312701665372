import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../interface/loader';
import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  HeaderPage,
  Button
} from '../../elements/elements';
import {
  fetchCouriers,
  clearCouriers,
  createCourier,
  updateCourier,
  fetchCouriersID,
  resetCourierPassword
} from '../../../actions/couriersActions';
import { fetchRestaurants } from '../../../actions/restaurantActions';

import InputMask from 'react-input-mask';

import ReactSelect from 'react-select';
import Select from '../../Select';
// import 'react-select/react-select.css';
import { getQueryString } from '../../elements/functions';

const getDefaultState = () => {
  const state = {
    selectedOption: '',
    courier: {
      firstName: '',
      lastName: '',
      restaurant: '',
      iikoId: '',
      phone: '',
      login: '',
      password: '',
      loc: {
        lat: 0,
        lon: 0
      },
      firebaseIDs: [''],
      yearRating: 0,
      weekRating: 0,
      monthRating: 0
    },
    load: true
  };

  return state;
};

class ModalComponent extends Component {
  state = getDefaultState();

  componentDidMount() {
    this.props.fetchCouriersID(() => this.setState({ load: false }));
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.show && newProps.show) {
      if (newProps.courier) {
        this.setState({ courier: newProps.courier, selectedOption: '' });
      } else {
        this.setState({ ...getDefaultState(), load: false });
      }
    }
  }

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      courier: {
        ...prevState.courier,
        [name]: value
      }
    }));
  };

  onChangeCourierPassword = e => {
    const value = e.target.value;
    this.setState({ courier: { ...this.state.courier, password: value } });
  };

  fetchCouriers() {
    const defaultQuery = {
      page: 1,
      period: 'day'
    };
    const query = {
      ...defaultQuery,
      ...getQueryString()
    };
    this.props.fetchCouriers(null, query);
  }

  refreshPage = () => {
    this.props.clearCouriers();
    this.fetchCouriers();
  };

  getFormattedCourierData = () => {
    // eslint-disable-next-line
    const { history, ...courierInfo } = this.state.courier;
    return {
      courier: {
        ...courierInfo,
        phone: courierInfo.phone.replace(/[^+\d]/gi, '')
      }
    };
  };

  onCreate = () => {
    const data = this.getFormattedCourierData();

    if (data.courier.restaurant && data.courier.iikoId) {
      this.props.createCourier(data, () => {
        this.props.toggleModal();
        this.refreshPage();
      });
    } else if (!data.courier.restaurant) {
      alert('Выберите ресторан');
    } else if (!data.courier.iikoId) {
      alert('Выберите курьера из Iiko');
    }
  };

  onUpdate = () => {
    const data = this.getFormattedCourierData();

    if (data.courier.restaurant && data.courier.iikoId) {
      this.props.updateCourier(data.courier._id, data, () => {
        this.props.toggleModal();
        this.refreshPage();
      });
    } else if (!data.courier.restaurant) {
      alert('Выберите ресторан');
    } else if (!data.courier.iikoId) {
      alert('Выберите курьера из Iiko');
    }
  };

  onChangeRestourant = value => {
    this.setState(prevState => ({
      ...prevState,
      courier: {
        ...prevState.courier,
        restaurant: value
      }
    }));
  };

  onChangeIiko = selectedOption => {
    const { couriersID } = this.props;
    const ind = selectedOption.label.indexOf(' ');
    const firstName = selectedOption.label.substring(ind);
    const lastName = selectedOption.label.substring(0, ind);
    let phone = '';

    if (selectedOption) {
      for (let i = 0; i < couriersID.length; i++) {
        if (selectedOption.value === couriersID[i].id) {
          if (couriersID[i].phone) {
            phone = couriersID[i].phone;
          }
          break;
        }
      }
    }

    this.setState(prevState => ({
      ...prevState,
      selectedOption,
      courier: {
        ...prevState.courier,
        firstName,
        lastName,
        phone,
        iikoId: selectedOption ? selectedOption.value : ''
      }
    }));
  };

  getRestourantName = id => {
    const { restaurants } = this.props;
    if (restaurants) {
      for (let i = 0; i < restaurants.length; i++) {
        if (id === restaurants[i]._id) return restaurants[i].name;
      }
    }
  };

  getRestourantItems = () => {
    const array = [];
    const { restaurants } = this.props;
    if (restaurants) {
      restaurants.map(restourant =>
        array.push({
          value: restourant._id,
          text: restourant.name
        })
      );
    }

    return array;
  };

  returnIikoIdValue = value => {
    const { couriersID } = this.props;

    if (typeof value === 'string') {
      if (value) {
        let restaurantName = false;
        for (let i = 0; i < couriersID.length; i++) {
          if (value === couriersID[i].id) restaurantName = couriersID[i].name;
        }

        return { value, label: restaurantName };
      }
      return '';
    }
    return value;
  };

  getIikoItems = () => {
    const array = [];
    const { couriersID } = this.props;

    if (typeof couriersID === 'object' && couriersID) {
      couriersID.map(courier =>
        array.push({
          value: courier.id,
          label: courier.name
        })
      );
    }

    return array;
  };

  render() {
    const { courier } = this.state;

    if (this.props.couriersID === 'Не найдено')
      return (
        <Modal show={this.props.show} toggleModal={this.props.toggleModal}>
          <HeaderPage
            little
            notFound
            style={{ paddingBottom: 20 }}
            title="Нет данных из Iiko..."
          />
        </Modal>
      );
    if (this.state.load)
      return (
        <Modal show={this.props.show} toggleModal={this.props.toggleModal}>
          <Loader relative />
        </Modal>
      );

    return (
      <Modal show={this.props.show} toggleModal={this.props.toggleModal}>
        <ModalTitle
          title={
            this.props.courier ? 'Подробнее о курьере' : 'Добавить курьера'
          }
        />
        <Form
          direction="column"
          onSubmit={this.props.courier ? this.onUpdate : this.onCreate}
          value={
            this.props.courier ? 'Сохранить изменения' : 'Добавить курьера'
          }
        >
          <Select
            default="Выберите ресторан"
            items={this.getRestourantItems()}
            label="Ресторан:"
            onChange={this.onChangeRestourant}
            value={
              this.props.courier
                ? this.getRestourantName(courier.restaurant)
                : this.getRestourantName(courier.restaurant)
            }
          />

          <div className="form-group">
            <label>Курьер из Iiko:</label>
            <ReactSelect
              className="search-select"
              name="iikoId"
              onChange={this.onChangeIiko}
              options={this.getIikoItems()}
              placeholder="Выберите курьера"
              value={
                this.state.selectedOption
                  ? this.state.selectedOption
                  : this.returnIikoIdValue(courier.iikoId)
              }
            />
          </div>

          <FormGroup
            id="lastName"
            name="lastName"
            onChange={this.changeInput}
            placeholder="Фамилия курьера"
            required
            value={courier.lastName}
          />

          <FormGroup
            id="firstName"
            name="firstName"
            onChange={this.changeInput}
            placeholder="Имя курьера"
            required
            value={courier.firstName}
          />

          <div
            style={{
              display: 'flex'
            }}
          >
            <FormGroup onlyLabel placeholder="Телефон">
              <InputMask
                className="input-element"
                id="phone"
                mask="+7 (999) 999 99 99"
                maskChar=""
                name="phone"
                onChange={this.changeInput}
                placeholder="Телефон"
                value={courier.phone}
              />
            </FormGroup>
            <FormGroup
              checked={courier.phoneConfirm}
              className="direction-row not-clickable"
              placeholder="номер подтвержден"
              type="checkbox"
              value=""
            />
          </div>

          <FormGroup
            id="login"
            name="login"
            onChange={this.changeInput}
            placeholder="Логин"
            required
            value={courier.login}
          />
          <FormGroup
            name="password"
            onChange={this.onChangeCourierPassword}
            placeholder="Пароль"
            value={this.state.courier.password}
          >
            {this.props.courier ? (
              <Button
                className="no-margin"
                onClick={() => {
                  this.props.resetCourierPassword(courier._id, {
                    password: this.state.courier.password
                  });
                }}
                style={{ marginTop: 0 }}
                value="Изменить"
              />
            ) : null}
          </FormGroup>
          <FormGroup
            name="tipLink"
            onChange={this.changeInput}
            placeholder="Ссылка на оплату чаевых"
            value={courier.tipLink}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    couriersID: state.couriers.couriersID,
    courier: state.modal.modalContent,
    restaurants: state.restaurants.restaurants
  };
}

export default connect(mapStateToProps, {
  fetchCouriersID,
  fetchRestaurants,
  fetchCouriers,
  clearCouriers,
  createCourier,
  updateCourier,
  resetCourierPassword
})(ModalComponent);
