import request from '../lib/request';

export const GET_LETTERS = 'GET_LETTERS';
export const CHANGE_LETTER = 'CHANGE_LETTER';

export const getLetters = () => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: GET_LETTERS,
      response
    });
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization: getState().login.token
  };

  await request('/letters', {
    success,
    headers
  });
};

export const changeLetter = (id, body) => async (dispatch, getState) => {
  const success = () => {
    dispatch({
      type: CHANGE_LETTER,
      response: body
    });
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization: getState().login.token
  };

  await request(`/letters/${id}`, {
    body: JSON.stringify(body),
    method: 'PUT',
    success,
    headers
  });
};
