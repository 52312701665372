import { logOut } from './loginActions';

import { API } from './index';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER = 'FETCH_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const CHANGE_STATE = 'CHANGE_STATE';
export const SEARCH_ORDER = 'SEARCH_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const UPDATE_ORDER_TIME = 'UPDATE_ORDER_TIME';

export function clearOrders() {
  return {
    type: CLEAR_ORDERS,
    payload: []
  };
}

export const fetchOrders = (skip, limit, search = '') => async (
  dispatch,
  getState
) => {
  let path = 'orders/manageOrders';
  if (skip || limit || search)
    path = `orders/manageOrders?skip=${skip}&limit=${limit}&search=${search}`;

  const response = await fetch(`${API.url}/${path}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_ORDERS,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateOrderTime = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const path = `/orders/${id}/setState`;

  const response = await fetch(`${API.url}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_ORDER_TIME,
        payload: json
      });

      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const changeState = (id, state, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/orders/${id}/setState`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ state })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 404:
      dispatch({
        type: SEARCH_ORDER,
        payload: 'Не найдено'
      });
      break;

    case 409:
      alert('Заказ со статусом "Закрыт" не может быть изменен');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchOrder = order => async dispatch => {
  dispatch({
    type: FETCH_ORDER,
    payload: order
  });
};

export const clearOrder = () => async dispatch => {
  dispatch({
    type: CLEAR_ORDER,
    payload: false
  });
};

export const searchOrder = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/orders/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  if (callback) await callback();

  switch (status) {
    case 200:
      const json = await response.json();

      dispatch({
        type: SEARCH_ORDER,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 404:
      dispatch({
        type: SEARCH_ORDER,
        payload: 'Не найдено'
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const calculate = (data, callback) => async dispatch => {
  const getModifiers = array => {
    const result = [];

    if (array && array.length) {
      for (let a = 0; a < array.length; a++) {
        for (let i = 0; i < array[a].modifiers.length; i++) {
          if (array[a].modifiers[i].amount) {
            const item = array[a].modifiers[i];
            result.push(item);
          }
        }
      }
    }

    return result;
  };

  const getOptionalModifiers = array => {
    const result = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].amount) {
        const item = array[i];
        delete item.images;
        result.push(item);
      }
    }

    return result;
  };

  const items = data.map(a => {
    return {
      amount: a.amount,
      id: a._id,
      modifiers: {
        modifiers: getModifiers(a.modifiers),
        optionalModifiers: getOptionalModifiers(a.optionalModifiers)
      }
    };
  });

  const response = await fetch(`${API.url}/cart/calculate`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      items,
      coupon: '',
      lat: 1,
      lon: 1,
      address: '',
      bonus: 0
    })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();
      return json;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const calculate2 = (data, callback) => async dispatch => {
  const response = await fetch(`${API.url}/cart/calculate`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      items: data.items,
      coupon: data.coupon,
      lat: 1,
      lon: 1,
      address: '',
      bonus: data.bonus
    })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback(json);
      return json;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const returnBonus = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/orders/${id}/return_bonus`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Бонусные баллы успешно возвращены!');

      if (callback) await callback();
      break;

    case 404:
      alert('Заказ не найден');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 409:
      alert('За заказ не списывались ББ, либо уже возвращались');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const setDeliveredBy = (id, data) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/orders/${id}/delivered_by`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addNewOrder = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/orders/new`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;
  const data2 = await response.json();

  switch (status) {
    case 200:
      if (callback) await callback(data2);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status} ${JSON.stringify(data2)}`);
      break;
  }
};

export const closeOrder = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/orders/setState`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;
  const data2 = await response.json();

  switch (status) {
    case 200:
      if (callback) await callback(data2);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status} ${JSON.stringify(data)}`);
      break;
  }
};
