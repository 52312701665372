import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import {
  fetchUsers,
  clearUsers,
  changeBirthDay
} from '../../../actions/usersActions';
import InputMask from 'react-input-mask';
import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDay: ''
    };
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.clearUsers();
    this.props.fetchUsers(this.props.userData.user, 'all');
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  onSubmit = () => {
    let { birthDay } = this.state;
    const id = this.props.userData.data._id;

    birthDay = moment.utc(birthDay, 'DD.MM.YYYY').toISOString();
    this.props.changeBirthDay(id, birthDay, this.refreshPage);
  };

  render() {
    const { birthDay } = this.state;
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Сохранить изменения'
        >
          <ModalTitle title='Изменить дату рождения' />
          <FormGroup
            onlyLabel
            placeholder='Дата рождения'
          >
            <InputMask
              className='form-control-input'
              mask='99.99.9999'
              name='birthDay'
              onChange={this.changeInput}
              placeholder='Дата рождения'
              required
              type='text'
              value={birthDay}
            />
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.modal.modalContent,
    couponTypes: state.users.couponTypes
  };
}

export default connect(mapStateToProps, {
  fetchUsers,
  clearUsers,
  changeBirthDay
})(ModalComponent);
