import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchSettings,
  clearSettings,
  updateSettings,
  fetchAppBanner,
  updateAppBanner,
  fetchCourierSettings,
  updateCourierSettings
} from '../actions/settingsActions';
import {
  toggleAddCouponsProductModal,
  toggleCouponsProductModal
} from '../actions/modalActions';
import {
  HeaderPage,
  FormGroup,
  Button,
  ImageBlock
} from '../components/elements/elements';
import { uploadImage } from '../actions';
import Loader from '../components/interface/loader';

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        lateDelivery: {},
        expensiveOrder: {},
        sliceSticker: {}
      },
      couriersSettings: {
        bonusesLifePeriod: 0,
        mainPageText: '',
        bannerText: '',
        QR: {
          fns: []
        }
      },
      banners: {
        appBanner: '',
        horizontalTabletBanner: '',
        tabletBanner: ''
      },
      isUploaded: {
        appBanner: false,
        horizontalTabletBanner: false,
        tabletBanner: false
      },
      isLoadingImage: {
        appBanner: false,
        horizontalTabletBanner: false,
        tabletBanner: false
      }
    };
  }

  componentDidMount() {
    this.props.fetchSettings();
    this.props.fetchAppBanner();
    this.props.fetchCourierSettings();
  }

  componentWillUnmount() {
    this.props.clearSettings();
  }

  componentDidUpdate(prevProps) {
    const { courierSettings } = this.props;

    if (prevProps.courierSettings.QR) {
      if (prevProps.courierSettings.QR.fns !== courierSettings.QR.fns)
        for (let i = 0; i < courierSettings.QR.fns.length; i++) {
          this.setState({
            couriersSettings: {
              ...this.state.couriersSettings,
              QR: {
                ...this.state.couriersSettings.QR,
                fns: courierSettings.QR.fns
              }
            }
          });
        }
    }
  }

  componentWillReceiveProps(nexProps) {
    this.setState({
      settings: nexProps.settings,
      banners: nexProps.banners,
      couriersSettings: nexProps.courierSettings
    });
  }

  handleChange = event => {
    const value = event.target.value;
    if (event.target.name) {
      const name = event.target.name;

      this.setState(prevState => ({
        settings: {
          ...prevState.settings,
          [name]: value
        }
      }));
    } else {
      const datatype = event.target.getAttribute('datatype');
      const datagroup = event.target.getAttribute('datagroup');

      this.setState(prevState => ({
        settings: {
          ...prevState.settings,
          [datagroup]: {
            ...prevState.settings[datagroup],
            [datatype]: value
          }
        }
      }));
    }
  };

  handleChangeCourierSettings = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      couriersSettings: {
        ...prevState.couriersSettings,
        [name]: value
      }
    }));
  };

  handleChangeAppBanner = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  uploadImage = bannerProp => event => {
    event.persist();

    this.setState(prevState => ({
      isLoadingImage: {
        ...prevState.isLoadingImage,
        [bannerProp]: true
      }
    }));

    this.props
      .uploadImage('image', event.target.files[0], 'all')
      .then(file => {
        this.setState(prevState => ({
          banners: {
            ...prevState.banners,
            [bannerProp]: file.url
          },
          isUploaded: {
            ...prevState.isUploaded,
            [bannerProp]: true
          },
          isLoadingImage: {
            ...prevState.isLoadingImage,
            [bannerProp]: false
          }
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  deleteImage = bannerProp => () => {
    this.setState(prevState => ({
      banners: {
        ...prevState.banners,
        [bannerProp]: ''
      }
    }));
  };

  renderFNInputs = () => {
    const { fns } = this.state.couriersSettings.QR;

    return fns.map((item, key) => (
      <FormGroup
        datagroup={`fns${key}`}
        datatype={`fns${key}`}
        direction='column'
        id={`fns${key}`}
        key={key}
        onChange={event => this.handleFNChange(key, event)}
        placeholder={`ФН ${key + 1}`}
        value={fns[key]}
      />
    ));
  };

  handleFNChange = (key, event) => {
    const { fns } = this.state.couriersSettings.QR;
    const { value } = event.target;
    const copyFNs = [...fns];

    if (value) copyFNs[key] = value;
    else delete copyFNs[key];
    this.setState({
      couriersSettings: {
        ...this.state.couriersSettings,
        QR: {
          ...this.state.couriersSettings.QR,
          fns: copyFNs
        }
      }
    });
  };

  handleSubmit = () => {
    const { banners } = this.state;
    const bannerBody = {};
    Object.entries(banners).forEach(([key, value]) => {
      bannerBody[key] = value.split('images/')[1];
    });
    const cb = () => {
      this.setState(prevState => {
        const isUploaded = {};
        Object.keys(prevState.isUploaded).forEach(key => {
          isUploaded[key] = false;
        });
        return { isUploaded };
      });
    };

    this.props.updateSettings(this.state.settings);
    this.props.updateAppBanner(JSON.stringify(bannerBody), cb);
    this.props.updateCourierSettings(this.state.couriersSettings);
  };

  render() {
    const { settings } = this.props;
    const { isLoadingImage, isUploaded } = this.state;
    const {
      appBanner,
      horizontalTabletBanner,
      tabletBanner
    } = this.state.banners;
    let sliceSticker;
    let expensiveOrder;

    if (settings) {
      sliceSticker = settings.sliceSticker;
      expensiveOrder = settings.expensiveOrder;
    }

    if (!this.props.settings || this.props.settings.length === 0)
      return <Loader />;

    return (
      <div>
        <HeaderPage
          button='Обновить'
          onClick={this.handleSubmit}
          title='Настройки'
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Период жизни бонусов'
        />
        <FormGroup
          datagroup='bonusesLifePeriod'
          datatype='bonusesLifePeriod'
          direction='column'
          id='bonusesLifePeriod'
          name='bonusesLifePeriod'
          onChange={this.handleChangeCourierSettings}
          placeholder='Период'
          value={this.state.couriersSettings.bonusesLifePeriod}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Редактирование текста "О Telepizza"'
        />
        <FormGroup
          datagroup='mainPageText'
          datatype='mainPageText'
          direction='column'
          id='mainPageText'
          name='mainPageText'
          onChange={this.handleChangeCourierSettings}
          placeholder='Введите текст'
          type='textarea'
          value={this.state.couriersSettings.mainPageText}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Редактирование текста "Доставка"'
        />
        <FormGroup
          datagroup='deliveryText'
          datatype='deliveryText'
          direction='column'
          id='deliveryText'
          name='deliveryText'
          onChange={this.handleChangeCourierSettings}
          placeholder='Введите текст'
          type='textarea'
          value={this.state.couriersSettings.deliveryText}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Купоны за долгую доставку'
        />
        <FormGroup
          datagroup='lateDelivery'
          datatype='minAmount'
          direction='column'
          id='lateDeliverySum'
          onChange={this.handleChange}
          placeholder='Минимальная сумма заказа (руб)'
          value={this.state.settings.lateDelivery.minAmount}
        />
        <FormGroup
          datagroup='lateDelivery'
          datatype='overdueDelivery'
          direction='column'
          id='lateDeliveryTime'
          onChange={this.handleChange}
          placeholder='Долгая доставка (мин)'
          value={this.state.settings.lateDelivery.overdueDelivery}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25,
            paddingTop: 40
          }}
          title='Купоны за стоимость заказа'
        >
          <Button
            onClick={() =>
              this.props.toggleCouponsProductModal({
                data: expensiveOrder,
                type: 'expensiveOrder'
              })
            }
            value='Продукты'
          />
          <Button
            onClick={() =>
              this.props.toggleAddCouponsProductModal({
                data: expensiveOrder,
                type: 'expensiveOrder'
              })
            }
            value='Добавить продукты'
          />
        </HeaderPage>
        <FormGroup
          datagroup='expensiveOrder'
          datatype='minAmount'
          direction='column'
          id='lateDeliverySum'
          onChange={this.handleChange}
          placeholder='Минимальная сумма заказа (руб)'
          value={this.state.settings.expensiveOrder.minAmount}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25,
            paddingTop: 40
          }}
          title='Слайс стикер'
        >
          <Button
            onClick={() =>
              this.props.toggleCouponsProductModal({
                data: sliceSticker,
                type: 'sliceSticker'
              })
            }
            value='Продукты'
          />
          <Button
            onClick={() =>
              this.props.toggleAddCouponsProductModal({
                data: sliceSticker,
                type: 'sliceSticker'
              })
            }
            value='Добавить продукты'
          />
        </HeaderPage>

        <FormGroup
          datagroup='sliceSticker'
          datatype='minAmount'
          direction='column'
          id='lateDeliverySum'
          onChange={this.handleChange}
          placeholder='Минимальная сумма заказа (руб)'
          value={this.state.settings.sliceSticker.minAmount}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25,
            paddingTop: 40
          }}
          title='Редактирование ФН'
        >
          <Button value='Добавить' />
        </HeaderPage>

        {this.renderFNInputs()}

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Редактирование баннеров загрузки приложения'
        />
        <ImageBlock
          front
          image={appBanner}
          loader={isLoadingImage.appBanner}
          name='appBanner'
          onDelete={this.deleteImage('appBanner')}
          onLoad={this.uploadImage('appBanner')}
          placeholder='Мобильная версия'
          withoutServer={!isUploaded.appBanner}
        />
        <ImageBlock
          front
          image={tabletBanner}
          loader={isLoadingImage.tabletBanner}
          name='tabletBanner'
          onDelete={this.deleteImage('tabletBanner')}
          onLoad={this.uploadImage('tabletBanner')}
          placeholder='Планшетная версия'
          withoutServer={!isUploaded.tabletBanner}
        />
        <ImageBlock
          front
          image={horizontalTabletBanner}
          loader={isLoadingImage.horizontalTabletBanner}
          name='horizontalTabletBanner'
          onDelete={this.deleteImage('horizontalTabletBanner')}
          onLoad={this.uploadImage('horizontalTabletBanner')}
          placeholder='Горизонтальная планшетная версия'
          withoutServer={!isUploaded.horizontalTabletBanner}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    settings: state.settings.settings,
    banners: state.settings.banners,
    courierSettings: state.settings.courierSettings
  };
}

export default connect(mapStateToProps, {
  updateCourierSettings,
  fetchCourierSettings,
  fetchSettings,
  updateSettings,
  clearSettings,
  toggleAddCouponsProductModal,
  toggleCouponsProductModal,
  fetchAppBanner,
  updateAppBanner,
  uploadImage
})(SettingsPage);
