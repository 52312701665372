import { API } from './index';
import { logOut } from './loginActions';
import { getQueryParametres } from './functions';
import { COURIERS_LIMIT } from '../lib/consts';

export const FETCH_COURIERS = 'FETCH_COURIERS';
export const FETCH_COURIERS_ID = 'FETCH_COURIERS_ID';
export const CLEAR_COURIERS = 'CLEAR_COURIERS';
export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const CLEAR_COURIERS_REQUESTS = 'CLEAR_COURIERS_REQUESTS';
export const FETCH_COURIER = 'FETCH_COURIER';
export const FETCH_COURIER_ORDERS = 'FETCH_COURIER_ORDERS';
export const CLEAR_COURIER_ORDERS = 'CLEAR_COURIER_ORDERS';
export const FETCH_COURIERS_RATING = 'FETCH_COURIERS_RATING';

export const fetchCouriers = (callback, query = {}) => async (
  dispatch,
  getState
) => {
  const defaultQuery = {
    limit: COURIERS_LIMIT
  };

  const response = await fetch(
    `${API.url}/couriers?${getQueryParametres({ ...query, ...defaultQuery })}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIERS,
        payload: json
      });
      if (callback) await callback(json);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const clearCouriers = callback => async dispatch => {
  dispatch({
    type: CLEAR_COURIERS,
    payload: false
  });
  if (callback) await callback();
};

export const addBlackList = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers/${id}/blackList`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ operation: 'add' })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createRuleBonuses = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/restaurants/${id}/courierBonuses`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;

    case 400:
      alert('Тип зоны указан не верно или не указан');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 409:
      alert('Интервал времени пересекается с существющим');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateRuleBonuses = (id, index, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/restaurants/${id}/courierBonuses/${index}`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;

    case 400:
      alert('Тип зоны указан не верно или не указан');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 409:
      alert('Интервал времени пересекается с существющим');
      break;

    case 404:
      alert('Правило не найдено');
      break;

    case 422:
      alert('Неверный индекс правила');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteRuleBonuses = (id, index, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/restaurants/${id}/courierBonuses/${index}`,
    {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;

    case 400:
      alert('Тип зоны указан не верно или не указан');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 404:
      alert('Правило не найдено');
      break;

    case 422:
      alert('Неверный индекс правила');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const removeBlackList = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers/${id}/blackList`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ operation: 'remove' })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createCourier = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Курьер успешо создан!');
      const json = await response.json();
      alert(`Запомните пароль для курьера - "${json.password}"`);
      if (callback) await callback({ status, json });
      break;

    case 409:
      alert('Курьер с таким логином уже существует!');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCouriersID = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers/from_iiko`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIERS_ID,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 404:
      dispatch({
        type: FETCH_COURIERS_ID,
        payload: 'Не найдено'
      });
      await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateCourier = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/couriers/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteCourier = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchRequests = data => async (dispatch, getState) => {
  const response = await fetch(
    `${API.url}/requests?${getQueryParametres(data)}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_REQUESTS,
        payload: json
      });
      break;

    case 404:
      dispatch({
        type: FETCH_REQUESTS,
        payload: 'Не найдено'
      });
      break;
    case 400:
      alert('Не найден ресторан');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const acceptRequest = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/admin/requests/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) callback();

      if (data.accepted) alert('Заявка принята');
      else alert('Заявка отклонена');

      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 400:
      alert('Не найдена заявка/заказ/курьер');
      break;
    case 409:
      alert('Данная заявка уже принята');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCourier = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/couriers/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIER,
        payload: json
      });
      if (callback) await callback(json);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCourierOrders = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const defaultData = {
    state: 'closed',
    limit: 40,
    page: 1
  };
  const response = await fetch(
    `${API.url}/couriers/${id}/orders?${getQueryParametres(
      data || defaultData
    )}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIER_ORDERS,
        payload: json
      });
      if (callback) await callback(json);
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const clearCouriersRequests = () => dispatch => {
  dispatch({
    type: CLEAR_COURIERS_REQUESTS,
    payload: []
  });
};

export function clearCourierOrders() {
  return {
    type: CLEAR_COURIER_ORDERS,
    payload: []
  };
}

export const editBonuses = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/couriers/orders/${id}/bonus`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCouriersRating = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/couriers/rating?${getQueryParametres(data)}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIERS_RATING,
        payload: json
      });
      if (callback) await callback(json);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const resetCourierPassword = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/couriers/${id}/reset_password`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert(`Пароль сброшен на ${json.password}`);
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const blockCourierApps = (courierId, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/couriers/${courierId}/blocked_apps`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback({ status, json });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCourierStats = (url, filename = 'couriers_stats') => async (dispatch, getState) => {
  if (!url) return;

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: getState().login.token
    }
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};
