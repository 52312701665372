import React, { useCallback } from 'react';
import { FormGroup } from '../elements/elements';

const paymentMethods = [
  {
    name: 'Наличными',
    id: 0
  },
  {
    name: 'Картой курьеру',
    id: 1
  },
  {
    name: 'Картой онлайн',
    id: 2
  },
  {
    name: 'Apple pay',
    id: 3
  },
  {
    name: 'Google pay',
    id: 4
  }
];

const PaymentMethods = ({ activeMethods = [], onChange }) => {
  const handleChange = useCallback(
    id => () => {
      const matchIndex = activeMethods.indexOf(id);

      if (matchIndex !== -1) {
        activeMethods.splice(matchIndex, 1);
      } else {
        activeMethods.push(id);
      }

      onChange(activeMethods.sort());
    },
    [activeMethods]
  );

  return (
    <div className='payment-methods'>
      {paymentMethods.map(item => (
        <FormGroup
          checked={activeMethods.includes(item.id)}
          id={item.id}
          key={item.id}
          name={item.name}
          onChange={handleChange(item.id)}
          placeholder={item.name}
          type='checkbox'
          value=''
        />
      ))}
    </div>
  );
};

export default PaymentMethods;
