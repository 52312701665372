export const fetchDaData = async body => {
  const request = await fetch(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token d3066bc6fd37662979678973a6dc15036398f5f6'
      },
      body: JSON.stringify(body)
    }
  );

  const response = await request.json();

  return response.suggestions;
};
