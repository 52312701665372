import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';

import {
  fetchRecalls,
  clearRecalls,
  fetchRecall,
  publicRecall,
  unpublicRecall,
  deleteRecall
} from '../actions/recallsActions';
import { fetchRestaurants } from '../actions/restaurantActions';

import {
  Table,
  HeaderPage,
  Button,
  SvgButton
} from '../components/elements/elements';
import RecallsModal from '../components/recalls/recallsModal';
import RecallAnswerModal from '../components/recalls/recallAnswerModal';

import moment from 'moment';
import { withRouter } from 'react-router-dom';

class RecallList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      answerShow: false,
      recallID: false
    };
  }

  title = [
    'Статус',
    'Дата',
    'Тема отзыва',
    'Название ресторана',
    'Пользователь',
    'Оценка',
    '',
    '',
    ''
  ];

  componentDidMount() {
    this.props.fetchRecalls();
    const { id } = this.props.match.params;
    this.props.fetchRestaurants();

    if (!this.state.show && id) this.openModal(id);
  }

  openModal = id => {
    this.props.fetchRecall(id);

    if (this.props.match.path === '/recalls/answer/:id') {
      this.setState({
        answerShow: !this.state.answerShow
      });
    } else if (this.props.match.path === '/recalls/:id') {
      this.setState({
        show: !this.state.show
      });
    }
  };

  refresh = () => {
    this.props.fetchRecalls();
  };

  toggleModal = event => {
    if (event) {
      this.props.history.push(`/recalls/${event.target.id}`);
    } else {
      this.setState({
        show: !this.state.show
      });
      this.props.history.push('/recalls');
    }
  };

  toggleAnswerModal = event => {
    if (event) {
      this.props.history.push(`/recalls/answer/${event.target.id}`);
    } else {
      this.setState({
        answerShow: !this.state.answerShow
      });
      this.props.history.push('/recalls');
    }
  };

  deleteRecall = id => {
    this.props.deleteRecall(id, () => {
      this.refresh();
    });
  };

  publicRecall = event => {
    this.props.publicRecall(event.target.id, () => {
      this.refresh();
    });
  };

  unpublicRecall = event => {
    this.props.unpublicRecall(event.target.id, () => {
      this.refresh();
    });
  };

  getRestaurantName = id => {
    const { restaurants } = this.props;

    if (id) {
      for (let i = 0; i < restaurants.length; i++) {
        if (restaurants[i]._id === id) return restaurants[i].name;
      }
    }
    return '-';
  };

  render() {
    if (!this.props.recalls || this.props.recalls.length === 0) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div style={{ overflowX: 'scroll' }}>
        {this.props.recall && this.props.recall.length !== 0 ? (
          <RecallsModal
            recall={this.props.recall}
            refresh={this.refresh}
            show={this.state.show}
            toggleModal={this.toggleModal}
          />
        ) : (
          ''
        )}
        {this.props.recall && this.props.recall.length !== 0 ? (
          <RecallAnswerModal
            recall={this.props.recall}
            refresh={this.refresh}
            show={this.state.answerShow}
            toggleModal={this.toggleAnswerModal}
          />
        ) : (
          ''
        )}

        <HeaderPage title='Отзывы' />

        <Table
          data={
            this.props.recalls
              ? this.props.recalls.map((recall, key) => (
                  <tr key={key}>
                    <td>{recall.reply ? 'Отвечено' : ''}</td>
                    <td>{moment(recall.createdAt).format('DD.MM.YY')}</td>
                    <td>{recall.subject}</td>
                    <td>{this.getRestaurantName(recall.restaurant)}</td>
                    <td>
                      {recall.author.name}
                      <br />
                      {recall.author.email}
                      <br />
                      {recall.author.phone}
                    </td>
                    <td>{recall.mark}</td>
                    {/* <td>{recall.moderated ? 'Да' : 'Нет'}</td> */}
                    <td>
                      <SvgButton
                        image='delete'
                        onClick={() => this.deleteRecall(recall._id)}
                      />
                    </td>
                    <td>
                      <Button
                        id={recall._id}
                        little
                        onClick={this.toggleModal}
                        value='Подробнее'
                      />
                    </td>
                    <td>
                      <Button
                        id={recall._id}
                        little
                        onClick={this.toggleAnswerModal}
                        value='Ответить'
                      />
                    </td>
                    <td>
                      <Button
                        id={recall._id}
                        little
                        onClick={
                          recall.moderated
                            ? this.unpublicRecall
                            : this.publicRecall
                        }
                        value={
                          recall.moderated
                            ? 'Отменить публикацию'
                            : 'Опубликовать'
                        }
                      />
                    </td>
                  </tr>
                ))
              : null
          }
          title={this.title}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    recall: state.recalls.recall,
    recalls: state.recalls.recalls,
    restaurants: state.restaurants.restaurants
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchRecalls,
      clearRecalls,
      fetchRecall,
      publicRecall,
      unpublicRecall,
      deleteRecall,
      fetchRestaurants
    }
  )(RecallList)
);
