import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import { fetchCategories, fetchTags } from '../actions/productActions';
import { HeaderPage, Table, SvgButton } from '../components/elements/elements';
import {
  fetchGroups,
  clearGroups,
  deleteGroup
} from '../actions/groupsActions';
import {
  toggleGroupModal,
  toggleProductModal,
  toggleAddProductsGroupModal
} from '../actions/modalActions';
import Select from '../components/Select';

class GroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      categoryName: localStorage.getItem('GroupCategoryName')
        ? JSON.parse(localStorage.getItem('GroupCategoryName'))
        : false,
      categoryID: localStorage.getItem('GroupCategoryID')
        ? JSON.parse(localStorage.getItem('GroupCategoryID'))
        : false
    };
  }

  componentDidMount() {
    if (this.state.categoryID) {
      this.setState({ update: true });
      this.props.fetchGroups(this.state.categoryID, () => {
        this.setState({ update: false });
      });
    }
    this.props.fetchTags();
    this.props.fetchCategories();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.groups) {
      if (
        localStorage.getItem('GroupCategoryName') &&
        JSON.parse(localStorage.getItem('GroupCategoryName')) !==
          this.state.categoryName
      ) {
        this.setState({
          categoryName: JSON.parse(localStorage.getItem('GroupCategoryName'))
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGroups();
  }

  refreshPage = () => {
    this.props.clearGroups();
    this.props.fetchGroups(this.state.categoryID);
  };

  getTitles = () => {
    return ['Название', 'Описание', '', ''];
  };

  onChangeSelect = (value, name) => {
    this.setState({ update: true });

    this.props.fetchGroups(value, () => {
      this.setState({ categoryID: value, categoryName: name, update: false });
      localStorage.setItem('GroupCategoryID', JSON.stringify(value));
      localStorage.setItem('GroupCategoryName', JSON.stringify(name));
    });
  };

  onDelete = id => {
    this.props.deleteGroup(id, this.refreshPage);
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  render() {
    const { groups } = this.props;

    return (
      <div>
        <HeaderPage
          button='Добавить группу'
          onClick={this.props.toggleGroupModal}
          title='Группы'
        />

        {!this.state.update && this.props.categories && (
          <Select
            default='Выберите категорию'
            items={this.getItems()}
            onChange={this.onChangeSelect}
            style={{ marginBottom: 50 }}
            value={this.state.categoryName && this.state.categoryName}
          />
        )}
        {!this.state.update ? (
          groups ? (
            typeof groups === 'object' ? (
              groups.length === 0 ? (
                <HeaderPage
                  little
                  notFound
                  title={`Ни одной группы в «${
                    this.state.categoryName
                  }» не создано...`}
                />
              ) : (
                <Table
                  data={groups.map((product, key) => (
                    <tr key={key}>
                      <td>{product.name}</td>
                      <td>{product.description}</td>
                      <td>
                        <div style={{ display: 'flex', float: 'right' }}>
                          {!product.singleProduct && (
                            <SvgButton
                              image='add'
                              onClick={() =>
                                this.props.toggleAddProductsGroupModal(product)
                              }
                              style={{ marginRight: 15 }}
                            />
                          )}
                          <SvgButton
                            image='edit'
                            onClick={
                              product.singleProduct
                                ? () => this.props.toggleProductModal(product)
                                : () => this.props.toggleGroupModal(product)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                  title={this.getTitles()}
                />
              )
            ) : (
              groups === 'Обновление' && <Loader />
            )
          ) : (
            groups === 'Обновление' && <Loader />
          )
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.products.categories,
    groups: state.groups.groups
  };
}

export default connect(
  mapStateToProps,
  {
    toggleAddProductsGroupModal,
    toggleProductModal,
    fetchCategories,
    fetchTags,
    fetchGroups,
    clearGroups,
    toggleGroupModal,
    deleteGroup
  }
)(GroupsPage);
