import { FETCH_PUSH_CAMPAIGNS } from '../actions/pushActions';

const INITIAL_STATE = {
  campaigns: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PUSH_CAMPAIGNS:
      return { ...state, campaigns: action.payload };

    default:
      return state;
  }
}
