import { logOut } from './loginActions';

import { API } from './index';

export const FETCH_RECALLS = 'FETCH_RECALLS';
export const FETCH_RECALL = 'FETCH_RECALL';
export const PUBLIC_RECALL = 'PUBLIC_RECALL';
export const DELETE_RECALL = 'DELETE_RECALL';
export const ANSWER_RECALL = 'ANSWER_RECALL';
export const CLEAR_RECALLS = 'CLEAR_RECALLS';
export const CLEAR_RECALL = 'CLEAR_RECALL';
export const UNPUBLIC_RECALL = 'UNPUBLIC_RECALL';

export function clearRecalls() {
  return {
    type: CLEAR_RECALLS,
    payload: []
  };
}

export function clearRecall() {
  return {
    type: CLEAR_RECALL,
    payload: []
  };
}

export const fetchRecalls = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/comments`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_RECALLS,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchRecall = id => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/comments/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_RECALL,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const publicRecall = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/comments/moderate/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify([])
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: PUBLIC_RECALL,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const unpublicRecall = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/comments/demoderate/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify([])
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UNPUBLIC_RECALL,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const answerRecall = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/comments/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: ANSWER_RECALL,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};
export const deleteRecall = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/comments/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      dispatch({
        type: DELETE_RECALL,
        payload: ''
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};
