import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import { fetchBonusLevels } from '../actions/statisticsActions';

import {
  fetchUsers,
  fetchAllUsers,
  fetchCouponTypes,
  startPage,
  clearUsers,
  clearAllUsers,
  addBlackList,
  deleteBlackList,
  defaultPassword,
  editCurrentUser,
  updateUser,
  userConfirm,
  addAddres,
  editAddres,
  deleteAddres,
  resetClientPassword
} from '../actions/usersActions';
import {
  Form,
  FormGroup,
  HeaderPage,
  Button,
  Table,
  SvgButton
} from '../components/elements/elements';

import Modal from '../components/isolatedModal';

import {
  toggleEditBonus,
  toggleChangeBirthdayModal,
  toggleEditOrders,
  toggleChangeLoginModal,
  toggleViewCouponModal,
  toggleHistoryBonus,
  toggleHistoryOrders,
  toggleHistoryCoupons,
  toggleAddCoupon,
  toggleAddOrderModal
} from '../actions/modalActions';
import moment from 'moment';
import Select from '../components/Select';
import AddressModal from '../components/modal/user/addAddressModal';
import CustomAlert from '../components/customAlert';
import OrderGeodata from '../components/OrderGeodata';

const TableAdress = ['Город', 'Улица', 'Дом', 'Корпус', 'Координаты', '', ''];

class PointsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: {
        lastName: '',
        firstName: '',
        middleName: ''
      },
      user: '',
      show: false,
      body: document.getElementsByTagName('body')[0],
      showStatusModal: false,
      showCoupon: false,
      showAllCoupon: false,
      showHistoryModal: false,
      showOrdersModal: false,
      showEditAddressModal: false,
      showAddressModal: false,
      showChangeDataModal: false,
      limitBonus: null,
      limitOrders: null,
      update: true,
      gender: '',
      page: 0
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.fetchCouponTypes(),
      this.props.fetchBonusLevels()
    ]);
    this.setState({ update: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.users.length && this.props.users[this.state.page].user) {
      const { lastName, firstName, middleName, sex } = this.props.users[
        this.state.page
      ].user;
      const isEnablePrevProps = Boolean(
        prevProps.users.length && prevProps.users[this.state.page].user
      );

      const isChangedNames =
        !isEnablePrevProps ||
        lastName !== prevProps.users[prevState.page].user.lastName ||
        firstName !== prevProps.users[prevState.page].user.firstName ||
        middleName !== prevProps.users[prevState.page].user.middleName;

      const isChangedGender =
        !isEnablePrevProps || sex !== prevProps.users[prevState.page].user.sex;

      if (isChangedNames) {
        this.setState({ userName: { lastName, firstName, middleName } });
      }

      if (isChangedGender) {
        this.setState({ gender: sex });
      }
    }
  }

  componentWillUnmount() {
    this.props.startPage();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.currentUser !== newProps.currentUser) {
      this.setState({
        user: newProps.currentUser
      });
    }
  }

  get isAdmin() {
    return this.props.userType === 'admin';
  }

  get isCallCenter() {
    return this.props.userType === 'callCentre';
  }

  get isReadCallCenter() {
    return this.props.userType === 'readCallCentre';
  }

  get isEditable() {
    return this.isAdmin || this.isCallCenter;
  }

  refresh = () => {
    const { limitBonus, limitOrders } = this.state;
    const { currentUser } = this.props;

    this.props.clearUsers();
    this.props.clearAllUsers();
    this.props.fetchAllUsers(currentUser, limitBonus, limitOrders);
    this.props.fetchUsers(currentUser, limitBonus, limitOrders);
  };

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ page: 0 });
    this.props.clearUsers();
    this.props.clearAllUsers();
    this.props.fetchAllUsers(this.state.user, null, null);
    this.props.fetchUsers(
      this.state.user,
      null,
      null,
      (user, limitBonus, limitOrders) => {
        this.setState({
          limitBonus,
          limitOrders
        });
        this.props.editCurrentUser(user);
        this.props.fetchUsers(user, limitBonus, limitOrders);
      }
    );
  };

  defaultPassword = event => {
    this.props.defaultPassword(event.target.id, this.refresh);
  };

  changeBonusSkip = count => {
    this.setState({
      skipBonus: this.state.limitBonus * count
    });
  };

  changeOrderSkip = count => {
    this.setState({
      skipOrders: this.state.limitOrders * count
    });
  };

  toggleChangeDataModal = () => {
    this.setState({
      showChangeDataModal: !this.state.showChangeDataModal
    });
  };

  addBlackList = event => {
    this.props.addBlackList(event.target.id, this.refresh);
  };

  getItemsSelect = () => {
    const { bonusLevels } = this.props;
    return bonusLevels.map(a => ({ value: a.name, text: a.name }));
  };

  removeBlackList = event => {
    this.props.deleteBlackList(event.target.id, this.refresh);
  };

  getReferralUserNumbers() {
    const { referralUsers } = this.props.users[this.state.page];

    if (referralUsers && referralUsers.length) {
      const phonesArray = referralUsers.map(item => item.phone);
      return phonesArray.join(', ');
    }
    return 'Пользователей, зарегистрированных по реферальной ссылке, нет';
  }

  onChangeStatusSelect = name => {
    const currentBonusLevel = this.props.bonusLevels.findIndex(el => {
      return name === el.name;
    });
    this.setState({
      currentStatus: name,
      currentBonusLevel
    });
  };

  toogleStatusModal = () => {
    this.setState({ showStatusModal: !this.state.showStatusModal });
  };

  blockUserRating = async id => {
    await this.props.updateUser(id, { ratingBlocked: true });
    this.refresh();
  };

  unblockUserRating = async id => {
    await this.props.updateUser(id, { ratingBlocked: false });
    this.refresh();
  };

  changeGender = value => () => {
    this.setState({
      gender: value
    });
  };

  submitGender = () => {
    if (this.props.users.length && this.props.users[this.state.page].user) {
      const { _id } = this.props.users[this.state.page].user;
      const data = {
        ...this.props.users[this.state.page].user,
        sex: this.state.gender
      };

      this.props.updateUser(_id, data);
    }
  };

  changeUserName = name => {
    return e => {
      this.setState({
        userName: {
          ...this.state.userName,
          [name]: e.target.value
        }
      });
    };
  };

  submitUserName = () => {
    if (this.props.users.length && this.props.users[this.state.page].user) {
      const { _id } = this.props.users[this.state.page].user;
      const data = {
        ...this.props.users[this.state.page].user,
        ...this.state.userName
      };

      this.props.updateUser(_id, data);
    }
  };

  onHideStatusModal = () => {
    this.setState({ showStatusModal: false });
  };

  onOpenAddressModal = () => {
    this.setState({ showAddressModal: true });
  };

  onHideAddressModal = () => {
    this.setState({ showAddressModal: false });
    document.body.classList.remove('scroll-brake');
  };

  onOpenEditAddressModal = id => {
    this.setState({ showEditAddressModal: true, dataId: id });
  };

  onOpenAlert = () => {
    this.setState({ alertOpen: true });
  };

  choosePage = index => {
    this.setState({ page: index });
  };

  onHideAlert = () => {
    this.setState({ alertOpen: false });
  };

  onHideEditAddressModal = () => {
    this.setState({ showEditAddressModal: false });
    document.body.classList.remove('scroll-brake');
  };

  getAdressData = () => {
    const { currentUser } = this.props;

    const { limitBonus, limitOrders } = this.state;

    const userInfo =
      this.props.users && this.props.users.length
        ? this.props.users[this.state.page].user
        : '';

    return userInfo.addresses.map((item, key) => {
      const order = {
        coords: {
          lat: item.lat || '-',
          lon: item.lon || '-'
        }
      };
      return (
        <tr key={key}>
          <td>{item.city || '-'}</td>
          <td>{item.street || '-'}</td>
          <td>{item.home || '-'}</td>
          <td>{item.housing || '-'}</td>
          <OrderGeodata
            order={order}
            withoutGeoService
          />
          <td>
            {this.isEditable && (
              <SvgButton
                image='edit'
                onClick={() => this.onOpenEditAddressModal(key)}
              />
            )}
          </td>
          <td>
            {this.isEditable && (
              <SvgButton
                image='delete'
                onClick={() =>
                  this.props.deleteAddres(userInfo._id, item._id, () => {
                    this.props.fetchAllUsers(
                      currentUser,
                      limitBonus,
                      limitOrders
                    );
                    this.props.fetchUsers(currentUser, limitBonus, limitOrders);
                  })
                }
              />
            )}
          </td>
        </tr>
      );
    });
  };

  addAddres = data => {
    const { currentUser } = this.props;

    const { limitBonus, limitOrders } = this.state;
    const userInfo = this.props.users.length
      ? this.props.users[this.state.page].user
      : '';
    this.props.addAddres(userInfo._id, data, () => {
      this.onHideAddressModal();
      this.props.fetchAllUsers(currentUser, limitBonus, limitOrders);
      this.props.fetchUsers(currentUser, limitBonus, limitOrders);
    });
  };

  editAddres = (data, addressId) => {
    const userInfo = this.props.users.length
      ? this.props.users[this.state.page].user
      : '';
    const { currentUser } = this.props;
    const { limitBonus, limitOrders } = this.state;

    this.props.editAddres(userInfo._id, data, addressId, () => {
      this.onHideEditAddressModal();
      this.props.fetchAllUsers(currentUser, limitBonus, limitOrders);
      this.props.fetchUsers(currentUser, limitBonus, limitOrders);
    });
  };

  getEditAddressData = () => {
    const { dataId } = this.state;
    const userInfo = this.props.users.length
      ? this.props.users[this.state.page].user
      : '';

    if (userInfo) {
      return { ...this.getAdressData(), ...userInfo.addresses[dataId] };
    }
    return {};
  };

  getAddressState = () => ({
    city: '',
    street: '',
    home: '',
    housing: '',
    lat: '',
    lon: '',
    streetType: '',
    apartment: '',
    doorphone: '',
    floor: '',
    entrance: ''
  });

  getSubscribeButtonValue = name => {
    const { user } = this.props.users[this.state.page];
    const { sendMailTypes = [] } = user;

    return sendMailTypes.includes(name) ? 'Отписать' : 'Подписать';
  };

  toggleUserSubscribe = name => {
    return () => {
      const { user } = this.props.users[this.state.page];
      const sendMailTypes = [...(user.sendMailTypes || [])];

      const matchId = sendMailTypes.indexOf(name);

      if (matchId !== -1) sendMailTypes.splice(matchId, 1);
      else sendMailTypes.push(name);

      this.props.updateUser(user._id, {
        ...user,
        sendMailTypes
      });
      this.refresh();
    };
  };

  confirmUser(phone) {
    return () => {
      const cb = () => {
        const { limitBonus, limitOrders } = this.state;
        const { currentUser } = this.props;
        this.props.fetchAllUsers(currentUser, limitBonus, limitOrders);
        this.props.fetchUsers(currentUser, limitBonus, limitOrders);
      };
      this.props.userConfirm(JSON.stringify({ phone }), cb);
    };
  }

  renderConfirmedUser() {
    const { phone, confirm } = this.props.users[this.state.page].user;
    const label = 'Подтверждение пользователя: ';

    const renderContent = () => {
      if (typeof confirm === 'boolean' && confirm) {
        return <div className='confirmed'>подтвержден</div>;
      }
      return (
        <Button
          little
          onClick={this.confirmUser(phone)}
          value='Подтвердить'
        />
      );
    };

    return (
      <div className='users-confirmed'>
        {label}
        {renderContent()}
      </div>
    );
  }

  changeUserStatus() {
    return async () => {
      const { user } = this.props.users[this.state.page];
      const { currentBonusLevel } = this.state;

      await this.props.updateUser(user._id, {
        ...user,
        bonusLevel: currentBonusLevel
      });
      this.toogleStatusModal();
      this.refresh();
    };
  }

  render() {
    const { currentUser } = this.props;

    const { update } = this.state;

    const userInfo = this.props.users.length
      ? this.props.users[this.state.page].user
      : '';

    const currentUserId =
      this.props.users[this.state.page] &&
      this.props.users[this.state.page].user &&
      this.props.users[this.state.page].user._id
        ? this.props.users[this.state.page].user._id
        : 'id';

    if (update) return <Loader />;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomAlert show={this.state.alertOpen}>
            <div className='title'>Пароль сброшен на ***</div>
            <div className='title'>Выслать уведомление?</div>
            <div>
              <Button
                onClick={() =>
                  this.props.resetClientPassword(
                    userInfo._id,
                    {
                      sendMail: false,
                      sendSms: true
                    },
                    this.onHideAlert
                  )
                }
                style={{ marginRight: '10px' }}
                value='СМС'
              />
              <Button
                onClick={() =>
                  this.props.resetClientPassword(
                    userInfo._id,
                    {
                      sendMail: true,
                      sendSms: false
                    },
                    this.onHideAlert
                  )
                }
                value='Email'
              />
            </div>
          </CustomAlert>
        </div>
        <Modal
          onHide={this.onHideStatusModal}
          show={this.state.showStatusModal}
        >
          {userInfo && this.isEditable ? (
            <div className='status-modal'>
              <HeaderPage title='Изменить статус' />
              <Select
                items={this.getItemsSelect()}
                onChange={this.onChangeStatusSelect}
              />
              <Button
                onClick={this.changeUserStatus()}
                value='Сохранить изменения'
              />
            </div>
          ) : null}
        </Modal>
        <Modal
          onHide={this.onHideAddressModal}
          show={this.state.showAddressModal}
        >
          <AddressModal
            data={this.getAddressState()}
            method='POST'
            onSubmit={this.addAddres}
            value='Сохранить'
          />
        </Modal>
        <Modal
          onHide={this.onHideEditAddressModal}
          show={this.state.showEditAddressModal}
        >
          <AddressModal
            data={this.getEditAddressData()}
            method='PUT'
            onEditSubmit={this.editAddres}
            value='Сохранить'
          />
        </Modal>
        <HeaderPage title='Пользователи' />
        <Form
          direction='row'
          onSubmit={this.handleSubmit}
          style={{ marginBottom: 60 }}
          value='Найти'
        >
          <FormGroup
            id='searchUser'
            name='user'
            onChange={this.handleChange}
            placeholder='Email или телефон'
            required
            value={this.state.user}
          />
        </Form>

        {!this.props.user ? null : this.props.user &&
          this.props.user === 'Не найдено' ? (
          <HeaderPage
            little
            notFound
            title={`Пользователь ${currentUser} не найден...`}
          />
        ) : this.props.user.length !== 0 && this.props.users.length !== 0 ? (
          <div>
            {this.props.users.map((user, index) => (
              <Button
                onClick={() => this.choosePage(index)}
                style={
                  index === this.state.page
                    ? { background: 'green', margin: '7px' }
                    : { margin: '7px' }
                }
                value={index + 1}
              />
            ))}

            <HeaderPage
              little
              style={{
                paddingBottom: 20,
                borderBottom: `${1}px solid #00000030`,
                marginBottom: 30
              }}
              title={currentUser}
            >
              {this.isEditable && (
                <Button
                  id={currentUserId}
                  onClick={() =>
                    this.props.toggleChangeLoginModal(
                      this.props.users[this.state.page].user
                    )
                  }
                  value='Изменить логин'
                />
              )}
              {this.isEditable && (
                <Button
                  id={currentUserId}
                  onClick={this.onOpenAlert}
                  value='Сбросить пароль'
                />
              )}
              {this.isEditable && (
                <>
                  <Button
                    noForm
                    onClick={
                      userInfo.ratingBlocked
                        ? () =>
                            this.unblockUserRating(
                              this.props.users[this.state.page].user._id
                            )
                        : () =>
                            this.blockUserRating(
                              this.props.users[this.state.page].user._id
                            )
                    }
                    value={
                      userInfo.ratingBlocked
                        ? 'Разблокировать отзывы '
                        : 'Заблокировать отзывы'
                    }
                  />
                  <Button
                    id={currentUserId}
                    onClick={
                      this.props.users[this.state.page].user.blacklisted
                        ? this.removeBlackList
                        : this.addBlackList
                    }
                    value={
                      this.props.users[this.state.page].user.blacklisted
                        ? 'Убрать из черного списка'
                        : 'Добавить в черный список'
                    }
                  />
                </>
              )}
            </HeaderPage>

            <Form
              className='users-user-name'
              hideButton={!this.isEditable}
              onSubmit={this.isEditable && this.submitUserName}
              value='Изменить'
            >
              <FormGroup
                onChange={this.changeUserName('lastName')}
                placeholder='Фамилия'
                readOnly={!this.isEditable}
                value={this.state.userName.lastName}
              />
              <FormGroup
                onChange={this.changeUserName('firstName')}
                placeholder='Имя'
                readOnly={!this.isEditable}
                value={this.state.userName.firstName}
              />
              <FormGroup
                onChange={this.changeUserName('middleName')}
                placeholder='Отчество'
                readOnly={!this.isEditable}
                value={this.state.userName.middleName}
              />
            </Form>

            {this.isEditable && (
              <>
                <div className='users-subscribe'>
                  Рекламные уведомления:
                  <Button
                    little
                    onClick={this.toggleUserSubscribe('adv')}
                    value={this.getSubscribeButtonValue('adv')}
                  />
                </div>

                <div className='users-subscribe'>
                  Информационные письма:
                  <Button
                    little
                    onClick={this.toggleUserSubscribe('info')}
                    value={this.getSubscribeButtonValue('info')}
                  />
                </div>
              </>
            )}

            {this.renderConfirmedUser()}

            <FormGroup
              disabled
              placeholder='Телефон'
              value={this.props.users[this.state.page].user.phone}
            />
            <FormGroup
              disabled
              placeholder='Почта'
              value={this.props.users[this.state.page].user.email}
            />
            <FormGroup
              onlyLabel
              placeholder='Пол'
            >
              {this.isEditable ? (
                <>
                  <FormGroup
                    checked={this.state.gender === 'M'}
                    onChange={this.changeGender('M')}
                    placeholder='Мужской'
                    type='radio'
                  />
                  <FormGroup
                    checked={this.state.gender === 'F'}
                    onChange={this.changeGender('F')}
                    placeholder='Женский'
                    type='radio'
                  />
                </>
              ) : (
                <>
                  <FormGroup
                    checked={this.state.gender === 'M'}
                    disabled
                    onChange={this.changeGender('M')}
                    placeholder='Мужской'
                    type='radio'
                  />
                  <FormGroup
                    checked={this.state.gender === 'F'}
                    disabled
                    onChange={this.changeGender('F')}
                    placeholder='Женский'
                    type='radio'
                  />
                </>
              )}
              {this.isEditable && (
                <Button
                  id={currentUserId}
                  onClick={this.submitGender}
                  value='Изменить'
                />
              )}
            </FormGroup>
            <FormGroup
              disabled
              placeholder='Дата рождения'
              value={
                this.props.users[this.state.page].user.birthDay
                  ? moment(
                      this.props.users[this.state.page].user.birthDay
                    ).format('DD.MM.YYYY')
                  : 'Нет данных'
              }
            >
              {this.isEditable && (
                <Button
                  id={currentUserId}
                  onClick={() =>
                    this.props.toggleChangeBirthdayModal({
                      data: this.props.users[this.state.page].user,
                      user: currentUser
                    })
                  }
                  value='Изменить'
                />
              )}
            </FormGroup>

            <FormGroup
              disabled
              placeholder='Статус'
              value={this.props.users[this.state.page].user.levelInfo.name}
            >
              {this.isEditable && (
                <Button
                  onClick={this.toogleStatusModal}
                  value='Изменить'
                />
              )}
            </FormGroup>
            {this.isEditable ? (
              <Form
                onSubmit={() =>
                  this.props.toggleEditBonus({
                    data: this.props.users[this.state.page].user,
                    user: currentUser
                  })
                }
                style={{ marginBottom: 20 }}
                value='Изменить'
              >
                <FormGroup
                  disabled
                  placeholder='Баллов'
                  value={`${
                    this.props.users[this.state.page].user.bonus
                  } баллов`}
                />
              </Form>
            ) : (
              <FormGroup
                disabled
                placeholder='Баллов'
                value={`${this.props.users[this.state.page].user.bonus} баллов`}
              />
            )}

            <Form
              onSubmit={() =>
                this.props.toggleHistoryBonus({
                  data: this.props.users[this.state.page].bonusHistory,
                  user: currentUser,
                  limits: {
                    limitBonus: this.state.limitBonus,
                    limitOrders: this.state.limitOrders,
                    skipBonus: this.state.skipBonus,
                    skipOrders: this.state.skipOrders,
                    changeSkip: this.changeBonusSkip
                  }
                })
              }
              style={{ marginBottom: 20 }}
              value='История'
            >
              <FormGroup
                disabled
                placeholder='История начислений'
                value={`Количество изменений: ${
                  this.props.users[this.state.page].bonusHistory.count
                }`}
              />
            </Form>

            <FormGroup
              disabled
              placeholder='Заказы'
              value={`${
                this.props.users[this.state.page].orderHistory.orders.filter(
                  order => order.state === 'Закрыта' && order.countedForStatus
                ).length
              } / ${
                this.props.users[this.state.page].orderHistory.orders.filter(
                  order => order.state === 'Закрыта'
                ).length
              } / ${this.props.users[this.state.page].orderHistory.count}`}
            >
              {this.isAdmin && (
                <Button
                  id={currentUserId}
                  onClick={() =>
                    this.props.toggleEditOrders({
                      data: this.props.users[this.state.page].user,
                      user: currentUser,
                      orders: this.props.users[this.state.page].orderHistory
                    })
                  }
                  value='Информация'
                />
              )}
              <Button
                id={currentUserId}
                onClick={() =>
                  this.props.toggleHistoryOrders({
                    data: this.props.users[this.state.page].orderHistory,
                    user: currentUser,
                    limits: {
                      limitBonus: this.state.limitBonus,
                      limitOrders: this.state.limitOrders,
                      skipBonus: this.state.skipBonus,
                      skipOrders: this.state.skipOrders,
                      changeSkip: this.changeBonusSkip
                    }
                  })
                }
                value='Заказы'
              />
            </FormGroup>

            <FormGroup
              disabled
              placeholder='Купоны'
              value={`Количество купонов: ${
                this.props.users[this.state.page].coupons.length
              }`}
            >
              <Button
                id={currentUserId}
                onClick={() =>
                  this.props.toggleHistoryCoupons({
                    coupons: this.props.users[this.state.page].couponHistory,
                    user: currentUser,
                    id: this.props.users[this.state.page].user._id
                  })
                }
                value='История'
              />
              <Button
                id={currentUserId}
                onClick={() =>
                  this.props.toggleViewCouponModal({
                    coupons: this.props.users[this.state.page].coupons,
                    user: currentUser,
                    id: this.props.users[this.state.page].user._id
                  })
                }
                value='Текущие'
              />
              {this.isEditable && (
                <Button
                  id={currentUserId}
                  onClick={() =>
                    this.props.toggleAddCoupon({
                      data: this.props.users[this.state.page].user,
                      user: currentUser
                    })
                  }
                  value='Добавить'
                />
              )}
            </FormGroup>

            <FormGroup
              disabled
              placeholder='Рефералы'
              value={this.getReferralUserNumbers()}
            />
            <Button
              id={currentUserId}
              onClick={() =>
                this.props.toggleAddOrderModal({
                  data: this.props.users[this.state.page].user,
                  user: currentUser
                })
              }
              value='Добавить заказ'
            />
            <HeaderPage
              little
              title='Адреса пользователя'
            >
              {this.isEditable && (
                <Button
                  onClick={this.onOpenAddressModal}
                  value='Добавить'
                />
              )}
            </HeaderPage>
            <Table
              data={this.getAdressData()}
              title={TableAdress}
            />
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.users.user,
    users: state.users.users,
    userType: state.login.type,
    currentUser: state.users.currentUser,
    bonusLevels: state.statistics.bonusLevels
  };
}

export default connect(mapStateToProps, {
  toggleEditBonus,
  fetchBonusLevels,
  fetchCouponTypes,
  editCurrentUser,
  toggleChangeLoginModal,
  toggleChangeBirthdayModal,
  toggleEditOrders,
  toggleViewCouponModal,
  toggleHistoryBonus,
  toggleHistoryOrders,
  toggleHistoryCoupons,
  toggleAddCoupon,
  toggleAddOrderModal,
  startPage,
  fetchUsers,
  fetchAllUsers,
  clearUsers,
  clearAllUsers,
  addBlackList,
  deleteBlackList,
  defaultPassword,
  updateUser,
  userConfirm,
  addAddres,
  editAddres,
  deleteAddres,
  resetClientPassword
})(PointsPage);
