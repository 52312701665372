import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  changeData,
  editCurrentUser
} from '../../../actions/usersActions';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import InputMask from 'react-input-mask';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        phone: '',
        email: ''
      },
      props: {}
    };
  }

  refreshPage = user => {
    this.props.toggleModal();
    this.props.clearUsers();
    this.props.editCurrentUser(user);
    this.props.fetchUsers(user.toLowerCase(), 'all');
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  };

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.setState({
        data: {
          phone: '',
          email: ''
        },
        props: {
          phone: newState.user.phone,
          email: newState.user.email
        }
      });
    }
  }

  onSubmit = event => {
    event.preventDefault();
    let body;
    const { data } = this.state;
    const id = this.props.user._id;

    const phone = data.phone.replace(/[()\s]/g, '');

    if (data.email === '') {
      body = {
        phone
      };
    } else if (phone === '') {
      body = {
        email: data.email
      };
    } else {
      body = {
        email: data.email,
        phone
      };
    }
    if (data.email === '')
      this.props.changeData(id, body, () => this.refreshPage(phone));
    else this.props.changeData(id, body, () => this.refreshPage(data.email));
  };

  render() {
    const { data, props } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Сохранить изменения'
        >
          <ModalTitle title='Изменить логин' />
          <FormGroup
            id='email'
            name='email'
            onChange={this.changeInput}
            placeholder={props.email}
            placeholderLabel='Почта'
            value={data.email}
          />
          <FormGroup
            onlyLabel
            placeholder='Телефон'
          >
            <InputMask
              className='input-element'
              id='phone'
              mask='+7 (999) 999 99 99'
              maskChar=''
              name='phone'
              onChange={this.changeInput}
              placeholder={props.phone}
              value={data.phone}
            />
          </FormGroup>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.modal.modalContent
  };
}

export default connect(
  mapStateToProps,
  { fetchUsers, clearUsers, changeData, editCurrentUser }
)(ModalComponent);
