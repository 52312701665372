import {
  TOGGLE_CAMPAIGN_MODAL,
  TOGGLE_CAMPAIGN_INDEX_MODAL,
  TOGGLE_VACANCIES_MODAL,
  TOGGLE_PRODUCT_MODAL,
  TOGGLE_GROUP_MODAL,
  TOGGLE_VIEW_COUPON_MODAL,
  TOGGLE_ADD_COUPON_MODAL,
  TOGGLE_EDIT_BONUS_MODAL,
  TOGGLE_HISTORY_BONUS_MODAL,
  TOGGLE_HISTORY_COUPONS_MODAL,
  TOGGLE_HISTORY_ORDERS_MODAL,
  TOGGLE_COURIERS_MODAL,
  TOGGLE_PROMOCODE_MODAL,
  TOGGLE_CHANGE_LOGIN_PRODUCT,
  TOGGLE_PROMOCODE_PRODUCT_MODAL,
  TOGGLE_ORDERS_MODAL,
  TOGGLE_EDIT_ORDERS,
  TOGGLE_ADD_COUPONS_PRODUCT,
  TOGGLE_COUPONS_PRODUCT,
  TOGGLE_ADD_PRODUCTS_GROUP,
  TOGGLE_PRODUCT_CAMPAIGN_MODAL,
  TOGGLE_DELIVERY_ZONES_MODAL,
  TOGGLE_RULE_BONUSES_MODAL,
  TOGGLE_CHANGE_BIRTHDAY_MODAL,
  TOGGLE_COUPON_MODAL,
  TOGGLE_ADD_ORDER_MODAL
} from '../actions/modalActions';

const INITIAL_STATE = {
  campaignModal: false,
  campaignIndexModal: false,
  vacanciesModal: false,
  productModal: false,
  groupModal: false,
  addProductsGroupModal: false,
  changeBirthdayModal: false,

  couponModal: false,
  addCouponModal: false,
  editBonusModal: false,
  historyBonusModal: false,
  historyOrdersModal: false,
  historyCouponsModal: false,
  viewCouponsModal: false,
  productCampaignModal: false,

  couriersModal: false,
  promocodeProductModal: false,
  promocodeModal: false,
  changeLoginModal: false,
  ordersModal: false,
  editOrdersModal: false,

  ruleBonusesModal: false,

  addCouponProductModal: false,
  couponProductModal: false,

  modalContent: false,
  deliveryZones: false,
  addOrderModal: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_RULE_BONUSES_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.ruleBonusesModal)
        return {
          ...state,
          ruleBonusesModal: true,
          modalContent: action.payload
        };
      return { ...state, ruleBonusesModal: false };

    case TOGGLE_CAMPAIGN_INDEX_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.campaignIndexModal)
        return {
          ...state,
          campaignIndexModal: true,
          modalContent: action.payload
        };
      return { ...state, campaignIndexModal: false };

    case TOGGLE_CHANGE_BIRTHDAY_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.changeBirthdayModal)
        return {
          ...state,
          changeBirthdayModal: true,
          modalContent: action.payload
        };
      return { ...state, changeBirthdayModal: false };

    case TOGGLE_PRODUCT_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.productModal)
        return { ...state, productModal: true, modalContent: action.payload };
      return { ...state, productModal: false };

    case TOGGLE_CAMPAIGN_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.campaignModal)
        return { ...state, campaignModal: true, modalContent: action.payload };
      return { ...state, campaignModal: false };

    case TOGGLE_VACANCIES_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.vacanciesModal)
        return { ...state, vacanciesModal: true, modalContent: action.payload };
      return { ...state, vacanciesModal: false };

    case TOGGLE_GROUP_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.groupModal)
        return { ...state, groupModal: true, modalContent: action.payload };
      return { ...state, groupModal: false };

    case TOGGLE_VIEW_COUPON_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.viewCouponsModal)
        return {
          ...state,
          viewCouponsModal: true,
          modalContent: action.payload
        };
      return { ...state, viewCouponsModal: false };

    case TOGGLE_COURIERS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.couriersModal)
        return { ...state, couriersModal: true, modalContent: action.payload };
      return { ...state, couriersModal: false };

    case TOGGLE_ADD_PRODUCTS_GROUP:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.addProductsGroupModal)
        return {
          ...state,
          addProductsGroupModal: true,
          modalContent: action.payload
        };
      return { ...state, addProductsGroupModal: false };

    case TOGGLE_DELIVERY_ZONES_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.deliveryZones)
        return { ...state, deliveryZones: true, modalContent: action.payload };
      return { ...state, deliveryZones: false };

    case TOGGLE_PRODUCT_CAMPAIGN_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.productCampaignModal)
        return {
          ...state,
          productCampaignModal: true,
          modalContent: action.payload
        };
      return { ...state, productCampaignModal: false };

    case TOGGLE_CHANGE_LOGIN_PRODUCT:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.changeLoginModal)
        return {
          ...state,
          changeLoginModal: true,
          modalContent: action.payload
        };
      return { ...state, changeLoginModal: false };

    case TOGGLE_ADD_COUPONS_PRODUCT:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.addCouponProductModal)
        return {
          ...state,
          addCouponProductModal: true,
          modalContent: action.payload
        };
      return { ...state, addCouponProductModal: false };

    case TOGGLE_COUPONS_PRODUCT:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.couponProductModal)
        return {
          ...state,
          couponProductModal: true,
          modalContent: action.payload
        };
      return { ...state, couponProductModal: false };

    case TOGGLE_ADD_COUPON_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.addCouponModal)
        return { ...state, addCouponModal: true, modalContent: action.payload };
      return { ...state, addCouponModal: false };

    case TOGGLE_HISTORY_BONUS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.historyBonusModal)
        return {
          ...state,
          historyBonusModal: true,
          modalContent: action.payload
        };
      return { ...state, historyBonusModal: false };

    case TOGGLE_HISTORY_COUPONS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.historyCouponsModal)
        return {
          ...state,
          historyCouponsModal: true,
          modalContent: action.payload
        };
      return { ...state, historyCouponsModal: false };

    case TOGGLE_PROMOCODE_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.promocodeModal)
        return { ...state, promocodeModal: true, modalContent: action.payload };
      return { ...state, promocodeModal: false };

    case TOGGLE_PROMOCODE_PRODUCT_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.promocodeProductModal)
        return {
          ...state,
          promocodeProductModal: true,
          modalContent: action.payload
        };
      return { ...state, promocodeProductModal: false };

    case TOGGLE_ORDERS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.ordersModal)
        return { ...state, ordersModal: true, modalContent: action.payload };
      return { ...state, ordersModal: false };

    case TOGGLE_EDIT_ORDERS:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.editOrdersModal)
        return {
          ...state,
          editOrdersModal: true,
          modalContent: action.payload
        };
      return { ...state, editOrdersModal: false };

    case TOGGLE_EDIT_BONUS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.editBonusModal)
        return { ...state, editBonusModal: true, modalContent: action.payload };
      return { ...state, editBonusModal: false };

    case TOGGLE_HISTORY_ORDERS_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.historyOrdersModal)
        return {
          ...state,
          historyOrdersModal: true,
          modalContent: action.payload
        };
      return { ...state, historyOrdersModal: false };

    case TOGGLE_COUPON_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.couponModal)
        return {
          ...state,
          couponModal: true,
          modalContent: action.payload
        };
      return { ...state, couponModal: false };

    case TOGGLE_ADD_ORDER_MODAL:
      document.documentElement.classList.toggle('document-modal-open');
      if (!state.addOrderModal)
        return {
          ...state,
          addOrderModal: true,
          modalContent: action.payload
        };
      return { ...state, addOrderModal: false };
    default:
      return state;
  }
}
