import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  fetchCampaigns,
  clearCampaigns,
  deleteCampaing
} from '../actions/campaignsActions';
import {
  toggleCampaignModal,
  toggleCampaignIndexModal
} from '../actions/modalActions';
import { Button } from 'react-bootstrap';

import {
  HeaderPage,
  Cross,
  SvgButton,
  Table
} from '../components/elements/elements';

import moment from 'moment';

class CampaignsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showCreateModal: false
    };
  }

  title = ['Название', 'Дата окончания', ''];

  componentDidMount() {
    this.props.fetchCampaigns();
  }

  componentWillUnmount() {
    this.props.clearCampaigns();
  }

  refresh = () => {
    this.props.clearCampaigns();
    this.props.fetchCampaigns();
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  toggleCreateModal = () => {
    this.props.toggleCampaignModal(false);
  };

  toggleIndexModal = () => {
    this.props.toggleCampaignIndexModal(false);
  };

  onDelete = id => {
    this.props.deleteCampaing(id, () => {
      this.refresh();
    });
  };

  render() {
    if (this.props.campaigns.length === 0) {
      return [
        <Button
          className='btn pull-right btn-success'
          key='1'
          onClick={this.toggleCreateModal}
        >
          Добавить
        </Button>,
        <Loader key='2' />
      ];
    }
    return (
      <div>
        <HeaderPage
          button='Добавить акцию'
          onClick={this.toggleCreateModal}
          title='Акции'
        />
        {this.props.campaigns ? (
          <Table
            data={
              this.props.campaigns &&
              this.props.campaigns.map((campaign, key) => (
                <tr key={key}>
                  <td>{campaign.title}</td>
                  <td>
                    {campaign.expireDate == null
                      ? 'Бессрочная'
                      : moment(campaign.expireDate)
                          .locale('ru')
                          .format('DD.MM.YYYY')}
                  </td>
                  <td>
                    <div>
                      <Cross onClick={() => this.onDelete(campaign._id)} />
                      <SvgButton
                        image='info'
                        onClick={() =>
                          this.props.toggleCampaignIndexModal(campaign)
                        }
                        style={{ margin: '0 20px' }}
                      />
                      <SvgButton
                        image='edit'
                        onClick={() => this.props.toggleCampaignModal(campaign)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            }
            title={this.title}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaigns.campaigns
  };
}

export default connect(mapStateToProps, {
  deleteCampaing,
  toggleCampaignModal,
  toggleCampaignIndexModal,
  fetchCampaigns,
  clearCampaigns
})(CampaignsIndex);
