import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  fetchOrders,
  clearOrders,
  changeState,
  fetchOrder,
  searchOrder,
  returnBonus
} from '../actions/ordersActions';
import { FormControl } from 'react-bootstrap';

import {
  HeaderPage,
  Table,
  SvgButton,
  Button,
  Input,
  FormGroup
} from '../components/elements/elements';
import OrdersModal from '../components/orders/ordersModal';
import BonusModal from '../components/isolatedModal';
import OrdersModalTime from '../components/orders/ordersModalTime';

import moment from 'moment';

moment.locale('ru');

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showTime: false,
      showBonus: false,
      limitOrders: 10,
      skipOrders: 0,
      currentOrder: {},
      reason: '',
      search: '',
      ordersState: {}
    };
  }

  title = [
    '№',
    'Дата',
    'Клиент',
    'Тип оплаты',
    'Сумма без учета скидок',
    'Сумма с учетом скидок',
    'Потраченные бонусные баллы',
    'Статус заказа',
    '',
    ''
  ];

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount() {
    this.props.fetchOrders(
      this.state.skipOrders,
      this.state.limitOrders,
      this.state.search
    );
  }

  changeOrderState = event => {
    const { ordersState } = this.state;
    ordersState[event.target.dataset.id] = event.target.value;

    this.props.changeState(event.target.dataset.id, event.target.value, () =>
      this.setState({ ordersState })
    );
  };

  refresh = () => {
    this.props.clearOrders();
    this.props.fetchOrders(
      this.state.skipOrders,
      this.state.limitOrders,
      this.state.search
    );
  };

  onChangeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  createOrdersPaginations = buttons => {
    const component = [];
    for (let i = 0; i < buttons; i++)
      component.push(
        <div
          className={
            Math.floor(this.state.skipOrders / this.state.limitOrders) === i
              ? 'pagination-button active'
              : 'pagination-button'
          }
          data-count={i}
          key={i}
          onClick={this.orderPagination}
        >
          {i + 1}
        </div>
      );
    return <div>{component}</div>;
  };

  toggleModal = () => {
    this.setState({
      show: !this.state.show
    });
  };

  toggleModalTime = () => {
    this.setState({
      showTime: !this.state.showTime
    });
  };

  toggleModalBonus = () => {
    this.setState({
      showBonus: true
    });
  };

  onHideModalBonus = () => {
    this.setState({
      showBonus: false
    });
    document.body.className = '';
  };

  openOrderInfo = order => {
    this.props.fetchOrder(order);
    this.toggleModal();
  };

  openTimeInfo = order => {
    this.toggleModalTime();
    this.props.fetchOrder(order);
  };

  openBonusInfo = order => {
    this.toggleModalBonus();
    this.props.fetchOrder(order);
  };

  onSubmitReturnBonus = () => {
    const { reason } = this.state;
    const { order } = this.props;

    const data = {
      reason
    };

    this.props.returnBonus(order._id, data, () => {
      this.onHideModalBonus();
      this.props.fetchOrders(
        this.state.skipOrders,
        this.state.limitOrders,
        this.state.search
      );
    });
  };

  orderPagination = event => {
    event.persist();

    this.setState({
      skipOrders: this.state.limitOrders * event.target.dataset.count
    });
    this.props.clearOrders();
    this.props.fetchOrders(
      this.state.limitOrders * event.target.dataset.count,
      this.state.limitOrders,
      this.state.search
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.searchOrder(this.state.currentOrder);
  };

  paymentMethod = payment => {
    switch (parseInt(payment, 0)) {
      case 0:
        return 'Наличными';
      case 1:
        return 'Картой курьеру';
      case 2:
        return 'Картой онлайн';
      case 3:
        return 'Apple Pay';
      case 4:
        return 'Google Pay';
      default:
        return payment;
    }
  };

  getUserInfo = order => {
    if (order.user)
      return (
        <td className='client-info-order'>
          Авторизован <br /> {order.user.email} <br />{' '}
          {order.userName ? order.userName[0] : null}{' '}
          {order.userName ? order.userName[1] : null} <br /> {order.userPhone}
        </td>
      );
    return (
      <td className='client-info-order'>
        Не авторизован <br /> {order.userPhone}
      </td>
    );
  };

  changeSearch = event => {
    this.setState({ search: event.target.value });
  };

  handleSearchKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const { skipOrders, limitOrders, search } = this.state;
    this.props.fetchOrders(skipOrders, limitOrders, search);
  };

  renderSearch = () => {
    return (
      <div className='orders__search'>
        <FormGroup
          name='search'
          onChange={this.changeSearch}
          onKeyDown={this.handleSearchKeyDown}
          placeholder='Поиск'
          value={this.state.search}
        />
        <Button
          onClick={this.handleSearch}
          value='Поиск'
        />
      </div>
    );
  };

  render() {
    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <BonusModal
          onHide={this.onHideModalBonus}
          show={this.state.showBonus}
        >
          {this.props.order ? (
            <React.Fragment>
              <Input
                disabled
                value={`Возврат бонусных баллов за заказ #${this.props.order._id}`}
              />
              <Button
                little
                onClick={this.onSubmitReturnBonus}
                style={{ marginTop: '30px' }}
                value='Вернуть бонусные баллы'
              />
            </React.Fragment>
          ) : null}
        </BonusModal>
        {this.props.order ? (
          <OrdersModal
            order={this.props.order}
            show={this.state.show}
            toggleModal={this.toggleModal}
          />
        ) : null}
        {this.props.order ? (
          <OrdersModalTime
            order={this.props.order}
            refresh={this.refresh}
            show={this.state.showTime}
            toggleModal={this.toggleModalTime}
          />
        ) : null}

        <HeaderPage title='Текущие заказы' />

        {this.renderSearch()}

        <Table
          data={
            this.props.orders.orders.length > 0
              ? this.props.orders.orders.map((order, key) => (
                  <tr key={key}>
                    <td>{order._id}</td>
                    <td>
                      {moment(order.createdAt)
                        .locale('ru')
                        .format('DD.MM.YY')}
                      <br />
                      {moment(order.createdAt)
                        .locale('ru')
                        .format('HH:mm')}
                    </td>
                    {this.getUserInfo(order)}
                    <td>{this.paymentMethod(order.paymentMethod)}</td>
                    <td>{order.originalSum}</td>
                    <td>{order.sum}</td>
                    <td>{order.bonus}</td>
                    <td className='order-select'>
                      <FormControl
                        componentClass='select'
                        data-id={order._id}
                        onChange={this.changeOrderState}
                        value={this.state.ordersState[order._id] || order.state}
                      >
                        <option
                          disabled
                          key='999'
                          value={
                            this.state.ordersState[order._id] || order.state
                          }
                        >
                          {this.state.ordersState[order._id] || order.state}
                        </option>
                        {this.props.orders.states
                          ? this.props.orders.states.map((states, key) => (
                              <option
                                key={key}
                                value={states}
                              >
                                {states}
                              </option>
                            ))
                          : null}
                      </FormControl>
                    </td>
                    <td>
                      <Button
                        className={`${
                          order.bonusReturned || order.bonus === 0
                            ? 'disabled'
                            : ''
                        }`}
                        little
                        onClick={() => this.openBonusInfo(order)}
                        value={`${
                          order.bonusReturned ? 'Уже возвращены' : 'Вернуть'
                        }`}
                      />
                    </td>
                    <td>
                      <SvgButton
                        image='time'
                        onClick={() => this.openTimeInfo(order)}
                      />
                    </td>
                    <td>
                      <SvgButton
                        image='info'
                        onClick={() => this.openOrderInfo(order)}
                      />
                    </td>
                    {/* <td>
                        <SvgButton
                          image='return'
                          onClick={() => this.openBonusInfo(order)}
                        />
                      </td> */}
                  </tr>
                ))
              : null
          }
          title={this.title}
        />
        {this.props.orders.count > this.state.limitOrders
          ? this.createOrdersPaginations(
              this.props.orders.count / this.state.limitOrders
            )
          : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.orders.orders,
    order: state.orders.order
  };
}

export default connect(mapStateToProps, {
  fetchOrders,
  clearOrders,
  changeState,
  fetchOrder,
  searchOrder,
  returnBonus
})(Orders);
