import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  changeState,
  fetchOrder,
  searchOrder,
  clearOrder,
  calculate,
  fetchOrders,
  returnBonus
} from '../actions/ordersActions';
import { HeaderPage, Form, FormGroup } from '../components/elements/elements';
import Select from '../components/Select';
import { toggleOrderModal } from '../actions/modalActions';

import moment from 'moment';

moment.locale('ru');

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showTime: false,
      limitOrders: 10,
      skipOrders: 0,

      order: '',
      currentOrder: '',
      reason: ''
    };
  }

  componentDidMount() {
    this.props.fetchOrders();
  }

  componentWillUnmount() {
    this.props.clearOrder();
  }

  refreshPage = () => {
    this.props.clearOrder();
    this.props.searchOrder(this.state.currentOrder);
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  toggleModal = () => {
    this.setState({
      show: !this.state.show
    });
  };

  toggleModalTime = () => {
    this.setState({
      showTime: !this.state.showTime
    });
  };

  openTimeInfo = order => {
    this.toggleModalTime();
    this.props.fetchOrder(order);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.searchOrder(this.state.order, () => {
      this.setState(prevState => ({
        ...prevState,
        currentOrder: this.state.order
      }));
    });
  };

  onChangeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  onSubmitReturnBonus = id => {
    const data = {
      reason: `Возврат бонусных баллов за заказ #${id}`
    };

    this.props.returnBonus(id, data, this.refreshPage);
  };

  getItems = () => {
    let states = false;
    if (this.props.orders) states = this.props.orders.states;

    if (states) {
      const items = states.map(a => {
        return { value: a, text: a };
      });
      return items;
    }
    return { value: 'Нет данных', text: 'Нет данных' };
  };

  checkConfirm = value => {
    return `Сменить статус заказа №${this.props.search._id} на "${value}"`;
  };

  onSelect = value => {
    // let confirm = window.confirm(
    //   `Сменить статус заказа №${  this.props.search._id  } на "${  value  }"`
    // );
    this.props.changeState(this.props.search._id, value, this.refreshPage);
  };

  paymentMethod = payment => {
    switch (parseInt(payment, 0)) {
      case 0:
        return 'Наличными';
      case 1:
        return 'Картой курьеру';
      case 2:
        return 'Картой онлайн';
      case 3:
        return 'Apple Pay';
      case 4:
        return 'Google Pay';
      default:
        return payment;
    }
  };

  get isCallCenter() {
    return this.props.userType === 'callCentre';
  }

  get isReadCallCenter() {
    return this.props.userType === 'readCallCentre';
  }

  get isEditable() {
    return !this.isReadCallCenter;
  }

  render() {
    return (
      <div>
        <HeaderPage title='Поиск заказа' />
        <Form
          onSubmit={this.handleSubmit}
          style={{ marginBottom: 60 }}
          value='Найти'
        >
          <FormGroup
            id='searchOrder'
            name='order'
            onChange={this.handleChange}
            placeholder='ID заказа'
            required
            value={this.state.order}
          />
        </Form>

        {!this.props.search ? null : this.props.search.length !== 0 ? (
          this.props.search === 'Не найдено' ? (
            <HeaderPage
              little
              notFound
              title={`Заказ ${this.state.currentOrder} не найден...`}
            />
          ) : (
            <div>
              <HeaderPage
                little
                style={{
                  paddingBottom: 20,
                  borderBottom: `${1}px solid #00000030`,
                  marginBottom: 30
                }}
                title={`Заказ №${this.props.search._id}`}
              >
                <p style={{ opacity: 0.5 }}>{this.props.search.device}</p>
              </HeaderPage>

              <Form
                onSubmit={() => this.props.toggleOrderModal(this.props.search)}
                style={{ marginBottom: 20 }}
                value='Подробнее'
              >
                <FormGroup
                  disabled
                  placeholder='Заказ'
                  value={`Заказано товаров: ${this.props.search.items.length}`}
                />
              </Form>
              <FormGroup
                disabled
                placeholder='Дата'
                value={moment(this.props.search.createdAt)
                  .locale('ru')
                  .format('DD.MM.YY HH:mm')}
              />
              <FormGroup
                disabled
                placeholder='Статус'
                value={this.props.search.state}
              >
                {this.isEditable ? (
                  <Select
                    confirm={this.checkConfirm}
                    default='Изменить статус'
                    isConfirm
                    items={this.getItems()}
                    onChange={this.onSelect}
                  />
                ) : null}
              </FormGroup>
              <FormGroup
                disabled
                placeholder='Авторизация на сайте'
                value={
                  this.props.search.user ? 'Авторизован' : 'Не авторизован'
                }
              />
              <FormGroup
                disabled
                placeholder='ФИО'
                value={`${this.props.search.userName[1]} ${this.props.search.userName[0]}`}
              />
              <FormGroup
                disabled
                placeholder='Телефон'
                value={this.props.search.userPhone}
              />
              <FormGroup
                disabled
                placeholder='Сумма заказа'
                value={this.props.search.originalSum}
              />
              <FormGroup
                disabled
                placeholder='Оплачено баллами'
                value={this.props.search.bonus}
              />
              <FormGroup
                disabled
                placeholder='К оплате'
                value={this.props.search.sum}
              />
              <FormGroup
                disabled
                placeholder='Метод оплаты'
                value={this.paymentMethod(this.props.search.paymentMethod)}
              />
              <Form
                button={`${
                  this.props.search.bonusReturned ||
                  this.props.search.bonus === 0 ||
                  !this.isEditable
                    ? 'disabled'
                    : ''
                }`}
                onSubmit={() => this.onSubmitReturnBonus(this.props.search._id)}
                style={{ marginBottom: 20 }}
                value={`${
                  this.props.search.bonusReturned ? 'Уже возвращены' : 'Вернуть'
                }`}
              >
                <FormGroup
                  disabled
                  onChange={this.onChangeInput}
                  placeholder='Вернуть баллы'
                  value={this.props.search.bonus}
                />
              </Form>
            </div>
          )
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    search: state.orders.search,
    order: state.orders.order,
    orders: state.orders.orders,
    userType: state.login.type
  };
}

export default connect(mapStateToProps, {
  changeState,
  fetchOrders,
  calculate,
  toggleOrderModal,
  clearOrder,
  fetchOrder,
  searchOrder,
  returnBonus
})(Orders);
