import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteCoupon,
  fetchUsers,
  clearUsers
} from '../../../actions/usersActions';
import {
  Modal,
  ModalTitle,
  Table,
  HeaderPage,
  PaginationBlock
} from '../../elements/elements';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parametres: {
        page: 1
      }
    };
  }

  count = 15;

  title = [
    'Заказ',
    'Дата',
    'Статус',
    'Устройство',
    'Количество',
    'Оплата',
    'Сумма заказа'
  ];

  refreshPage = () => {
    this.props.clearUsers();
    this.props.fetchUsers(this.props.coupons.user, 'all');
  };

  getArrayData = (coupons, page, count) => {
    const array = [];

    coupons.forEach((a, index) => {
      if (index >= count * (page - 1) && index < count * page) {
        array.push(a);
      }
    });

    return array;
  };

  createPaginations = count => {
    const array = [];
    const { parametres } = this.state;

    for (let i = 0; i < count; i++) {
      array.push(
        <div
          className={this.renderPaginationClass(
            parseInt(parametres.page, 10) === parseInt(i + 1, 10)
          )}
          data-count={i}
          key={i}
          onClick={event =>
            this.refreshPagination(event.currentTarget.innerText)
          }
        >
          {i + 1}
        </div>
      );
    }

    return <PaginationBlock items={array} />;
  };

  renderPaginationClass = active => {
    let className = 'pagination-block-element';
    if (active) className += ' active';

    return className;
  };

  refreshPagination = number => {
    this.setState({
      parametres: {
        page: number
      }
    });
  };

  paymentMethod = payment => {
    switch (parseInt(payment, 0)) {
      case 0:
        return 'Наличными';
      case 1:
        return 'Картой курьеру';
      case 2:
        return 'Картой онлайн';
      default:
        return payment;
    }
  };

  getWordEnd = (count, word) => {
    const response = word;
    if (count === 0) return `${response}ов`;
    else if (count === 1) return response;
    else if (count > 1 && count <= 4) return `${response}а`;
    else if (count > 4) return `${response}ов`;
    return response;
  };

  render() {
    let data = this.props.data.data;
    if (data) data = data.orders;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        {data && data.length > 0 ? (
          [
            <ModalTitle
              key='modal-table'
              title='Заказы пользователя'
            />,
            <Table
              data={this.getArrayData(
                data,
                this.state.parametres.page,
                this.count
              ).map((order, key) => (
                <tr key={key}>
                  <td>{order._id}</td>
                  <td>
                    {order.createdAt &&
                      moment(order.createdAt).format('DD.MM.YYYY')}
                  </td>
                  <td>{order.state}</td>
                  <td>{order.device}</td>
                  <td>{`${order.items.length} ${this.getWordEnd(
                    order.items.length,
                    'продукт'
                  )}`}</td>
                  <td>{this.paymentMethod(order.paymentMethod)}</td>
                  <td>{order.sum}</td>
                  {/* <td>{this.couponQuantity(coupon)}</td> */}
                  {/* <td>{coupon.expireDate ? 'до ' + moment(coupon.expireDate).locale('ru').format('DD.MM.YYYY') : 'Бессрочный'}</td> */}
                  {/* <td><Cross noModalCross onClick={() => this.deleteCoupon(coupon._id)} /></td> */}
                </tr>
              ))}
              key='order-table'
              title={this.title}
            />,
            <div key='order-pagination'>
              {data.length > this.count
                ? this.createPaginations(data.length / this.count)
                : null}
            </div>
          ]
        ) : (
          <HeaderPage
            little
            notFound
            style={{ paddingBottom: 20 }}
            title='У пользователя нет купонов...'
          />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  deleteCoupon,
  fetchUsers,
  clearUsers
})(ModalComponent);
