import React, { Component, useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getQueryString, pushHistory } from '../components/elements/functions';

import Loader from '../components/interface/loader';
import {
  fetchCouriers,
  clearCouriers,
  removeBlackList,
  addBlackList,
  deleteCourier,
  fetchCourier,
  fetchCouriersRating,
  resetCourierPassword,
  blockCourierApps,
  updateCourier
} from '../actions/couriersActions';
import { toggleCouriersModal } from '../actions/modalActions';
import { fetchRestaurants } from '../actions/restaurantActions';

import {
  HeaderPage,
  Table,
  SvgButton,
  Button,
  Cross,
  FormGroup
} from '../components/elements/elements';
import Pagination from '../components/Pagination';
import Select from '../components/Select';
import { googleKey } from '../actions/index';
import courierIcon from '../images/courierIcon.png';
import Modal from '../components/isolatedModal';
import moment from 'moment';
import 'moment/locale/ru';
import Datepicker from 'react-datepicker';
import carOfflineIcon from '../images/carOffline.png';

import * as clipboard from 'clipboard-polyfill';
import { COURIERS_LIMIT } from '../lib/consts';

const periodSelectItems = [
  {
    value: 'day',
    text: 'День'
  },
  {
    value: 'week',
    text: 'Неделя'
  },
  {
    value: 'month',
    text: 'Месяц'
  },
  {
    value: 'year',
    text: 'Год'
  },
  {
    value: 'all',
    text: 'Общий'
  },
  {
    value: 'custom',
    text: 'Выберите период'
  }
];

const ratingTitle = ['Внешность', 'Опрятность', 'Скорость', 'Кол-во оценок'];

const CourierPhone = ({ phone, onSave }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(phone);
  }, [phone]);

  return (
    <div className='courier-phone'>
      <InputMask
        className='input-element'
        mask='+7 (999) 999 99 99'
        maskChar=''
        onChange={e => setValue(e.target.value)}
        placeholder='Укажите телефон'
        value={value}
      />
      <span
        className='save-btn'
        onClick={() => onSave(value.replace(/[\s)(]/gm, ''))}
      >
        Сохранить
      </span>
    </div>
  );
};

const loadGoogleApi = onload => {
  const apiUrl = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;

  if (!document.querySelectorAll(`[src="${apiUrl}"]`).length) {
    document.body.appendChild(
      Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        src: apiUrl,
        onload
      })
    );
  } else {
    onload();
  }
};

const createMarker = (position, title, active) => {
  const icon = {
    url: active ? courierIcon : carOfflineIcon,
    scaledSize: new window.google.maps.Size(15, 30)
  };

  const marker = new window.google.maps.Marker({
    position,
    title,
    icon
  });

  return marker;
};

const Map = ({ show, couriers, selectedCourier, apiLoaded }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);

  useEffect(() => {
    if (mapRef && mapRef.current && apiLoaded) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: {
          lat: 59.939095,
          lng: 30.315868
        },
        zoom: 10,
        disableDefaultUI: true,
        zoomControl: true
      });

      setMap(map);

      setInfoWindow(
        new window.google.maps.InfoWindow({ disableAutoPan: true })
      );
    }
  }, [mapRef, apiLoaded]);

  useEffect(() => {
    if (!map || !infoWindow || !couriers.length) return;

    const markers = [];

    couriers.forEach(item => {
      if (selectedCourier && item._id !== selectedCourier) return;

      if (!item.loc) return;

      const coords = {
        lat: item.loc.lat,
        lng: item.loc.lon
      };
      const title = `${item.lastName} ${item.firstName.trim()}\n${moment(
        item.locUpdated
      ).format('HH:mm')}`;

      const marker = createMarker(coords, title, item.active);

      window.google.maps.event.addListener(marker, 'click', e => {
        infoWindow.setContent(title);
        infoWindow.setPosition(e.latLng);
        infoWindow.open(map);
      });

      marker.setMap(map);

      markers.push(marker);
    });

    return () => {
      markers.forEach(item => item.setMap(null));
    };
  }, [map, couriers, selectedCourier, infoWindow]);

  return (
    <div
      ref={mapRef}
      style={{
        width: '100%',
        marginBottom: '20px',
        height: show ? 400 : 0
      }}
    />
  );
};

class Couriers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapApiLoaded: false,
      ymaps: true,
      update: true,
      interval: false,
      view: '',
      limitOrders: 15,
      custom: false,
      selectedCourier: '',
      period: 'day',
      periodText: '',
      periodValue: 'day',
      from: '',
      to: '',
      begin: '',
      end: '',
      currentCourier: '',
      list: {
        count: 20,
        history: []
      },
      parametres: {
        page: 1,
        count: 15,
        slices: false
      },
      show: false,
      categoryName: localStorage.getItem('CatalogCategoryName')
        ? JSON.parse(localStorage.getItem('CatalogCategoryName'))
        : false,
      categoryID: localStorage.getItem('CatalogCategoryID')
        ? JSON.parse(localStorage.getItem('CatalogCategoryID'))
        : false,
      restaurant: {
        name: '',
        value: ''
      },
      openedFinishedOrders: []
    };
  }

  pushHistory = pushHistory.bind(this);

  componentDidMount() {
    this.props.fetchRestaurants();
    this.fetchWithQuery();

    loadGoogleApi(() => this.setState({ mapApiLoaded: true }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { search: prevSearch } = prevProps.location;
    const { search } = this.props.location;
    if (getQueryString('page', search) !== getQueryString('page', prevSearch)) {
      this.fetchWithQuery();
    }
  }

  toogleModal = async id => {
    await this.props.fetchCourier(id);
    await this.props.fetchCouriersRating({ courierId: id });

    this.setState({
      show: true,
      currentCourier: id,
      period: 'day',
      begin: '',
      end: ''
    });
  };

  onChangeModalShow = () => {
    this.setState({ show: false });
  };

  acceptCustomPeriod = () => {
    const { begin, end, currentCourier } = this.state;

    const beginToString = moment(begin, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();
    const endToString = moment(end, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();

    const data = {
      courierId: currentCourier,
      from: beginToString,
      to: endToString
    };

    this.props.fetchCouriersRating(data);
  };

  componentWillUnmount() {
    const { interval } = this.state;
    this.props.clearCouriers();
    if (interval) {
      clearInterval(interval);
    }
  }

  refreshPage = () => {
    this.setState({ update: true });
    this.fetchWithQuery();
  };

  getRestaurantName = id => {
    const { restaurants } = this.props;
    if (restaurants) {
      for (let i = 0; i < restaurants.length; i++) {
        if (id === restaurants[i]._id) {
          return restaurants[i].name;
        }
      }
    }
  };

  getClassNameCourierName = courier => {
    if (
      courier &&
      courier.courierAppsNames &&
      courier.courierAppsNames.length
    ) {
      return 'courier-name nameUsedApps';
    }

    return 'courier-name';
  };

  getRatingTable = () => {
    const { period } = this.state;
    const { courierRating } = this.props;

    if (period === 'day') {
      return courierRating.dayRating;
    }
    if (period === 'week') {
      return courierRating.weekRating;
    }
    if (period === 'month') {
      return courierRating.monthRating;
    }
    if (period === 'year') {
      return courierRating.yearRating;
    }
    if (period === 'all') {
      return courierRating.rating;
    }
    if (period === 'custom') {
      return courierRating.periodRating;
    }
  };

  renderPeriodInputs = () => {
    const { period } = this.state;

    if (period === 'custom') {
      return (
        <React.Fragment>
          <input
            className='input-element'
            name='begin'
            onChange={this.onChangeInput}
            placeholder='от'
            style={{ width: '30%' }}
            type='date'
            value={this.state.begin}
          />
          <input
            className='input-element'
            name='end'
            onChange={this.onChangeInput}
            placeholder='до'
            style={{
              margin: '0 20px',
              width: '30%'
            }}
            type='date'
            value={this.state.end}
          />
          <Button
            little
            onClick={this.acceptCustomPeriod}
            value='Потвердить'
          />
        </React.Fragment>
      );
    }
  };

  onChangeInput = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  deleteCourier = courier => {
    const a = window.confirm('Вы уверены, что хотите удалить курьера');
    if (a) {
      this.props.deleteCourier(courier._id, this.refreshPage);
    }
  };

  getTitles = () => {
    const items = ['ФИО', 'Ресторан'];

    if (['callCentre', 'regionalManager'].includes(this.props.userType)) {
      items.push('Телефон');
    }

    if (['admin', 'regionalManager'].includes(this.props.userType)) {
      items.push(
        ...[
          'Бонус',
          'Рейтинг',
          'Скорость',
          'Заказы',
          'Закрыты через айку',
          'Закрытые через курьерку',
          'Остальные',
          'Ссылка на оплату чаевых'
        ]
      );

      if (this.props.userType !== 'regionalManager') {
        items.push(...['Черный список', '', '', '']);
      }
    }

    return items;
  };

  onChangePeriodSelect = value => {
    this.setState({ period: value });
  };

  toggleMapInterval = () => {
    const { interval, mapOpen } = this.state;

    if (mapOpen) {
      clearInterval(interval);
      this.setState({
        interval: null,
        mapOpen: false
      });
    } else {
      this.setState({
        interval: setInterval(() => {
          if (!this.state.update) {
            this.fetchWithQuery(true);
          }
        }, 30 * 1000),
        mapOpen: true
      });
    }
  };

  // filters

  selectCourier = courierId => {
    this.setState({
      selectedCourier: courierId
    });
  };

  getPeriodFilterItems = () => [
    {
      text: 'День',
      value: 'day'
    },
    {
      text: 'Неделя',
      value: 'week'
    },
    {
      text: 'Месяц',
      value: 'month'
    },
    {
      text: 'Произвольный период',
      value: 'custom'
    }
  ];

  filterPeriod = (period, text) => {
    this.props.history.push('');

    if (period === 'custom') {
      return this.setState({
        custom: true,
        periodValue: '',
        periodText: text
      });
    }
    this.setState(
      {
        periodText: text,
        periodValue: period,
        custom: false,
        from: '',
        to: ''
      },
      this.fetchWithQuery
    );
  };

  filterDate = name => date => {
    this.setState({ [name]: date });
  };

  onSearch = () => {
    this.fetchWithQuery();
  };

  fetchWithQuery = async (disableUpdate = false) => {
    const { periodValue, from, to, search, restaurant } = this.state;

    const query = {
      page: getQueryString('page') || 1
    };

    if (search) {
      query.find = search;
      query.page = 1;
    }

    if (restaurant.value) {
      query.restaurantId = restaurant.value;
      query.page = 1;
    }

    if (periodValue) {
      query.period = periodValue;
    }
    if (from) {
      query.from = moment(from)
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0
        })
        .toISOString();
    }
    if (to) {
      query.to = moment(to)
        .set({
          hours: 23,
          minutes: 59,
          seconds: 0
        })
        .toISOString();
    }

    if (!disableUpdate) {
      this.setState({ update: true });
    }

    this.pushHistory({
      ...query,
      restaurantId: restaurant.value || '',
      period: periodValue || '',
      find: search || '',
      from: from || '',
      to: to || ''
    });

    if (Object.keys(query).length) {
      await this.props.fetchCouriers(null, query);
    } else {
      await this.props.fetchCouriers(null);
    }

    this.setState({ update: false, openedFinishedOrders: [] });
  };

  // render

  handleChangeSearch = e => this.setState({ search: e.target.value });

  handleKeyDownSearch = e => {
    if (e.key === 'Enter') {
      this.fetchWithQuery();
    }
  };

  renderFilters = () => {
    const { search } = this.state;

    return (
      <div className='courier-filters'>
        <div className='courier-filters__search'>
          <FormGroup
            labelHidden
            onChange={this.handleChangeSearch}
            onKeyDown={this.handleKeyDownSearch}
            placeholder='Поиск'
            value={search}
          />
          <Button
            onClick={this.onSearch}
            value='Поиск'
          />
        </div>

        {this.props.userType !== 'callCentre' && (
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}
          >
            {this.state.custom && (
              <div className='courier-filters__date'>
                <div>
                  <div className='courier-filters__label'>От:</div>
                  <Datepicker
                    dateForm='MM/DD/YYYY'
                    onChange={this.filterDate('from')}
                    selected={this.state.from}
                  />
                </div>
                <div>
                  <div className='courier-filters__label'>До:</div>
                  <Datepicker
                    dateForm='MM/DD/YYYY'
                    onChange={this.filterDate('to')}
                    selected={this.state.to}
                  />
                </div>
                <Button
                  little
                  onClick={this.fetchWithQuery}
                  style={{
                    marginRight: '20px',
                    height: '48px'
                  }}
                  value='Применить'
                />
              </div>
            )}
            <Select
              items={this.getPeriodFilterItems()}
              onChange={this.filterPeriod}
              value={this.state.periodText}
            />
          </div>
        )}
      </div>
    );
  };

  getSelectItems = () => {
    const { restaurants } = this.props;
    const array = [];

    array.push({
      text: 'Все рестораны',
      value: ''
    });

    if (restaurants && restaurants.length) {
      for (let i = 0; i < restaurants.length; i++) {
        array.push({
          text: restaurants[i].name,
          value: restaurants[i]._id
        });
      }
    }

    return array;
  };

  onChangeSelectRestaurant = (value, name) => {
    this.setState(
      {
        restaurant: {
          value,
          name
        }
      },
      this.fetchWithQuery
    );
  };

  renderRestaurantFilter = () => {
    const { restaurant } = this.state;

    return (
      <div className='courier-filters-restaurants'>
        <Select
          items={this.getSelectItems()}
          onChange={this.onChangeSelectRestaurant}
          value={restaurant.name}
        />
      </div>
    );
  };

  courierInfoAppsNames = courier => {
    if (
      courier &&
      courier.courierAppsNames &&
      courier.courierAppsNames.length
    ) {
      const apps = [];
      for (let i = 0; i < courier.courierAppsNames.length; i++) {
        const element = courier.courierAppsNames[i];
        const appsDate = moment(element.createdAt).format('lll');
        apps.push(
          <div>{element.name}</div>,
          <div className='appsDate'>{appsDate}</div>
        );
      }
      return apps;
    }
    return 'не использовались';
  };

  getOrders = (courier, name) => {
    if (!courier.periodRating || !courier.periodRating.ordersStat) {
      return '-';
    }
    const { ordersStat } = courier.periodRating;
    let count = 0;
    ordersStat.forEach(el => {
      if (
        name === 'courier'
          ? ['auto', 'courier'].includes(el._id)
          : el._id === name
      ) {
        count += el.count;
      }
    });

    return count || '-';
  };

  toggleFinishedOrder = i => {
    return () => {
      const openedFinishedOrders = [...this.state.openedFinishedOrders];
      openedFinishedOrders[i] = !openedFinishedOrders[i];

      this.setState({ openedFinishedOrders });
    };
  };

  calculateRestaurantStat = stat => {
    if (stat.length) {
      const restaurantIds = [];
      const restaurants = [];

      stat.forEach(el => {
        if (el.restaurant && !restaurantIds.includes(el.restaurant._id)) {
          restaurantIds.push(el.restaurant._id);
          restaurants.push({
            name: el.restaurant.name,
            _id: el.restaurant._id,
            iiko: 0,
            courier: 0,
            auto: 0,
            other: 0
          });
        }
      });
      restaurantIds.forEach(restaurantId => {
        stat.forEach(el => {
          if (el.restaurant && restaurantId === el.restaurant._id) {
            const restaurant = restaurants.find(
              elem => elem._id === restaurantId
            );
            if (el._id === null) {
              restaurant.other += el.count;
            } else {
              restaurant[el._id] += el.count;
            }
          }
        });
      });
      return restaurants;
    }
    return null;
  };

  getRestaurantStat = (stat, i) => {
    const { openedFinishedOrders } = this.state;
    const restaurants = this.calculateRestaurantStat(stat);
    if (restaurants && restaurants.length && openedFinishedOrders[i]) {
      return (
        <tr>
          <td colSpan={100}>
            {Boolean(restaurants) &&
              restaurants.map(el => (
                <div>
                  {el.name}
                  <div style={{ marginLeft: '10px' }}>
                    iiko: {el.iiko || '-'}
                    <br />
                    курьер: {el.courier || '-'}
                    <br />
                    авто: {el.auto || '-'}
                    <br />
                    остальные: {el.other || '-'}
                    <br />
                  </div>
                </div>
              ))}
          </td>
        </tr>
      );
    }
    return null;
  };

  copyInfo = () => {
    const { couriers } = this.props;
    if (couriers && couriers.length) {
      const info = [];
      couriers.forEach(courier => {
        const { ordersStat, ...stat } = courier.periodRating;
        const restaraunts = this.calculateRestaurantStat(ordersStat);
        if (restaraunts && restaraunts.length > 1) {
          info.push({
            name: `${courier.firstName} ${courier.lastName}`,
            restaraunts,
            ...stat
          });
        }
      });
      if (info.length) clipboard.writeText(JSON.stringify(info));
    }
  };

  render() {
    if (this.state.update || this.state.uploadRestourants) {
      return <Loader />;
    }

    const { couriers, courier, pagination } = this.props;
    const { total } = pagination;

    const courierInfo = this.props.courier.courier;
    const courierRating = this.getRatingTable();

    return (
      <div>
        <Modal
          fullscreen
          onHide={this.onChangeModalShow}
          show={this.state.show}
        >
          <div className='requests-modal'>
            {courierInfo && courierRating ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <div style={{ width: '50%' }}>
                  <HeaderPage
                    little
                    title='Информация о курьере'
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <div className='name'>Имя:</div>
                    <div style={{ marginLeft: '5px' }}>
                      {courierInfo.firstName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>Фамилия:</div>
                    <div style={{ marginLeft: '5px' }}>
                      {courierInfo.lastName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>Отчество:</div>
                    <div style={{ marginLeft: '5px' }}>
                      {courierInfo.secondName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>В черном списке:</div>
                    <div style={{ marginLeft: '5px' }}>
                      {courierInfo.blacklisted ? 'Есть' : 'Нет'}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>Кол-во заказов:</div>
                    <div style={{ marginLeft: '5px' }}>
                      {courier.countOrders}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>Средняя скорость:</div>
                    <div style={{ marginLeft: '5px' }}>{courier.speed}</div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px'
                    }}
                  >
                    <div className='name'>Кол-во бонусов</div>
                    <div style={{ marginLeft: '5px' }}>{courier.balance}</div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <div
                      className='name'
                      style={{
                        marginRight: '5px'
                      }}
                    >
                      Приложения:
                    </div>
                    <div
                      className='apps'
                      style={{
                        width: '100%'
                      }}
                    >
                      {this.courierInfoAppsNames(courier)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: '50%',
                    marginLeft: 20
                  }}
                >
                  <div
                    className='header'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}
                  >
                    <HeaderPage
                      little
                      title='Рейтинг курьера'
                    />
                    <Select
                      items={periodSelectItems}
                      onChange={this.onChangePeriodSelect}
                      style={{ width: '25%' }}
                    />
                  </div>
                  {this.renderPeriodInputs()}
                  <Table
                    data={[
                      <tr>
                        <td>{courierRating.appearance.toFixed(1)}</td>
                        <td>{courierRating.civility.toFixed(1)}</td>
                        <td>{courierRating.speed.toFixed(1)}</td>
                        <td>{courierRating.count.toFixed(1)}</td>
                      </tr>
                    ]}
                    style={{ marginTop: '40px' }}
                    title={ratingTitle}
                  />
                  {courier && courier.courier && courier.courier.appsNames && (
                    <>
                      <div
                        className='name'
                        style={{ marginTop: 70 }}
                      >
                        Используемые:
                      </div>
                      <div className='apps'>
                        {courier.courier.appsNames.map(app => (
                          <div
                            className='apps-block'
                            onClick={() => {
                              const apps = courier.blockedApps;
                              const matchIndex = apps.indexOf(app);
                              if (matchIndex !== -1) {
                                apps.splice(matchIndex, 1);
                              } else {
                                apps.push(app);
                              }
                              this.props.blockCourierApps(
                                courier.courier._id,
                                { apps },
                                () =>
                                  this.props.fetchCourier(courier.courier._id)
                              );
                            }}
                            style={{
                              color: courier.blockedApps.includes(app)
                                ? 'red'
                                : 'black'
                            }}
                          >
                            <div>{app}</div>
                            <SvgButton image='block' />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </Modal>

        <HeaderPage title='Курьеры'>
          {this.state.selectedCourier && (
            <Button
              onClick={() => this.setState({ selectedCourier: '' })}
              value='Отменить выбор курьера'
            />
          )}
          <Button
            onClick={this.toggleMapInterval}
            value={this.state.mapOpen ? 'Закрыть карту' : 'Открыть карту'}
          />
          {this.props.userType === 'admin' && (
            <Button
              onClick={() => {
                this.props.toggleCouriersModal();
              }}
              value='Добавить курьера'
            />
          )}
        </HeaderPage>

        {this.renderFilters()}
        {this.renderRestaurantFilter()}

        {couriers ? (
          typeof couriers === 'object' ? (
            couriers.length === 0 ? (
              <HeaderPage
                little
                style={{
                  opacity: 0.5,
                  justifyContent: 'center'
                }}
                title='В базе нет данных по курьерам...'
              />
            ) : (
              [
                <Button
                  little
                  onClick={this.copyInfo}
                  value='Скопировать'
                />,
                <Map
                  apiLoaded={this.state.mapApiLoaded}
                  couriers={couriers}
                  selectedCourier={this.state.selectedCourier}
                  show={this.state.mapOpen}
                />,
                <Table
                  data={couriers.map((courier, key) => {
                    const { ordersStat } = courier.periodRating;
                    const cnClosedOrder = ordersStat.length
                      ? 'closed-order'
                      : '';
                    return (
                      <React.Fragment>
                        <tr key={key}>
                          <td
                            className={this.getClassNameCourierName(courier)}
                            onClick={() => this.selectCourier(courier._id)}
                          >
                            {courier.lastName} {courier.firstName}
                          </td>
                          <td>{this.getRestaurantName(courier.restaurant)}</td>
                          {['callCentre', 'regionalManager'].includes(
                            this.props.userType
                          ) && (
                            <td>
                              <CourierPhone
                                onSave={phone =>
                                  this.props.updateCourier(
                                    courier._id,
                                    { courier: { phone } },
                                    () => alert('Телефон успешно сохранен')
                                  )
                                }
                                phone={courier.phone}
                              />
                            </td>
                          )}
                          {['admin', 'regionalManager'].includes(
                            this.props.userType
                          ) && (
                            <>
                              <td>
                                {courier.periodRating.balance
                                  ? courier.periodRating.balance
                                  : '-'}
                              </td>
                              <td>
                                {courier.periodRating.rating
                                  ? courier.periodRating.rating
                                  : '-'}
                              </td>
                              <td>
                                {courier.periodRating.speed
                                  ? courier.periodRating.speed
                                  : '-'}
                              </td>
                              <td>
                                {courier.periodRating.countOrders
                                  ? courier.periodRating.countOrders
                                  : '-'}
                              </td>
                              <td
                                className={cnClosedOrder}
                                onClick={this.toggleFinishedOrder(key)}
                              >
                                {this.getOrders(courier, 'iiko')}
                              </td>
                              <td
                                className={cnClosedOrder}
                                onClick={this.toggleFinishedOrder(key)}
                              >
                                {this.getOrders(courier, 'courier')}
                              </td>
                              <td
                                className={cnClosedOrder}
                                onClick={this.toggleFinishedOrder(key)}
                              >
                                {this.getOrders(courier, null)}
                              </td>
                              <td>{courier.tipLink ? '+' : '-'}</td>
                              {this.props.userType !== 'regionalManager' && (
                                <>
                                  <td>
                                    <Button
                                      little
                                      onClick={
                                        courier.blacklisted
                                          ? () =>
                                              this.props.removeBlackList(
                                                courier._id,
                                                this.refreshPage
                                              )
                                          : () =>
                                              this.props.addBlackList(
                                                courier._id,
                                                this.refreshPage
                                              )
                                      }
                                      value={
                                        courier.blacklisted
                                          ? 'Убрать'
                                          : 'Добавить'
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      little
                                      onClick={() =>
                                        this.toogleModal(courier._id, 'курьер')
                                      }
                                      value='Подробнее'
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      little
                                      onClick={() =>
                                        this.props.resetCourierPassword(
                                          courier._id,
                                          this.refreshPage
                                        )
                                      }
                                      value='Сбросить'
                                    />
                                  </td>
                                  <td>
                                    <div style={{ display: 'flex' }}>
                                      <SvgButton
                                        image='edit'
                                        onClick={() => {
                                          this.props.toggleCouriersModal(
                                            courier
                                          );
                                        }}
                                        style={{ marginRight: 10 }}
                                      />
                                      <Link
                                        to={`/couriers/orders/${courier._id}`}
                                      >
                                        <SvgButton
                                          image='order'
                                          style={{ marginRight: 10 }}
                                        />
                                      </Link>
                                      <Cross
                                        onClick={() =>
                                          this.deleteCourier(courier)
                                        }
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                            </>
                          )}
                        </tr>
                        {this.getRestaurantStat(ordersStat, key)}
                      </React.Fragment>
                    );
                  })}
                  key='table'
                  style={{ marginTop: 50 }}
                  title={this.getTitles()}
                />,
                <Pagination
                  count={total}
                  limit={COURIERS_LIMIT}
                />
              ]
            )
          ) : (
            couriers === 'Обновление' && <Loader />
          )
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pagination: state.couriers.pagination,
    couriers: state.couriers.couriers,
    restaurants: state.restaurants.restaurants,
    courier: state.couriers.courier,
    orders: state.couriers.courierOrders,
    courierRating: state.couriers.courierRating,
    userType: state.login.type
  };
}

export default connect(mapStateToProps, {
  blockCourierApps,
  deleteCourier,
  fetchCourier,
  fetchRestaurants,
  fetchCouriers,
  clearCouriers,
  toggleCouriersModal,
  removeBlackList,
  addBlackList,
  fetchCouriersRating,
  resetCourierPassword,
  updateCourier
})(Couriers);
