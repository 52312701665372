import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Loader from '../../interface/loader';
import DatePicker from 'react-datepicker';

import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  LoadingPhoto,
  HeaderPage,
  Input
} from '../../elements/elements';
import {
  fetchCampaing,
  updateCampaing,
  fetchCampaigns,
  clearCampaigns,
  createCampaing
} from '../../../actions/campaignsActions';

import { server, uploadImage } from '../../../actions/index';

import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

import { days } from '../../../lib/consts';
import PaymentMethods from '../../PaymentMethods';

moment.locale('ru');

const defaultWorkTime = '2019-07-29T09:00:00.078Z';

const defaultState = {
  campaign: {},
  isDate: false,
  isPromocode: false,
  loadBanner: false,
  loadPreview: false
};
class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  refresh = () => {
    this.props.clearCampaigns();
    this.props.fetchCampaigns();
  };

  componentWillReceiveProps(newState) {
    if (this.props.show !== newState.show) {
      if (newState.campaign) {
        const workTimeWithMoment = {};

        for (const key in newState.campaign.workTime) {
          if (newState.campaign.workTime.hasOwnProperty(key)) {
            const value = newState.campaign.workTime[key];

            workTimeWithMoment[key] = [
              {
                from: moment(value[0] ? value[0].from : defaultWorkTime).format(
                  'HH:mm'
                ),
                to: moment(value[0] ? value[0].to : defaultWorkTime).format(
                  'HH:mm'
                )
              }
            ];
          }
        }

        const newCampaign = { ...newState.campaign };
        newCampaign.workTime = workTimeWithMoment;

        this.setState({
          campaign: newCampaign,
          isDate: Boolean(newState.campaign.expireDate),
          isPromocode: !newState.campaign.promocode,
          loadBanner: false,
          loadPreview: false
        });
      } else {
        this.setState(defaultState);
      }
    }
  }

  changeCheckbox = event => {
    const name = event.target.name;
    const checked = event.target.checked;

    if (
      name === 'inSlider' ||
      name === 'inFooter' ||
      name === 'hide' ||
      name === 'active' ||
      name === 'selfdelivery' ||
      name === 'delivery'
    ) {
      this.setState(prevState => ({
        campaign: {
          ...prevState.campaign,
          [name]: checked
        }
      }));
    } else {
      this.setState({
        [name]: checked
      });
    }
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        [name]: value
      }
    }));
  };

  uploadImage = event => {
    event.persist();

    this.setState({ [event.target.dataset.loader]: true });
    this.props
      .uploadImage('image', event.target.files[0])
      .then(picture => {
        this.setState(prevState => ({
          [event.target.dataset.loader]: false,
          campaign: {
            ...prevState.campaign,
            [event.target.name]: picture
          }
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  deleteImage = name => {
    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        [name]: ''
      }
    }));
  };

  onChange(currentDate) {
    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        expireDate: currentDate.toISOString()
      }
    }));
  }

  updateCampaign = () => {
    const data = { ...this.state.campaign };
    const workTime = {};

    if (this.state.isPromocode) data.promocode = null;
    if (!this.state.isDate) data.expireDate = null;
    if (!this.state.campaign.banner) data.banner = null;
    if (!this.state.campaign.preview) data.preview = null;

    for (const key in data.workTime) {
      if (data.workTime.hasOwnProperty(key)) {
        const value = data.workTime[key][0];

        const time = {
          from: value.from.split(':'),
          to: value.to.split(':')
        };
        workTime[key] = [
          {
            from: moment()
              .hours(Number(time.from[0]))
              .minutes(Number(time.from[1]))
              .toISOString(),
            to: moment()
              .hours(Number(time.to[0]))
              .minutes(Number(time.to[1]))
              .toISOString()
          }
        ];
      }
    }
    data.workTime = workTime;

    this.props.updateCampaing(this.state.campaign._id, data, this.refresh);
    this.props.toggleModal();
  };

  createCampaign = () => {
    const data = { ...this.state.campaign };
    if (this.state.isPromocode === true) data.promocode = null;
    if (this.state.isDate === false) data.expireDate = null;

    this.props.createCampaing(data, this.refresh);
    this.props.toggleModal();
  };

  renderTimeInputs = () => {
    const { workTime } = this.state.campaign;

    const result = [];

    if (workTime) {
      for (const key in workTime) {
        if (workTime.hasOwnProperty(key)) {
          const index = Number(key);
          result.push(
            <FormGroup
              onlyLabel
              placeholder={days[index]}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'from')}
                style={{ marginRight: '5px' }}
                type='time'
                value={workTime[key][0] ? workTime[key][0].from : ''}
              />

              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'to')}
                type='time'
                value={workTime[key][0] ? workTime[key][0].to : ''}
              />
            </FormGroup>
          );
        }
      }
    }
    return result;
  };

  onChangeTimeInput = (event, key, type) => {
    const { value } = event.target;

    if (type === 'from') {
      this.setState({
        campaign: {
          ...this.state.campaign,
          workTime: {
            ...this.state.campaign.workTime,
            [key]: [{ ...this.state.campaign.workTime[key][0], from: value }]
          }
        }
      });
    }
    if (type === 'to') {
      this.setState({
        campaign: {
          ...this.state.campaign,
          workTime: {
            ...this.state.campaign.workTime,
            [key]: [{ ...this.state.campaign.workTime[key][0], to: value }]
          }
        }
      });
    }
  };

  onChangePaymentMethods = paymentMethods =>
    this.setState({
      campaign: {
        ...this.state.campaign,
        paymentMethods
      }
    });

  render() {
    const { campaign } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle
          title={this.props.campaign ? `«${campaign.title}»` : 'Добавить акцию'}
        />
        <Form
          direction='column'
          onSubmit={
            this.props.campaign ? this.updateCampaign : this.createCampaign
          }
          value={this.props.campaign ? 'Сохранить изменения' : 'Создать акцию'}
        >
          <FormGroup
            id='title'
            name='title'
            onChange={this.changeInput}
            placeholder='Название акции'
            required
            value={campaign.title}
          />

          <FormGroup
            id='description'
            name='description'
            onChange={this.changeInput}
            placeholder='Описание'
            required
            type='textarea'
            value={campaign.description}
          />

          <FormGroup
            id='instruction'
            name='instruction'
            onChange={this.changeInput}
            placeholder='Порядок применения'
            type='textarea'
            value={campaign.instruction}
          />

          <FormGroup
            id='shortDescription'
            name='shortDescription'
            onChange={this.changeInput}
            placeholder='Короткое описание'
            required
            type='textarea'
            value={campaign.shortDescription}
          />

          <FormGroup
            id='videoLinkSite'
            name='videoLinkSite'
            onChange={this.changeInput}
            placeholder='Видео подсказки (сайт)'
            value={campaign.videoLinkSite}
          />

          <FormGroup
            id='videoLinkApp'
            name='videoLinkApp'
            onChange={this.changeInput}
            placeholder='Видео подсказки (телефон)'
            value={campaign.videoLinkApp}
          />

          <FormGroup
            checked={this.state.isDate}
            id='isDate'
            name='isDate'
            onChange={this.changeCheckbox}
            placeholder='Дата окончания'
            type='checkbox'
            value=''
          />

          {this.state.isDate ? (
            <DatePicker
              className='form-control'
              locale='ru'
              name='expireDate'
              onChange={currentDate => this.onChange(currentDate)}
              placeholderText='Выберите дату'
              required
              value={
                campaign.expireDate
                  ? moment(campaign.expireDate).format('DD.MM.YYYY')
                  : 'Выберите дату'
              }
            />
          ) : null}

          <FormGroup
            checked={this.state.isPromocode}
            id='isPromocode'
            name='isPromocode'
            onChange={this.changeCheckbox}
            placeholder='Акция без промокода'
            type='checkbox'
            value=''
          />
          <FormGroup
            checked={this.state.campaign.selfdelivery}
            id='selfdelivery'
            name='selfdelivery'
            onChange={this.changeCheckbox}
            placeholder='Акция на самовывоз'
            type='checkbox'
          />
          <FormGroup
            checked={this.state.campaign.delivery}
            id='delivery'
            name='delivery'
            onChange={this.changeCheckbox}
            placeholder='Акция на доставку'
            type='checkbox'
          />

          {this.state.isPromocode ? null : (
            <FormGroup
              indentBottom='10'
              labelHidden
              name='promocode'
              onChange={this.changeInput}
              placeholder='Промокод'
              required={this.state.isPromocode ? false : 'required'}
              type='text'
              value={campaign.promocode}
            />
          )}

          <FormGroup
            checked={campaign.inSlider}
            id='inSlider'
            name='inSlider'
            onChange={this.changeCheckbox}
            placeholder='Отображать в слайдере'
            type='checkbox'
          />

          <FormGroup
            checked={campaign.inFooter}
            id='inFooter'
            name='inFooter'
            onChange={this.changeCheckbox}
            placeholder='Отображать в футере'
            type='checkbox'
          />

          <FormGroup
            checked={campaign.hide}
            id='hide'
            name='hide'
            onChange={this.changeCheckbox}
            placeholder='Скрыть акцию полностью'
            type='checkbox'
          />
          <FormGroup
            checked={campaign.active}
            id='active'
            name='active'
            onChange={this.changeCheckbox}
            placeholder='Активировать акцию'
            type='checkbox'
          />

          <HeaderPage
            little
            title='Методы оплаты'
          />
          <PaymentMethods
            activeMethods={campaign.paymentMethods}
            onChange={this.onChangePaymentMethods}
          />

          <div>
            <HeaderPage
              little
              title='Время действия акции'
            />
            {this.renderTimeInputs()}
          </div>

          <div
            className={campaign.banner ? 'image-block' : 'image-block default'}
          >
            <LoadingPhoto
              loader={this.state.loadBanner}
              loaderName='loadBanner'
              name='banner'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
            />
            <div className='image-block-name'>Баннер акции</div>
            {campaign.banner ? (
              <img
                alt=''
                className='uploadImg'
                src={server + campaign.banner}
              />
            ) : null}
          </div>

          <div
            className={campaign.preview ? 'image-block' : 'image-block default'}
          >
            <LoadingPhoto
              loader={this.state.loadPreview}
              loaderName='loadPreview'
              name='preview'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
            />
            <div className='image-block-name'>Превью акции</div>
            {campaign.preview ? (
              <img
                alt=''
                className='uploadImg'
                src={server + campaign.preview}
              />
            ) : null}
          </div>
          <div
            className={
              campaign.smallImg ? 'image-block' : 'image-block default'
            }
          >
            <LoadingPhoto
              loader={this.state.loadPreview}
              loaderName='loadSmallImg'
              name='smallImg'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
            />
            <div className='image-block-name'>Корзина</div>
            {campaign.smallImg ? (
              <img
                alt=''
                className='uploadImg'
                src={server + campaign.smallImg}
              />
            ) : null}
          </div>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { campaign: state.modal.modalContent };
}

export default connect(
  mapStateToProps,
  {
    fetchCampaigns,
    fetchCampaing,
    updateCampaing,
    uploadImage,
    createCampaing,
    clearCampaigns
  }
)(ModalComponent);
