import {
  FETCH_STATISTICS,
  FETCH_STATISTICS_PROMOCODES,
  FETCH_USERTYPE_STATISTIC,
  FETCH_RESTAURANTS,
  FETCH_BONUS_LEVELS,
  FETCH_COUPONS_STATISTIC,
  FETCH_THURSDAY_STATISTIC
} from '../actions/statisticsActions';

const INITIAL_STATE = {
  statistics: false,
  bonusLevels: [],
  restaurants: [],
  userStatistic: [],
  couponsStatistic: {},
  thursdayStatistic: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_STATISTICS:
      return { ...state, statistics: action.payload };

    case FETCH_STATISTICS_PROMOCODES:
      return { ...state, promocodesStatistic: action.payload };

    case FETCH_USERTYPE_STATISTIC:
      return { ...state, userStatistic: action.payload.userLevels };

    case FETCH_RESTAURANTS:
      return { ...state, restaurants: action.payload };

    case FETCH_BONUS_LEVELS:
      return { ...state, bonusLevels: action.payload };

    case FETCH_COUPONS_STATISTIC:
      return { ...state, couponsStatistic: action.payload };

    case FETCH_THURSDAY_STATISTIC:
      return { ...state, thursdayStatistic: action.payload };
    default:
      return state;
  }
}
