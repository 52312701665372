import React, { Component } from 'react';
import { SvgButton } from '../elements';
import './style.css';

export class PaginationBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: parseInt(this.props.page, 10),
      count: parseInt(this.props.count, 10)
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.page !== newProps.page) {
      this.setState({
        page: parseInt(newProps.page, 10)
      });
    }

    if (this.props.count !== newProps.count) {
      this.setState({
        count: parseInt(newProps.count, 10)
      });
    }
  }

  getClassName = () => {
    let className = 'pagination-new-block';
    if (this.props.className) className += this.props.className;

    return className;
  };

  renderPaginationClass = active => {
    let className = 'pagination-element';
    if (active) className += ' active';

    return className;
  };

  renderPaginations = () => {
    const array = [];
    const { page, count } = this.state;

    if (this.props.changePage) {
      if (page === 1) {
        for (let i = 0; i < count && i < 3; i++) {
          array.push(
            <div
              className={this.renderPaginationClass(
                parseInt(page, 10) === parseInt(i + 1, 10)
              )}
              data-count={i}
              key={i}
              onClick={event =>
                this.props.changePage(event.currentTarget.innerText)
              }
            >
              {i + 1}
            </div>
          );
        }
      } else {
        for (let i = 0; i < count; i++) {
          if (i > page - 4 && i < page + 2) {
            array.push(
              <div
                className={this.renderPaginationClass(
                  parseInt(page, 10) === parseInt(i + 1, 10)
                )}
                data-count={i}
                key={i}
                onClick={event =>
                  this.props.changePage(event.currentTarget.innerText)
                }
              >
                {i + 1}
              </div>
            );
          }
        }
      }
    } else alert('changePage is not fined');

    return array;
  };

  onSubmit = event => {
    event.preventDefault();
    if (this.props.changePage) this.props.changePage(this.pageCount.value);
    else alert('changePage is not fined');
  };

  nextPage = () => {
    const { page } = this.state;
    if (this.props.changePage) this.props.changePage(page + 1);
    else alert('changePage is not fined');
  };

  nextPageCount = () => {
    const { page } = this.state;
    if (this.props.changePage) this.props.changePage(page + 2);
    else alert('changePage is not fined');
  };

  prevPage = () => {
    const { page } = this.state;
    if (this.props.changePage) this.props.changePage(page - 1);
    else alert('changePage is not fined');
  };

  prevPageCount = () => {
    const { page } = this.state;
    if (this.props.changePage) this.props.changePage(page - 2);
    else alert('changePage is not fined');
  };

  render() {
    const { page, count } = this.state;

    return (
      <div className={this.getClassName()}>
        <div className='pagination-control-panel'>
          {page !== 1 && (
            <div
              className='pagination-button prev'
              onClick={this.prevPage}
            >
              Назад
            </div>
          )}
          {page > 3 && <div className='pagination-button dotten'>...</div>}
          {this.renderPaginations()}
          {page < count - 2 && page + 2 < count && (
            <div className='pagination-button dotten'>...</div>
          )}
          {page !== count && (
            <div
              className='pagination-button next'
              onClick={this.nextPage}
            >
              Вперед
            </div>
          )}
        </div>
        <form
          className='page-search'
          onSubmit={this.onSubmit}
        >
          <input
            className='input-element'
            maxLength='3'
            name='page'
            onChange={this.onChange}
            placeholder={this.props.page}
            ref={node => (this.pageCount = node)}
            type='text'
          />
          <button>
            <SvgButton image='search' />
          </button>
        </form>
      </div>
    );
  }
}
