import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const UPLOADIMAGE_CATEGORY = 'UPLOADIMAGE_CATEGORY';

export const fetchCategories = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu/categories`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CATEGORIES,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCategory = id => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu/categories/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CATEGORY,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateCategory = category => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu/categories`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(category)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_CATEGORY,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
