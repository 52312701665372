export const fetchQRResponse = url => async (dispatch, getState) => {
  if (!url) return;

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: getState().login.token
    }
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'qr_review_stat.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};
