import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '../components/Select';
import Loader from '../components/interface/loader';
import { fetchCampaignsList } from '../actions/campaignsActions';
import { HeaderPage, Table, SvgButton } from '../components/elements/elements';
import {
  toggleProductModal,
  toggleProductCampaignModal
} from '../actions/modalActions';
import {
  fetchProducts,
  fetchTags,
  catalogUpdate,
  fetchCategories,
  fetchMenu,
  clearCatalog
} from '../actions/productActions';

class ProductIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      categoryName: localStorage.getItem('CatalogCategoryName')
        ? JSON.parse(localStorage.getItem('CatalogCategoryName'))
        : false,
      categoryID: localStorage.getItem('CatalogCategoryID')
        ? JSON.parse(localStorage.getItem('CatalogCategoryID'))
        : false
    };
  }

  componentDidMount() {
    if (this.state.categoryID) {
      this.setState({ update: true });
      this.props.fetchMenu(this.state.categoryID, () => {
        this.setState({ update: false });
      });
    }
    this.props.fetchTags();
    this.props.fetchCategories();
    this.props.fetchCampaignsList();
  }

  componentWillUnmount() {
    this.props.clearCatalog();
  }

  onUpdate = () => {
    this.setState({ update: true });
    this.props.catalogUpdate(() => this.setState({ update: false }));
  };

  getTitles = () => {
    return ['Название', 'Описание', 'ID', 'Цена', 'Размер', ''];
  };

  onChangeSelect = (value, name) => {
    this.setState({ update: true });

    this.props.fetchMenu(value, () => {
      this.setState({ categoryID: value, categoryName: name, update: false });
      localStorage.setItem('CatalogCategoryID', JSON.stringify(value));
      localStorage.setItem('CatalogCategoryName', JSON.stringify(name));
    });
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  render() {
    // if(this.props.products === 'Обновление') (123);

    return (
      <div>
        {this.state.update && <Loader />}
        <HeaderPage
          button='Обновить каталог'
          onClick={this.onUpdate}
          title='Каталог'
        />

        {!this.state.update && this.props.categories && (
          <Select
            default='Выберите категорию'
            items={this.getItems()}
            onChange={this.onChangeSelect}
            style={{ marginBottom: 50 }}
            value={this.state.categoryName && this.state.categoryName}
          />
        )}
        {!this.state.update ? (
          typeof this.props.products === 'object' ? (
            this.props.products.length === 0 ? (
              <HeaderPage
                little
                style={{ opacity: 0.5, justifyContent: 'center' }}
                title={`В «${this.state.categoryName}» товаров нет...`}
              />
            ) : (
              <Table
                data={this.props.products.map((product, key) => (
                  <tr key={key}>
                    <td>{product.name}</td>
                    <td>{product.description}</td>
                    <td>{product._id}</td>
                    <td>{product.price}</td>
                    <td>
                      {product.additionalInfo
                        ? product.additionalInfo.size
                        : '-'}
                    </td>
                    <td>
                      <div style={{ display: 'flex', float: 'right' }}>
                        <SvgButton
                          image='campaign'
                          onClick={() =>
                            this.props.toggleProductCampaignModal(product)
                          }
                          style={{ marginRight: 15 }}
                        />
                        <SvgButton
                          image='edit'
                          onClick={() => this.props.toggleProductModal(product)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
                title={this.getTitles()}
              />
            )
          ) : (
            this.props.products === 'Обновление' && <Loader />
          )
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.products.categories,
    products: state.products.products,
    tags: state.products.tags
  };
}
export default connect(
  mapStateToProps,
  {
    fetchCampaignsList,
    toggleProductCampaignModal,
    clearCatalog,
    fetchProducts,
    fetchTags,
    catalogUpdate,
    fetchCategories,
    fetchMenu,
    toggleProductModal
  }
)(ProductIndex);
