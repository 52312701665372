import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteCoupon,
  fetchUsers,
  clearUsers
} from '../../../actions/usersActions';
import {
  Modal,
  ModalTitle,
  Table,
  HeaderPage,
  PaginationBlock
} from '../../elements/elements';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parametres: {
        page: 1
      }
    };
  }

  count = 15;

  title = ['Дата', 'Событие', 'Количество'];

  refreshPage = () => {
    this.props.clearUsers();
    this.props.fetchUsers(this.props.coupons.user, 'all');
  };

  getArrayData = (data, page, count) => {
    const array = [];

    data.forEach((a, index) => {
      if (index >= count * (page - 1) && index < count * page) {
        array.push(a);
      }
    });

    return array;
  };

  createPaginations = count => {
    const array = [];
    const { parametres } = this.state;

    for (let i = 0; i < count; i++) {
      array.push(
        <div
          className={this.renderPaginationClass(
            parseInt(parametres.page, 10) === parseInt(i + 1, 10)
          )}
          data-count={i}
          key={i}
          onClick={event =>
            this.refreshPagination(event.currentTarget.innerText)
          }
        >
          {i + 1}
        </div>
      );
    }

    return <PaginationBlock items={array} />;
  };

  renderPaginationClass = active => {
    let className = 'pagination-block-element';
    if (active) className += ' active';

    return className;
  };

  refreshPagination = number => {
    this.setState({
      parametres: {
        page: number
      }
    });
  };

  render() {
    const data = this.props.history.data
      ? this.props.history.data
      : this.props.history;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        {data.history && data.history.length > 0 ? (
          [
            <ModalTitle
              key='modal-title'
              title='Бонусная история'
            />,
            <Table
              data={this.getArrayData(
                data.history,
                this.state.parametres.page,
                this.count
              ).map((item, key) => (
                <tr key={key}>
                  <td>{moment(item.updatedAt).format('DD.MM.YYYY')}</td>
                  <td>{item.reason}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
              key='modal-table'
              title={this.title}
            />,
            <div key='pagination'>
              {data.history.length > this.count
                ? this.createPaginations(data.history.length / this.count)
                : null}
            </div>
          ]
        ) : (
          <HeaderPage
            little
            notFound
            style={{ paddingBottom: 20 }}
            title='У пользователя нет истории...'
          />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    history: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  deleteCoupon,
  fetchUsers,
  clearUsers
})(ModalComponent);
