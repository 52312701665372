import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_DELETED_USERS = 'FETCH_DELETED_USERS';
export const CLEAR_DELETED_USERS = 'CLEAR_DELETED_USERS';

export const fetchDeletedUsers = (user, callback) => async (
  dispatch,
  getState
) => {
  const url = `/users/deleted?login=${user.toLowerCase()}`;
  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });
  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (!callback) {
        dispatch({
          type: FETCH_DELETED_USERS,
          payload: json
        });
      }
      if (callback) await callback(user, callback);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 400:
      dispatch({
        type: FETCH_DELETED_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 404:
      dispatch({
        type: FETCH_DELETED_USERS,
        payload: 'Не найдено'
      });
      if (callback) await callback(user);
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const recoverDeletedUser = (id, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/users/recover_account`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ id })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const clearDeletedUsers = callback => async dispatch => {
  dispatch({
    type: CLEAR_DELETED_USERS,
    payload: null
  });
  if (callback) await callback();
};
