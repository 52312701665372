import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';
export const FETCH_APP_BANNER = 'FETCH_APP_BANNER';

export const FETCH_COURIER_SETTINGS = 'FETCH_COURIER_SETTINGS';
export const UPDATE_COURIER_SETTINGS = 'UPDATE_COURIER_SETTINGS';
export const CLEAR_COURIER_SETTINGS = 'CLEAR_COURIER_SETTINGS';

export const clearSettings = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_SETTINGS,
    payload: []
  });
};

export const clearCourierSettings = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_COURIER_SETTINGS,
    payload: []
  });
};

export const updateSettings = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/coupons/settings`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_SETTINGS,
        payload: json
      });
      alert('Настройки обновлены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateCourierSettings = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/settings`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_COURIER_SETTINGS,
        payload: json
      });
      alert('Настройки обновлены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchSettings = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/coupons/settings`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_SETTINGS,
        payload: json
      });
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createSettings = (body, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/coupons/coupon_settings`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body
  });

  const status = await response.status;

  switch (status) {
    case 200:
      await dispatch(fetchSettings());
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteSettings = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/coupons/coupon_settings/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      await dispatch(fetchSettings());
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCourierSettings = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/settings`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COURIER_SETTINGS,
        payload: json
      });
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchAppBanner = cb => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/banners`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_APP_BANNER,
        payload: json
      });
      if (cb) await cb();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateAppBanner = (body, cb) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/banners`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body
  });

  const status = await response.status;

  switch (status) {
    case 200:
      dispatch(fetchAppBanner());
      if (cb) await cb();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteCouponProducts = (data, type, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/coupons/settings/${type}/delete_products`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Продукты удалены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addCouponProducts = (data, type, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/coupons/settings/${type}/add_products`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Продукты добавлены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
