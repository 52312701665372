import { USER_INFO, USER_SIGNIN, LOGOUT } from '../actions/loginActions';
import Cookie from 'react-cookies';

const INITIAL_STATE = {
  token: Cookie.load('Token') ? Cookie.load('Token') : null,
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  type: localStorage.getItem('userType')
    ? JSON.parse(localStorage.getItem('userType'))
    : null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_SIGNIN:
      Cookie.save('Token', action.payload.token);
      return { ...state, token: action.payload.token };

    case USER_INFO:
      localStorage.setItem('userInfo', JSON.stringify(action.payload.info));
      if (action.payload.type)
        localStorage.setItem(
          'userType',
          JSON.stringify(action.payload.type || {})
        );
      return {
        ...state,
        userInfo: action.payload.info,
        type: action.payload.type || state.type
      };

    case LOGOUT:
      Cookie.remove('Token');
      localStorage.clear();
      return { ...state, token: null, userInfo: null, type: null };

    default:
      return state;
  }
}
