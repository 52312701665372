import React from 'react';
import { connect } from 'react-redux';
import { updateCategory } from '../../actions/categoryActions';
import { uploadImage } from '../../actions';

import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Checkbox
} from 'react-bootstrap';

class CategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: this.props.category
    };

    this.handleChange = this.handleChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ categoryData: newProps.category });
  }

  handleChange(event) {
    event.persist();

    if (event.target.type === 'checkbox') {
      event.target.value = !this.state.categoryData.isIncludedInMenu;
    }

    this.setState(prevState => ({
      categoryData: {
        ...prevState.categoryData,
        [event.target.name]: event.target.value
      }
    }));
  }

  async uploadImage(event) {
    event.persist();
    const picture = await this.props
      .uploadImage('image', event.target.files[0])
      .then(function(result) {
        return result.payload.data.file.url;
      })
      .catch(function(error) {
        alert(error);
      });
    this.setState(prevState => ({
      categoryData: {
        ...prevState.categoryData,
        [event.target.name]: picture
      }
    }));
  }

  handleSubmit() {
    this.props.updateCategory(this.state.categoryData);
    alert('Данные обновлены!');
    this.props.toggleModal();
  }

  render() {
    const categoryName = this.props.category.name;
    const iconPic = this.state.categoryData.iconPic;
    const backgroundPic = this.state.categoryData.backgroundPic;

    return (
      <div>
        <Modal
          onHide={this.props.toggleModal}
          show={this.props.show}
        >
          <Modal.Body>
            <Form id='promocodeForm'>
              <h3 className='text-center'>{categoryName}</h3>
              <FormGroup>
                <ControlLabel>Ссылка на иконку</ControlLabel>
                <FormControl
                  name='iconPic'
                  onChange={this.uploadImage}
                  type='file'
                />
                {iconPic ? (
                  <p>
                    Текущая иконка: <a href={iconPic}>{iconPic}</a>
                  </p>
                ) : (
                  ''
                )}
              </FormGroup>
              <FormGroup>
                <ControlLabel>Ссылка на фон</ControlLabel>
                <FormControl
                  name='backgroundPic'
                  onChange={this.uploadImage}
                  type='file'
                />
                {backgroundPic ? (
                  <p>
                    Текущий фон: <a href={backgroundPic}>{backgroundPic}</a>
                  </p>
                ) : (
                  ''
                )}
              </FormGroup>
              <FormGroup>
                <Checkbox
                  defaultChecked={this.state.categoryData.isIncludedInMenu}
                  name='isIncludedInMenu'
                  onChange={this.handleChange}
                  required
                >
                  Отображать в меню
                </Checkbox>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Приоритет</ControlLabel>
                <FormControl
                  name='order'
                  onChange={this.handleChange}
                  required
                  value={this.state.categoryData.order}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='success'
              form='promocodeForm'
              onClick={this.handleSubmit}
            >
              Обновить
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.props.toggleModal}
            >
              Отменить
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  { updateCategory, uploadImage }
)(CategoryModal);
