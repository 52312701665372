import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  Cross
} from '../../elements/elements';
import {
  refreshGroups,
  fetchGroups,
  addGroupProducts
} from '../../../actions/groupsActions';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      array: []
    };
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show)
      this.setState({ group: { ...newState.group }, array: [] });
  }

  refreshPage = id => {
    if (!id) return alert('Не передан id для обновления страницы.');
    this.props.toggleModal();
    this.props.refreshGroups();
    this.props.fetchGroups(id);
  };

  onDelete = element => {
    const { array } = this.state;
    array.splice(element, 1);

    this.setState({ array });
  };

  onChange = (event, element) => {
    const { array } = this.state;
    array[element] = event.target.value;

    this.setState({ array });
  };

  renderInputs = response => {
    if (response.length > 0) {
      const array = [];
      for (let a = 0; a <= response.length; a++) {
        array.push(
          <div
            className={`datepicker-block ${a === 0 && 'first'}`}
            key={a}
          >
            <FormGroup
              id={`addingProduct${a}`}
              labelHidden
              name={`addingProduct${a}`}
              onChange={event => this.onChange(event, a)}
              placeholder='Добавьте ID...'
              value={response[a] ? response[a] : ''}
            />
            {response[a] && (
              <Cross
                className='datepicker-cross'
                noModalCross
                onClick={() => this.onDelete(a)}
              />
            )}
          </div>
        );
      }
      return array;
    }
    return (
      <div
        className='datepicker-block first'
        key='0'
      >
        <FormGroup
          id={`addingProduct${0}`}
          labelHidden
          name={`addingProduct${0}`}
          onChange={event => this.onChange(event, 0)}
          placeholder='Добавьте ID...'
          value={response[0]}
        />
      </div>
    );
  };

  onUpdate = () => {
    const { array } = this.state;
    const { group } = this.props;
    const data = { products: [...array] };

    this.props.addGroupProducts(group._id, data, () =>
      this.refreshPage(group.productCategoryId)
    );
  };

  render() {
    const { array } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle title='Добавить продукты' />
        <Form
          direction='column'
          onSubmit={this.onUpdate}
          value='Добавить продукты'
        >
          {this.renderInputs(array)}
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  addGroupProducts,
  refreshGroups,
  fetchGroups
})(ModalComponent);
