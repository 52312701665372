import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  HeaderPage,
  Table,
  Cross,
  SvgButton
} from '../components/elements/elements';

import { fetchSettings, deleteSettings } from '../actions/settingsActions';

import {
  toggleAddCouponsProductModal,
  toggleCouponModal
} from '../actions/modalActions';

class Coupons extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchSettings();
  }

  onDelete = item => {
    return () => {
      if (window.confirm(`Удалить купон ${item.name}`)) {
        this.props.deleteSettings(item._id);
      }
    };
  };

  getTableData = () => {
    const { coupons } = this.props;
    if (coupons) {
      return Object.keys(coupons).map((key, i) => (
        <tr key={`tr-${i}`}>
          <td>{coupons[key].name}</td>
          <td>
            <div>
              <Cross onClick={this.onDelete(coupons[key])} />
              <SvgButton
                image='add'
                onClick={() =>
                  this.props.toggleAddCouponsProductModal({
                    ...coupons[key],
                    type: coupons[key].couponType
                  })
                }
                style={{ marginRight: 10 }}
              />
              <SvgButton
                image='edit'
                onClick={() => this.props.toggleCouponModal(coupons[key])}
                style={{ marginRight: '10px' }}
              />
            </div>
          </td>
        </tr>
      ));
    }
  };

  render() {
    if (!Object.keys(this.props.coupons).length) return <Loader />;

    return (
      <div>
        <HeaderPage
          button='Добавить купон'
          onClick={this.props.toggleCouponModal}
          title='Купоны'
        />
        <Table
          data={this.getTableData()}
          title={['Название', '']}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupons: state.settings.settings
  };
}

const mapDispatchToProps = {
  fetchSettings,
  deleteSettings,
  toggleCouponModal,
  toggleAddCouponsProductModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupons);
