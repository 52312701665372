import { CHANGE_LETTER, GET_LETTERS } from '../actions/lettersActions';

const STATE = {
  list: []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_LETTERS:
      return { ...state, list: action.response.letters };

    case CHANGE_LETTER:
      return {
        ...state,
        list: state.list.map(letter =>
          letter._id === action.response._id ? action.response : letter
        )
      };

    default:
      return state;
  }
}
