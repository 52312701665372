import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';

import {
  getCatalogXML,
  getDeliveryClubSettings,
  setDeliveryClubSettings
} from '../actions/deliveryClubActions';
import {
  HeaderPage,
  Table,
  ModalTitle,
  FormGroup,
  Button,
  Form,
  Cross
} from '../components/elements/elements';

import Modal from '../components/isolatedModal';

class Page extends Component {
  state = {
    update: true,
    activeCategory: '',
    modalInputName: '',
    setSettingsMode: '',
    showCategoryModal: false
  };

  componentDidMount = () => {
    this.getDeliveryClubSettings();
  };

  toggleCategoryModal = category => {
    this.setState({
      showCategoryModal: !this.state.showCategoryModal,
      activeCategory: category || '',
      setSettingsMode: 'enlarge',
      modalInputName: ''
    });
  };

  onChangeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  renderCategoryModal = () => {
    const { modalInputName, showCategoryModal, activeCategory } = this.state;

    return (
      <Modal
        onHide={this.toggleCategoryModal}
        show={showCategoryModal}
      >
        <ModalTitle
          title={
            activeCategory
              ? `Добавить подкатегорию в «${activeCategory}»`
              : 'Добавить категорию'
          }
        />
        <Form
          onSubmit={this.setDeliveryClubSettings}
          value='Добавить'
        >
          <FormGroup
            labelHidden
            name='modalInputName'
            onChange={this.onChangeInput}
            placeholder='Введите название'
            value={modalInputName}
          />
        </Form>
      </Modal>
    );
  };

  setDeliveryClubSettings = async () => {
    const settings = await this.props.getDeliveryClubSettings();
    const {
      activeCategory,
      activeSubcategory,
      setSettingsMode,
      modalInputName
    } = this.state;

    const data = settings.map(a => ({
      ...a,
      subcategories: a.subcategories.map(b => ({
        ...b,
        products: [...b.products]
      }))
    }));

    if (setSettingsMode === 'reduce') {
      if (activeCategory && activeSubcategory) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].name === activeCategory) {
            for (let w = 0; w < data[i].subcategories.length; w++) {
              if (data[i].subcategories[w].name === activeSubcategory) {
                data[i].subcategories.splice(w, 1);
                break;
              }
            }
            break;
          }
        }
      } else if (activeCategory) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].name === activeCategory) {
            data.splice(i, 1);
            break;
          }
        }
      }
    } else if (setSettingsMode === 'enlarge') {
      if (activeCategory) {
        for (let i = 0; i < data.length; i++) {
          if (activeCategory === data[i].name) {
            if (data[i].subcategories.length) {
              data[i].subcategories.push({
                name: modalInputName,
                products: []
              });
            } else {
              data[i].subcategories.push({
                name: modalInputName,
                products: [...data[i].products]
              });

              if (data[i].products.length) data[i].products = [];
            }
            break;
          }
        }
      } else {
        data.push({
          name: modalInputName,
          products: [],
          subcategories: []
        });
      }
    }

    this.props.setDeliveryClubSettings({ categories: data }, () => {
      this.setState(
        {
          update: true,
          activeCategory: '',
          modalInputName: '',
          setSettingsMode: '',
          showCategoryModal: false
        },
        this.getDeliveryClubSettings
      );
    });
  };

  getDeliveryClubSettings = async () => {
    await this.props.getDeliveryClubSettings(true);
    this.setState({ update: false, showCategoryModal: false });
  };

  removeSubcategory = (category, subcategory) => {
    const confirm = window.confirm(
      `Вы точно хотите удалите подкатегорию «${subcategory}» в категории «${category}»?`
    );

    if (confirm) {
      const confirm2 = window.confirm(
        `Вы точно уверены, что хотите удалите подкатегорию «${subcategory}» в категории «${category}»?`
      );

      if (confirm2) {
        this.setState(
          {
            setSettingsMode: 'reduce',
            activeCategory: category,
            activeSubcategory: subcategory
          },
          this.setDeliveryClubSettings
        );
      }
    }
  };

  removeCategory = category => {
    const confirm = window.confirm(
      `Вы точно хотите удалите категорию «${category}»?`
    );
    if (confirm) {
      const confirm2 = window.confirm(
        `Вы точно уверены, что хотите удалите категорию «${category}»?`
      );

      if (confirm2) {
        this.setState(
          {
            setSettingsMode: 'reduce',
            activeCategory: category
          },
          this.setDeliveryClubSettings
        );
      }
    }
  };

  renderCategories = () => {
    const { settings } = this.props;
    const result = [];

    const getWordEnd = (word, value) => {
      let answer = `${value} `;

      const string = value.toString();
      let numeral = parseInt(string[string.length - 1], 10);

      // Hardcode
      if (value === 11) numeral = 5;
      if (value === 12) numeral = 5;
      if (value === 13) numeral = 5;
      if (value === 14) numeral = 5;

      switch (word.toLowerCase()) {
        case 'продукт':
          if (numeral === 0) return (answer += 'продуктов');
          else if (numeral === 1) return (answer += 'продукт');
          else if (numeral > 1 && numeral < 5) return (answer += 'продукта');
          else if (numeral >= 5) return (answer += 'продуктов');
          return (answer += word);
        default:
          break;
      }

      return answer;
    };

    const getCategoryProductsLength = category => {
      let count = 0;
      count += category.products.length;
      category.subcategories.forEach(element => {
        count += element.products.length;
      });
      return count;
    };

    const getTitle = category => {
      return `
        <div style="display: flex">
          <p>${category.name}</p>
          <p style="opacity: .5; margin-left: 12px; font-size: 18px">
            ${getWordEnd('продукт', getCategoryProductsLength(category))}
          </p>
        </div>
        `;
    };

    for (let i = 0; i < settings.length; i++) {
      result.push(
        <React.Fragment key={i}>
          <HeaderPage
            little
            style={{
              paddingBottom: 20,
              borderBottom: !settings[i].subcategories.length
                ? 'none'
                : '1px solid #00000030',
              marginBottom: 30
            }}
            title={getTitle(settings[i])}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => this.removeCategory(settings[i].name)}
                style={{ marginRight: 25 }}
                value='Удалить категорию'
              />
              <Button
                onClick={() => this.toggleCategoryModal(settings[i].name)}
                value='Добавить подкатегорию'
              />
            </div>
          </HeaderPage>

          {settings[i].subcategories.length ? (
            <Table
              data={settings[i].subcategories.map((a, index) => (
                <tr key={settings[i].name + index}>
                  <td>{a.name}</td>
                  <td>{getWordEnd('продукт', a.products.length)}</td>
                  <td>
                    <Cross
                      onClick={() =>
                        this.removeSubcategory(settings[i].name, a.name)
                      }
                    />
                  </td>
                </tr>
              ))}
              title={['Подкатегория', 'Продуктов', '']}
            />
          ) : null}
        </React.Fragment>
      );
    }
    return result;
  };

  getCatalogXML = () => {
    this.props.getCatalogXML();
  };

  render() {
    const { update } = this.state;
    if (update) return <Loader />;

    return (
      <div>
        {this.renderCategoryModal()}

        <HeaderPage title='Категории Delivery Club'>
          <Button
            onClick={() => this.toggleCategoryModal(false)}
            style={{ marginRight: 10 }}
            value='Добавить категорию'
          />
          <Button
            onClick={this.getCatalogXML}
            value='Выгрузить XML'
          />
        </HeaderPage>

        {this.renderCategories().length ? (
          this.renderCategories()
        ) : (
          <HeaderPage
            little
            style={{ marginTop: 50, opacity: 0.5, justifyContent: 'center' }}
            title='Нет категорий'
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.deliveryClub.settings
  };
};

const mapDispatchToProps = dispatchEvent => ({
  getCatalogXML: (...arg) => dispatchEvent(getCatalogXML(...arg)),
  getDeliveryClubSettings: (...arg) =>
    dispatchEvent(getDeliveryClubSettings(...arg)),
  setDeliveryClubSettings: (...arg) =>
    dispatchEvent(setDeliveryClubSettings(...arg))
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
