import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { server } from '../../actions/index';
import Loader from '../interface/loader';

import arrow from '../../images/arrow.svg';
import edit from '../../images/edit.svg';
import info from '../../images/info.svg';
import time from '../../images/time.svg';
import search from '../../images/search.svg';
import campaign from '../../images/campaign.svg';
import add from '../../images/add.svg';
import returnBonus from '../../images/return.svg';
import order from '../../images/order.svg';
import viewList from '../../images/view-list.svg';
import dropdown from '../../images/dropdown.svg';
import deletes from '../../images/delete.svg';
import block from '../../images/block.svg';
import attach from '../../images/attach.svg';

import './elements.css';

export class PaginationBlock extends Component {
  getClassName = () => {
    let className = 'pagination-block';
    if (this.props.className) className += this.props.className;

    return className;
  };

  renderPaginations = items => {
    const array = items.map(a => {
      return a;
    });
    return array;
  };

  render() {
    return (
      <div className={this.getClassName()}>
        {this.renderPaginations(this.props.items)}
      </div>
    );
  }
}

export class FlexBlock extends Component {
  getClassName = () => {
    const { className, isColumn } = this.props;
    let cn = 'flex-block';
    if (className) cn += ` ${className}`;
    if (isColumn) cn += ' column';
    return cn;
  };

  render() {
    return <div className={this.getClassName()}>{this.props.children}</div>;
  }
}

export class ImageBlock extends Component {
  getClassName = () => {
    let className = 'image-block';
    if (!this.props.image) className += ' default';
    if (this.props.front) className += ' front';
    if (this.props.little) className += ' little';

    return className;
  };

  render() {
    return (
      <div
        className={this.getClassName()}
        style={this.props.style && this.props.style}
      >
        <LoadingPhoto
          index={this.props.index}
          loader={this.props.loader}
          loaderName={this.props.loaderName}
          name={this.props.name}
          onDelete={this.props.onDelete}
          onLoad={this.props.onLoad}
          onlyLoad={this.props.onlyLoad && this.props.onlyLoad}
          onlyRead={this.props.onlyRead}
        />
        <div className='image-block-name'>{this.props.placeholder}</div>
        {this.props.image ? (
          <img
            alt=''
            className='uploadImg'
            src={
              this.props.withoutServer
                ? this.props.image
                : server + this.props.image
            }
          />
        ) : null}
      </div>
    );
  }
}

export class Button extends Component {
  getClassName = () => {
    let className = 'button-element';

    if (this.props.little) className += ' little';
    if (this.props.uploadFile) className += ' uploadFile';
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  };

  render() {
    if (this.props.noForm) {
      return (
        <div
          className={this.getClassName()}
          id={this.props.id && this.props.id}
          onClick={
            this.props.onClick ? event => this.props.onClick(event) : null
          }
          style={this.props.style && this.props.style}
          type={this.props.type ? this.props.type : ''}
        >
          {this.props.value}
        </div>
      );
    }

    return (
      <button
        className={this.getClassName()}
        id={this.props.id && this.props.id}
        onClick={this.props.onClick ? event => this.props.onClick(event) : null}
        style={this.props.style && this.props.style}
        type={this.props.type ? this.props.type : ''}
      >
        {this.props.value}
      </button>
    );
  }
}

export class FormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkbox: false,
      textarea: false,
      radio: false
    };
  }

  componentDidMount() {
    if (this.props.type === 'checkbox') this.setState({ checkbox: true });
    else if (this.props.type === 'textarea') this.setState({ textarea: true });
    else if (this.props.type === 'radio') this.setState({ radio: true });
    else if (this.props.type === 'file') this.setState({ file: true });
  }

  componentClass = () => {
    let formClass = 'form-group';

    if (this.state.checkbox) formClass += ' checkbox';
    if (this.state.textarea) formClass += ' textarea';
    if (this.state.radio) formClass += ' radio';
    if (this.props.onlyLabel) formClass += ' only-label';
    if (this.props.labelTop) formClass += ' label-top';
    if (this.props.items && this.props.items.length > 1)
      formClass += ' label-top';
    if (this.props.className) formClass += ` ${this.props.className}`;

    if (this.props.direction === 'column') formClass += ' column';

    return formClass;
  };

  getStyle = () => {
    let array = {};

    if (this.props.style) array = { ...this.props.style };
    if (this.props.indentBottom)
      array = { ...array, marginBottom: parseInt(this.props.indentBottom, 10) };
    // if(this.state.file) array = {...array, justifyContent: 'flex-start'}

    return array;
  };

  uploadFile = event => {
    this.setState({
      readyFile: event.target.files[0]
    });
  };

  getFile = () => {
    return this.state.readyFile;
  };

  drawInput = (type, { children, ...props }) => {
    switch (type) {
      case 'textarea':
        return <TextArea
          {...props}
          type={type || 'text'}
        />;
      case 'checkbox':
        return (
          <label className='checkbox-label'>
            <Input
              {...props}
              hidden
              type={type || 'text'}
            />
            <div
              className='checkbox'
              key='checkbox'
              name={props.name && props.name}
            />
          </label>
        );
      case 'radio':
        return [
          <Input
            {...props}
            hidden
            key='input-radio'
            type={type || 'text'}
          />,
          <div
            className='radio'
            id={props.id && props.id}
            key='radio'
            name={props.name && props.name}
            onClick={props.onChange}
          />
        ];
      case 'file':
        return [
          <input
            {...props}
            hidden
            key='fileInput'
            onChange={this.uploadFile}
            ref={node => (this.file = node)}
            type='file'
          />,
          <div
            key='fileBox'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className='uploadFile button-element'
              key='fileButton'
              onClick={() => this.file.click()}
            >
              Выберите файл
            </div>
            <p
              key='label'
              style={{ marginLeft: 20 }}
            >
              {this.state.readyFile
                ? this.state.readyFile.name
                : 'Файл не выбран'}
            </p>
          </div>
        ];

      case 'multipleInputs':
        if (this.props.items && this.props.items.length) {
          return (
            <div style={{ width: '100%' }}>
              {' '}
              {this.props.items.map((item, key) => (
                <Input
                  key={`input ${key}`}
                  style={{ marginTop: '10px' }}
                  value={item.message}
                />
              ))}
            </div>
          );
        }
        return <Input value='Купоны не применены' />;

      default:
        return <Input {...props} />;
    }
  };

  getLabelValue = () => {
    if (this.props.type !== 'checkbox' && this.props.type !== 'radio') {
      if (this.props.placeholderLabel) return `${this.props.placeholderLabel}:`;
      return `${this.props.placeholder}:`;
    }
    if (this.props.placeholderLabel) return this.props.placeholderLabel;
    return this.props.placeholder;
  };

  render() {
    return (
      <div
        className={this.componentClass()}
        style={this.getStyle()}
      >
        {this.props.labelHidden ? null : (
          <label htmlFor={this.props.id && this.props.id}>
            {this.getLabelValue()}
          </label>
        )}
        {!this.props.onlyLabel && this.drawInput(this.props.type, this.props)}
        {this.props.children && (
          <div className='children-block'>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export class Form extends Component {
  onSubmit = event => {
    if (event) event.preventDefault();
    if (this.props.onSubmit) this.props.onSubmit(event);
  };

  renderButton = () => {
    const { icon, value, button, hideButton } = this.props;

    if (hideButton) {
      return null;
    }

    if (!icon)
      return (
        <Button
          className={`${button === 'disabled' ? ' disabled' : null}`}
          value={value}
        />
      );

    return (
      <button>
        <SvgButton
          image={icon}
          onClick={this.onSubmit}
        />
      </button>
    );
  };

  renderClassName = () => {
    const { direction } = this.props;
    let className = 'form-element';

    if (direction === 'column') className += ' column';
    else className += ' row';

    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  };

  render() {
    return (
      <form
        {...this.props}
        className={this.renderClassName()}
        onSubmit={this.onSubmit}
      >
        {this.props.children}
        {this.renderButton()}
      </form>
    );
  }
}

export class Table extends Component {
  render() {
    const { className } = this.props;

    return (
      <table
        className={`table-element ${className || ''}`}
        style={this.props.style && this.props.style}
      >
        <thead>
          <tr>
            {this.props.title &&
              this.props.title.map((title, index) => {
                if (index === this.props.title.length - 1)
                  return <th key={`titleRow${index}`}>{title}</th>;
                if (typeof title === 'string')
                  return <th key={`titleRow${index}`}>{title}</th>;
                return title;
              })}
          </tr>
        </thead>
        <tbody>{this.props.data || null}</tbody>
      </table>
    );
  }
}

export class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const className = this.props.className
      ? `input-element ${this.props.className}`
      : 'input-element';

    return (
      <input
        {...this.props}
        className={className}
        maxLength={this.props.maxlength ? this.props.maxlength : '100'}
        minLength={this.props.minlength ? this.props.minlength : '1'}
        ref={node => {
          if (this.props.refName) this.updateZones = node;
        }}
        type={this.props.type ? this.props.type : 'text'}
      />
    );
  }
}

export class TextArea extends Component {
  render() {
    const className = this.props.className
      ? `textarea-element ${this.props.className}`
      : 'textarea-element';

    return (
      <textarea
        {...this.props}
        className={className}
        minLength={this.props.minlength ? this.props.minlength : '1'}
        onChange={event => this.props.onChange(event)}
      />
    );
  }
}

export class HeaderPage extends Component {
  getClass = () => {
    let className = 'header-page';
    if (this.props.little) className += ' little';
    if (this.props.onlyTitle) className += ' only-title';
    if (this.props.notFound) className += ' not-found';

    return className;
  };

  render() {
    return (
      <div
        className={this.getClass()}
        style={this.props.style && this.props.style}
      >
        {this.props.title ? (
          <div
            className='page-title'
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />
        ) : null}
        {this.props.button ? (
          <Button
            noForm={this.props.noForm && this.props.noForm}
            onClick={() => this.props.onClick()}
            value={this.props.button}
          />
        ) : null}
        {this.props.children && (
          <div className='children-block'>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export class ModalTitle extends Component {
  render() {
    return <div className='modal-title'>{this.props.title}</div>;
  }
}

export class Cross extends Component {
  onClick = event => {
    if (this.props.onClick) this.props.onClick(event);
  };

  getClass = () => {
    let className = 'cross-element';
    if (this.props.noModalCross) className += ' no-modal';
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  };

  render() {
    return (
      <div
        className={this.getClass()}
        id={this.props.id}
        onClick={this.onClick}
      >
        <div
          className={
            this.props.noModalCross
              ? 'no-modal cross-element-line'
              : 'cross-element-line'
          }
        />
        <div
          className={
            this.props.noModalCross
              ? 'no-modal cross-element-line'
              : 'cross-element-line'
          }
        />
      </div>
    );
  }
}

export class SvgButton extends Component {
  path = image => {
    switch (image) {
      case 'arrow':
        return arrow;
      case 'info':
        return info;
      case 'time':
        return time;
      case 'delete':
        return deletes;
      case 'block':
        return block;
      case 'edit':
        return edit;
      case 'add':
        return add;
      case 'campaign':
        return campaign;
      case 'search':
        return search;
      case 'return':
        return returnBonus;
      case 'order':
        return order;
      case 'view-list':
        return viewList;
      case 'attach':
        return attach;

      default:
        return false;
    }
  };

  render() {
    return (
      <div
        className={`svg-button-element svg-${this.props.image}`}
        data-content={this.props.dataContent ? this.props.dataContent : null}
        onClick={this.props.onClick ? () => this.props.onClick() : null}
        style={this.props.style && this.props.style}
      >
        <ReactSVG
          className='svg-image-picture'
          src={this.path(this.props.image)}
          wrapperclassname='svg-image'
        />
      </div>
    );
  }
}

export class Modal extends React.PureComponent {
  static defaultProps = {
    animationDuration: 300
  };

  constructor(props) {
    super(props);
    this.state = {
      modalState: false,
      animation: false,
      show: this.props.show
    };
  }

  toggleModal = (show, event) => {
    if (event) event.persist();

    if (show) {
      if (event) {
        if (event.currentTarget.className !== 'no-modal') {
          if (
            event.target.className === 'cross-element' ||
            event.target.className === 'cross-element-line' ||
            event.target.className === 'modal-element-block-wrapper'
          ) {
            this.props.toggleModal();
          }
        }
      }
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.show) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ modalState: true }, () => {
          const modal = document.getElementById('modal');
          if (modal) modal.classList.add('document-modal-open');
        });
      }, 1);
    } else {
      if (this.state.show) {
        this.setState({ modalState: false });
        setTimeout(() => {
          this.setState({ show: false }, () => {
            const modal = document.getElementById('modal');
            if (modal) modal.classList.remove('document-modal-open');
          });
        }, this.props.animationDuration);
      }
    }
  }

  render() {
    if (this.state.show) {
      return (
        <div
          className={
            this.state.modalState
              ? 'modal-element active'
              : 'modal-element hidden'
          }
        >
          <div
            className='modal-element-block-wrapper'
            onClick={event => this.toggleModal(this.state.show, event)}
          >
            <div
              className={
                this.state.modalState
                  ? 'modal-element-block active'
                  : 'modal-element-block'
              }
            >
              <Cross onClick={() => this.toggleModal(this.state.show)} />
              <div className='modal-element-content'>{this.props.children}</div>
            </div>
          </div>
          <div
            className={
              this.state.modalState
                ? 'modal-element-background active'
                : 'modal-element-background active'
            }
          />
        </div>
      );
    }
    return null;
  }
}

export class LoadingPhoto extends Component {
  render() {
    return (
      <div className='loading-elements'>
        {!this.props.onlyRead ? (
          <div className='load-elements-panel'>
            <div
              className='load-element load'
              onClick={() => this.fileInput.click()}
            >
              <ReactSVG
                className='svg-image'
                src={dropdown}
              />
            </div>

            {!this.props.onlyLoad && (
              <div
                className='load-element delete'
                onClick={() =>
                  this.props.onDelete(
                    this.fileInput.name,
                    this.fileInput.dataset.index
                  )
                }
              >
                <ReactSVG
                  className='svg-image'
                  src={deletes}
                />
              </div>
            )}
          </div>
        ) : null}

        <input
          className='update-photo-input'
          data-index={this.props.index}
          data-loader={this.props.loaderName}
          hidden
          name={this.props.name && this.props.name}
          onChange={this.props.onLoad}
          ref={node => (this.fileInput = node)}
          type='file'
        />

        {this.props.loader ? <Loader className='small' /> : null}
      </div>
    );
  }
}

export const SortableItem = SortableElement(({ props, onClick }) => {
  const handleClick = () => onClick(props);

  return (
    <tr>
      <td>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SvgButton image='view-list' />
          <div style={{ marginLeft: '10px' }}>{props}</div>
        </div>
      </td>
      <td>
        <Cross
          noModalCross
          onClick={handleClick}
        />
      </td>
    </tr>
  );
});

export const RenderIngridientsTable = SortableContainer(
  ({ items, onClick }) => {
    return (
      <Table
        data={items.map((value, index) => (
          <SortableItem
            index={index}
            key={`item-${index}`}
            onClick={onClick}
            props={value}
          />
        ))}
        style={{ marginBottom: 30 }}
        title={['Ингредиент', '']}
      />
    );
  }
);
