import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from '../components/Select';
import Loader from '../components/interface/loader';
import { toggleDeliveryZones } from '../actions/modalActions';

import {
  fetchRestaurants,
  clearRestaurants,
  deleteRestaurant
} from '../actions/restaurantActions';

import {
  Table,
  HeaderPage,
  SvgButton,
  Button,
  Cross
} from '../components/elements/elements';

moment.locale('ru');

class RestaurantIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueSelect: 'week',
      update: true,
      tableUpdate: false,
      from: '',
      to: ''
    };
  }

  title = [
    'Название',
    'Средний балл',
    'Скорость доставки',
    'Работа оператора',
    'Вкус еды',
    'Горячий заказ',
    'Вежливость курьеров',
    'Кол-во оценок',
    '',
    ''
  ];

  async componentDidMount() {
    await this.props.fetchRestaurants('', { from: new Date(0).toISOString() });
    this.setState({ update: false });
  }

  componentWillUnmount() {
    this.props.clearRestaurants();
  }

  onDelete = (id, name) => {
    const deleteConfirm = window.confirm(`Вы точно хотите удалить ${name}?`);
    if (deleteConfirm) {
      this.props.deleteRestaurant(id, () => {
        this.props.clearRestaurants();
        this.props.fetchRestaurants();
      });
    }
  };

  getItems = () => {
    const items = [
      { value: 'allTime', text: 'Все время' },
      { value: 'week', text: 'Неделя' },
      { value: 'month', text: 'Месяц' },
      { value: 'custom', text: 'Выбрать период' }
    ];
    return items;
  };

  toNumber = variable => {
    if (variable) {
      variable = Number(variable);
      return variable.toFixed(1);
    }
    return '-';
  };

  renderPeriodInputs = () => {
    const { valueSelect } = this.state;

    if (valueSelect === 'custom') {
      return (
        <React.Fragment>
          <input
            className='input-element'
            name='from'
            onChange={this.onChangeInput}
            placeholder='от'
            style={{ width: '30%' }}
            type='date'
            value={this.state.from}
          />
          <input
            className='input-element'
            name='to'
            onChange={this.onChangeInput}
            placeholder='до'
            style={{ margin: '0 20px', width: '30%' }}
            type='date'
            value={this.state.to}
          />
          <Button
            little
            onClick={this.acceptCustomPeriod}
            value='Потвердить'
          />
        </React.Fragment>
      );
    }
  };

  onChangeInput = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  acceptCustomPeriod = () => {
    const { from, to } = this.state;

    const fromToString = moment(from, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();
    const toToString = moment(to, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();

    const data = {
      from: fromToString,
      to: toToString
    };

    this.props.fetchRestaurants('', data);
  };

  onChangeSelect = async value => {
    await this.setState({
      valueSelect: value
    });
    if (this.state.valueSelect === 'allTime') {
      this.props.fetchRestaurants('', { from: new Date(0).toISOString() });
    } else if (this.state.valueSelect === 'custom') {
      this.setState({ tableUpdate: true });
    } else this.props.fetchRestaurants('', { period: value });
  };

  getRating = (restaurant, type) => {
    if (restaurant.periodPointsRating) {
      switch (type) {
        case 'average':
          return this.toNumber(restaurant.periodAverageRating);
        case 'speed':
          return this.toNumber(restaurant.periodPointsRating.speedRating);
        case 'call':
          return this.toNumber(restaurant.periodPointsRating.callRating);

        case 'taste':
          return this.toNumber(restaurant.periodPointsRating.tasteRating);

        case 'hot':
          return this.toNumber(restaurant.periodPointsRating.hotRating);

        case 'driver':
          return this.toNumber(restaurant.periodPointsRating.driverRating);

        case 'rating':
          return this.toNumber(restaurant.periodRatingCount);
        default:
          break;
      }
    } else return '-';
  };

  render() {
    if (this.state.update) return <Loader />;
    return (
      <div>
        <HeaderPage title='Рестораны'>
          <Select
            items={this.getItems()}
            onChange={this.onChangeSelect}
            style={{ marginRight: 20 }}
          />
          <Link
            style={{ marginRight: 12 }}
            to='/restaurants/new'
          >
            <Button value='Добавить' />
          </Link>
          <Button
            onClick={this.props.toggleDeliveryZones}
            style={{ minWidth: `${200}px` }}
            value='Загрузить зоны'
          />
        </HeaderPage>
        <div>{this.renderPeriodInputs()}</div>
        <div style={{ overflowX: 'scroll' }}>
          <Table
            data={
              this.props.restaurants &&
              this.props.restaurants.map((restaurant, key) => (
                <tr key={key}>
                  <td>{restaurant.name}</td>
                  <td>
                    {this.getRating(
                      restaurant,

                      'average'
                    )}
                  </td>
                  <td>{this.getRating(restaurant, 'speed')}</td>
                  <td>{this.getRating(restaurant, 'call')}</td>
                  <td>{this.getRating(restaurant, 'taste')}</td>
                  <td>{this.getRating(restaurant, 'hot')}</td>
                  <td>{this.getRating(restaurant, 'driver')}</td>
                  <td>{this.getRating(restaurant, 'rating')}</td>
                  <td>
                    <Link to={`/restaurants/edit/${restaurant._id}`}>
                      <SvgButton image='edit' />
                    </Link>
                  </td>
                  <td>
                    <Cross
                      onClick={() =>
                        this.onDelete(restaurant._id, restaurant.name)
                      }
                    />
                  </td>
                </tr>
              ))
            }
            style={{ marginTop: '50px' }}
            title={this.title}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurants: state.restaurants.restaurants
  };
}

export default connect(
  mapStateToProps,
  { fetchRestaurants, clearRestaurants, deleteRestaurant, toggleDeliveryZones }
)(RestaurantIndex);
