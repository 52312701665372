import { GET_PAYMENTS } from '../actions/paymentsActions';

const STATE = {
  items: [],
  pagination: {
    count: 0
  }
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        items: action.response.order,
        pagination: action.response.pagination
      };

    default:
      return state;
  }
}
