import request from '../lib/request';

export const GET_PUSHES = 'GET_PUSHES';
export const CREATE_PUSH = 'CREATE_PUSH';
export const UPDATE_PUSH = 'UPDATE_PUSH';
export const DELETE_PUSH = 'DELETE_PUSH';

export const getPushes = () => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: GET_PUSHES,
      response
    });
  };

  await request('/settings/pushes', {
    success,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });
};

export const createPush = (body, done) => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: CREATE_PUSH,
      response
    });

    dispatch(getPushes());
  };

  await request('/settings/pushes', {
    body: JSON.stringify(body),
    success,
    done,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });
};

export const updatePush = (body, id, done) => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: UPDATE_PUSH,
      response
    });

    dispatch(getPushes());
  };

  await request(`/settings/pushes/${id}`, {
    body: JSON.stringify(body),
    success,
    done,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });
};

export const deletePush = id => async (dispatch, getState) => {
  const success = response => {
    dispatch({
      type: DELETE_PUSH,
      response
    });

    dispatch(getPushes());
  };

  await request(`/settings/pushes/${id}`, {
    success,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });
};
