import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';

import {
  getCatalogXML,
  getDeliveryClubSettings,
  setDeliveryClubSettings
} from '../actions/deliveryClubActions';

import {
  HeaderPage,
  Table,
  SvgButton,
  ModalTitle,
  FormGroup,
  FlexBlock,
  ImageBlock,
  Cross,
  Button
} from '../components/elements/elements';

import {
  fetchProduct,
  fetchMenu,
  fetchCategories
} from '../actions/productActions';

import { getLocalStorage } from '../components/elements/functions';
import Modal from '../components/isolatedModal';
import Select from '../components/Select';

class Page extends Component {
  state = {
    product: {},
    update: true,
    updateModal: false,
    modalProducts: [],
    modalCategory: '',
    showAddModal: false,
    showInfoModal: false,
    titles: ['Название продукта', ''],
    products: getLocalStorage('DeliveryClubProducts') || [],
    showSubcategory: getLocalStorage('ShowSubcategory') || false,
    categoriesLength: getLocalStorage('DeliveryClubSubcategoriesLength') || [],

    reduceArray: [],
    enlargeArray: [],

    category: getLocalStorage('DeliveryClubCategory') || '',
    subcategory: getLocalStorage('DeliveryClubSubcategory') || ''
  };

  componentDidMount = async () => {
    await this.props.fetchCategories();
    this.getDeliveryClubSettings();
  };

  toggleInfoModal = async (product, findProduct) => {
    const id = findProduct
      ? product.products[0]._id
      : product
      ? product.id
      : false;

    if (product) {
      this.props.fetchProduct(id).then(result => {
        this.setState({
          showInfoModal: !this.state.showInfoModal,
          product: result
        });
      });
    } else {
      this.setState({
        showInfoModal: !this.state.showInfoModal,
        product: {}
      });
    }
  };

  checkProductList = array => {
    const handled = {};
    const grouped = [];

    for (let i = 0; i < array.length; i++) {
      if (!handled[array[i].name]) handled[array[i].name] = grouped.length;

      if (!grouped[handled[array[i].name]]) {
        grouped[handled[array[i].name]] = {
          name: array[i].name,
          products: []
        };
      }

      grouped[handled[array[i].name]].products.push(array[i]);
    }

    return grouped;
  };

  toggleAddModal = () => {
    const { categories } = this.props;
    const { showAddModal, products } = this.state;
    const id =
      products.length && products[0] ? products[0].productCategoryId : false;
    let categoryName = '';

    if (!showAddModal) {
      if (id) {
        for (let i = 0; i < categories.length; i++) {
          if (categories[i].id === id) {
            categoryName = categories[i].name;
            break;
          }
        }

        this.props.fetchMenu(id, result => {
          const data = this.checkProductList(result);

          this.setState({
            modalProducts: data,
            modalCategory: categoryName,
            showAddModal: !this.state.showAddModal
          });
        });
      } else {
        this.setState({
          showAddModal: !this.state.showAddModal,
          modalCategory: categoryName
        });
      }
    } else {
      this.setState({
        showAddModal: !this.state.showAddModal,
        modalCategory: categoryName,
        modalProducts: []
      });
    }
  };

  onChangeSubcategory = (value, label) => {
    const { settings } = this.props;
    const { category } = this.state;
    let list = [];

    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name === category) {
        if (settings[i].subcategories && settings[i].subcategories.length) {
          for (let c = 0; c < settings[i].subcategories.length; c++) {
            if (settings[i].subcategories[c].name === label) {
              list = settings[i].subcategories[c].products;
              break;
            }
          }
        }
        break;
      }
    }

    localStorage.setItem('DeliveryClubSubcategory', JSON.stringify(label));
    localStorage.setItem('DeliveryClubProducts', JSON.stringify(list));
    this.setState({ subcategory: label, products: list });
  };

  onChangeCategory = (value, label) => {
    const { settings } = this.props;
    let list = [];
    let show = false;
    let categoriesLength = 0;

    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name === label) {
        if (settings[i].products && settings[i].products.length) {
          list = settings[i].products;
          break;
        } else if (
          settings[i].subcategories &&
          settings[i].subcategories.length
        ) {
          categoriesLength = settings[i].subcategories.length;
          show = true;
          break;
        }
      }
    }

    localStorage.setItem('DeliveryClubCategory', JSON.stringify(label));
    localStorage.setItem('DeliveryClubProducts', JSON.stringify(list));
    localStorage.setItem(
      'DeliveryClubSubcategoriesLength',
      JSON.stringify(categoriesLength)
    );

    if (show) localStorage.setItem('ShowSubcategory', JSON.stringify(show));
    else localStorage.removeItem('DeliveryClubSubcategory');

    this.setState(() => {
      const result = {
        showSubcategory: show,
        categoriesLength,
        category: label,
        products: list
      };

      if (show) return result;
      return { ...result, subcategory: '' };
    });
  };

  getCategories = () => {
    const { settings } = this.props;
    const result = [];

    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name)
        result.push({ text: settings[i].name, value: settings[i].name });
    }

    return result;
  };

  getSubcategories = () => {
    const { settings } = this.props;
    const { category } = this.state;
    let result = [];

    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name === category) {
        if (settings[i].subcategories && settings[i].subcategories.length) {
          result = settings[i].subcategories.map(a => ({
            text: a.name,
            value: a.name
          }));
        }
      }
    }

    return result;
  };

  deleteProductCategory = product => {
    const { subcategory, category } = this.state;

    const confirm = window.confirm(
      `Вы точно хотите удалить продукт «${product.name}» из ${
        subcategory ? `подкатегории «${subcategory}» в ` : ''
      }категории «${category}»?`
    );

    if (confirm)
      this.setState({ reduceArray: [product.id] }, this.updateDeliverySettings);
  };

  renderProductList = () => {
    const { products } = this.state;
    const result = [];

    for (let i = 0; i < products.length; i++) {
      if (products[i]) {
        result.push(
          <tr key={i}>
            <td>{products[i].name}</td>
            <td>
              <div style={{ display: 'flex', float: 'right' }}>
                <SvgButton
                  image='info'
                  onClick={() => this.toggleInfoModal(products[i])}
                  style={{ marginRight: 10 }}
                />
                <Cross
                  image='info'
                  onClick={() => this.deleteProductCategory(products[i])}
                />
              </div>
            </td>
          </tr>
        );
      }
    }

    return result;
  };

  getCatalogXML = () => {
    this.props.getCatalogXML();
  };

  addProductTags = id => {
    let tags = [];
    const arrayTags = [];

    if (this.props.tags) {
      for (let i = 0; i < this.props.tags.length; i++) {
        if (id === this.props.tags[i].id) {
          tags = this.props.tags[i].tags;
          break;
        }
      }

      if (tags && tags.length > 0) {
        tags.map((tag, key) =>
          arrayTags.push(
            <FormGroup
              checked={this.checkFilter(tag)}
              id={`checkboxTags${key}`}
              name={tag}
              onChange={this.handleTags}
              placeholder={tag}
              type='checkbox'
            />
          )
        );
        return [
          <HeaderPage
            onlyTitle
            style={{ paddingTop: `${0}px` }}
            title='Тэги продукта:'
          />,
          <div
            className='product-modal-tags'
            style={{ marginBottom: 30 }}
          >
            {arrayTags}
          </div>
        ];
      }
    }
    return null;
  };

  renderProducts = () => {
    const {
      category,
      showSubcategory,
      subcategory,
      products,
      titles
    } = this.state;

    if ((showSubcategory && !subcategory) || !category) return null;
    else if (!products.length)
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            little
            onClick={this.toggleAddModal}
            style={{ alignSelf: 'flex-end' }}
            value='Добавить продукт'
          />
          <HeaderPage
            little
            style={{ marginTop: 50, opacity: 0.5, justifyContent: 'center' }}
            title={`В «${category}${
              subcategory ? `/${subcategory}` : ''
            }» продуктов нет...`}
          />
        </div>
      );
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          little
          onClick={this.toggleAddModal}
          style={{ alignSelf: 'flex-end' }}
          value='Добавить продукт'
        />
        <Table
          data={this.renderProductList()}
          style={{ marginTop: 30 }}
          title={titles}
        />
      </div>
    );
  };

  renderModalProducts = () => {
    const { modalProducts, enlargeArray, products, reduceArray } = this.state;

    const getButtonLabel = (name, id) => {
      for (let i = 0; i < modalProducts.length; i++) {
        if (modalProducts[i].name === name) {
          const listID = modalProducts[i].products.map(a => a._id);

          if (listID.indexOf(id) !== -1) {
            if (reduceArray.indexOf(id || listID[0]) !== -1) return 'Добавить';
            return 'Убрать';
          }
          if (enlargeArray.indexOf(id || listID[0]) !== -1) return 'Убрать';
          return 'Добавить';
        }
      }
    };

    const addProductInList = (name, id) => {
      const reduce = [...reduceArray];
      const enlarge = [...enlargeArray];

      for (let i = 0; i < modalProducts.length; i++) {
        if (modalProducts[i].name === name) {
          const listID = modalProducts[i].products.map(a => a._id);

          if (listID.indexOf(id) === -1) {
            const workID = id || listID[0];
            if (enlargeArray.indexOf(workID) !== -1)
              enlarge.splice(enlargeArray.indexOf(workID), 1);
            else enlarge.push(workID);

            this.setState({ enlargeArray: enlarge });
          } else {
            const workID = id || listID[0];

            if (reduceArray.indexOf(workID) !== -1)
              reduce.splice(reduceArray.indexOf(workID), 1);
            else reduce.push(workID);

            this.setState({ reduceArray: reduce });
          }

          break;
        }
      }
    };

    return modalProducts.map((a, index) => {
      let deliveryId = '';
      for (let i = 0; i < products.length; i++) {
        if (products[i] && products[i].name === a.name) {
          deliveryId = products[i].id;
          break;
        }
      }

      return (
        <tr key={index}>
          <td>{a.name}</td>
          <td>
            <div
              style={{ display: 'flex', alignItems: 'center', float: 'right' }}
            >
              <SvgButton
                image='info'
                onClick={() => {
                  this.toggleAddModal();
                  this.toggleInfoModal(a, true);
                }}
              />
              <Button
                little
                onClick={() => addProductInList(a.name, deliveryId)}
                style={{ marginLeft: 15, cursor: 'pointer' }}
                value={getButtonLabel(a.name, deliveryId)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  getCategoriesItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  onChangeCategoriesSelect = (value, name) => {
    this.setState({ updateModal: true });
    this.props.fetchMenu(value, result => {
      const data = this.checkProductList(result);
      this.setState({
        modalCategory: name,
        modalProducts: data,
        updateModal: false
      });
    });
  };

  addModalRef = component => {
    if (component) {
      this.addModal = component;
    }
  };

  renderAddModal = () => {
    const {
      showAddModal,
      modalProducts,
      modalCategory,
      updateModal
    } = this.state;

    return (
      <Modal
        onHide={this.toggleAddModal}
        reference={this.addModalRef}
        show={showAddModal}
      >
        {!updateModal ? (
          <React.Fragment>
            <ModalTitle title='Добавить продукт' />
            <Select
              default='Выберите категорию'
              items={this.getCategoriesItems()}
              maxHeight={334}
              onChange={this.onChangeCategoriesSelect}
              style={{ marginBottom: 20 }}
              value={modalCategory}
            />
            <Table
              data={this.renderModalProducts()}
              titles={['Название продукта']}
            />
            <Button
              onClick={this.updateDeliverySettings}
              style={{ marginTop: modalProducts.length ? 30 : 0 }}
              value='Добавить'
            />
          </React.Fragment>
        ) : (
          <Loader relative />
        )}
      </Modal>
    );
  };

  updateDeliverySettings = async () => {
    const settings = await this.props.getDeliveryClubSettings();
    const { category, subcategory, enlargeArray, reduceArray } = this.state;

    if (!enlargeArray.length && !reduceArray.length) {
      alert('Добавьте или удалите продукты, чтобы сохранить изменения');
      return false;
    }

    const data = settings.map(a => ({
      ...a,
      subcategories: a.subcategories.map(b => ({
        ...b,
        products: [...b.products]
      }))
    }));

    for (let i = 0; i < data.length; i++) {
      if (data[i].name === category) {
        if (subcategory) {
          if (data[i].subcategories && data[i].subcategories.length) {
            for (let c = 0; c < data[i].subcategories.length; c++) {
              if (data[i].subcategories[c].name === subcategory) {
                const products = data[i].subcategories[c].products;

                if (reduceArray.length) {
                  reduceArray.forEach(element => {
                    if (products.indexOf(element) !== -1)
                      products.splice(products.indexOf(element), 1);
                  });
                }

                if (enlargeArray.length) {
                  enlargeArray.forEach(element => {
                    products.push(element);
                  });
                }
                break;
              }
            }
          }
          break;
        } else {
          const products = data[i].products;

          if (reduceArray.length) {
            reduceArray.forEach(element => {
              if (products.indexOf(element) !== -1)
                products.splice(products.indexOf(element), 1);
            });
          }

          if (enlargeArray.length) {
            enlargeArray.forEach(element => {
              products.push(element);
            });
          }
        }

        break;
      }
    }

    this.props.setDeliveryClubSettings({ categories: data }, () => {
      if (this.addModal) {
        this.setState(
          {
            update: true
          },
          () => {
            this.addModal.onHide(() => {
              this.setState({
                modalProducts: [],
                update: true,
                enlargeArray: [],
                reduceArray: []
              });
              this.getDeliveryClubSettings();
            });
          }
        );
      }
    });
  };

  getDeliveryClubSettings = async () => {
    const { category, subcategory } = this.state;
    let newCategory = '';
    let newSubcategory = '';
    let products = [];
    let showSubcategory = false;
    let categoriesLength = 0;

    await this.props.getDeliveryClubSettings(true, response => {
      for (let i = 0; i < response.length; i++) {
        if (category && response[i].name === category) {
          newCategory = response[i].name;

          if (response[i].subcategories && response[i].subcategories.length) {
            for (let k = 0; k < response[i].subcategories.length; k++) {
              categoriesLength = response[i].subcategories.length;
              showSubcategory = true;
              if (response[i].subcategories[k].name === subcategory) {
                products = response[i].subcategories[k].products;
                newSubcategory = response[i].subcategories[k].name;
                break;
              }
            }
          } else {
            products = response[i].products;
          }
          break;
        }
      }
    });

    const length = getLocalStorage('DeliveryClubSubcategoriesLength') || 0;
    if (length !== categoriesLength) showSubcategory = true;

    localStorage.setItem(
      'DeliveryClubSubcategory',
      JSON.stringify(newSubcategory)
    );
    localStorage.setItem('DeliveryClubCategory', JSON.stringify(newCategory));
    localStorage.setItem('DeliveryClubProducts', JSON.stringify(products));
    localStorage.setItem(
      'DeliveryClubSubcategoriesLength',
      JSON.stringify(categoriesLength)
    );

    if (newSubcategory)
      localStorage.setItem('ShowSubcategory', JSON.stringify(newSubcategory));
    else localStorage.removeItem('ShowSubcategory');

    this.setState({
      update: false,
      products,
      category: newCategory,
      subcategory: newSubcategory,
      categoriesLength,
      showSubcategory
    });
  };

  renderInfoModal = () => {
    const { showInfoModal, product } = this.state;

    return (
      <Modal
        onHide={this.toggleInfoModal}
        show={showInfoModal}
      >
        {product && product.name ? (
          <React.Fragment>
            <ModalTitle
              title={product.name ? product.name : 'Редактирование продукта'}
            />

            <ImageBlock
              front
              image={product.images[0]}
              loader={this.state.loaderPreview}
              loaderName='loaderPreview'
              name='images[0]'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
              onlyRead
              placeholder='Превью продукта'
            />
            <FormGroup
              disabled
              id='name'
              name='name'
              onChange={this.changeInput}
              placeholder='Название продукта'
              required
              value={product.name}
            />
            <FormGroup
              disabled
              id='description'
              name='description'
              onChange={this.changeInput}
              placeholder='Описание'
              type='textarea'
              value={product.description}
            />

            {product.additionalInfo && (
              <FormGroup
                disabled
                id='dough'
                name='dough'
                onChange={this.changeInput}
                placeholder='Тесто'
                required
                value={product.additionalInfo.dough}
              />
            )}
            {product.additionalInfo && (
              <FormGroup
                disabled
                id='size'
                name='size'
                onChange={this.changeInput}
                placeholder='Размер'
                required
                value={product.additionalInfo.size}
              />
            )}

            {product && this.addProductTags(product.productCategoryId)}

            <HeaderPage
              little
              style={{ opacity: 1, justifyContent: 'center', paddingTop: 20 }}
              title='Дополнительные фото:'
            />

            <FlexBlock>
              <ImageBlock
                front
                image={product.images[1]}
                loader={this.state.loaderPhoto1}
                loaderName='loaderPhoto1'
                name='images[1]'
                onDelete={this.deleteImage}
                onLoad={this.uploadImage}
                onlyRead
                placeholder='Фото 1'
              />
              <ImageBlock
                front
                image={product.images[2]}
                loader={this.state.loaderPhoto2}
                loaderName='loaderPhoto2'
                name='images[2]'
                onDelete={this.deleteImage}
                onLoad={this.uploadImage}
                onlyRead
                placeholder='Фото 2'
              />
            </FlexBlock>

            <FlexBlock>
              <ImageBlock
                front
                image={product.images[3]}
                loader={this.state.loaderPhoto3}
                loaderName='loaderPhoto3'
                name='images[3]'
                onDelete={this.deleteImage}
                onLoad={this.uploadImage}
                onlyRead
                placeholder='Фото 3'
              />
              <ImageBlock
                front
                image={product.images[4]}
                loader={this.state.loaderPhoto4}
                loaderName='loaderPhoto4'
                name='images[4]'
                onDelete={this.deleteImage}
                onLoad={this.uploadImage}
                onlyRead
                placeholder='Фото 4'
              />
            </FlexBlock>
          </React.Fragment>
        ) : (
          <Loader relative />
        )}
      </Modal>
    );
  };

  render() {
    const { update, category, showSubcategory, subcategory } = this.state;

    return (
      <div>
        {this.renderAddModal()}
        {this.renderInfoModal()}

        {update ? (
          <Loader />
        ) : (
          <React.Fragment>
            <HeaderPage
              button='Выгрузить XML'
              onClick={this.getCatalogXML}
              title='Каталог Delivery Club'
            />

            {this.getCategories().length ? (
              <React.Fragment>
                <Select
                  default='Выберите категорию'
                  items={this.getCategories()}
                  onChange={this.onChangeCategory}
                  value={category}
                />
                {showSubcategory && (
                  <Select
                    default='Выберите подкатегорию'
                    items={this.getSubcategories()}
                    onChange={this.onChangeSubcategory}
                    style={{ marginTop: 20 }}
                    value={subcategory}
                  />
                )}
                {this.renderProducts()}
              </React.Fragment>
            ) : (
              <HeaderPage
                little
                style={{
                  marginTop: 50,
                  opacity: 0.5,
                  justifyContent: 'center'
                }}
                title='Нет категорий'
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.products.categories,
    settings: state.deliveryClub.settings
  };
};

const mapDispatchToProps = dispatchEvent => ({
  getCatalogXML: (...arg) => dispatchEvent(getCatalogXML(...arg)),
  fetchCategories: (...arg) => dispatchEvent(fetchCategories(...arg)),
  fetchMenu: (...arg) => dispatchEvent(fetchMenu(...arg)),
  fetchProduct: (...arg) => dispatchEvent(fetchProduct(...arg)),
  getDeliveryClubSettings: (...arg) =>
    dispatchEvent(getDeliveryClubSettings(...arg)),
  setDeliveryClubSettings: (...arg) =>
    dispatchEvent(setDeliveryClubSettings(...arg))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
