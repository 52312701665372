import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makePush } from '../actions/pushActions';
import { HeaderPage, Form, FormGroup } from '../components/elements/elements';
import Select from '../components/Select';

const items = [
  { text: 'Акция', value: 'campaigns' },
  { text: 'Купоны', value: 'coupons' },
  { text: 'Профиль', value: 'profile' },
  { text: 'Бонусные баллы', value: 'bonuses' },
  { text: 'Личные данные', value: 'personal' },
  { text: 'История заказов', value: 'history' },
  { text: 'Сканировать чек', value: 'scan' },
  { text: 'Без перехода', value: '' }
];

class PushPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pushText: '',
      pushType: 'campaigns'
    };
  }

  handleChange = event => {
    this.setState({ pushText: event.target.value });
  };

  makePush = () => {
    const data = { text: this.state.pushText, pushType: this.state.pushType };
    if (this.state.pushType === '') delete data.pushType;

    this.props.makePush(data);
  };

  handleChangeSelect = value => {
    this.setState({ pushType: value });
  };

  render() {
    // Лучшая пицца в Телепицце! Следите за акциями!
    return [
      <HeaderPage
        key='1'
        title='Push уведомления'
      />,
      <Form
        direction='row'
        key='2'
        onSubmit={this.makePush}
        style={{ marginBottom: 60 }}
        value='Отправить'
      >
        <FormGroup
          id='searchUser'
          minlength='10'
          name='user'
          onChange={this.handleChange}
          placeholder='Сообщение'
          required
          value={this.state.user}
        />
        <Select
          items={items}
          onChange={this.handleChangeSelect}
          style={{ marginLeft: '40px', width: '30%' }}
        />
      </Form>
    ];
  }
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  { makePush }
)(PushPage);
