import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchCourierSettings,
  updateCourierSettings,
  clearCourierSettings
} from '../actions/settingsActions';
import {
  toggleAddCouponsProductModal,
  toggleCouponsProductModal
} from '../actions/modalActions';
import { HeaderPage, FormGroup } from '../components/elements/elements';
import Loader from '../components/interface/loader';

class SettingsPage extends Component {
  state = {
    courierSettings: {}
  };

  componentDidMount() {
    this.props.fetchCourierSettings();
  }

  componentWillUnmount() {
    this.props.clearCourierSettings();
  }

  componentWillReceiveProps(nexProps) {
    this.setState({ courierSettings: nexProps.courierSettings });
  }

  handleChange = event => {
    const value = event.target.value;
    if (event.target.name) {
      const name = event.target.name;
      this.setState(prevState => ({
        courierSettings: {
          ...prevState.courierSettings,
          [name]: value
        }
      }));
    } else {
      const datatype = event.target.getAttribute('datatype');
      const datagroup = event.target.getAttribute('datagroup');

      this.setState(prevState => ({
        courierSettings: {
          ...prevState.courierSettings,
          [datagroup]: {
            ...prevState.courierSettings[datagroup],
            [datatype]: value
          }
        }
      }));
    }
  };

  handleSubmit = () =>
    this.props.updateCourierSettings(this.state.courierSettings);

  render() {
    const { courierSettings } = this.state;

    if (!courierSettings || courierSettings.length === 0) return <Loader />;
    return (
      <div>
        <HeaderPage
          button='Обновить'
          onClick={this.handleSubmit}
          title='Настройки'
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Время подтверждения заказа'
        />
        <FormGroup
          direction='column'
          id='minutesToDeliverOrder'
          maxlength='2'
          name='minutesToDeliverOrder'
          onChange={this.handleChange}
          placeholder='Минимальное время подтверждения заказа (минуты)'
          value={courierSettings.minutesToDeliverOrder}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Учет заказов'
        />
        <FormGroup
          direction='column'
          id='minLevelUpSum'
          name='minLevelUpSum'
          onChange={this.handleChange}
          placeholder='Минимальная сумма'
          value={courierSettings.minLevelUpSum}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Автоматическое подтверждения заказа'
        />
        <FormGroup
          direction='column'
          id='minAutoDistance'
          maxlength='3'
          name='minAutoDistance'
          onChange={this.handleChange}
          placeholder='Радиус автоматического подтверждения заказа (метры)'
          value={courierSettings.minAutoDistance}
        />

        <HeaderPage
          little
          style={{
            paddingBottom: 15,
            borderBottom: `${1}px solid #00000030`,
            marginBottom: 25
          }}
          title='Радиус подтверждения заказа'
        />
        <FormGroup
          direction='column'
          id='minManualDistance'
          maxlength='4'
          name='minManualDistance'
          onChange={this.handleChange}
          placeholder='Минимальный радиус подтверждения заказа (метры)'
          value={courierSettings.minManualDistance}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    courierSettings: state.settings.courierSettings
  };
}

export default connect(mapStateToProps, {
  fetchCourierSettings,
  updateCourierSettings,
  clearCourierSettings,
  toggleAddCouponsProductModal,
  toggleCouponsProductModal
})(SettingsPage);
