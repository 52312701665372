import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ChangeRequestsPage from '../../pages/changeRequestsPage';

import CouponsPage from '../../pages/couponsPage';
import LettersPage from '../../pages/lettersPage';
import OptionalModifiersSettings from '../../pages/optionalModifiers';
import OptionalModifiersProduct from '../../pages/optionalModifiersProduct';
import PaymentsReportPage from '../../pages/paymentsReportPage';
import PromocodesPage from '../../pages/promocodesPage';
import CampaignsPage from '../../pages/campaignsPage';
import RecallsPage from '../../pages/recallsPage';
import VacanciesPage from '../../pages/vacanciesPage';
import CategoriesPage from '../../pages/categoriesPage';
import ProductsPage from '../../pages/productsPage';
import RestaurantsPage from '../../pages/restaurantsPage';
import RestaurantForm from '../../pages/restaurantForm';
import OrdersSearchPage from '../../pages/ordersSearchPage';
import OrdersPage from '../../pages/ordersPage';
import UsersPage from '../../pages/usersPage';
import DeletedUsersPage from '../../pages/deletedUsersPage';
import PushPage from '../../pages/pushPage';
import EmailsPage from '../../pages/emailsPage';
import SettingsPage from '../../pages/settingsPage';
import StatisticPage from '../../pages/statisticPage';
import QRStatisticPage from '../../pages/QRStatisticPage';
import GroupsPage from '../../pages/groupsPage';
import CouriersPage from '../../pages/couriersPage';
import CustomEmails from '../../pages/emailsCustomPage';
import LoginPage from '../../pages/loginPage';
import SeoPage from '../../pages/seoPage';
import DeliveryClubCatalogPage from '../../pages/deliveryClubCatalogPage';
import DeliveryClubCategoriesPage from '../../pages/deliveryClubCategoriesPage';
import CouriersBonusesPage from '../../pages/couriersBonusesPage';
import CouriersSettingsPage from '../../pages/couriersSettingsPage';
import CouriersRequestsPage from '../../pages/couriersRequestsPage';
import CourierOrdersPage from '../../pages/courierOrdersPage';
import AdviseSettingsPage from '../../pages/adviseSettingsPage';
import PushesPage from '../../pages/pushesPage';
import CouriersReportPage from '../../pages/CouriersReportPage';
import { PaymentDelivery } from '../../pages/services';

// import StartPage from '../../pages/startPage';

class Routes extends Component {
  render() {
    const { token } = this.props;

    return (
      <div id="content-page">
        <Route
          component={token ? SettingsPage : LoginPage}
          exact
          path="/setting"
        />
        <Route
          component={token ? CouponsPage : LoginPage}
          exact
          path="/coupons"
        />
        <Route
          component={token ? PromocodesPage : LoginPage}
          exact
          path="/promocodes"
        />
        <Route
          component={token ? DeliveryClubCatalogPage : LoginPage}
          exact
          path="/delivery-club"
        />
        <Route
          component={token ? DeliveryClubCategoriesPage : LoginPage}
          exact
          path="/delivery-club-categories"
        />
        <Route
          component={token ? CampaignsPage : LoginPage}
          exact
          path="/campaigns"
        />
        <Route
          component={token ? RecallsPage : LoginPage}
          exact
          path="/recalls"
        />
        <Route
          component={token ? RecallsPage : LoginPage}
          exact
          path="/recalls/:id"
        />
        <Route
          component={token ? RecallsPage : LoginPage}
          exact
          path="/recalls/answer/:id"
        />
        <Route
          component={token ? VacanciesPage : LoginPage}
          exact
          path="/vacancies"
        />
        <Route
          component={token ? CategoriesPage : LoginPage}
          exact
          path="/categories"
        />
        <Route
          component={token ? ProductsPage : LoginPage}
          exact
          path="/products"
        />
        <Route
          component={token ? RestaurantsPage : LoginPage}
          exact
          path="/restaurants"
        />
        <Route
          component={token ? RestaurantForm : LoginPage}
          exact
          path="/restaurants/new"
        />
        <Route
          component={token ? RestaurantForm : LoginPage}
          exact
          path="/restaurants/edit/:id"
        />
        <Route
          component={token ? OrdersSearchPage : LoginPage}
          exact
          path="/searchOrders"
        />
        <Route
          component={token ? OrdersPage : LoginPage}
          exact
          path="/orders"
        />
        <Route component={token ? UsersPage : LoginPage} exact path="/users" />
        <Route
          component={token ? DeletedUsersPage : LoginPage}
          exact
          path="/deletedUsers"
        />
        <Route component={token ? PushPage : LoginPage} exact path="/push" />
        <Route
          component={token ? EmailsPage : LoginPage}
          exact
          path="/emails"
        />
        <Route
          component={token ? CustomEmails : LoginPage}
          exact
          path="/custom-emails"
        />
        <Route
          component={token ? StatisticPage : LoginPage}
          exact
          path="/statistic"
        />
        <Route
          component={token ? QRStatisticPage : LoginPage}
          exact
          path="/qrstatistic"
        />
        <Route
          component={token ? GroupsPage : LoginPage}
          exact
          path="/groups"
        />
        <Route
          component={token ? CouriersBonusesPage : LoginPage}
          exact
          path="/couriers-bonuses"
        />
        <Route
          component={token ? CouriersSettingsPage : LoginPage}
          exact
          path="/couriers-settings"
        />
        <Route
          component={token ? AdviseSettingsPage : LoginPage}
          exact
          path="/advise-settings"
        />
        <Route
          component={token ? OptionalModifiersSettings : LoginPage}
          exact
          path="/optional-modifiers"
        />
        <Route
          component={token ? OptionalModifiersProduct : LoginPage}
          exact
          path="/optional-modifiers/:productId"
        />
        <Route
          component={token ? CourierOrdersPage : LoginPage}
          exact
          path="/couriers/orders/:id"
        />
        <Route
          component={token ? CouriersRequestsPage : LoginPage}
          exact
          path="/couriers-requests"
        />
        <Route
          component={token ? ChangeRequestsPage : LoginPage}
          exact
          path="/change_requests/:type"
        />
        <Route component={token ? CouriersPage : LoginPage} exact path="/" />
        <Route component={token ? SeoPage : LoginPage} exact path="/seo" />
        <Route
          component={token ? StatisticPage : LoginPage}
          exact
          path="/statistics"
        />
        <Route
          component={token ? LettersPage : LoginPage}
          exact
          path="/letters"
        />
        <Route
          component={token ? PushesPage : LoginPage}
          exact
          path="/pushes"
        />
        <Route
          component={token ? PaymentsReportPage : LoginPage}
          exact
          path="/payments-report"
        />
        <Route
          component={token ? PaymentDelivery : LoginPage}
          exact
          path="/service-payment-delivery"
        />
        <Route
          component={token ? CouriersReportPage : LoginPage}
          exact
          path="/couriers-report"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token
  };
}

export default withRouter(connect(mapStateToProps, {})(Routes));
