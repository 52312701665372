import {
  FETCH_DELETED_USERS,
  CLEAR_DELETED_USERS
} from '../actions/deletedUsersActions';

const INITIAL_STATE = {
  deletedUser: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DELETED_USERS:
      return { ...state, deletedUser: action.payload };
    case CLEAR_DELETED_USERS:
      return { ...state, deletedUser: action.payload };
    default:
      return state;
  }
}
