import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import {
  updateVacancy,
  createVacancy,
  fetchVacancies,
  clearVacancies
} from '../../../actions/vacanciesActions';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vacancy: []
    };
  }

  refresh = () => {
    this.props.clearVacancies();
    this.props.fetchVacancies();
  };

  componentWillReceiveProps(newState) {
    if (this.props.show !== newState.show) {
      this.setState({
        vacancy: newState.vacancy
      });
    }
  }

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      vacancy: {
        ...prevState.vacancy,
        [name]: value
      }
    }));
  };

  updateData = () => {
    this.props.updateVacancy(this.props.vacancy._id, this.state.vacancy, () => {
      this.refresh();
      this.props.toggleModal();
    });
  };

  createData = () => {
    this.props.createVacancy(this.state.vacancy, () => {
      this.refresh();
      this.props.toggleModal();
    });
  };

  render() {
    const { vacancy } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.props.vacancy ? this.updateData : this.createData}
          value={
            this.props.vacancy ? 'Сохранить изменения' : 'Создать вакансию'
          }
        >
          <ModalTitle
            title={this.props.vacancy ? `«${vacancy.name}»` : 'Добавить акцию'}
          />

          <FormGroup
            id='name'
            name='name'
            onChange={this.changeInput}
            placeholder='Название вакансии'
            required
            value={vacancy.name}
          />
          <FormGroup
            id='interview'
            name='interview'
            onChange={this.changeInput}
            placeholder='Место встречи'
            required
            type='textarea'
            value={vacancy.interview}
          />
          <FormGroup
            id='description'
            name='description'
            onChange={this.changeInput}
            placeholder='Описание'
            required
            type='textarea'
            value={vacancy.description}
          />
          <FormGroup
            id='requirements'
            name='requirements'
            onChange={this.changeInput}
            placeholder='Требования'
            required
            type='textarea'
            value={vacancy.requirements}
          />
          <FormGroup
            id='duties'
            name='duties'
            onChange={this.changeInput}
            placeholder='Обязанности'
            required
            type='textarea'
            value={vacancy.duties}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { vacancy: state.modal.modalContent };
}

export default connect(mapStateToProps, {
  fetchVacancies,
  clearVacancies,
  updateVacancy,
  createVacancy
})(ModalComponent);
