import React from 'react';
import arrayMove from 'array-move';
import { connect } from 'react-redux';

import { updateCatalog, fetchMenu } from '../../../actions/productActions';
import {
  refreshGroups,
  fetchGroups,
  updateGroup,
  createGroup,
  deleteGroupProducts
} from '../../../actions/groupsActions';

import {
  Modal,
  ModalTitle,
  Form,
  FormGroup,
  ImageBlock,
  FlexBlock,
  HeaderPage,
  Table,
  Cross,
  SvgButton,
  RenderIngridientsTable
} from '../../elements/elements';

import Select from '../../Select';
import { uploadImage } from '../../../actions';

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      array: [],
      deleteArray: [],
      arrayIngredient: [],
      deleteArrayIngredient: [],
      group: {
        name: '',
        description: '',
        images: [],
        products: [],
        removableIngredients: [],
        tags: []
      },

      nameIngredient: '',
      loaderPreview: false,
      loaderPhoto1: false,
      loaderPhoto2: false,
      loaderPhoto3: false,
      loaderPhoto4: false,

      productCategoryId: false,
      productCategoryName: false
    };
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      if (newState.group) {
        this.setState({
          array: [],
          deleteArray: [],
          group: newState.group
        });
      } else {
        this.setState({
          array: [],
          deleteArray: [],
          group: {
            name: '',
            description: '',
            tags: [],
            images: [],
            products: [],
            removableIngredients: []
          },

          nameIngredient: ''
        });
      }
    }
  }

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'nameIngredient') {
      this.setState({ [name]: value });
    }

    this.setState(prevState => ({
      group: {
        ...prevState.group,
        [name]: value
      }
    }));
  };

  onChangeArray = (event, element) => {
    const { array } = this.state;
    array[element] = event.target.value;

    this.setState({ array });
  };

  handleChange = event => {
    event.persist();
    if (event.target.name && event.target.value) {
      if (event.target.name === 'size' || event.target.name === 'dough') {
        this.setState(prevState => ({
          group: {
            ...prevState.group,
            additionalInfo: {
              ...prevState.group.additionalInfo,
              [event.target.name]: event.target.value
            }
          }
        }));
      }
      this.setState(prevState => ({
        group: {
          ...prevState.group,
          [event.target.name]: event.target.value
        }
      }));
    }
  };

  uploadImage = event => {
    event.persist();
    const arrayImages = this.state.group.images;

    this.setState({ [event.target.dataset.loader]: true });

    this.props
      .uploadImage('image', event.target.files[0])
      .then(picture => {
        if (event.target.dataset.loader === 'loaderPreview')
          arrayImages[0] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto1')
          arrayImages[1] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto2')
          arrayImages[2] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto3')
          arrayImages[3] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto4')
          arrayImages[4] = picture;

        this.setState(prevState => ({
          ...prevState,
          group: {
            ...prevState.group,
            images: arrayImages
          },
          [event.target.dataset.loader]: false
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  deleteImage = name => {
    const arrayImages = this.state.group.images;

    if (name === 'images[0]') arrayImages[0] = '';
    else if (name === 'images[1]') arrayImages[1] = '';
    else if (name === 'images[2]') arrayImages[2] = '';
    else if (name === 'images[3]') arrayImages[3] = '';
    else if (name === 'images[4]') arrayImages[4] = '';

    this.setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        images: arrayImages
      }
    }));
  };

  getTitles = () => {
    return ['Наименование', 'ID', ''];
  };

  getTitlesProduct = () => {
    return ['Наименование', ''];
  };

  refreshPage = (id, name) => {
    if (!id) return alert('Не передан id для обновления страницы.');
    this.props.toggleModal();
    this.props.refreshGroups();
    this.props.fetchGroups(id);

    if (name) {
      localStorage.setItem('GroupCategoryID', JSON.stringify(id));
      localStorage.setItem('GroupCategoryName', JSON.stringify(name));
    }
  };

  addIngredient = () => {
    const { nameIngredient } = this.state;

    this.setState(prevState => ({
      ...prevState,
      nameIngredient: '',
      group: {
        ...prevState.group,
        removableIngredients: [
          ...prevState.group.removableIngredients,
          nameIngredient
        ]
      }
    }));
  };

  onUpdate = () => {
    const images = [];
    const { group, deleteArray } = this.state;

    for (let i = 0; i < group.images.length; i++) {
      if (!group.images[i]) images[i] = '';
      else images[i] = group.images[i];
    }

    const data = {
      group: {
        ...group,
        images
      }
    };

    if (deleteArray && deleteArray.length) {
      const deleteData = { products: deleteArray };

      this.props.deleteGroupProducts(this.state.group._id, deleteData, () => {
        this.props.updateGroup(this.state.group._id, data, () =>
          this.refreshPage(group.productCategoryId)
        );
      });
    } else {
      this.props.updateGroup(this.state.group._id, data, () =>
        this.refreshPage(group.productCategoryId)
      );
    }
  };

  onCreate = () => {
    const { group, array } = this.state;

    const data = {
      group: { ...group, productCategoryId: this.state.productCategoryId }
    };
    data.group.products = array;

    this.props.createGroup(data, () =>
      this.refreshPage(
        this.state.productCategoryId,
        this.state.productCategoryName
      )
    );
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  onChangeSelect = (value, name) => {
    this.setState({ productCategoryId: value, productCategoryName: name });
  };

  renderInputs = response => {
    if (response.length > 0) {
      const array = [];
      for (let a = 0; a <= response.length; a++) {
        array.push(
          <div
            className={`datepicker-block ${a === 0 && 'first'}`}
            key={a}
          >
            <FormGroup
              id={`addingProduct${a}`}
              labelHidden
              name={`addingProduct${a}`}
              onChange={event => this.onChangeArray(event, a)}
              placeholder='Добавьте ID...'
              value={response[a] ? response[a] : ''}
            />
            {response[a] && (
              <Cross
                className='datepicker-cross'
                noModalCross
                onClick={() => this.onDelete(a, response[a])}
              />
            )}
          </div>
        );
      }
      return array;
    }
    return (
      <div
        className='datepicker-block first'
        key={0}
      >
        <FormGroup
          id={`addingProduct${0}`}
          labelHidden
          name={`addingProduct${0}`}
          onChange={event => this.onChangeArray(event, 0)}
          placeholder='Добавьте ID...'
          value={response[0] ? response[0] : ''}
        />
      </div>
    );
  };

  deleteProduct = (id, key) => {
    const products = [...this.state.group.products];
    const { deleteArray } = this.state;

    products.splice(key, 1);
    deleteArray.push(id);

    this.setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        products
      }
    }));
  };

  deleteIngredient = a => {
    const { removableIngredients } = this.state.group;
    const index = removableIngredients.indexOf(a);

    if (index !== -1) {
      removableIngredients.splice(index, 1);

      this.setState(prevState => ({
        ...prevState,

        group: {
          ...prevState.group,
          removableIngredients: [...removableIngredients]
        }
      }));
    }
  };

  onDelete = element => {
    const { array } = this.state;
    array.splice(element, 1);

    this.setState({ array });
  };

  checkFilter = tag => {
    if (this.props.group.tags.indexOf(tag) !== -1) return true;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ group }) => ({
      group: {
        ...this.state.group,
        removableIngredients: arrayMove(
          group.removableIngredients,
          oldIndex,
          newIndex
        )
      }
    }));
  };

  handleTags = event => {
    event.persist();

    if (this.state.group.tags == null) {
      this.setState(prevState => ({
        group: {
          ...prevState.group,
          tags: [event.target.name]
        }
      }));
    } else {
      if (this.checkFilter(event.target.name)) {
        const tagsData = this.state.group.tags;
        tagsData.splice(this.state.group.tags.indexOf(event.target.name), 1);

        this.setState(prevState => ({
          group: {
            ...prevState.group,
            tags: tagsData
          }
        }));
      } else {
        this.setState(prevState => ({
          group: {
            ...prevState.group,
            tags: [...prevState.group.tags, event.target.name]
          }
        }));
      }
    }
  };

  addProductTags = id => {
    let tags = [];

    const arrayTags = [];

    if (this.props.tags) {
      for (let i = 0; i < this.props.tags.length; i++) {
        if (id === this.props.tags[i].id) {
          tags = this.props.tags[i].tags;
          break;
        }
      }

      if (tags && tags.length > 0) {
        tags.map((tag, key) =>
          arrayTags.push(
            <FormGroup
              checked={this.checkFilter(tag)}
              id={`checkboxTags${key}`}
              key={`form-group-${key}`}
              name={tag}
              onChange={this.handleTags}
              placeholder={tag}
              type='checkbox'
            />
          )
        );
        return (
          <React.Fragment>
            <HeaderPage
              onlyTitle
              style={{ paddingTop: `${0}px` }}
              title='Тэги продукта:'
            />
            <div
              className='product-modal-tags'
              style={{ marginBottom: 30 }}
            >
              {arrayTags}
            </div>
          </React.Fragment>
        );
      }
    }
    return null;
  };

  render() {
    const { group, array } = this.state;
    const { removableIngredients } = this.state.group;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.props.group.name ? this.onUpdate : this.onCreate}
          value={
            this.props.group.name ? 'Сохранить изменения' : 'Добавить группу'
          }
        >
          <ModalTitle
            title={this.props.group.name ? group.name : 'Добавить группу'}
          />

          {!this.props.group.name && this.props.categories && (
            <Select
              default='Выберите категорию'
              items={this.getItems()}
              onChange={this.onChangeSelect}
              style={{ marginBottom: 30 }}
              value={this.state.categoryName && this.state.categoryName}
            />
          )}

          <ImageBlock
            front
            image={group.images[0]}
            loader={this.state.loaderPreview}
            loaderName='loaderPreview'
            name='images[0]'
            onDelete={this.deleteImage}
            onLoad={this.uploadImage}
            placeholder='Превью группы'
          />
          <FormGroup
            id='name'
            name='name'
            onChange={this.changeInput}
            placeholder='Название продукта'
            required
            value={group.name}
          />
          <FormGroup
            id='description'
            name='description'
            onChange={this.changeInput}
            placeholder='Описание'
            type='textarea'
            value={group.description ? group.description : ''}
          />

          {this.props.group.name && !group.singleProduct && (
            <HeaderPage
              little
              notFound={group.products.length > 0}
              style={{ opacity: 1, justifyContent: 'center' }}
              title={
                group.products.length > 0
                  ? 'Продукты группы'
                  : 'В группе продуктов нет...'
              }
            />
          )}

          {this.props.group.name &&
            !group.singleProduct &&
            group.products.length > 0 && (
              <Table
                data={group.products.map((group, key) => (
                  <tr key={key}>
                    <td>{group.name}</td>
                    <td>{group._id}</td>
                    <td>
                      <Cross
                        noModalCross
                        onClick={() => this.deleteProduct(group._id, key)}
                      />
                    </td>
                  </tr>
                ))}
                style={{ marginBottom: 30 }}
                title={this.getTitles()}
              />
            )}
          {removableIngredients ? (
            <RenderIngridientsTable
              items={this.state.group.removableIngredients}
              onClick={this.deleteIngredient}
              onSortEnd={this.onSortEnd}
            />
          ) : (
            'Ингредиенты отсутствуют'
          )}
          <div className='name-ingredient'>
            <FormGroup
              id='nameIngredient'
              name='nameIngredient'
              onChange={this.changeInput}
              placeholder='Название ингредиента'
              value={this.state.nameIngredient}
            />
            <SvgButton
              image='add'
              onClick={this.addIngredient}
              style={{ marginLeft: 15 }}
            />
          </div>
          {group && this.addProductTags(group.productCategoryId)}

          {!this.props.group.name && (
            <HeaderPage
              little
              style={{ opacity: 1, justifyContent: 'center' }}
              title='Продукты группы'
            />
          )}
          {!this.props.group.name && (
            <div style={{ marginBottom: 20 }}>{this.renderInputs(array)}</div>
          )}

          <HeaderPage
            little
            style={{ opacity: 1, justifyContent: 'center', paddingTop: 20 }}
            title='Дополнительные фото:'
          />

          <FlexBlock>
            <ImageBlock
              front
              image={group.images[1]}
              loader={this.state.loaderPhoto1}
              loaderName='loaderPhoto1'
              name='images[1]'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
              placeholder='Фото 1'
            />
            <ImageBlock
              front
              image={group.images[2]}
              loader={this.state.loaderPhoto2}
              loaderName='loaderPhoto2'
              name='images[2]'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
              placeholder='Фото 2'
            />
          </FlexBlock>

          <FlexBlock>
            <ImageBlock
              front
              image={group.images[3]}
              loader={this.state.loaderPhoto3}
              loaderName='loaderPhoto3'
              name='images[3]'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
              placeholder='Фото 3'
            />
            <ImageBlock
              front
              image={group.images[4]}
              loader={this.state.loaderPhoto4}
              loaderName='loaderPhoto4'
              name='images[4]'
              onDelete={this.deleteImage}
              onLoad={this.uploadImage}
              placeholder='Фото 4'
            />
          </FlexBlock>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.modal.modalContent,
    tags: state.products.tags,
    categories: state.products.categories
  };
}

export default connect(mapStateToProps, {
  deleteGroupProducts,
  createGroup,
  updateGroup,
  refreshGroups,
  uploadImage,
  updateCatalog,
  fetchGroups,
  fetchMenu
})(ModalComponent);
