import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { API } from '../actions/index';
import { fetchCourierStats } from '../actions/couriersActions';

const BASE_FILENAME = 'CourierReport_';

class CouriersReportPage extends Component {
  state = {
    from: null,
    to: null,
    url: ''
  };

  componentDidMount() {}

  componentWillUnmount() {}

  componentWillReceiveProps(nexProps) {}

  onChangeDate = name => date => {
    this.setState({ [name]: date });
  };

  getOldFile = ({ from, to }) => {
    if (!to) return;
    const query = `from=${this.state.from}&to=${moment(this.state.to).add(
      1,
      'd'
    )}`;
    const url = `${API.url}/restaurants/all/delivery_courier.xlsx?${query}`;
    this.props.fetchCourierStats(url);
  };

  getFile = ({ from, to }) => {
    if (!to) return;

    const dateFrom = this.state.from;
    const dateTo = this.state.to;

    const filename = `${BASE_FILENAME}${moment(dateFrom).format(
      'DD_MM_HH:mm'
    )}-${moment(dateTo).format('DD_MM_HH:mm')}`;

    const query = `from=${dateFrom}&to=${moment(dateTo).add(1, 'd')}`;
    const url = `${API.url}/restaurants/all/couriers-fresh-report.xlsx?${query}`;
    this.props.fetchCourierStats(url, filename);
  };

  getFileByDates = ({ from, to }) => {
    if (!to) return;

    const dateFrom = this.state.from;
    const dateTo = this.state.to;

    const filename = `${BASE_FILENAME}${moment(dateFrom).format(
      'DD_MM_HH:mm'
    )}-${moment(dateTo).format('DD_MM_HH:mm')}`;

    const query = `from=${dateFrom}&to=${moment(dateTo).add(1, 'd')}`;
    const url = `${API.url}/restaurants/all/couriers-fresh-report-with-days.xlsx?${query}`;
    this.props.fetchCourierStats(url, filename);
  };

  render() {
    const { from, to } = this.state;

    return (
      <div>
        <div className='date-pickers-wrapper row'>
          <div className='date-picker-container'>
            <span>Период от</span>
            <DatePicker
              className='form-control'
              dateFormat='dd.MM.yyyy'
              onChange={this.onChangeDate('from')}
              placeholderText='от'
              selected={this.state.from}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <div className='date-picker-container'>
            <span>до</span>
            <DatePicker
              className='form-control'
              dateFormat='dd.MM.yyyy'
              onChange={this.onChangeDate('to')}
              placeholderText='до'
              selected={this.state.to}
              showMonthDropdown
              showYearDropdown
            />
          </div>
        </div>
        <Button
          bsStyle='success'
          onClick={() =>
            this.getOldFile({
              from,
              to
            })
          }
        >
          Скачать
        </Button>
        <Button
          bsStyle='success'
          onClick={() =>
            this.getFile({
              from,
              to
            })
          }
          style={{
            marginLeft: 20
          }}
        >
          Скачать (новый алгоритм)
        </Button>
        <Button
          bsStyle='success'
          onClick={() =>
            this.getFileByDates({
              from,
              to
            })
          }
          style={{
            marginLeft: 20
          }}
        >
          Скачать (по датам)
        </Button>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // courierSettings: state.settings.courierSettings
  };
}

export default connect(mapStateToProps, {
  fetchCourierStats
})(CouriersReportPage);
