import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../interface/loader';
import {
  deleteCoupon,
  fetchUsers,
  clearUsers,
  getUserHistory
} from '../../../actions/usersActions';
import { Modal, ModalTitle, Table, HeaderPage } from '../../elements/elements';
import { PaginationBlock } from '../../elements/paginations/index';
import { getCouponName } from '../../elements/functions';

import moment from 'moment';
import { isNumber } from 'util';
import Select from '../../Select';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {
        count: 0,
        history: []
      },
      parametres: {
        page: 1,
        count: 15,
        slices: false
      },
      loading: true,
      couponType: ''
    };
  }

  title = ['Купон', 'Код', 'Сотрудник', 'Событие'];

  titleSlices = ['Купон', 'Код', 'Сотрудник', 'Событие', 'Кол-во'];

  loadData = page => {
    const id = this.props.data && this.props.data.id;
    const parametres = {
      ...this.state.parametres,
      couponType: this.state.couponType
    };

    this.setState(() => ({
      loading: true
    }));

    if (page) {
      parametres.page = page;
      this.props.getUserHistory(id, 'coupon', parametres, a =>
        this.setState(prevState => ({
          loading: false,
          list: a,
          parametres: {
            ...prevState.parametres,
            page
          }
        }))
      );
    } else {
      this.props.getUserHistory(id, 'coupon', parametres, a =>
        this.setState({
          loading: false,
          list: a
        })
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) this.loadData();

    if (this.state.couponType !== prevState.couponType) {
      this.loadData();
    }
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.setState({ loading: true });
    } else {
      this.setState({
        loading: true,
        list: {
          count: 0,
          history: []
        },
        parametres: {
          page: 1,
          count: 15
        }
      });
    }
  }

  refreshPage = () => {
    this.props.clearUsers();
    this.props.fetchUsers(this.props.data.user, 'all');
  };

  getArrayData = (list, page, count) => {
    const array = [];

    list.forEach((a, index) => {
      if (index >= count * (page - 1) && index < count * page) {
        array.push(a);
      }
    });

    return array;
  };

  renderPaginationClass = active => {
    let className = 'pagination-block-element';
    if (active) className += ' active';

    return className;
  };

  refreshPagination = number => {
    if (!isNumber(number)) number = parseInt(number, 10);

    this.setState(prevState => ({
      parametres: {
        ...prevState.parametres,
        page: number
      }
    }));
  };

  getOperation = operation => {
    switch (operation) {
      case 'created':
        return 'Создание';
      case 'used':
        return 'Использование';
      case 'archived':
        return 'Архивирован';
      case 'admin_deleted':
        return 'Удален администратором';
      default:
        return operation;
    }
  };

  createPaginations = count => {
    const { parametres } = this.state;
    const pages = Math.ceil(count);

    return (
      <PaginationBlock
        changePage={this.loadData}
        count={pages}
        page={parametres.page}
      />
    );
  };

  changeMode = event => {
    const value = event.target.id === 'mode-2';

    this.setState(
      prevState => ({
        parametres: {
          ...prevState.parametres,
          slices: value,
          page: 1
        }
      }),
      this.loadData
    );
  };

  getHistorySlices = (slice, key) => {
    const array = [];

    if (slice.history) {
      for (let i = 0; i < slice.history.length; i++) {
        const element = slice.history[i];
        array.push(
          <tr key={`${key} - ${i}element`}>
            <td style={{ width: 140 }}>Купон-слайс</td>
            <td>{slice.couponCode}</td>
            <td>{slice.giver ? slice.giver.login : ''}</td>
            <td>{`${moment(element.date).format(
              'DD.MM.YYYY HH:mm'
            )} - ${this.getOperation(element.operation)}`}</td>
            <td>{element.slicesAmount}</td>
          </tr>
        );
      }
    }

    return array;
  };

  getItems = () => {
    const { couponTypes } = this.props;
    let array = [];
    if (couponTypes)
      array = couponTypes.map(a => ({ value: a, text: getCouponName(a) }));
    return array;
  };

  onSelect = value => {
    this.setState({
      couponType: value
    });
  };

  getTableData = () => {
    const { list, parametres } = this.state;
    return list.history.map((coupon, key) => {
      if (parametres.slices) {
        return this.getHistorySlices(coupon, key);
      }

      return (
        <tr key={key}>
          <td style={{ width: 290 }}>
            {coupon.couponType ? getCouponName(coupon.couponType) : '-'}
          </td>
          <td>{coupon.couponCode}</td>
          <td>{coupon.giver ? coupon.giver.login : ''}</td>
          <td>
            {coupon.history.map((a, index) => [
              `${moment(a.date).format(
                'DD.MM.YYYY HH:mm'
              )} - ${this.getOperation(a.operation)}`,
              <br key={index} />
            ])}
          </td>
        </tr>
      );
    });
  };

  render() {
    const { parametres, list, loading, couponType } = this.state;

    if (loading)
      return (
        <Modal
          show={this.props.show}
          toggleModal={this.props.toggleModal}
        >
          <Loader relative />
        </Modal>
      );
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle
          key='couponsTitle'
          title='История начислений купонов'
        />
        <div
          className='flex-row-container'
          key='mode'
          style={{ alignSelf: 'flex-start', width: '100%' }}
        >
          <Select
            default='Выберите тип купона'
            items={this.getItems()}
            maxHeight={222}
            onChange={this.onSelect}
            value={couponType}
          />
        </div>
        {list && list.history && list.history.length ? (
          <div style={{ width: '100%' }}>
            <Table
              data={this.getTableData()}
              key='couponsTable'
              title={parametres.slices ? this.titleSlices : this.title}
            />
            {!parametres.slices &&
              list &&
              list.count > parametres.count &&
              this.createPaginations(list.count / parametres.count)}
          </div>
        ) : (
          <HeaderPage
            little
            notFound
            style={{ paddingBottom: 20 }}
            title='У пользователя нет истории начисления...'
          />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.modal.modalContent,
    couponTypes: state.users.couponTypes
  };
}

export default connect(mapStateToProps, {
  getUserHistory,
  deleteCoupon,
  fetchUsers,
  clearUsers
})(ModalComponent);
