import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import {
  updateProduct,
  updateCatalog,
  fetchMenu
} from '../../../actions/productActions';

import { uploadImage } from '../../../actions';

import { refreshGroups, fetchGroups } from '../../../actions/groupsActions';
import {
  Modal,
  ModalTitle,
  Form,
  FormGroup,
  ImageBlock,
  FlexBlock,
  HeaderPage,
  SvgButton,
  RenderIngridientsTable
} from '../../elements/elements';

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {
        images: [],
        additionalImage: '',
        removableIngredients: [],
        forceUpdateProduct: false
      },
      loaderPreview: false,
      loaderPhoto1: false,
      loaderPhoto2: false,
      loaderPhoto3: false,
      loaderPhoto4: false,
      loaderAdditionalImage: false,
      nameIngredient: ''
    };
  }

  checkFilter = tag => {
    if (this.state.product.tags.indexOf(tag) !== -1) return true;
  };

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.setState({
        product: newState.product
      });
    }
  }

  handleChangeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      product: {
        ...prevState.product,
        [name]: value
      }
    }));
  };

  handleChangeInputIngredients = event => {
    const value = event.target.value;

    this.setState(prevState => ({
      nameIngredient: value
    }));
  };

  handleChangeTags = event => {
    event.persist();

    if (this.state.product.tags == null) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          tags: [event.target.name]
        }
      }));
    } else {
      if (this.checkFilter(event.target.name)) {
        const tagsData = this.state.product.tags;
        tagsData.splice(this.state.product.tags.indexOf(event.target.name), 1);

        this.setState(prevState => ({
          product: {
            ...prevState.product,
            tags: tagsData
          }
        }));
      } else {
        this.setState(prevState => ({
          product: {
            ...prevState.product,
            tags: [...prevState.product.tags, event.target.name]
          }
        }));
      }
    }
  };

  handleLoadImage = event => {
    event.persist();

    let { additionalImage } = this.state.product;
    const { images } = this.state.product;

    this.setState({ [event.target.dataset.loader]: true });

    this.props
      .uploadImage('image', event.target.files[0])
      .then(picture => {
        if (event.target.dataset.loader === 'loaderPreview')
          images[0] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto1')
          images[1] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto2')
          images[2] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto3')
          images[3] = picture;
        else if (event.target.dataset.loader === 'loaderPhoto4')
          images[4] = picture;
        else if (event.target.dataset.loader === 'loaderAdditionalImage')
          additionalImage = picture;

        this.setState(prevState => ({
          ...prevState,
          product: {
            ...prevState.product,
            images,
            additionalImage
          },
          [event.target.dataset.loader]: false
        }));
      })
      .catch(error => {
        alert(error);
      });
  };

  handleDeleteImage = name => {
    let { additionalImage } = this.state.product;
    const { images } = this.state.product;

    if (name === 'images[0]') images[0] = '';
    else if (name === 'images[1]') images[1] = '';
    else if (name === 'images[2]') images[2] = '';
    else if (name === 'images[3]') images[3] = '';
    else if (name === 'images[4]') images[4] = '';
    else if (name === 'additionalImage') additionalImage = '';

    this.setState(prevState => ({
      ...prevState,
      product: {
        ...prevState.product,
        images,
        additionalImage
      }
    }));
  };

  addProductTags = id => {
    let tags = [];
    const arrayTags = [];

    if (this.props.tags) {
      for (let i = 0; i < this.props.tags.length; i++) {
        if (id === this.props.tags[i].id) {
          tags = this.props.tags[i].tags;
          break;
        }
      }

      if (tags && tags.length > 0) {
        tags.map((tag, key) =>
          arrayTags.push(
            <FormGroup
              checked={this.checkFilter(tag)}
              id={`checkboxTags${key}`}
              key={key}
              name={tag}
              onChange={this.handleChangeTags}
              placeholder={tag}
              type='checkbox'
            />
          )
        );
        return [
          <HeaderPage
            onlyTitle
            style={{ paddingTop: `${0}px` }}
            title='Тэги продукта:'
          />,
          <div
            className='product-modal-tags'
            style={{ marginBottom: 30 }}
          >
            {arrayTags}
          </div>
        ];
      }
    }
    return null;
  };

  refreshPage = id => {
    this.props.updateCatalog();
    this.props.fetchMenu(id);

    this.props.refreshGroups();
    this.props.fetchGroups(id);
  };

  handleSubmit = () => {
    this.props.updateProduct(this.state.product, () => {
      this.refreshPage(this.state.product.productCategoryId);
      this.props.toggleModal();
    });
  };

  handleForceUpdateProduct = () => {
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        forceUpdateProduct: !prevState.product.forceUpdateProduct
      }
    }));
  };

  addIngredient = () => {
    const { nameIngredient } = this.state;

    this.setState(prevState => ({
      ...prevState,
      nameIngredient: '',
      product: {
        ...prevState.product,
        removableIngredients: [
          ...prevState.product.removableIngredients,
          nameIngredient
        ]
      }
    }));
  };

  handleDeleteIngredient = a => {
    const { removableIngredients } = this.state.product;
    const index = removableIngredients.indexOf(a);

    if (index !== -1) {
      removableIngredients.splice(index, 1);

      this.setState(prevState => ({
        ...prevState,

        product: {
          ...prevState.product,
          removableIngredients: [...removableIngredients]
        }
      }));
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ product }) => ({
      product: {
        ...this.state.product,
        removableIngredients: arrayMove(
          product.removableIngredients,
          oldIndex,
          newIndex
        )
      }
    }));
  };

  render() {
    const { product } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.handleSubmit}
          value='Сохранить изменения'
        >
          <ModalTitle
            title={product.name ? product.name : 'Редактирование продукта'}
          />

          <ImageBlock
            front
            image={product.images[0]}
            loader={this.state.loaderPreview}
            loaderName='loaderPreview'
            name='images[0]'
            onDelete={this.handleDeleteImage}
            onLoad={this.handleLoadImage}
            placeholder='Превью продукта'
          />
          <FormGroup
            id='name'
            name='name'
            onChange={this.handleChangeInput}
            placeholder='Название продукта'
            required
            value={product.name}
          />
          <FormGroup
            id='description'
            name='description'
            onChange={this.handleChangeInput}
            placeholder='Описание'
            type='textarea'
            value={product.description}
          />

          {product.additionalInfo && (
            <FormGroup
              disabled
              id='dough'
              name='dough'
              onChange={this.handleChangeInput}
              placeholder='Тесто'
              required
              value={product.additionalInfo.dough}
            />
          )}
          {product.additionalInfo && (
            <FormGroup
              disabled
              id='size'
              name='size'
              onChange={this.handleChangeInput}
              placeholder='Размер'
              required
              value={product.additionalInfo.size}
            />
          )}

          <FormGroup
            checked={product.forceUpdateProduct}
            id='forceUpdateProduct'
            onChange={this.handleForceUpdateProduct}
            placeholder='Сохранить изображение только для продукта'
            type='checkbox'
          />
          {product.removableIngredientsCustomization && (
            <>
              {product.removableIngredients ? (
                <RenderIngridientsTable
                  items={this.state.product.removableIngredients}
                  onClick={this.handleDeleteIngredient}
                  onSortEnd={this.handleSortEnd}
                />
              ) : (
                'Ингредиенты отсутствуют'
              )}
              <div className='name-ingredient'>
                <FormGroup
                  id='nameIngredient'
                  name='nameIngredient'
                  onChange={this.handleChangeInputIngredients}
                  placeholder='Название ингредиента'
                  value={this.state.nameIngredient}
                />
                <SvgButton
                  image='add'
                  onClick={this.addIngredient}
                  style={{ marginLeft: 15 }}
                />
              </div>
            </>
          )}

          {product && this.addProductTags(product.productCategoryId)}

          <HeaderPage
            little
            style={{ opacity: 1, justifyContent: 'center', paddingTop: 20 }}
            title='Дополнительные фото:'
          />

          <FlexBlock>
            <ImageBlock
              front
              image={product.images[1]}
              loader={this.state.loaderPhoto1}
              loaderName='loaderPhoto1'
              name='images[1]'
              onDelete={this.handleDeleteImage}
              onLoad={this.handleLoadImage}
              placeholder='Фото 1'
            />
            <ImageBlock
              front
              image={product.images[2]}
              loader={this.state.loaderPhoto2}
              loaderName='loaderPhoto2'
              name='images[2]'
              onDelete={this.handleDeleteImage}
              onLoad={this.handleLoadImage}
              placeholder='Фото 2'
            />
          </FlexBlock>

          <FlexBlock>
            <ImageBlock
              front
              image={product.images[3]}
              loader={this.state.loaderPhoto3}
              loaderName='loaderPhoto3'
              name='images[3]'
              onDelete={this.handleDeleteImage}
              onLoad={this.handleLoadImage}
              placeholder='Фото 3'
            />
            <ImageBlock
              front
              image={product.images[4]}
              loader={this.state.loaderPhoto4}
              loaderName='loaderPhoto4'
              name='images[4]'
              onDelete={this.handleDeleteImage}
              onLoad={this.handleLoadImage}
              placeholder='Фото 4'
            />
          </FlexBlock>

          <FlexBlock className='center'>
            <ImageBlock
              front
              image={product.additionalImage}
              loader={this.state.loaderAdditionalImage}
              loaderName='loaderAdditionalImage'
              name='additionalImage'
              onDelete={this.handleDeleteImage}
              onLoad={this.handleLoadImage}
              placeholder='Фото 5'
            />
          </FlexBlock>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.modal.modalContent,
    tags: state.products.tags
  };
}

export default connect(mapStateToProps, {
  refreshGroups,
  fetchGroups,
  updateProduct,
  uploadImage,
  updateCatalog,
  fetchMenu
})(ModalComponent);
