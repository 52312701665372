import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import Redusers from './reducers';
import App from './pages/index';

const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
  collapsed: true
});

const createStoreWithMiddleware = applyMiddleware(
  thunk,
  promise,
  logger
)(createStore);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={createStoreWithMiddleware(Redusers)}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('content')
);
