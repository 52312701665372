import { API } from './index';
import { logOut } from './loginActions';
import request from '../lib/request';

export const FETCH_PROMOCODES = 'FETCH_PROMOCODES';
export const CLEAR_PROMOCODES = 'CLEAR_PROMOCODES';
export const UPDATE_PROMOCODES = 'UPDATE_PROMOCODES';

export const clearPromocodes = callback => async dispatch => {
  dispatch({
    type: CLEAR_PROMOCODES,
    payload: false
  });
  if (callback) callback();
};

export const updatePromocodes = callback => async dispatch => {
  dispatch({
    type: UPDATE_PROMOCODES,
    payload: 'Обновление'
  });
  if (callback) callback();
};

export const fetchPromocodes = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/promocodes`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_PROMOCODES,
        payload: json
      });
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const removeProductPromocode = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/promocodes/${id}/delete_products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Продукты промокода удалены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addProductPromocode = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/promocodes/${id}/add_products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Продукты промокода добавлены!');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createPromocode = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/promocodes`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updatePromocode = (id, data, success) => async (
  dispatch,
  getState
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: getState().login.token
  };

  await request(`/promocodes/${id}`, {
    body: JSON.stringify(data),
    method: 'PUT',
    success,
    headers
  });
};

export const deletePromocode = (id, done) => async (dispatch, getState) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: getState().login.token
  };

  await request(`/promocodes/${id}`, {
    method: 'DELETE',
    done,
    headers
  });
};
