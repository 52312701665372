import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  HeaderPage,
  Table,
  Button,
  FormGroup,
  SvgButton
} from '../components/elements/elements';
import Modal from '../components/isolatedModal';
import { fetchRestaurants } from '../actions/restaurantActions';
import {
  fetchRequests,
  acceptRequest,
  clearCouriersRequests
} from '../actions/couriersActions';
import Select from '../components/Select';

import moment from 'moment';
import 'moment/locale/ru';

import OrderGeodata from '../components/OrderGeodata';

import $ from 'jquery';
import 'floating-scroll';
import 'floating-scroll/dist/jquery.floatingscroll.css';

const typeItems = [
  {
    value: 'accepted',
    text: 'Принятые'
  },
  {
    value: 'declined',
    text: 'Отклоненные'
  },
  {
    value: 'processed',
    text: 'Ожидают решения'
  }
];

const title = [
  '№',
  'Дата заявки',
  'Менеджер / Админ',
  'Имя курьера',
  'Время',
  'Время указанное менеджером',
  'Зона',
  'Зона указанная менеджером',
  'Баллы указанные менеджером',
  'Баллы по заказу',
  'Баллы по заявке',
  'Комментарий',
  'Адрес',
  'Номер заказа',
  'Сервис геокодирования',
  'Координаты',
  'Дата проблемы',
  'Координаты курьера',
  ''
];

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachmentsModalShow: false,
      attachments: [],
      rejectAcceptModalShow: false,
      acceptId: -1,
      rejectAcceptComment: '',
      restaurant: { name: '', value: '' },
      type: { name: 'Ожидают решения', value: 'processed' },
      show: false,
      requestId: -1,
      update: true,
      period: 'day',
      periodText: '',
      from: '',
      to: ''
    };
  }

  async componentDidMount() {
    await this.props.fetchRestaurants();
    this.fetchRequests();

    this.setState({ update: false }, this.initFloatingTable);
  }

  componentDidUpdate() {
    this.initFloatingTable();
  }

  componentWillUnmount() {
    this.props.clearCouriersRequests();
  }

  initFloatingTable = () => {
    const el = $('.floating-scroll');

    if (el.length) {
      el.floatingScroll();
    }
  };

  getSelectItems = () => {
    const { restaurants } = this.props;
    const array = [];

    array.push({
      text: 'Все рестораны',
      value: ''
    });

    if (restaurants && restaurants.length) {
      for (let i = 0; i < restaurants.length; i++) {
        array.push({
          text: restaurants[i].name,
          value: restaurants[i]._id
        });
      }
    }

    return array;
  };

  onChangeSelectRestaurant = (value, name) => {
    this.setState(
      {
        restaurant: {
          value,
          name
        }
      },
      this.fetchRequests
    );
  };

  onChangeTypeSelect = (value, name) => {
    this.setState({ type: { name, value } }, this.fetchRequests);
  };

  toggleAttachmentsModal = attachments => {
    attachments = Array.isArray(attachments) ? attachments : [];
    this.setState({
      attachmentsModalShow: !this.state.attachmentsModalShow,
      attachments
    });
  };

  openRejectAcceptModal = id => {
    this.setState({ rejectAcceptModalShow: true, acceptId: id });
  };

  closeRejectAcceptModal = isSubmit => {
    return () => {
      if (isSubmit) {
        if (!this.state.rejectAcceptComment)
          return alert('Укажите комментарий.');

        this.props.acceptRequest(
          this.state.acceptId,
          {
            accepted: false,
            adminComment: this.state.rejectAcceptComment
          },
          () => {
            this.setState(
              {
                rejectAcceptModalShow: false,
                acceptId: -1,
                rejectAcceptComment: ''
              },
              this.fetchRequests
            );
          }
        );
      } else {
        this.setState({ rejectAcceptModalShow: false, acceptId: -1 });
      }
    };
  };

  getButtons = ({ id, iikoOrder }) => {
    const { type } = this.state;

    let attachments = [];
    if (iikoOrder) attachments = iikoOrder.screenshots;

    const attachmentsButton = (
      <SvgButton
        image="attach"
        onClick={() => this.toggleAttachmentsModal(attachments)}
      />
    );

    const infoBtn = (
      <SvgButton image="info" onClick={() => this.toggleModal(id)} />
    );

    let content = [attachmentsButton, infoBtn];

    if (type.value === 'processed') {
      content = [
        attachmentsButton,
        infoBtn,
        <SvgButton
          image="arrow"
          onClick={() =>
            this.props.acceptRequest(id, { accepted: true }, this.fetchRequests)
          }
        />,
        <SvgButton
          image="delete"
          onClick={() => this.openRejectAcceptModal(id)}
        />
      ];
    }

    return (
      <td>
        <div className="requests-table-actions">{content}</div>
      </td>
    );
  };

  toggleModal = id => {
    const { show } = this.state;
    this.setState({ show: !show, requestId: id });
  };

  translateDate = date => {
    const newDate = moment(date).format('LLL');
    return newDate;
  };

  acceptCustomPeriod = () => {
    const { from, to, period } = this.state;

    const beginToString = moment(from, 'YYYY.MM.DD').toISOString();
    const endToString = moment(to, 'YYYY.MM.DD').toISOString();

    const data = {
      period,
      from: beginToString,
      to: endToString
    };

    this.fetchRequests(data);
  };

  fetchRequests = async () => {
    const { period, restaurant, from, to, type } = this.state;

    let accepted = '';
    let processed = '';

    if (type.value === 'accepted') accepted = true;
    if (type.value === 'declined') accepted = false;
    if (type.value === 'processed') processed = false;

    const query = {
      period: period === 'custom' ? '' : period,
      restaurant: restaurant.value,
      accepted,
      processed,
      from,
      to
    };

    for (const key in query) {
      if (typeof query[key] !== 'boolean' && !query[key]) delete query[key];
    }

    this.setState({ update: true });
    await this.props.fetchRequests(query);
    this.setState({ update: false });
  };

  onChangeInput = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  onChangeRejectAcceptComment = e => {
    this.setState({ rejectAcceptComment: e.target.value });
  };

  onChangePeriodSelect = (value, text) => {
    this.setState({ period: value, periodText: text, from: '', to: '' }, () => {
      if (value !== 'custom') this.fetchRequests();
    });
  };

  getTableItems = () => {
    const { requests } = this.props;

    return requests;
  };

  getItems = () => {
    const items = [
      { value: 'day', text: 'День' },
      { value: 'week', text: 'Неделя' },
      { value: 'month', text: 'Месяц' },
      { value: 'year', text: 'Год' },
      { value: 'custom', text: 'Выбрать период' }
    ];

    return items;
  };

  getDeliveryTime = iikoOrder => {
    if (!iikoOrder) return '-';
    return iikoOrder.diff || '-';
  };

  getZone = info => {
    if (info) {
      const { closeArea, goldenMile } = info;

      if (goldenMile) return 'ЗМ';
      if (closeArea) return 'ГЗ';
      return 'НГЗ';
    }

    return '-';
  };

  getRequestStatus = accepted => {
    if (accepted) return 'Принята';
    if (accepted === false) return 'Отклонена';
    return 'Ожидает решения';
  };

  getManagerDeliveryZone = zone => {
    switch (zone) {
      case 'guaranteed':
        return 'ГЗ';
      case 'goldenMile':
        return 'ЗМ';
      case 'nonGuaranteed':
        return 'НГЗ';
      default:
        return zone || '-';
    }
  };

  getManagerDeliveryTime = ({ deliveryTime, iikoOrder }) => {
    if (!deliveryTime || !iikoOrder) return '-';

    const { printTime } = iikoOrder;
    const diff = moment(deliveryTime).diff(moment(printTime));
    const result = Math.round(diff / 60000);

    if (!Number.isNaN(result)) {
      return result;
    }
  };

  renderPeriodInputs = () => {
    const { period } = this.state;

    if (period === 'custom') {
      return (
        <div style={{ marginBottom: '20px' }}>
          <input
            className="input-element"
            name="from"
            onChange={this.onChangeInput}
            placeholder="от"
            style={{ width: '30%' }}
            type="date"
            value={this.state.from}
          />
          <input
            className="input-element"
            name="to"
            onChange={this.onChangeInput}
            placeholder="до"
            style={{ margin: '0 20px', width: '30%' }}
            type="date"
            value={this.state.to}
          />
          <Button little onClick={this.acceptCustomPeriod} value="Потвердить" />
        </div>
      );
    }
  };

  renderAttachments() {
    const { attachments } = this.state;
    if (!attachments.length)
      return <HeaderPage little title="Вложения отсутствуют" />;

    return (
      <div className="attachments-modal">
        <HeaderPage little title="Вложения" />

        {attachments.map(attachment => {
          const link = `https://${process.env.REACT_APP_BASE_URL}${attachment}`;

          return (
            <a
              className="couriers-requests-attachment"
              href={link}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="attach" src={link} />
            </a>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      restaurant,
      type,
      show,
      update,
      periodText,
      attachmentsModalShow,
      rejectAcceptModalShow,
      rejectAcceptComment
    } = this.state;

    const data = this.props.requests.find(
      item => item.id === this.state.requestId
    );

    const items = this.getTableItems();

    if (update) return <Loader />;
    return (
      <div>
        <Modal
          onHide={this.closeRejectAcceptModal()}
          show={rejectAcceptModalShow}
        >
          <div className="reject-accept-modal">
            <HeaderPage little title="Комментарий" />

            <FormGroup
              onChange={this.onChangeRejectAcceptComment}
              placeholder="Введите комментарий"
              rows="10"
              type="textarea"
              value={rejectAcceptComment}
            />

            <div className="button-container">
              <Button
                onClick={this.closeRejectAcceptModal(true)}
                value="Отклонить заявку"
              />
            </div>
          </div>
        </Modal>
        <Modal onHide={this.toggleAttachmentsModal} show={attachmentsModalShow}>
          {this.renderAttachments()}
        </Modal>
        <Modal onHide={this.toggleModal} show={show}>
          {data && (
            <div className="requests-modal">
              <HeaderPage little title="Информация о заявке" />

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="name">Ресторан:</div>
                <div style={{ marginLeft: '5px' }}>{data.restaurant.name}</div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px'
                }}
              >
                <div className="name">Статус заявки:</div>
                <div style={{ marginLeft: '5px' }}>
                  {this.getRequestStatus(data.accepted)}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px'
                }}
              >
                <div className="name">Время создание заказа:</div>
                <div style={{ marginLeft: '5px' }}>
                  {data.iikoOrder
                    ? this.translateDate(data.iikoOrder.createdTime)
                    : '-'}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px'
                }}
              >
                <div className="name">
                  Время отправки заказа на кухонный экран:
                </div>
                <div style={{ marginLeft: '5px' }}>
                  {data.iikoOrder
                    ? this.translateDate(data.iikoOrder.printTime)
                    : '-'}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px'
                }}
              >
                <div className="name">Время отправки заказа на курьера :</div>
                <div style={{ marginLeft: '5px' }}>
                  {data.iikoOrder
                    ? this.translateDate(data.iikoOrder.iikoSendTime)
                    : '-'}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px'
                }}
              >
                <div className="name">Время фактической доставки :</div>
                <div style={{ marginLeft: '5px' }}>
                  {data.iikoOrder
                    ? this.translateDate(data.iikoOrder.deliveryTime)
                    : '-'}
                </div>
              </div>
            </div>
          )}
        </Modal>

        <HeaderPage title="Заявки">
          <Select
            items={this.getItems()}
            onChange={this.onChangePeriodSelect}
            value={periodText}
          />
          <Select
            default="Выберите тип"
            items={typeItems}
            onChange={this.onChangeTypeSelect}
            value={type.name}
          />
        </HeaderPage>
        <div>{this.renderPeriodInputs()}</div>

        <Select
          items={this.getSelectItems()}
          onChange={this.onChangeSelectRestaurant}
          style={{ marginBottom: 50 }}
          value={restaurant.name}
        />
        {items.length !== 0 && type.value !== '' ? (
          <div className="floating-scroll">
            <Table
              className="couriers-requests-table"
              data={items.map((request, key) => (
                <tr key={key}>
                  <td>
                    {request.id}
                    {request.iikoOrder && request.iikoOrder.deferred ? (
                      <div style={{ color: 'red' }}>(отложенный)</div>
                    ) : null}
                  </td>
                  <td>{this.translateDate(request.createdAt)}</td>
                  <td>
                    {request.user.login} /{' '}
                    {request.admin ? request.admin.login : '-'}
                  </td>
                  <td>
                    {request.courier
                      ? `${request.courier.firstName} ${request.courier.lastName}`
                      : '-'}
                  </td>
                  <td>{this.getDeliveryTime(request.iikoOrder)}</td>
                  <td>{this.getManagerDeliveryTime(request)}</td>
                  <td>{this.getZone(request.iikoOrder)}</td>
                  <td style={{ color: 'red' }}>
                    {this.getManagerDeliveryZone(request.deliveryZone)}
                  </td>
                  <td>
                    {request.deliveryBonus && request.deliveryBonus !== 0
                      ? request.deliveryBonus
                      : '-'}
                  </td>
                  <td>
                    {request.iikoOrder || request.iikoOrder === 0
                      ? request.iikoOrder.bonus
                      : '-'}
                  </td>
                  <td>{request.bonus}</td>
                  <td>{request.comment}</td>
                  <td>
                    {request.iikoOrder ? request.iikoOrder.address : null}
                  </td>
                  <td>
                    {request.iikoOrder
                      ? `${request.order}/${request.iikoOrder.number}`
                      : null}
                  </td>
                  <OrderGeodata order={request.iikoOrder} />
                  <td>
                    {request.iikoOrder && request.iikoOrder.problemTime
                      ? this.translateDate(request.iikoOrder.problemTime)
                      : '-'}
                  </td>
                  <td>
                    {request.iikoOrder &&
                    request.iikoOrder.courierProblemOnCoords
                      ? `${request.iikoOrder.courierProblemOnCoords.lat} ${request.iikoOrder.courierProblemOnCoords.lon}`
                      : '-'}
                  </td>
                  {this.getButtons(request)}
                </tr>
              ))}
              title={title}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurants: state.restaurants.restaurants,
    restaurant: state.restaurants.restaurant,
    requests: state.couriers.requests
  };
}

export default connect(mapStateToProps, {
  fetchRestaurants,
  fetchRequests,
  acceptRequest,
  clearCouriersRequests
})(SettingsPage);
