import {
  FETCH_CAMPAIGNS_LIST,
  FETCH_CAMPAIGNS,
  UPDATE_CAMPAING,
  FETCH_CAMPAIGN,
  CLEAR_CAMPAIGNS,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN
} from '../actions/campaignsActions';

const INITIAL_STATE = {
  campaigns: [],
  productCampaigns: [],
  campaign: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_CAMPAIGNS:
      return { ...state, campaigns: action.payload };

    case FETCH_CAMPAIGNS_LIST:
      return {
        ...state,
        productCampaigns: action.payload.filter(a => a.promocode)
      };

    case DELETE_CAMPAIGN:
      return { ...state, campaign: action.payload };

    case FETCH_CAMPAIGNS:
      return { ...state, campaigns: action.payload };

    case CREATE_CAMPAIGN:
      return { ...state, campaign: action.payload };

    case UPDATE_CAMPAING:
      return { ...state, campaign: action.payload };

    case FETCH_CAMPAIGN:
      return { ...state, campaign: action.payload };

    default:
      return state;
  }
}
