import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Modal,
  ModalTitle,
  FormGroup,
  Cross
} from '../../elements/elements';
import {
  clearSettings,
  fetchSettings,
  addCouponProducts
} from '../../../actions/settingsActions';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: {},
      products: [],
      categories: []
    };
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.clearSettings();
    this.props.fetchSettings();
  };

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show)
      this.setState({
        coupon: { ...newState.coupon.data },
        products: [],
        categories: []
      });
  }

  onDelete = (type, element) => () => {
    const elems = [...this.state[type]];
    elems.splice(element, 1);

    this.setState({ [type]: elems });
  };

  onChange = (type, element) => event => {
    const elems = [...this.state[type]];
    elems[element] = event.target.value;

    this.setState({ [type]: elems });
  };

  renderInputs = (type, response) => {
    if (response.length > 0) {
      const array = [];
      for (let a = 0; a <= response.length; a++) {
        if (a === 0) {
          array.push(
            <div
              className='datepicker-block first'
              key={a}
            >
              <FormGroup
                id={`adding-${type}-${a}`}
                labelHidden
                name={`adding-${type}-${a}`}
                onChange={this.onChange(type, a)}
                placeholder='Добавьте ID...'
                value={response[a]}
              />
              <Cross
                className='datepicker-cross'
                noModalCross
                onClick={this.onDelete(type, a)}
              />
            </div>
          );
        } else if (a === response.length) {
          array.push(
            <div
              className='datepicker-block'
              key={a}
            >
              <FormGroup
                id={`adding-${type}-${a}`}
                labelHidden
                name={`adding-${type}-${a}`}
                onChange={this.onChange(type, a)}
                placeholder='Добавьте ID...'
                value={response[a] ? response[a] : ''}
              />
            </div>
          );
        } else {
          array.push(
            <div
              className='datepicker-block'
              key={a}
            >
              <FormGroup
                id={`adding-${type}-${a}`}
                labelHidden
                name={`adding-${type}-${a}`}
                onChange={this.onChange(type, a)}
                placeholder='Добавьте ID...'
                value={response[a]}
              />
              <Cross
                className='datepicker-cross'
                noModalCross
                onClick={this.onDelete(type, a)}
              />
            </div>
          );
        }
      }
      return array;
    } 
      return (
        <div className='datepicker-block first'>
          <FormGroup
            id={`adding-${type}-${0}`}
            labelHidden
            name={`adding-${type}-${0}`}
            onChange={this.onChange(type, 0)}
            placeholder='Добавьте ID...'
            value={response[0]}
          />
        </div>
      );
    
  };

  onUpdate = () => {
    const { products, categories } = this.state;
    const { coupon } = this.props;

    const data = { products, categories };

    this.props.addCouponProducts(data, coupon.type, this.refreshPage);
  };

  render() {
    const { products, categories } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onUpdate}
          value='Добавить'
        >
          <div style={{ 'margin-bottom': '20px' }}>
            <ModalTitle title='Добавить продукты' />
            {this.renderInputs('products', products)}
          </div>
          <ModalTitle title='Добавить категории' />
          {this.renderInputs('categories', categories)}
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupon: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  clearSettings,
  fetchSettings,
  addCouponProducts
})(ModalComponent);
