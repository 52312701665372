import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/interface/loader';

import {
  Modal,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel
} from 'react-bootstrap';
import {
  fetchRecall,
  deleteRecall,
  fetchRecalls
} from '../../actions/recallsActions';

class RecallsModal extends Component {
  onCancel = () => {
    this.props.toggleModal();
  };

  deleteRecall = () => {
    this.props.deleteRecall(this.props.recall._id, () => {
      this.props.refresh();
      this.onCancel();
    });
  };

  changeHandler = event => {
    if (event) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  render() {
    if (this.props.recall) {
      return (
        <div>
          <Modal
            onHide={this.onCancel}
            show={this.props.show}
          >
            <Modal.Body>
              <Form
                id='campaignForm'
                onSubmit={this.handleSubmit}
              >
                <h3 className='text-center'>Подробности комментария</h3>
                {!this.props.recall ? (
                  <div className='spinner-wrapper'>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <FormGroup>
                      <ControlLabel>Оценка комментария</ControlLabel>
                      <FormControl
                        name='mark'
                        onChange={this.changeHandler}
                        type='text'
                        value={this.props.recall.mark}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Имя пользователя</ControlLabel>
                      <FormControl
                        name='name'
                        onChange={this.changeHandler}
                        type='text'
                        value={this.props.recall.author.name}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Почта пользователя</ControlLabel>
                      <FormControl
                        name='email'
                        onChange={this.changeHandler}
                        type='text'
                        value={this.props.recall.author.email}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Телефон пользователя</ControlLabel>
                      <FormControl
                        name='phone'
                        onChange={this.changeHandler}
                        type='text'
                        value={this.props.recall.author.phone}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Тема отзыва</ControlLabel>
                      <FormControl
                        name='attribute'
                        onChange={this.changeHandler}
                        required
                        type='subject'
                        value={this.props.recall.subject}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Текст отзыва</ControlLabel>
                      <FormControl
                        className='textarea'
                        componentClass='textarea'
                        name='text'
                        onChange={this.changeHandler}
                        required
                        type='text'
                        value={this.props.recall.text}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Ответ на отзыв</ControlLabel>
                      {this.props.recall.reply ? (
                        <FormControl
                          className='textarea'
                          componentClass='textarea'
                          name='text'
                          onChange={this.changeHandler}
                          required
                          type='text'
                          value={this.props.recall.reply}
                        />
                      ) : (
                        <div>Отсутствует</div>
                      )}
                    </FormGroup>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle='danger'
                className='left'
                onClick={this.deleteRecall}
              >
                Удалить
              </Button>
              <Button
                bsStyle='default'
                onClick={this.onCancel}
              >
                Закрыть
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    recall: state.recalls.recall
  };
}

export default connect(mapStateToProps, {
  fetchRecall,
  deleteRecall,
  fetchRecalls
})(RecallsModal);
