import {FETCH_GROUPS, CLEAR_GROUPS, REFRESH_GROUPS} from '../actions/groupsActions';

const INITIAL_STATE = {
    groups: false
};


export default function( state = INITIAL_STATE, action ) {
  switch( action.type ) {    

    case FETCH_GROUPS:
      return {...state, groups: action.payload};

    case CLEAR_GROUPS:
      return {...state, groups: action.payload};

    case REFRESH_GROUPS:
      return {...state, groups: action.payload};

    default:
      return state;
  }
}