import { API } from './index';
import { getQueryParametres } from './functions';
import { logOut } from './loginActions';

export const FETCH_STATISTICS = 'FETCH_STATISTICS';
export const FETCH_STATISTICS_PROMOCODES = 'FETCH_STATISTICS_PROMOCODES';
export const FETCH_USERTYPE_STATISTIC = 'FETCH_USERTYPE_STATISTIC';
export const FETCH_BONUS_LEVELS = 'FETCH_BONUS_LEVELS';
export const FETCH_COUPONS_STATISTIC = 'FETCH_COUPONS_STATISTIC';
export const FETCH_THURSDAY_STATISTIC = 'FETCH_THURSDAY_STATISTIC';
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';

export const fetchStatisctics = (data, callback) => async (
  dispatch,
  getState
) => {
  let url = '/statistics';
  if (data) url += `?${getQueryParametres(data)}`;

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();
      dispatch({
        type: FETCH_STATISTICS,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchStatiscticPromocode = (data, callback) => async (
  dispatch,
  getState
) => {
  let url = '/promocodes/statistics';

  if (data) url += `?${getQueryParametres(data)}`;

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();

      dispatch({
        type: FETCH_STATISTICS_PROMOCODES,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchUserTypeStatistic = (data, callback) => async (
  dispatch,
  getState
) => {
  let url = '/users/statistics';

  if (data) url += `?${getQueryParametres(data)}`;

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();

      dispatch({
        type: FETCH_USERTYPE_STATISTIC,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchBonusLevels = callback => async (dispatch, getState) => {
  const url = '/users/bonus_levels';

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();

      dispatch({
        type: FETCH_BONUS_LEVELS,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCouponsStatistic = (data, callback) => async (
  dispatch,
  getState
) => {
  let url = '/coupons/statistics';

  if (data) url += `?${getQueryParametres(data)}`;

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();

      dispatch({
        type: FETCH_COUPONS_STATISTIC,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchThursdayStatistic = (data, callback) => async (
  dispatch,
  getState
) => {
  let url = '/coupons/thursdayStats';

  if (data) url += `?${getQueryParametres(data)}`;

  const response = await fetch(API.url + url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback();

      dispatch({
        type: FETCH_THURSDAY_STATISTIC,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
