import React, { Component } from 'react';

import './style.scss';

class OrderGeodata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: {
        dadata: false,
        iiko: false,
        google: false
      },
      lat: '',
      lon: ''
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.closeMenu.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeMenu.bind(this));
  }

  orderRef = React.createRef();

  closeMenu = (e: any) => {
    if (this.orderRef.current && !this.orderRef.current.contains(e.target)) {
      const isOpen = {
        dadata: false,
        iiko: false,
        google: false
      };
      this.setState({ isOpen });
    }
  };

  getGeoService = () => {
    const { order } = this.props;
    if (!order) return '-';

    const { deliveredCoordsType } = order;

    switch (deliveredCoordsType) {
      case 'dadata':
        return 'Dadata';
      case 'coords':
        return 'iiko (2Gis)';
      case 'google':
        return 'Google';
      default:
        return '-';
    }
  };

  openMenu = (type, lat, lon) => {
    return () => {
      const numberLat = Number(lat);
      const numberLon = Number(lon);
      const canOpen = !Number.isNaN(numberLat) && !Number.isNaN(numberLon);
      if (canOpen) {
        const isOpen = {
          dadata: false,
          iiko: false,
          google: false
        };
        isOpen[type] = !this.state.isOpen[type];
        this.setState({ lat, lon, isOpen });
      }
    };
  };

  toMap = type => {
    return () => {
      const { lat, lon } = this.state;
      const yUrl = (lat, lon) => {
        return `https://yandex.ru/maps/2/saint-petersburg/?text=${lat},${lon}`;
      };
      const gUrl = (lat, lon) => {
        return `https://www.google.ru/maps/d/viewer?mid=1WQ9vprBvNX_7BYTXic1vWIzE-DUZKCc6&ll=${lat}%2C${lon}&z=18`;
      };
      if (type === 'yandex') {
        window.open(yUrl(lat, lon), '_blank');
      } else {
        window.open(gUrl(lat, lon), '_blank');
      }
    };
  };

  mapsMenu = type => {
    const { isOpen, lat, lon } = this.state;
    const cn = isOpen[type] ? 'maps-menu maps-menu_open' : 'maps-menu';
    return (
      <div className={cn}>
        Открыть в:
        <br />
        <div
          className='maps-menu__item'
          onClick={this.toMap('yandex', lat, lon)}
        >
          yandex maps
        </div>
        <div
          className='maps-menu__item'
          onClick={this.toMap('google', lat, lon)}
        >
          google maps
        </div>
      </div>
    );
  };

  getGeoCoords = () => {
    const { order, withoutGeoService } = this.props;
    if (!order) return '-';

    const { dadata, coords, google } = order;

    if (!dadata && !coords && !google) return '-';
    const geoServiceName = type => {
      if (withoutGeoService) return '';
      if (type === 'dadata') return 'Dadata:';
      if (type === 'iiko') return 'Iiko:';
      if (type === 'google') return 'Google:';
      return '-';
    };

    const result = [];
    if (dadata)
      result.push(
        <span
          key='dadata'
          onClick={this.openMenu('dadata', dadata.lat, dadata.lon)}
        >
          {geoServiceName('dadata')} {dadata.lat} {dadata.lon}
          {this.mapsMenu('dadata')}
        </span>
      );
    if (coords)
      result.push(
        <span
          key='iiko'
          onClick={this.openMenu('iiko', coords.lat, coords.lon)}
        >
          {geoServiceName('iiko')} {coords.lat} {coords.lon}
          {this.mapsMenu('iiko')}
        </span>
      );
    if (google)
      result.push(
        <span
          key='google'
          onClick={this.openMenu('google', google.lat, google.lon)}
        >
          {geoServiceName('google')} {google.lat} {google.lon}
          {this.mapsMenu('google')}
        </span>
      );

    return (
      <div
        className='order-geodata-coords'
        ref={this.orderRef}
      >
        {result}
      </div>
    );
  };

  render() {
    const { withoutGeoService } = this.props;
    return (
      <>
        {!withoutGeoService && <td>{this.getGeoService()}</td>}
        <td>{this.getGeoCoords()}</td>
      </>
    );
  }
}

export default OrderGeodata;
