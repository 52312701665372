// @flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { ReactSVG as Svg } from 'react-svg';

import close from './images/modal-close.svg';

import './style.scss';

type Props = {
  show: boolean,
  reference?: any => void,
  onHide: () => {},
  children?: string,
  modalName?: string,
  fullscreen: Boolean
};

type State = {
  id: number | string,
  visible: boolean,
  body: any
};

export default class Modal extends Component<Props, State> {
  clickReady = true;

  static defaultProps = {
    reference: () => {},
    children: '',
    modalName: ''
  };

  state = {
    visible: false,
    id: this.getId(),
    body: document.getElementsByTagName('body')[0]
  };

  componentDidMount = () => {
    if (this.props.reference && typeof this.props.reference === 'function')
      this.props.reference(this);
    window.addEventListener('keydown', this.onPressedKey.bind(this));
  };

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show && !prevProps.show) {
      this.brakeEnable();
      setTimeout(() => {
        this.setState({
          visible: true
        });
      }, 0);
    }

    if (!show && prevProps.show) {
      this.onHide();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.onPressedKey.bind(this));
  };

  onHide = callback => {
    const { onHide } = this.props;

    if (this.clickReady) {
      this.clickReady = false;
      this.setState({ visible: false });

      setTimeout(() => {
        this.brakeDisable();
        onHide();
        this.clickReady = true;
        if (typeof callback === 'function') callback();
      }, 500);
    }
  };

  onPressedKey(event: any) {
    const { show } = this.props;
    if (show && event.key === 'Escape') this.onHide();
  }

  brakeEnable() {
    document.getElementsByTagName('html')[0].classList.add('scroll-brake');
  }

  brakeDisable() {
    document.getElementsByTagName('html')[0].classList.remove('scroll-brake');
  }

  getId(): string | number {
    const id: string = new Date().getTime().toString();
    const checkElements = document.getElementById(id);
    if (checkElements) return parseInt(id, 10) + 1;

    return id;
  }

  getClassName = () => {
    const { visible } = this.state;

    let className = 'isolate-modal';
    if (visible) className += ' visible';

    return className;
  };

  renderModal() {
    const { children, modalName, fullscreen } = this.props;
    const { id } = this.state;

    return (
      <div className={this.getClassName()} data-modal={modalName} id={id}>
        <div className="content-scroll">
          <div className={`content ${fullscreen ? 'fullscreen' : ''}`}>
            <Svg className="close" onClick={this.onHide} path={close} />
            {children || null}
          </div>
        </div>
        <div className="fade" onClick={this.onHide} />
      </div>
    );
  }

  render() {
    const { body } = this.state;
    const { show } = this.props;

    if (show) return ReactDOM.createPortal(this.renderModal(), body);
    return null;
  }
}
