import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);

const DatePicker = props => {
  let cn = 'datepicker-wrapper';
  if (props.isFullWidth) {
    cn += ' full-width';
  }

  return (
    <div className={cn}>
      <ReactDatePicker
        {...props}
        locale='ru'
      />
    </div>
  );
};

export default DatePicker;
