import {
  FETCH_COURIERS,
  CLEAR_COURIERS,
  FETCH_COURIERS_ID,
  FETCH_REQUESTS,
  FETCH_COURIER,
  FETCH_COURIER_ORDERS,
  CLEAR_COURIER_ORDERS,
  FETCH_COURIERS_RATING,
  CLEAR_COURIERS_REQUESTS
} from '../actions/couriersActions';

const INITIAL_STATE = {
  couriers: [],
  couriersID: false,
  requests: [],
  courier: {},
  courierOrders: [],
  pagination: {},
  courierRating: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COURIERS:
      return {
        ...state,
        couriers: action.payload.couriers || action.payload,
        pagination: action.payload.pagination || {}
      };

    case FETCH_COURIERS_ID:
      return { ...state, couriersID: action.payload };

    case CLEAR_COURIERS:
      return { ...state, couriers: action.payload };

    case FETCH_REQUESTS:
      return { ...state, requests: action.payload };
    case CLEAR_COURIERS_REQUESTS:
      return { ...state, requests: action.payload };
    case FETCH_COURIER:
      return { ...state, courier: action.payload };
    case FETCH_COURIER_ORDERS:
      return {
        ...state,
        courierOrders: action.payload.iikoorders,
        pagination: action.payload.pagination
      };
    case CLEAR_COURIER_ORDERS:
      return { ...state, courierOrders: action.payload };
    case FETCH_COURIERS_RATING:
      return { ...state, courierRating: action.payload };

    default:
      return state;
  }
}
