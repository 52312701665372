import { GET_DELIVERY_CLUB_SETTINGS } from '../actions/deliveryClubActions';

const INITIAL_STATE = {
  settings: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case GET_DELIVERY_CLUB_SETTINGS: 
      return {...state, settings: action.response}
      
    default:
      return state;
  }
}