import { logOut } from './loginActions';

import { API } from './index';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_TAGS = 'FETCH_TAGS';
export const UPLOADIMAGE_PRODUCT = 'UPLOADIMAGE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_MENU = 'FETCH_CATEGORIES_MENU';
export const FETCH_RECOMMENDED = 'FETCH_RECOMMENDED';
export const CLEAR_CATALOG = 'CLEAR_CATALOG';
export const UPDATE_CATALOG = 'UPDATE_CATALOG';
export const CLEAR_CATEGORIES_MENU = 'CLEAR_CATEGORIES_MENU';

export const clearCategories = () => async dispatch => {
  dispatch({
    type: CLEAR_CATEGORIES_MENU,
    payload: ''
  });
};

export const clearCatalog = () => async dispatch => {
  dispatch({
    type: CLEAR_CATALOG,
    payload: false
  });
};

export const updateCatalog = () => async dispatch => {
  dispatch({
    type: UPDATE_CATALOG,
    payload: 'Обновление'
  });
};

export const catalogUpdate = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu/update`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      alert(JSON.stringify(`Каталог обновлен!!!${json.data}`));
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addCampaignProduct = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/menu/products/${id}/add_to_campaigns`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const getProductsByName = (name, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/menu/products/search/${name}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      if (callback) await callback(json);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const removeCampaignProduct = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/menu/products/${id}/remove_from_campaigns`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchMenu = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu?categoryId=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CATEGORIES_MENU,
        payload: json
      });
      if (callback) await callback(json);
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchRecommended = () => async (dispatch, getState) => {
  const response = await fetch(
    `${API.url}/menu/products/03b3b7ed-30f8-4ac5-b115-0d2f9a02cae4/recommended`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_RECOMMENDED,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const saveRecommended = data => async (dispatch, getState) => {
  const request = await fetch(`${API.url}/menu/recommended `, {
    method: 'put',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await request.status;

  if (status === 200) {
    alert('Успешно сохранено.');
  } else {
    switch (status) {
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};

export const fetchCategories = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/menu/categories`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CATEGORIES,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchProducts = id => async dispatch => {
  const request = await fetch(`${API.url}/menu/${id}`, {
    method: 'get'
  });

  const status = await request.status;

  if (status === 200) {
    const response = await request.json();

    dispatch({
      type: 'FETCH_PRODUCTS',
      payload: response
    });

    return response;
  }
  switch (status) {
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchProduct = (id, cb) => async dispatch => {
  const request = await fetch(`${API.url}/menu/products/${id}`, {
    method: 'get'
  });

  const status = await request.status;

  if (status === 200) {
    const response = await request.json();

    dispatch({
      type: FETCH_PRODUCT,
      payload: response
    });

    if (cb && typeof cb === 'function') cb(response);

    return response;
  }
  switch (status) {
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const settingProductModifiers = (id, body, cb) => async (
  dispatch,
  getState
) => {
  const request = await fetch(`${API.url}/menu/custom_group/${id}/modifiers`, {
    method: 'put',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await request.status;

  if (status === 200) {
    const response = await request.json();

    if (cb && typeof cb === 'function') cb(response);

    return response;
  }
  switch (status) {
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchTags = () => async (dispatch, getState) => {
  const request = await fetch(`${API.url}/menu/categories`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await request.status;

  if (status === 200) {
    const response = await request.json();

    dispatch({
      type: FETCH_TAGS,
      payload: response
    });
  } else {
    switch (status) {
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};

export const updateProduct = (product, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/menu`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(product)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_PRODUCT,
        payload: json
      });
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};
