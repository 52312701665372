import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchCampaigns,
  clearCampaigns,
  deleteCampaing
} from '../actions/campaignsActions';
import { toggleCampaignModal } from '../actions/modalActions';
import { HeaderPage, FormGroup, Form } from '../components/elements/elements';

class CampaignsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  changeInput = event => {};

  updateSeo = () => {};

  render() {
    const { text } = this.state;
    return (
      <div>
        <HeaderPage title='Группы' />
        <Form
          direction='column'
          onSubmit={this.updateSeo}
          value='Сохранить изменения'
        >
          <FormGroup
            id='text'
            name='text'
            onChange={this.changeInput}
            placeholder='Seo-текст'
            required
            type='textarea'
            value={text}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaigns.campaigns
  };
}

export default connect(mapStateToProps, {
  deleteCampaing,
  toggleCampaignModal,
  fetchCampaigns,
  clearCampaigns
})(CampaignsIndex);
