import { API } from './index';
import { logOut } from './loginActions';

const downloadFile = (filename, text) => {
  const body = document.body;
  const link = document.createElement('a');

  body.appendChild(link);
  link.style.display = 'none';
  link.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  link.setAttribute('download', filename);
  link.click();
  body.removeChild(link);
};

export const GET_DELIVERY_CLUB_SETTINGS = 'GET_DELIVERY_CLUB_SETTINGS';

export const getCatalogXML = () => async (dispatch, getState) => {
  const request = await fetch(`${API.url}/zakazaka/menu.xml`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await request.status;

  if (status === 200) {
    const text = await request.text();
    downloadFile('menu.xml', text);
  } else {
    switch (status) {
      case 500:
        alert('Ошибка сервера');
        break;
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};

export const getDeliveryClubSettings = (populate: boolean, callback) => async (
  dispatch,
  getState
) => {
  const request = await fetch(
    `${API.url}/zakazaka/settings${populate ? '?populate=true' : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await request.status;

  if (status === 200) {
    const result = await request.json();

    if (typeof callback === 'function') callback(result.categories);

    if (populate) {
      dispatch({
        type: GET_DELIVERY_CLUB_SETTINGS,
        response: result.categories
      });
    } else return result.categories;
  } else {
    switch (status) {
      case 500:
        alert('Ошибка сервера');
        break;
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};

export const setDeliveryClubSettings = (
  data: any,
  callback: () => void
) => async (dispatch, getState) => {
  const request = await fetch(`${API.url}/zakazaka/settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await request.status;

  if (status === 200) {
    alert('Настройки успешно сохранены');
    if (typeof callback === 'function') callback();
  } else {
    switch (status) {
      case 500:
        alert('Ошибка сервера');
        break;
      case 401:
        dispatch(logOut());
        break;
      default:
        alert(`Ошибка ${status}`);
        break;
    }
  }
};
