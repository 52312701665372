import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalTitle, Form, FormGroup } from '../../elements/elements';
import { Button } from 'react-bootstrap';
import Select from '../../Select';
import SelectInput from '../../SelectInput';
import moment from 'moment';
import { getProductsByName } from '../../../actions/productActions';
import {
  addNewOrder,
  closeOrder,
  calculate2
} from '../../../actions/ordersActions';
import DateTimePicker from 'react-datetime-picker';
import { toggleProductCampaignModal } from '../../../actions/modalActions';

moment.locale('ru');

class CurrentProductModifyComponent extends Component {
  constructor(props) {
    super(props);
    const opModifiers = [];
    if (this.props.item && this.props.item.optionalModifiers) {
      this.props.item.optionalModifiers.forEach(group => {
        if (group.title === 'Целая пицца') {
          group.modifiers.forEach(modif => {
            opModifiers.push({
              name: modif.name,
              price: modif.price,
              id: modif.id,
              amount: 0,
              min: group.minAmount,
              max: group.maxAmount
            });
          });
        }
      });
    }
    this.state = {
      modifiers: opModifiers
    };
  }

  modifierAction = (i, type) => {
    const modifiers_ = this.state.modifiers;
    switch (type) {
      case 'add':
        if (modifiers_[i].amount < modifiers_[i].max) modifiers_[i].amount++;
        break;
      case 'reduce': {
        if (modifiers_[i].amount > modifiers_[i].min) modifiers_[i].amount--;
        break;
      }
      default:
        break;
    }
    this.setState({ modifiers: modifiers_ });
  };

  render() {
    return (
      <div style={{ margin: 'auto' }}>
        {this.props.item && this.props.item.name ? (
          <p>{this.props.item.name}</p>
        ) : (
          ''
        )}
        {this.state.modifiers.map((modif, index) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '5px 0px',
                justifyContent: 'space-between'
              }}
            >
              <div>{`${modif.name} ${modif.price} руб`}</div>
              <div
                style={{
                  margin: '0px 5px',
                  minWidth: '95px',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  className='btn btn-primary'
                  onClick={() => {
                    this.modifierAction(index, 'reduce');
                  }}
                >
                  -
                </Button>
                <span style={{ margin: '0px 3px', minWidth: '20px' }}>
                  {modif.amount}
                </span>
                <Button
                  className='btn btn-primary'
                  onClick={() => {
                    this.modifierAction(index, 'add');
                  }}
                >
                  +
                </Button>
              </div>
            </div>
          );
        })}
        <Button
          className='btn btn-primary'
          onClick={() => {
            this.props.applyModifiers(
              this.props.item.id,
              this.state.modifiers.filter(modif => modif.amount > 0)
            );
          }}
        >
          apply
        </Button>
      </div>
    );
  }
}

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProductModify: false,
      findedItems: [],
      productInput: '',
      selectedProduct: '',
      sumChange: 0,
      date: null,
      delayedTime: 0,
      phone: '',
      email: '',
      items: [],
      coupon: '',
      coupons: [],
      browser: '',
      comment: '',
      bonus: '0',
      contactlessDelivery: false,
      totalSum: 0,
      confirmCall: false,
      paymentMethod: 1,
      selfdelivery: false,
      name: '',
      lastName: '',
      address: {
        apartment: '',
        entrance: '',
        floor: '',
        doorphone: '',
        streetType: '',
        housing: '',
        street: '',
        city: '',
        home: '',
        lon: 0,
        lat: 0
      },
      closed: false,
      total: 0
    };
  }

  onCancel = () => {
    this.props.toggleModal();
  };

  changeField = fieldname => {
    return e => {
      this.setState({
        [fieldname]: e.target.value
      });
    };
  };

  changeBonusField = e => {
    if (
      this.props.order.data &&
      Number(e.target.value) <= this.props.order.data.bonus &&
      Number(e.target.value) >= 0
    ) {
      this.setState({
        bonus: e.target.value
      });
    }
  };

  changeProductInput = () => {
    return e => {
      this.setState({
        productInput: e.target.value
      });
    };
  };

  /*
  productsFind = () => {
    const re = new RegExp('^' + this.state.productInput + '.*', 'i')
    const res = this.props.products.filter(product => re.test(product.name))
    this.setState({ findedItems: res })
  }; */
  productsFind = () => {
    this.props.getProductsByName(this.state.productInput, res =>
      this.setState({ findedItems: res })
    );
  };

  productsAdd = () => {
    const finded = this.state.findedItems.find(
      item => item._id === this.state.selectedProduct
    );
    // const alreadyAdded = this.state.items.find(
    //  item => item._id === this.state.selectedProduct
    // );
    if (finded && true) {
      // !alreadyAdded) {
      const items = this.state.items;
      items.push({ ...finded, amount: 1 });
      this.setState({ items });
    }
  };

  handleChangeSelect = value => {
    this.setState({ selectedProduct: value });
  };

  setProduct = value => {
    this.props.toggleProductCampaignModal({ _id: value }, finded => {
      const items = this.state.items;
      items.push({ ...finded, amount: 1 });
      this.setState({ items }, this.calculate(items));
    });
  };

  handleChangeSelfDelieverySelect = value => {
    this.setState({ selfdelivery: Boolean(value) });
  };

  handleChangeDateTime = value => {
    this.setState({ date: value });
  };

  handleChangePaymentMethodSelect = value => {
    this.setState({ paymentMethod: value });
  };

  minusProduct = index => {
    const newItems = this.state.items;
    if (newItems[index].amount > 1)
      newItems[index] = {
        ...newItems[index],
        amount: newItems[index].amount - 1
      };
    this.setState({ items: newItems }, this.calculate(newItems));
  };

  plusProduct = index => {
    const newItems = this.state.items;
    if (newItems[index].amount < 99)
      newItems[index] = {
        ...newItems[index],
        amount: newItems[index].amount + 1
      };
    this.setState({ items: newItems }, this.calculate(newItems));
  };

  deleteProduct = index => {
    const newItems = this.state.items.filter((item, ind) => ind !== index);
    this.setState(
      {
        items: newItems,
        currentProductModify: false
      },
      this.calculate(newItems)
    );
  };

  handleChangeAddressSelect = value => {
    const address = this.props.order.data.addresses.find(
      addr => addr._id === value
    );
    this.setState({ address });
  };

  handleChangeRestaurantAddressSelect = value => {
    const rest = this.props.restaurants.find(rest => rest._id === value);
    if (rest)
      this.setState({
        address: { ...rest.address, lat: rest.loc.lat, lon: rest.loc.lon }
      });
  };

  openProductModifierList = itemId => {
    const item = this.state.items.find(item => {
      return item.id === itemId;
    });
    this.setState({ currentProductModify: item });
  };

  hideProductModifierList = () => {
    this.setState({ currentProductModify: false });
  };

  applyModifiers = (itemId, modifierList) => {
    const items = this.state.items.map(item => {
      if (item.id === itemId) {
        item.appliedOptionalModifiers = modifierList;
      }
      return item;
    });
    this.setState({ items, currentProductModify: false });
  };

  changeCloseState = e => {
    this.setState({ closed: e.target.checked });
  };

  calculate = items => {
    const data = {
      randomId: new Date().getTime(),
      items: items.map(item => {
        return {
          id: item.id,
          amount: item.amount,
          name: item.name,
          price: item.price,
          sum: item.price * item.amount,
          modifiers: {
            modifiers: [],
            optionalModifiers: item.appliedOptionalModifiers || []
          }
        };
      }),
      bonus: this.state.bonus,
      coupon: this.state.coupon
    };
    this.props.calculate2(data, res => {
      if (res.sum || res.sum === 0) {
        this.setState({ total: res.sum });
      }
    });
  };

  createOrder = () => {
    const data = {
      userId: this.props.order.data._id,
      paymentMethod: this.state.paymentMethod || 0,
      selfdelivery: this.state.selfdelivery,
      confirmCall: false,
      coupons: this.state.coupon ? [this.state.coupon] : [],
      comment: this.state.comment,
      bonus: this.state.bonus,
      items: this.state.items.map(item => {
        return {
          id: item.id,
          amount: item.amount,
          name: item.name,
          price: item.price,
          sum: item.price * item.amount,
          modifiers: {
            modifiers: [],
            optionalModifiers: item.appliedOptionalModifiers || []
          }
        };
      }),
      phone: this.props.order.data.phone,
      email: this.props.order.data.email,
      name: this.props.order.data.firstName,
      lastName: this.props.order.data.lastName,
      address: this.state.address,
      lon: this.state.address.lon,
      lat: this.state.address.lat,
      createdAt: this.state.date ? new Date(this.state.date) : null,
      createdByAdmin: true
    };
    this.props.addNewOrder(data, res => {
      if (this.state.closed) {
        this.props.closeOrder(
          {
            state: 'Закрыта',
            orders: [res.orderID]
          },
          res2 => {
            alert(`Заказ ${res.orderID} создан`);
            alert(`Заказ ${res.orderID} закрыт`);
          }
        );
      } else {
        alert(`Заказ ${res.orderID} создан`);
      }
    });
  };

  render() {
    let firstName = '';

    let lastName = '';

    let email = '';

    let phone = '';
    let bonus = '';
    if (this.props.order.data) {
      firstName = this.props.order.data.firstName;
      lastName = this.props.order.data.lastName;
      email = this.props.order.data.email;
      phone = this.props.order.data.phone;
      bonus = this.props.order.data.bonus;
    }
    return (
      <div>
        <Modal
          show={this.props.show}
          toggleModal={this.props.toggleModal}
        >
          <ModalTitle title='Добавить заказ' />
          <Form
            direction='column'
            onSubmit={this.createOrder}
            style={{ marginBottom: 60 }}
            value='Отправить'
          >
            <FormGroup
              disabled
              id='useremail'
              name='email'
              onChange={this.changeField('email')}
              placeholder='email'
              required
              value={email}
            />
            <FormGroup
              disabled
              id='userphone'
              name='phone'
              onChange={this.changeField('phone')}
              placeholder='phone'
              required
              value={phone}
            />
            <FormGroup
              disabled
              id='userfirstname'
              name='firstName'
              onChange={this.changeField('name')}
              placeholder='Имя'
              required
              value={firstName}
            />
            <FormGroup
              disabled
              id='userlastname'
              name='lastName'
              onChange={this.changeField('lastName')}
              placeholder='Фамилия'
              required
              value={lastName}
            />
            <FormGroup
              id='comment'
              name='comment'
              onChange={this.changeField('comment')}
              placeholder='Коммент'
              value={this.state.comment}
            />
            <FormGroup
              id='coupon'
              name='coupon'
              onChange={this.changeField('coupon')}
              placeholder='Купон/промокод'
              value={this.state.coupon}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            />
            <div style={{ marginBottom: '20px' }}>
              <SelectInput setProduct={this.setProduct} />

              {this.state.items.map((item, index) => {
                let addInfo = '';
                if (item.additionalInfo) {
                  if (item.additionalInfo.size)
                    addInfo += `${item.additionalInfo.size} `;
                  if (item.additionalInfo.dough)
                    addInfo += item.additionalInfo.dough;
                }
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline'
                    }}
                  >
                    <FormGroup
                      disabled
                      id={item.name}
                      labelHidden
                      name={item.name}
                      style={{
                        marginBottom: '0px',
                        marginTop: '20px',
                        marginRight: '10px'
                      }}
                      value={`${item.name} ${addInfo} ${item.price}`}
                    />
                    <Button
                      className='btn btn-primary'
                      onClick={() => {
                        this.minusProduct(index);
                      }}
                    >
                      -
                    </Button>
                    <span
                      style={{ margin: '0px 3px', minWidth: '20px' }}
                    >{`${item.amount}шт`}</span>
                    <Button
                      className='btn btn-primary'
                      onClick={() => {
                        this.plusProduct(index);
                      }}
                    >
                      +
                    </Button>
                    <Button
                      className='btn btn-primary'
                      onClick={() => {
                        this.deleteProduct(index);
                      }}
                    >
                      X
                    </Button>
                    {this.state.currentProductModify &&
                    this.state.currentProductModify.id === item.id ? (
                      <Button
                        className='btn btn-primary'
                        onClick={() => {
                          this.hideProductModifierList();
                        }}
                      >
                        hide
                      </Button>
                    ) : (
                      <Button
                        className='btn btn-primary'
                        onClick={() => {
                          this.openProductModifierList(item.id);
                        }}
                      >
                        edit
                      </Button>
                    )}
                  </div>
                );
              }) || 'no items'}
              {this.state.currentProductModify ? (
                <CurrentProductModifyComponent
                  applyModifiers={this.applyModifiers}
                  item={this.state.currentProductModify}
                />
              ) : (
                ''
              )}
            </div>
            <div>{`Итоговая сумма: ${this.state.total}`}</div>
            <Select
              default='Тип заказа'
              items={[
                {
                  text: 'Самовывоз',
                  value: true
                },
                {
                  text: 'Доставка',
                  value: false
                }
              ]}
              onChange={this.handleChangeSelfDelieverySelect}
              withClear
            />
            <Select
              default='Выберите тип оплаты'
              items={[
                { value: '0', text: 'Наличными' },
                { value: '1', text: 'Картой при получении заказа' },
                { value: '2', text: 'Картой онлайн' }
              ]}
              onChange={this.handleChangePaymentMethodSelect}
              withClear
            />
            {this.state.selfdelivery ? (
              <Select
                default='Выберите ресторан'
                items={this.props.restaurants.map(rest => {
                  return {
                    text: rest.name,
                    value: rest._id
                  };
                })}
                onChange={this.handleChangeRestaurantAddressSelect}
                withClear
              />
            ) : this.props.order &&
              this.props.order.data &&
              this.props.order.data.addresses ? (
              <Select
                default='Выберите адрес'
                items={this.props.order.data.addresses.map(address => {
                  return {
                    text: `${address.city} ${address.streetType} ${address.street} ${address.home}`,
                    value: address._id
                  };
                })}
                onChange={this.handleChangeAddressSelect}
                withClear
              />
            ) : (
              'loading'
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormGroup
                id='bonus'
                maxlength='4'
                name='bonus'
                onChange={this.changeBonusField}
                placeholder='бонусы'
                value={this.state.bonus}
              />{' '}
              / {bonus}{' '}
            </div>
            <FormGroup
              checked={this.state.closed}
              id='closed'
              name='closed'
              onChange={this.changeCloseState}
              placeholder='Закрыть после создания'
              type='checkbox'
            />
            <DateTimePicker
              onChange={this.handleChangeDateTime}
              value={this.state.date}
            />
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.modal.modalContent,
    restaurants: state.restaurants.restaurants,
    categories: state.products.categories,
    products: state.products.products
  };
}

export default connect(mapStateToProps, {
  getProductsByName,
  addNewOrder,
  calculate2,
  closeOrder,
  toggleProductCampaignModal
})(ModalComponent);
