/* eslint-disable */

import { API } from '../../actions';
import { getQueryParameters } from './dependencies';

const defaultErrors = {
  500: 'Ошибка сервера',
  504: 'Превышено время ожидания ответа от сервера'
};

const request = async (pathname, options) => {
  const {
    done,
    body,
    query,
    method,
    errors,
    failed,
    headers,
    success,
    successStatuses
  } = options;

  let path = `${API.url}${pathname}`;
  if (query) path += `?${getQueryParameters(query)}`;

  const requestOptions = { method };

  if (body) requestOptions.body = body;
  if (headers) requestOptions.headers = headers;
  else requestOptions.headers = { 'Content-Type': 'application/json' };

  try {
    const serverRequest = await fetch(path, requestOptions);

    const response = await serverRequest.json();
    const status = await serverRequest.status;
    const statuses = successStatuses || [200];
    const errorsStatuses = { ...errors, ...defaultErrors };

    if (statuses.indexOf(status) !== -1) {
      if (success) {
        success(response, status);
      }
    } else {
      if (failed) {
        failed(response, status);
      } else {
        for (const key in errorsStatuses) {
          if (errorsStatuses.hasOwnProperty(key)) {
            if (Number(status) === Number(key)) {
              console.error(path, errorsStatuses[key]);
            }
          }
        }
      }
    }

    if (done) done(response, status);
  } catch (error) {
    console.log(`ERROR FETCH: ${path}`);
    console.log(error);
  }
};

export default request;
