import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_VACANCIES = 'FETCH_VACANCIES';
export const CREATE_VACANCY = 'CREATE_VACANCY';
export const CLEAR_VACANCIES = 'CLEAR_VACANCIES';

export const DELETE_VACANCY = 'DELETE_VACANCY';
export const UPDATE_VACANCY = 'UPDATE_VACANCY';
export const FETCH_VACANCY = 'FETCH_VACANCY';

export const fetchVacancies = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/vacancies`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_VACANCIES,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export function clearVacancies() {
  return {
    type: CLEAR_VACANCIES,
    payload: []
  };
}

export const fetchVacancy = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/vacancies/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_VACANCY,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }

  if (callback && typeof callback === 'function') callback();
};

export const deleteVacancy = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/vacancies/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Вакансия успешно удалена');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createVacancy = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/vacancies`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Вакансия успешно создана');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateVacancy = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/vacancies/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      alert('Вакансия успешно обновлена');
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
