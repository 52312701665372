import React from 'react';
import { connect } from 'react-redux';
import {
  addManager,
  fetchManager,
  clearManager
} from '../../actions/restaurantActions';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';

class ManagerCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      managerData: {
        login: '',
        password: '',
        restaurantId: this.props.restourant
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    event.persist();

    this.setState(prevState => ({
      managerData: {
        ...prevState.managerData,
        [event.target.name]: event.target.value
      }
    }));
  }

  refresh = () => {
    this.props.clearManager();
    this.props.fetchManager(this.props.restourant);
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = { ...this.state.managerData };
    data.restaurantId = this.props.restourant;

    this.props.addManager(data, this.refresh);

    this.props.onPressClose();

    this.setState({
      managerData: {
        login: '',
        password: '',
        restaurantId: ''
      }
    });
  }

  render() {
    return (
      <div>
        <Modal
          onHide={this.props.toggleModal}
          show={this.props.show}
        >
          <Modal.Body>
            <Form
              id='newManager'
              onSubmit={this.handleSubmit}
            >
              <h3 className='text-center'>Добавить менеджера</h3>

              <FormGroup>
                <ControlLabel>Логин:</ControlLabel>
                <FormControl
                  name='login'
                  onChange={this.handleChange}
                  required='required'
                  type='text'
                  value={this.state.managerData.login}
                />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Пароль:</ControlLabel>
                <FormControl
                  name='password'
                  onChange={this.handleChange}
                  required='required'
                  type='text'
                  value={this.state.managerData.password}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='success'
              form='newManager'
              type='submit'
            >
              Добавить
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.props.onPressClose}
            >
              Отменить
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories.categories
  };
}

export default connect(mapStateToProps, {
  addManager,
  fetchManager,
  clearManager
})(ManagerCreate);
