import queryString from 'query-string';
import moment from 'moment';
import { DEFAULT_TIME } from '../../lib/consts';

export const getCouponName = type => {
  switch (type) {
    case 'dessertMonthly':
      return 'Купон на десерт (от уровня Эксперта и выше)';
    case 'monthlyMediumPizza':
      return 'Купон на пиццу 30см за статус (от уровня Гуру и выше)';
    case 'morsMonthly':
      return 'Купон на морс (от уровня VIP и выше)';
    case 'lateDelivery':
      return 'Купон на пиццу 23см за опоздание курьера';
    case 'monthlySmallPizza':
      return 'Купон на пиццу 23см за статус (от уровня VIP и выше)';
    case 'levelUp':
      return 'Купон на пиццу 23см за переход на новый статус';
    case 'snackMonthly':
      return 'Купон на закуску (от уровня Сенсей и выше)';
    case 'expensiveOrder':
      return 'Купон на пиццу 30 см за заказ от 900р';
    case 'download':
      return 'Купон на бесплатную пепперони';
    case 'monthlyBigPizza':
      return 'Купон на пиццу 35см за статус (от уровень Император Пиццы)';
    case 'app':
      return 'Купон на пепперони 23см за скачивание приложения';
    case 'sliceSticker':
      return 'Купон-слайс';
    case 'coffeeSticker':
      return 'Кофе-слайс';
    case 'firstOrder':
      return 'Купон 30% скидка за первый заказ';
    case 'greatThursday':
      return 'Великолепный четверг';
    case 'birthdaySale':
      return 'Купон на скидку на День Рождения';
    case 'newProductsTest':
      return 'Купон на бесплатный тест новинок';
    default:
      return type;
  }
};

export const getLocalStorage = name => {
  let response = false;
  if (name) {
    if (
      localStorage.getItem(name) &&
      localStorage.getItem(name) !== 'undefined' &&
      localStorage.getItem(name) !== 'false'
    ) {
      const storage = localStorage.getItem(name);
      const result = JSON.parse(storage);

      if (result && result.timestamp) {
        const currentTime = new Date().getTime();
        const expireTime = 200000;

        if (currentTime - result.timestamp > expireTime) {
          response = false;
          localStorage.setItem(name, JSON.stringify(false));
        } else response = result;
      } else response = result;
    } else response = false;
  }

  return response;
};

export const getQueryString = (param, search) => {
  const params = queryString.parse(search || window.location.search);

  for (const key in params) {
    if (!params[key]) delete params[key]; // выпилить если пустой
  }

  return param ? params[param] : params;
};

export function pushHistory(query) {
  const { history } = this.props;

  const formatedQuery = {
    ...getQueryString(),
    ...query
  };

  for (const key in formatedQuery) {
    if (!formatedQuery[key]) {
      delete formatedQuery[key];
    }
  }

  history.push(
    `${history.location.pathname}?${queryString.stringify({
      ...formatedQuery
    })}`
  );
}

export const formatTwoDigitNumber = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

export const getRemainingTime = (value: string | null) => {
  let result = DEFAULT_TIME;
  const date = value ? moment(value) : null;
  const now = moment();

  if (date && date.diff(now) > 0) {
    const value = moment.duration(date.diff(now));

    const years = value.years() ? `${value.years()}г ` : '';
    const months = value.months() ? `${value.months()}м ` : '';
    const days = value.days() ? `${value.days()}д ` : '';
    const hours = value.hours() ? `${value.hours()}ч ` : '';
    const time = `${formatTwoDigitNumber(
      value.minutes()
    )}:${formatTwoDigitNumber(value.seconds())}`;

    result = `${years}${months}${days}${hours}${time}`;
  }

  return result;
};
