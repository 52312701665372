import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';

import moment from 'moment';

import Loader from '../components/interface/loader';
import {
  fetchStatisctics,
  fetchStatiscticPromocode,
  fetchUserTypeStatistic,
  fetchBonusLevels,
  fetchCouponsStatistic,
  fetchThursdayStatistic
} from '../actions/statisticsActions';
import { fetchRestaurants } from '../actions/restaurantActions';
import { HeaderPage, Button } from '../components/elements/elements';
import { getCouponName } from '../components/elements/functions';

import '../css/style.css';
import Select from '../components/Select';

moment.locale('ru');

const defaultGraphicData = {
  label: 'Graphic Label',
  fill: true,
  lineTension: 0.1,
  backgroundColor: 'rgba(75,192,192,0.4)',
  borderColor: 'rgba(75,192,192,1)',
  borderCapStyle: 'square',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(75,192,192,1)',
  pointBackgroundColor: 'rgba(75,192,192,1)',
  pointBorderWidth: 0,
  pointHoverRadius: 10,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgb(62, 62, 76)',
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 10
};

const daysSelectItems = [
  {
    text: 'Все дни',
    value: ''
  },
  {
    text: 'Воскресенье',
    value: '0'
  },
  {
    text: 'Понедельник',
    value: '1'
  },
  {
    text: 'Вторник',
    value: '2'
  },
  {
    text: 'Среда',
    value: '3'
  },
  {
    text: 'Четверг',
    value: '4'
  },
  {
    text: 'Пятница',
    value: '5'
  },
  {
    text: 'Суббота',
    value: '6'
  }
];

const NOT_AUTH_USERS = 'Незарегистрированные пользователи';
const ALL_USERS = 'Все пользователи';
const ALL_RESTAURANTS = 'Все рестораны';
class StatisticPage extends Component {
  state = {
    graphic: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      data: [65, 59, 80, 81, 56, 55, 40]
    },
    period: 'week',
    status: ALL_USERS,
    restaurant: ALL_RESTAURANTS,
    type: {},
    begin: '',
    end: '',
    couponsType: '',
    dayOfWeek: ''
  };

  async componentDidMount() {
    await Promise.all([
      this.props.fetchBonusLevels(),
      this.props.fetchRestaurants(),
      this.fetchGraphic()
    ]);
  }

  onChangeInput = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  getCouponsType = () => {
    const items = [
      { value: '', text: 'Все' },
      { value: 'archived', text: 'Архивированные' },
      { value: 'used', text: 'Использованные' }
    ];

    return items;
  };

  getItemsStatus = () => {
    const { bonusLevels } = this.props;

    return [
      {
        value: ALL_USERS,
        text: ALL_USERS
      },
      {
        value: 'Все зарегистрированные пользователи',
        text: 'Все зарегистрированные пользователи'
      },
      ...bonusLevels.map(a => ({ value: a.name, text: a.name }))
    ];
  };

  getRestaurantsStatus = () => {
    const { restaurants } = this.props;

    return [
      {
        value: ALL_RESTAURANTS,
        text: ALL_RESTAURANTS
      },
      ...restaurants.map(r => ({ value: r.iikoId, text: r.name }))
    ];
  };

  getPromocodesGraphic = (attribute, graphicName) => {
    const { status } = this.state;
    const { promocodesStatistic } = this.props;

    let data;

    if (promocodesStatistic) {
      for (const key in promocodesStatistic) {
        if (promocodesStatistic.hasOwnProperty(key)) {
          if (key === status) {
            data = promocodesStatistic[key];
            break;
          }
        }
      }
    }

    if (data && graphicName) {
      if (attribute === 'data') {
        return {
          labels: data.map(a => a.promocode),
          datasets: [
            {
              ...defaultGraphicData,
              placeholder: 'Применено купонов',
              data: data.map(a => a.count)
            }
          ]
        };
      }

      if (attribute === 'options') {
        return {
          legend: {
            display: false
          }
        };
      }
    }

    return {};
  };

  getCouponsGraphic = (attribute, graphicName) => {
    const { couponsStatistic } = this.props;
    let { status } = this.state;

    if (
      couponsStatistic &&
      Object.keys(couponsStatistic).length &&
      graphicName
    ) {
      if (attribute === 'data') {
        const statuses = this.getItemsStatus();
        if (!couponsStatistic[status] && status === statuses[1].value) {
          status = statuses[0].value;
        }
        return {
          labels: couponsStatistic[status].map((a, i, arr) => {
            const label = getCouponName(a.coupon);
            if (label) {
              if (i + 1 === arr.length) return label;
              return [
                label.slice(0, label.length / 2),
                label.slice(label.length / 2)
              ];
            }
            return '';
          }),
          datasets: [
            {
              ...defaultGraphicData,
              placeholder: 'Кол-во пользователей',
              data: couponsStatistic[status].map(a => a.count)
            }
          ]
        };
      }

      if (attribute === 'options') {
        return {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 80,
                  fontSize: 10
                }
              }
            ]
          }
        };
      }
    }
  };

  getUserTypeGraphic = (attribute, graphicName) => {
    const { userStatistic } = this.props;

    if (userStatistic && graphicName) {
      if (attribute === 'data') {
        return {
          labels: userStatistic.map(a => a.userLevel),
          datasets: [
            {
              ...defaultGraphicData,
              placeholder: 'Кол-во пользователей',
              data: userStatistic.map(a => a.count)
            }
          ]
        };
      }

      if (attribute === 'options') {
        return {
          legend: {
            display: false
          }
        };
      }
    }
  };

  getThursdayGraphic = (attribute, graphicName) => {
    const { thursdayStatistic } = this.props;
    if (thursdayStatistic && graphicName) {
      if (attribute === 'data') {
        const graph = { labels: [], datasets: [] };
        const data = {};
        Object.keys(thursdayStatistic).forEach(a => {
          graph.labels.push(a);
          Object.keys(thursdayStatistic[a]).forEach(sale => {
            if (!data[sale]) data[sale] = [];
            data[sale].push(thursdayStatistic[a][sale]);
          });
        });
        Object.keys(data).forEach(d => {
          graph.datasets.push({
            ...defaultGraphicData,
            label: d,
            fill: false,
            placeholder: d,
            data: data[d]
          });
        });
        return graph;
      }

      if (attribute === 'options') {
        return {
          legend: {
            display: false
          }
        };
      }
    }
  };

  getGraphicPlaceholder = name => {
    switch (name) {
      case 'orders':
        return 'Заказов';

      case 'ordersSum':
        return 'Заказов на сумму';

      case 'ordersByUserType':
        return 'Заказов';

      case 'ordersQuantityUserType':
        return 'Количество заказов';

      case 'ratings':
        return 'Оценок';

      case 'registrations':
        return 'Регистраций';

      case 'bonusWasted':
        return 'Потрачено баллов';

      case 'bonusGiven':
        return 'Выдано баллов';

      case 'bonusTurnover':
        return 'Всего баллов';

      case 'registrationsWithCitizenCard':
        return 'Пользователей';

      default:
        return 'Неизвестный name';
    }
  };

  getCurStat = (name, customName) => item => {
    const { status, restaurant } = this.state;
    const statuses = this.getItemsStatus();
    if (
      customName === 'ordersQuantityUserType' &&
      name === 'ordersByUserType'
    ) {
      if (status === statuses[1].value) {
        let count = 0;
        item.forEach(el => {
          if (
            el.type !== NOT_AUTH_USERS &&
            (restaurant === ALL_RESTAURANTS || el.restaurant === restaurant)
          )
            count += el.count;
        });
        return count;
      }
      let count = 0;
      for (let i = 0; i < item.length; i++) {
        if (
          status === item[i].type &&
          (restaurant === ALL_RESTAURANTS || item[i].restaurant === restaurant)
        )
          count += item[i].count;
      }
      return count;
    }
    if (name === 'ordersByUserType') {
      if (status === statuses[1].value) {
        let sum = 0;
        item.forEach(el => {
          if (
            el.type !== NOT_AUTH_USERS &&
            (restaurant === ALL_RESTAURANTS || el.restaurant === restaurant)
          )
            sum += el.sum;
        });
        return sum;
      }
      let sum = 0;
      for (let i = 0; i < item.length; i++) {
        if (
          status.toLowerCase() === item[i].type.toLowerCase() &&
          (restaurant === ALL_RESTAURANTS || item[i].restaurant === restaurant)
        )
          sum += item[i].sum;
      }
      return sum;
    }
    if (name === 'orders') {
      let count = 0;
      for (let i = 0; i < item.length; i++) {
        if (restaurant === ALL_RESTAURANTS || item[i].restaurant === restaurant)
          count += item[i].count;
      }
      return count;
    }
    if (name === 'ratings') {
      let count = 0;
      for (let i = 0; i < item.length; i++) {
        if (restaurant === ALL_RESTAURANTS || item[i].restaurant === restaurant)
          count += item[i].count;
      }
      return count;
    }
    if (name === 'ordersSum') {
      let sum = 0;
      for (let i = 0; i < item.length; i++) {
        if (restaurant === ALL_RESTAURANTS || item[i].restaurant === restaurant)
          sum += item[i].sum;
      }
      return sum;
    }

    if (name === 'bonusWasted') return Math.abs(item);

    return item;
  };

  getGraphic = (attribute, name, customName) => {
    const labelDefault = ['', '', '', '', '', '', '', ''];
    const dataDefault = [1, 1, 1, 1, 1, 1, 1, 1];
    if (name) {
      if (attribute === 'data') {
        const data = {
          labels: this.props.statistics
            ? this.props.statistics[name].label.map(day =>
                moment(day).format('DD.MM')
              )
            : labelDefault,
          datasets: [
            {
              ...defaultGraphicData,
              placeholder: this.getGraphicPlaceholder(name),
              data: this.props.statistics
                ? this.props.statistics[name].data.map(
                    this.getCurStat(name, customName)
                  )
                : dataDefault
            }
          ]
        };

        return data;
      }

      if (attribute === 'options') {
        const options = {
          legend: {
            display: false
          }
        };

        return options;
      }
    }
  };

  fetchGraphic = async () => {
    const { period, couponsType, dayOfWeek, begin, end } = this.state;

    const beginToString = moment(begin, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();
    const endToString = moment(end, 'YYYY.MM.DD')
      .add('hours', 3)
      .toISOString();

    const mainMethodData = { period };
    const additionalMethodData = { type: couponsType };

    if (period === 'custom') {
      mainMethodData.end = endToString;
      mainMethodData.begin = beginToString;
      additionalMethodData.to = endToString;
      additionalMethodData.from = beginToString;
    } else {
      mainMethodData.day_of_week = dayOfWeek;

      additionalMethodData.period = period;
      additionalMethodData.day_of_week = dayOfWeek;
    }

    return new Promise(async res => {
      await Promise.all([
        this.props.fetchStatisctics(mainMethodData),
        this.props.fetchCouponsStatistic(additionalMethodData),
        this.props.fetchUserTypeStatistic(additionalMethodData),
        this.props.fetchStatiscticPromocode(additionalMethodData),
        this.props.fetchThursdayStatistic(additionalMethodData)
      ]);

      res();
    });
  };

  acceptCustomPeriod = () => {
    this.fetchGraphic();
  };

  renderPeriodInputs = () => {
    const { period } = this.state;

    if (period === 'custom') {
      return (
        <>
          <input
            className='input-element'
            name='begin'
            onChange={this.onChangeInput}
            placeholder='от'
            style={{ width: '30%' }}
            type='date'
            value={this.state.begin}
          />
          <input
            className='input-element'
            name='end'
            onChange={this.onChangeInput}
            placeholder='до'
            style={{ margin: '0 20px', width: '30%' }}
            type='date'
            value={this.state.end}
          />
          <Button
            little
            onClick={this.acceptCustomPeriod}
            value='Потвердить'
          />
        </>
      );
    }
  };

  onChangePeriodSelect = value => {
    this.setState({ period: value }, () => {
      if (value !== 'custom') {
        this.setState({ begin: '', end: '' }, this.fetchGraphic);
      }
    });
  };

  onChangeStatusSelect = (value, name) => {
    this.setState({ status: name });
  };

  onChangeRestaurantsStatusSelect = (value, name) => {
    this.setState({ restaurant: value });
  };

  onChangeDaysSelect = async value => {
    await this.setState({ dayOfWeek: value });
    this.fetchGraphic();
  };

  onChangeCouponsTypeSelect = value => {
    this.setState({ couponsType: value }, () => {
      this.fetchGraphic();
    });
  };

  getItems = () => {
    const items = [
      { value: 'week', text: 'Неделя' },
      { value: 'month', text: 'Месяц' },
      { value: 'year', text: 'Год' },
      { value: 'custom', text: 'Выбрать период' }
    ];

    return items;
  };

  getUserStatistic = defaultTitle => {
    const { couponsStatistic } = this.props;
    return `${defaultTitle} ${Number(couponsStatistic.count).toLocaleString(
      'ru-RU'
    )}`;
  };

  getStatisticOrdersByTypeTitle = (defaultTitle, type /* sum, count */) => {
    const { statistics } = this.props;
    const { status, restaurant } = this.state;
    let total = 0;
    if (statistics && statistics.ordersByUserType) {
      statistics.ordersByUserType.data.map(day => {
        day.map(item => {
          if (item.type === status || status === ALL_USERS) {
            if (
              restaurant === ALL_RESTAURANTS ||
              restaurant === item.restaurant
            ) {
              total += item[type];
            }
          }
          return item;
        });
        return day;
      });
      return `${defaultTitle} ${total.toLocaleString('ru-RU')}`;
    }

    return defaultTitle;
  };

  getStatisticCountTitle = (name, defaultTitle) => {
    const { statistics } = this.props;
    const { restaurant } = this.state;
    let total = 0;
    if (name === 'ratings') {
      statistics.ratings.data.map(day => {
        day.map(item => {
          if (
            restaurant === ALL_RESTAURANTS ||
            restaurant === item.restaurant
          ) {
            total += item.count;
          }
          return item;
        });
        return day;
      });
    } else if (name === 'ordersSum') {
      statistics.ordersSum.data.map(day => {
        day.map(item => {
          if (
            restaurant === ALL_RESTAURANTS ||
            restaurant === item.restaurant
          ) {
            total += item.sum;
          }
          return item;
        });
        return day;
      });
    } else if (name === 'orders') {
      statistics.orders.data.map(day => {
        day.map(item => {
          if (
            restaurant === ALL_RESTAURANTS ||
            restaurant === item.restaurant
          ) {
            total += item.count;
          }
          return item;
        });
        return day;
      });
    }
    return `${defaultTitle} ${total.toLocaleString('ru-RU')}`;
  };

  getStatisticTitle = (name, defaultTitle) => {
    const { statistics } = this.props;
    if (statistics && statistics[name] && String(statistics[name].total)) {
      return `${defaultTitle} ${statistics[name].total.toLocaleString(
        'ru-RU'
      )}`;
    }

    return defaultTitle;
  };

  getGraphicName = (name, customName) => {
    const { status } = this.state;
    const statuses = this.getItemsStatus();
    if (status === statuses[0].value && name === 'ordersByUserType') {
      return customName === 'ordersQuantityUserType' ? 'orders' : 'ordersSum';
    }
    return name;
  };

  render() {
    const { statistics } = this.props;
    const { status } = this.state;

    if (!statistics)
      return (
        <Loader>
          <div>Загрузка статистики...</div>
        </Loader>
      );
    return (
      <div>
        <HeaderPage title='Статистика'>
          <Select
            items={this.getItems()}
            onChange={this.onChangePeriodSelect}
          />
          <Select
            items={daysSelectItems}
            onChange={this.onChangeDaysSelect}
          />
          <Select
            items={this.getItemsStatus()}
            onChange={this.onChangeStatusSelect}
            style={{ marginLeft: '5px' }}
          />
        </HeaderPage>
        <HeaderPage>
          <div>
            <Select
              items={this.getRestaurantsStatus()}
              onChange={this.onChangeRestaurantsStatusSelect}
              style={{ marginLeft: '20px' }}
            />
          </div>
        </HeaderPage>
        <div>{this.renderPeriodInputs()}</div>

        <div>
          <HeaderPage
            onlyTitle
            title={this.getStatisticCountTitle('ordersSum', 'Заказов на сумму')}
          />
          <Line
            data={this.getGraphic('data', 'ordersSum')}
            options={this.getGraphic('options', 'ordersSum')}
          />
        </div>
        <div>
          <HeaderPage
            onlyTitle
            title={this.getStatisticTitle(
              'registrationsWithCitizenCard',
              'Пользователи, зарегистрированные с помощью карты ЕКП'
            )}
          />
          <Line
            data={this.getGraphic('data', 'registrationsWithCitizenCard')}
            options={this.getGraphic('options', 'registrationsWithCitizenCard')}
          />
        </div>

        <div className='flex-graphic mt40'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticOrdersByTypeTitle(
                `Заказов на сумму «${status}»`,
                'sum'
              )}
            />
            <Line
              data={this.getGraphic(
                'data',
                this.getGraphicName('ordersByUserType')
              )}
              options={this.getGraphic('options', 'ordersByUserType')}
            />
          </div>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticOrdersByTypeTitle(
                `Заказы «${status}»`,
                'count'
              )}
            />
            <Line
              data={this.getGraphic(
                'data',
                this.getGraphicName(
                  'ordersByUserType',
                  'ordersQuantityUserType'
                ),
                'ordersQuantityUserType'
              )}
              options={this.getGraphic('options', 'ordersQuantityUserType')}
            />
          </div>
        </div>

        <div className='flex-graphic mt40'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticCountTitle('orders', 'Заказы')}
            />
            <Line
              data={this.getGraphic('data', 'orders')}
              options={this.getGraphic('options', 'orders')}
            />
          </div>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticTitle('registrations', 'Регистрации')}
            />
            <Line
              data={this.getGraphic('data', 'registrations')}
              options={this.getGraphic('options', 'registrations')}
            />
          </div>
        </div>

        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticCountTitle('ratings', 'Оценки')}
            />
            <Line
              data={this.getGraphic('data', 'ratings')}
              options={this.getGraphic('options', 'ratings')}
            />
          </div>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticTitle('bonusTurnover', 'Всего баллов')}
            />
            <Line
              data={this.getGraphic('data', 'bonusTurnover')}
              options={this.getGraphic('options', 'bonusTurnover')}
            />
          </div>
        </div>

        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticTitle('bonusGiven', 'Выдано баллов')}
            />
            <Line
              data={this.getGraphic('data', 'bonusGiven')}
              options={this.getGraphic('options', 'bonusGiven')}
            />
          </div>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticTitle('bonusWasted', 'Потрачено баллов')}
            />
            <Line
              data={this.getGraphic('data', 'bonusWasted')}
              options={this.getGraphic('options', 'bonusWasted')}
            />
          </div>
        </div>
        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title={`Промокоды «${status}»`}
            />
            <Line
              data={this.getPromocodesGraphic('data', 'promocodes')}
              options={this.getPromocodesGraphic('options', 'promocodes')}
            />
          </div>
        </div>

        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getStatisticTitle('userType', 'Кол-во пользователей')}
            />
            <Line
              data={this.getUserTypeGraphic('data', 'userType')}
              options={this.getUserTypeGraphic('options', 'userType')}
            />
          </div>
        </div>
        <Select
          items={this.getCouponsType()}
          onChange={this.onChangeCouponsTypeSelect}
          style={{ width: '25%', marginTop: '50px' }}
        />
        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title={this.getUserStatistic(
                `Купоны для пользователей «${status}»`
              )}
            />
            <Line
              data={this.getCouponsGraphic('data', 'coupons')}
              options={this.getCouponsGraphic('options', 'coupons')}
            />
          </div>
        </div>
        <div className='flex-graphic mt20'>
          <div>
            <HeaderPage
              onlyTitle
              title='Статистика по купонам четверга'
            />
            <Line
              data={this.getThursdayGraphic('data', 'thursday')}
              options={this.getThursdayGraphic('options', 'thursday')}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    statistics: state.statistics.statistics,
    promocodesStatistic: state.statistics.promocodesStatistic,
    userStatistic: state.statistics.userStatistic,
    bonusLevels: state.statistics.bonusLevels,
    restaurants: state.restaurants.restaurants,
    couponsStatistic: state.statistics.couponsStatistic,
    thursdayStatistic: state.statistics.thursdayStatistic
  };
}

export default connect(mapStateToProps, {
  fetchStatisctics,
  fetchStatiscticPromocode,
  fetchUserTypeStatistic,
  fetchBonusLevels,
  fetchRestaurants,
  fetchCouponsStatistic,
  fetchThursdayStatistic
})(StatisticPage);
