import {
  START_PAGE,
  FETCH_COUPONS_TYPES,
  FETCH_USERS,
  CLEAR_USERS,
  CLEAR_ALL_USERS,
  CURRENT_USER,
  GET_CHANGE_REQUESTS,
  FETCH_ALL_USERS
} from '../actions/usersActions';

const INITIAL_STATE = {
  user: '',
  users: [],
  currentUser: '',
  couponTypes: [],
  changeRequests: {
    requests: [],
    count: 0
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS:
      return { ...state, user: action.payload };
    case FETCH_ALL_USERS:
      return { ...state, users: action.payload };

    case CLEAR_ALL_USERS:
      return { ...state, users: action.payload };

    case FETCH_COUPONS_TYPES:
      return { ...state, couponTypes: action.payload };

    case CLEAR_USERS:
      return { ...state, user: action.payload };

    case START_PAGE:
      return { ...state, user: action.payload };

    case CURRENT_USER:
      return { ...state, currentUser: action.payload };

    case GET_CHANGE_REQUESTS:
      return { ...state, changeRequests: action.response };

    default:
      return state;
  }
}
