import moment from 'moment';
import React, { Component } from 'react';
import DadataSuggestions from 'react-dadata-suggestions';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import ManagerCreate from '../components/restaurant/managerCreate';
import PolygoneModal from '../components/restaurant/polygonModal';
import ManagerModal from '../components/restaurant/managerModal';
import {
  createRestaurant,
  fetchRestaurant,
  updateRestaurant,
  deleteRestaurant,
  clearRestourant,
  fetchZone,
  clearZone,
  fetchManager,
  clearManager
} from '../actions/restaurantActions';
import { uploadImage, googleKey } from '../actions';

import {
  HeaderPage,
  FormGroup,
  Button,
  Table,
  SvgButton,
  ImageBlock,
  Form,
  Input
} from '../components/elements/elements';
import InputMask from 'react-input-mask';
import Select from '../components/Select';
import { days } from '../lib/consts';
import RestaurantFormZones from '../components/RestaurantFormZones';

const token = 'd7cf5115a7f29a4ddc0621cd23bfb5dae1902f0b';

const defaultworkTimeForEveryDay = '09';

const restaurantZones = [
  {
    value: 'closeAreaClose',
    label: 'Гарантированная зона',
    timerName: 'closeAreaDateReset'
  },
  {
    value: 'notGuaranteedClose',
    label: 'Негарантированная зона',
    timerName: 'notGuaranteedDateReset'
  },
  {
    value: 'goldenMileClose',
    label: 'Золотая миля',
    timerName: 'goldenMileDateReset'
  },
  {
    value: 'specialClose',
    label: 'Специальная зона',
    timerName: 'specialDateReset'
  }
];

class RestaurantForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorZone: [
        '#00FF0050',
        '#0000FF50',
        '#FF000050',
        '#FFFF0050',
        '#00FFFF50',
        '#FF00FF50',
        '#00FFas50',
        '#dsFF0050',
        '#ccFF0050',
        '#00FFcc50',
        '#00FF3150',
        '#00FF4250',
        '#55FF0050',
        '#65FF0050',
        '#44FF4450'
      ],
      showCreateModal: false,
      showPolygoneModal: false,
      showManagerModal: false,
      formAction: null,
      restaurantData: {
        pictures: {
          photos: []
        },
        additionalServices: {},
        workTimeForEveryDay: {},
        _id: undefined,
        iikoId: '',
        reservation: {},
        hatching: {
          direction: '0',
          color: ''
        }
      },
      sberbankAuth: {
        bankLogin: '',
        bankPassword: '',
        bankMerchant: ''
      },
      loc: {
        lat: null,
        lon: null
      },
      manager: {},
      loaders: {},
      mapState: {
        map: null,
        autocomplete: null,
        markers: [],
        mapConfig: {
          center: { lat: 59.939095, lng: 30.315868 },
          zoom: 10,
          disableDefaultUI: true,
          zoomControl: true
        }
      },
      update: true,
      zoneTimes: {}
    };
  }

  refresh = () => {
    this.props.clearZone();
    this.props.clearRestourant();
    this.props.clearManager();
    this.props.fetchRestaurant(this.props.currentID._id);
    this.props.fetchZone();
    this.props.fetchManager(this.props.currentID._id);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.restaurant) {
      const workTimeForEveryDayWithMoment = {};

      for (const key in nextProps.restaurant.workTimeForEveryDay) {
        if (nextProps.restaurant.workTimeForEveryDay.hasOwnProperty(key)) {
          const value = nextProps.restaurant.workTimeForEveryDay[key];

          workTimeForEveryDayWithMoment[key] = [
            {
              from: moment(
                value.from.hours
                  ? value.from.hours
                  : defaultworkTimeForEveryDay,
                'HH'
              ).format('HH:mm'),
              to: moment(
                value.to.hours ? value.to.hours : defaultworkTimeForEveryDay,
                'HH'
              ).format('HH:mm'),
              close: Boolean(value.close),
              selfDeliveryClose: Boolean(value.selfDeliveryClose)
            }
          ];
        }
      }

      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...nextProps.restaurant,
          workTimeForEveryDay: workTimeForEveryDayWithMoment
        }
      }));
      if (!this.props.managers)
        this.props.fetchManager(nextProps.restaurant._id);
    }
  }

  async componentDidMount() {
    const script = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;
    await this.fetchScript(script);

    if (this.props.match.params.id) {
      this.setState(prevState => ({
        ...prevState,
        formAction: 'update'
      }));
      await Promise.all([
        this.props.fetchRestaurant(this.props.match.params.id),
        this.props.fetchZone(),
        this.props.fetchManager(this.props.match.params.id)
      ]);
    } else {
      this.setState(prevState => ({
        ...prevState,
        formAction: 'create'
      }));
    }

    this.setState({ update: false });
  }

  fetchScript = source => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', source);
      script.onload = () => resolve();
      document.body.appendChild(script);
    });
  };

  initMap = () => {
    const { mapState, restaurantData } = this.state;
    const map = new window.google.maps.Map(this.googleMap, mapState.mapConfig);

    if (
      restaurantData.loc &&
      restaurantData.loc.lat &&
      restaurantData.loc.lon
    ) {
      const coords = {
        lat: restaurantData.loc.lat,
        lng: restaurantData.loc.lon
      };

      new window.google.maps.Marker({
        position: coords,
        map
      });
    }

    this.setState(prevState => ({
      mapState: {
        ...prevState.mapState,
        map
      }
    }));
  };

  getMetroItems = () => {
    const items = [
      { value: 'Не выбрано', text: 'Не выбрано' },
      { value: 'blue', text: 'Синяя' },
      { value: 'red', text: 'Красная' },
      { value: 'green', text: 'Зеленая' },
      { value: 'orange', text: 'Оранжевая' },
      { value: 'purple', text: 'Фиолетовая' }
    ];

    return items;
  };

  getPlanItems = () => {
    const items = [
      { value: 'Не выбрано', text: 'Не выбрано' },
      { value: 'Ресторан на Рыбацком', text: 'Ресторан на Рыбацком' },
      { value: 'Ресторан на Фурштатской', text: 'Ресторан на Фурштатской' },
      { value: 'Ресторан на Баррикадной', text: 'Ресторан на Баррикадной' },
      { value: 'Ресторан на Ветеранов', text: 'Ресторан на Ветеранов' },
      { value: 'Ресторан на Варшавской', text: 'Ресторан на Варшавской' },
      { value: 'Ресторан на Ленинском', text: 'Ресторан на Ленинском' },
      {
        value: 'Ресторан на Стародеревенской',
        text: 'Ресторан на Стародеревенской'
      },
      { value: 'Ресторан на Королёва', text: 'Ресторан на Королёва' },
      { value: 'Ресторан на Искровском', text: 'Ресторан на Искровском' },
      {
        value: 'Ресторан на Васильевском острове',
        text: 'Ресторан на Васильевском острове'
      },
      { value: 'Ресторан на Просвещения', text: 'Ресторан на Просвещения' },
      { value: 'Ресторан на Лесной', text: 'Ресторан на Лесной' },
      { value: 'Ресторан на Кудрово', text: 'Ресторан на Кудрово' },
      { value: 'Ресторан на Петроградке', text: 'Ресторан на Петроградке' },
      { value: 'ЖК "Юбилейный квартал', text: 'ЖК "Юбилейный квартал' },
      { value: 'Ресторан Лени Голикова', text: 'Ресторан Лени Голикова' }
    ];

    return items;
  };

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        [event.target.name]: event.target.value
      }
    }));
  };

  handleChangeLocation = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        loc: {
          ...prevState.restaurantData.loc,
          [event.target.name]: event.target.value
        }
      }
    }));
  };

  handleChangeHatching = name => e =>
    this.setState({
      restaurantData: {
        ...this.state.restaurantData,
        hatching: {
          ...this.state.restaurantData.hatching,
          [name]: e.target.value
        }
      }
    });

  handleChangeHatchingDirection = direction =>
    this.setState({
      restaurantData: {
        ...this.state.restaurantData,
        hatching: {
          ...this.state.restaurantData.hatching,
          direction
        }
      }
    });

  handleChangeSberbank = (event, field) => {
    event.persist();

    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        sberbankAuth: {
          ...prevState.restaurantData.sberbankAuth,
          [field]: event.target.value
        }
      },
      sberbankAuth: {
        ...prevState.sberbankAuth,
        [event.target.name]: event.target.value
      }
    }));
  };

  handleChangeReservation = value => {
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        reservation: {
          ...prevState.restaurantData.reservation,
          floorPlan: value
        }
      }
    }));
  };

  handleMetroLine = value => {
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        metroLineColor: value
      }
    }));
  };

  changeAdditionalServices = event => {
    const name = event.target.name;
    const checked = event.target.checked;

    if (name) {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          additionalServices: {
            ...prevState.restaurantData.additionalServices,
            [name]: checked
          }
        }
      }));
    }
  };

  handleWorkingTime = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        workTime: {
          ...prevState.restaurantData.workTime,
          [event.target.name]: event.target.value
        }
      }
    }));
  };

  renderMetroValue = value => {
    switch (value) {
      case 'blue':
        return 'Синяя';
      case 'green':
        return 'Зеленая';
      case 'red':
        return 'Красная';
      case 'orange':
        return 'Оранжевая';
      case 'purple':
        return 'Фиолетовая';
      default:
        return value;
    }
  };

  handleOpenTime = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        openTime: {
          ...prevState.restaurantData.openTime,
          [event.target.name]: event.target.value
        }
      }
    }));
  };

  getZoneTimesIso = () => {
    const { zoneTimes } = this.state;
    const result = {};

    Object.keys(zoneTimes).forEach(key => {
      if (zoneTimes[key]) {
        result[key] = moment()
          .add(zoneTimes[key], 'hours')
          .toISOString();
      }
    });

    return result;
  };

  handleCreate = () => {
    this.props.createRestaurant(
      {
        ...this.state.restaurantData,
        ...this.getZoneTimesIso()
      },
      () => {
        window.location.href = '/restaurants';
      }
    );
  };

  handleUpdate = () => {
    const data = {
      ...this.state.restaurantData,
      ...this.getZoneTimesIso(),
      sberbankAuth: {}
    };

    const workTime = {};

    for (const key in data.workTimeForEveryDay) {
      if (data.workTimeForEveryDay.hasOwnProperty(key)) {
        const value = data.workTimeForEveryDay[key][0];

        const time = {
          from: value.from.split(':'),
          to: value.to.split(':')
        };
        workTime[key] = {
          from: {
            hours: time.from[0]
          },
          to: {
            hours: time.to[0]
          },
          close: Boolean(value.close),
          selfDeliveryClose: Boolean(value.selfDeliveryClose)
        };
      }
    }
    data.workTimeForEveryDay = workTime;

    if (this.state.sberbankAuth && this.state.sberbankAuth.bankLogin)
      data.sberbankAuth.username = this.state.sberbankAuth.bankLogin;
    if (this.state.sberbankAuth && this.state.sberbankAuth.bankPassword)
      data.sberbankAuth.password = this.state.sberbankAuth.bankPassword;
    if (this.state.sberbankAuth && this.state.sberbankAuth.bankMerchant)
      data.sberbankAuth.merchant = this.state.sberbankAuth.bankMerchant;

    this.props.updateRestaurant(data._id, data, this.refresh);
  };

  handleDelete = () => {
    const conf = window.confirm(
      `Вы точно хотите удалить ${this.state.restaurantData.name}?`
    );

    if (conf) {
      this.props.deleteRestaurant(this.state.restaurantData._id, () => {
        window.location.href = `${window.location.origin}/restaurants`;
      });
    }
  };

  deleteImage = (name, index) => {
    if (name === 'banner') {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          pictures: {
            ...prevState.restaurantData.pictures,
            // потому что тут массив
            banner: ''
          }
        }
      }));
    } else if (name === 'photos') {
      const arrayImages = this.state.restaurantData.pictures.photos;
      arrayImages.splice(index, 1);

      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          pictures: {
            ...prevState.restaurantData.pictures,
            // потому что тут массив
            photos: arrayImages
          }
        }
      }));
    }
  };

  uploadImage = async event => {
    event.persist();

    this.setState(prevState => ({
      ...prevState,
      loaders: {
        ...prevState.loaders,
        [event.target.dataset.loader]: true
      }
    }));

    const picture = await this.props
      .uploadImage('image', event.target.files[0])
      .then(function(result) {
        return result;
      })
      .catch(function(error) {
        alert(error);
      });

    if (event.target.name === 'photos') {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          pictures: {
            ...prevState.restaurantData.pictures,
            // потому что тут массив
            photos: [...prevState.restaurantData.pictures.photos, picture]
          }
        },
        loaders: {
          ...prevState.loaders,
          [event.target.dataset.loader]: false
        }
      }));
    } else if (event.target.name === 'banner') {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          pictures: {
            ...prevState.restaurantData.pictures,
            banner: picture
          }
        },
        loaders: {
          ...prevState.loaders,
          [event.target.dataset.loader]: false
        }
      }));
    }
  };

  handleModeWork = event => {
    if (event.target.id === 'mode-1') {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          workTime: {
            ...prevState.restaurantData.workTime,
            days: [1, 2, 3, 4, 5]
          },
          openTime: {
            ...prevState.restaurantData.openTime,
            days: [1, 2, 3, 4, 5]
          }
        }
      }));
    } else if (event.target.id === 'mode-2') {
      this.setState(prevState => ({
        ...prevState,
        restaurantData: {
          ...prevState.restaurantData,
          workTime: {
            ...prevState.restaurantData.workTime,
            days: [1, 2, 3, 4, 7]
          },
          openTime: {
            ...prevState.restaurantData.openTime,
            days: [1, 2, 3, 4, 7]
          }
        }
      }));
    }
  };

  daDataSelect = suggestion => {
    this.setState(prevState => ({
      ...prevState,
      restaurantData: {
        ...prevState.restaurantData,
        loc: {
          lon: suggestion.data.geo_lon
            ? suggestion.data.geo_lon
            : this.state.restaurantData.loc.lon
            ? this.state.restaurantData.loc.lon
            : '',
          lat: suggestion.data.geo_lat
            ? suggestion.data.geo_lat
            : this.state.restaurantData.loc.lat
            ? this.state.restaurantData.loc.lat
            : ''
        },
        address: {
          city: suggestion.data.city_with_type,
          street: suggestion.data.street_with_type,
          home: suggestion.data.house,
          housing: suggestion.data.block,
          streetType: suggestion.data.street_type_full
        }
      }
    }));
  };

  toggleCreateModal = () => {
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  };

  togglePolygoneModal = () => {
    this.setState({
      showPolygoneModal: !this.state.showPolygoneModal
    });
  };

  toggleModal = manager => {
    this.setState({
      manager: manager || '',
      showManagerModal: !this.state.showManagerModal
    });
  };

  addMapRef = node => {
    if (!this.googleMap) {
      this.googleMap = node;
      this.initMap();
    }
  };

  onChangeTimeClose = (key, prop = 'close') => {
    this.setState({
      restaurantData: {
        ...this.state.restaurantData,
        workTimeForEveryDay: {
          ...this.state.restaurantData.workTimeForEveryDay,
          [key]: [
            {
              ...this.state.restaurantData.workTimeForEveryDay[key][0],
              [prop]: !this.state.restaurantData.workTimeForEveryDay[key][0][
                prop
              ]
            }
          ]
        }
      }
    });
  };

  onChangeTimeInput = (event, key, type) => {
    const { value } = event.target;

    this.setState({
      restaurantData: {
        ...this.state.restaurantData,
        workTimeForEveryDay: {
          ...this.state.restaurantData.workTimeForEveryDay,
          [key]: [
            {
              ...this.state.restaurantData.workTimeForEveryDay[key][0],
              [type]: value
            }
          ]
        }
      }
    });
  };

  renderTimeInputs = () => {
    const { workTimeForEveryDay } = this.state.restaurantData;

    const result = [];

    if (workTimeForEveryDay) {
      for (const key in workTimeForEveryDay) {
        if (workTimeForEveryDay.hasOwnProperty(key)) {
          if (!workTimeForEveryDay[key][0]) continue;
          const index = Number(key);
          result.push(
            <FormGroup
              onlyLabel
              placeholder={days[index]}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'from')}
                style={{ marginRight: '5px' }}
                type='time'
                value={
                  workTimeForEveryDay[key][0].from
                    ? workTimeForEveryDay[key][0].from
                    : ''
                }
              />

              <Input
                onChange={event => this.onChangeTimeInput(event, key, 'to')}
                type='time'
                value={
                  workTimeForEveryDay[key][0].to
                    ? workTimeForEveryDay[key][0].to
                    : ''
                }
              />

              <FormGroup
                checked={workTimeForEveryDay[key][0].close}
                onChange={() => this.onChangeTimeClose(key)}
                placeholder='Закрыт'
                type='checkbox'
              />
              <FormGroup
                checked={workTimeForEveryDay[key][0].selfDeliveryClose}
                onChange={() =>
                  this.onChangeTimeClose(key, 'selfDeliveryClose')
                }
                placeholder='Закрыт самовывоз'
                type='checkbox'
              />
            </FormGroup>
          );
        }
      }
    }
    return result;
  };

  onChangeZone = (key: string) => () => {
    const { restaurantData } = this.state;
    this.setState({
      restaurantData: {
        ...restaurantData,
        [key]: !restaurantData[key]
      }
    });
  };

  handleChangeTimer = timerName => value => {
    this.setState(({ zoneTimes }) => ({
      zoneTimes: {
        ...zoneTimes,
        [timerName]: value
      }
    }));
  };

  renderZoneCheckboxes = () => {
    const { restaurantData } = this.state;

    return (
      <RestaurantFormZones
        onChangeTimer={this.handleChangeTimer}
        onChangeZone={this.onChangeZone}
        restaurantData={restaurantData}
        restaurantZones={restaurantZones}
      />
    );
  };

  render() {
    if (this.state.update) return <Loader />;
    else if (this.state.formAction === 'update' && !this.state.restaurantData)
      return <Loader />;

    const { direction, color } = this.state.restaurantData.hatching;

    const loc = this.state.restaurantData.loc;
    let workDays;
    let coords;

    if (this.props.zone) {
      coords = this.props.zone;
      for (let i = 0; i < coords.length; i++)
        coords[i].color = this.state.colorZone[i];
    }

    if (this.state.restaurantData) {
      if (this.state.restaurantData.workTime) {
        if (this.state.restaurantData.workTime.days)
          workDays = this.state.restaurantData.workTime.days.toString();
      }
    }

    return (
      <div>
        <ManagerCreate
          onPressClose={this.toggleCreateModal}
          restourant={this.state.restaurantData._id}
          show={this.state.showCreateModal}
          toggleModal={this.toggleCreateModal}
        />

        <ManagerModal
          manager={this.state.manager}
          refresh={this.refresh}
          show={this.state.showManagerModal}
          toggleModal={this.toggleModal}
        />
        {this.state.restaurantData._id && (
          <PolygoneModal
            coords={coords}
            loc={loc}
            onPressClose={this.togglePolygoneModal}
            restourantData={this.state.restaurantData}
            show={this.state.showPolygoneModal}
            toggleModal={this.togglePolygoneModal}
          />
        )}

        <HeaderPage
          little
          title={
            this.state.formAction === 'create'
              ? 'Добавить новый ресторан'
              : this.state.restaurantData.name
          }
        />

        <Form
          direction='column'
          onSubmit={
            this.state.formAction === 'update'
              ? this.handleUpdate
              : this.handleCreate
          }
          value={this.state.formAction === 'update' ? 'Обновить' : 'Создать'}
        >
          <div
            ref={node => this.addMapRef(node)}
            style={{ height: '70vh', width: '100%' }}
          />

          <HeaderPage
            onlyTitle
            title='Рейтинг ресторана'
          />
          <div className='flex-row-container end'>
            <FormGroup
              direction='column'
              disabled
              placeholder='Средний балл'
              value={
                this.state.restaurantData.averageRating
                  ? this.state.restaurantData.averageRating
                  : '-'
              }
            />
            <FormGroup
              direction='column'
              disabled
              placeholder='Скорость доставки'
              value={
                this.state.restaurantData.pointsRating
                  ? this.state.restaurantData.pointsRating.speed
                  : '-'
              }
            />
            <FormGroup
              direction='column'
              disabled
              placeholder='Работа оператора'
              value={
                this.state.restaurantData.pointsRating
                  ? this.state.restaurantData.pointsRating.call
                  : '-'
              }
            />
            <FormGroup
              direction='column'
              disabled
              placeholder='Вкус еды'
              value={
                this.state.restaurantData.pointsRating
                  ? this.state.restaurantData.pointsRating.taste
                  : '-'
              }
            />
            <FormGroup
              direction='column'
              disabled
              placeholder='Горячий заказ'
              value={
                this.state.restaurantData.pointsRating
                  ? this.state.restaurantData.pointsRating.hot
                  : '-'
              }
            />
            <FormGroup
              direction='column'
              disabled
              placeholder='Вежливость курьеров'
              value={
                this.state.restaurantData.pointsRating
                  ? this.state.restaurantData.pointsRating.driver
                  : '-'
              }
            />
          </div>

          <HeaderPage
            onlyTitle
            title='Общая информация'
          />
          <FormGroup
            id='name'
            name='name'
            onChange={this.handleChange}
            placeholder='Название'
            required
            value={this.state.restaurantData.name}
          />
          <FormGroup
            labelTop
            onlyLabel
            placeholder='Адрес'
          >
            <DadataSuggestions
              className='dadata-restourant'
              onSelect={this.daDataSelect}
              placeholder='Адрес'
              query={
                this.state.restaurantData.address
                  ? this.state.restaurantData.address.housing
                    ? `${this.state.restaurantData.address.city}, ${this.state.restaurantData.address.street} дом ${this.state.restaurantData.address.home} корпус ${this.state.restaurantData.address.housing}`
                    : `${this.state.restaurantData.address.city}, ${this.state.restaurantData.address.street} дом ${this.state.restaurantData.address.home}`
                  : this.state.suggestion
              }
              token={token}
            />
          </FormGroup>
          <FormGroup
            id='phone'
            name='phone'
            onChange={this.handleChange}
            placeholder='Телефон'
            required
            value={this.state.restaurantData.phone}
          />
          <FormGroup
            id='email'
            name='email'
            onChange={this.handleChange}
            placeholder='Почта'
            required
            value={this.state.restaurantData.email}
          />
          <FormGroup
            id='metroStation'
            name='metroStation'
            onChange={this.handleChange}
            placeholder='Станция метро'
            required
            value={this.state.restaurantData.metroStation}
          />
          <Select
            items={this.getMetroItems()}
            label='Ветка метро:'
            onChange={this.handleMetroLine}
            value={this.renderMetroValue(
              this.state.restaurantData.metroLineColor
            )}
          />
          <Select
            items={this.getPlanItems()}
            label='План ресторана:'
            onChange={this.handleChangeReservation}
            value={
              this.state.restaurantData.reservation
                ? this.state.restaurantData.reservation.floorPlan
                : ''
            }
          />
          <FormGroup
            id='iikoId'
            name='iikoId'
            onChange={this.handleChange}
            placeholder='IikoId'
            required
            value={this.state.restaurantData.iikoId}
          />

          <HeaderPage
            onlyTitle
            title='Координаты'
          />
          <div className='flex-row-container'>
            <FormGroup
              direction='column'
              name='lat'
              onChange={this.handleChangeLocation}
              placeholder='Широта'
              required
              value={loc ? loc.lat : ''}
            />
            <FormGroup
              direction='column'
              name='lon'
              onChange={this.handleChangeLocation}
              placeholder='Долгота'
              required
              value={loc ? loc.lon : ''}
            />
          </div>

          <HeaderPage
            onlyTitle
            title='Штриховка'
          />
          <div className='flex-row-container'>
            <Select
              direction='column'
              items={[
                {
                  text: 'Горизонтально',
                  value: '90'
                },
                {
                  text: 'Вертикально',
                  value: '0'
                },
                {
                  text: 'По диагонали слева направо',
                  value: '45'
                },
                {
                  text: 'По диагонали справа налево',
                  value: '-45'
                }
              ]}
              label='Направление'
              onChange={this.handleChangeHatchingDirection}
              value={direction}
            />
            <FormGroup
              direction='column'
              name='color'
              onChange={this.handleChangeHatching('color')}
              placeholder='Цвет'
              value={color}
            />
          </div>

          <HeaderPage
            onlyTitle
            title='Логин Сбербанка'
          />
          <div className='flex-row-container'>
            <FormGroup
              direction='column'
              name='bankLogin'
              onChange={event => this.handleChangeSberbank(event, 'username')}
              placeholder='Логин'
              value={this.state.sberbankAuth.bankLogin}
            />
            <FormGroup
              direction='column'
              name='bankPassword'
              onChange={event => this.handleChangeSberbank(event, 'password')}
              placeholder='Пароль'
              value={this.state.sberbankAuth.bankPassword}
            />
            <FormGroup
              direction='column'
              name='bankMerchant'
              onChange={event => this.handleChangeSberbank(event, 'merchant')}
              placeholder='Мерчант'
              value={this.state.sberbankAuth.bankMerchant}
            />
          </div>

          <HeaderPage
            onlyTitle
            title='Дополнительные сервисы'
          />
          <div className='flex-row-container'>
            <FormGroup
              checked={
                this.state.restaurantData.additionalServices
                  ? this.state.restaurantData.additionalServices.footballStream
                  : false
              }
              id='footballStream'
              name='footballStream'
              onChange={this.changeAdditionalServices}
              placeholder='Трансляции футбольных матчей'
              type='checkbox'
            />
            <FormGroup
              checked={
                this.state.restaurantData.additionalServices
                  ? this.state.restaurantData.additionalServices.animatorProgram
                  : false
              }
              id='animatorProgram'
              name='animatorProgram'
              onChange={this.changeAdditionalServices}
              placeholder='Анимационные программы'
              type='checkbox'
            />
            <FormGroup
              checked={
                this.state.restaurantData.additionalServices
                  ? this.state.restaurantData.additionalServices
                      .cookingMasterClasses
                  : false
              }
              id='cookingMasterClasses'
              name='cookingMasterClasses'
              onChange={this.changeAdditionalServices}
              placeholder='Детские мастер-классы'
              type='checkbox'
            />
            <FormGroup
              checked={
                this.state.restaurantData.additionalServices
                  ? this.state.restaurantData.additionalServices.childRoom
                  : false
              }
              id='childRoom'
              name='childRoom'
              onChange={this.changeAdditionalServices}
              placeholder='Детская игровая комната'
              type='checkbox'
            />
            <FormGroup
              checked={
                this.state.restaurantData.additionalServices
                  ? this.state.restaurantData.additionalServices.internet
                  : false
              }
              id='internet'
              name='internet'
              onChange={this.changeAdditionalServices}
              placeholder='Бесплатный wi-fi без ограничений'
              type='checkbox'
            />
          </div>

          <HeaderPage
            onlyTitle
            title='Часы работы доставки'
          />
          <div className='flex-row-container'>
            <FormGroup
              direction='column'
              onlyLabel
              placeholder='В рабочие дни'
            >
              <InputMask
                className='form-control-input'
                mask='99:99-99:99'
                name='weekHours'
                onChange={this.handleWorkingTime}
                required
                type='text'
                value={
                  this.state.restaurantData.workTime
                    ? this.state.restaurantData.workTime.weekHours
                    : ''
                }
              />
            </FormGroup>
            <FormGroup
              direction='column'
              onlyLabel
              placeholder='В выходные дни'
            >
              <InputMask
                className='form-control-input'
                mask='99:99-99:99'
                name='weekendHours'
                onChange={this.handleWorkingTime}
                required
                type='text'
                value={
                  this.state.restaurantData.workTime
                    ? this.state.restaurantData.workTime.weekendHours
                    : ''
                }
              />
            </FormGroup>
          </div>

          <div>
            <HeaderPage
              onlyTitle
              title='Часы открытия ресторана'
            />
            {this.renderTimeInputs()}
          </div>

          <div>
            <HeaderPage
              onlyTitle
              title='Зоны доставки ретораны'
            />
            {this.renderZoneCheckboxes()}
          </div>

          <HeaderPage
            onlyTitle
            title='Фотография баннера'
          />
          <ImageBlock
            front
            image={
              this.state.restaurantData.pictures &&
              this.state.restaurantData.pictures.banner &&
              this.state.restaurantData.pictures.banner
            }
            loader={this.state.loaders.loaderBanner}
            loaderName='loaderBanner'
            name='banner'
            onDelete={this.deleteImage}
            onLoad={this.uploadImage}
            placeholder='Баннер'
          />

          <HeaderPage
            onlyTitle
            title='Фотогалерея'
          />
          {this.state.restaurantData.pictures ? (
            this.state.restaurantData.pictures.photos &&
            this.state.restaurantData.pictures.photos.length > 0 ? (
              <div>
                {this.state.restaurantData.pictures.photos.map(
                  (link, index) => (
                    <ImageBlock
                      image={this.state.restaurantData.pictures.photos[index]}
                      index={index}
                      key={index}
                      little
                      loader={this.state.loaders.loaderBanner}
                      loaderName={`loadPhoto${index}`}
                      name='photos'
                      onDelete={this.deleteImage}
                      onLoad={this.uploadImage}
                      placeholder={`Фото ${index + 1}`}
                    />
                  )
                )}
                <ImageBlock
                  little
                  loader={this.state.loaders.default}
                  loaderName='default'
                  name='photos'
                  onLoad={this.uploadImage}
                  onlyLoad
                  placeholder='Добавить фото'
                  style={{ minHeight: 108 }}
                />
              </div>
            ) : (
              <ImageBlock
                little
                loader={this.state.loaders.default}
                loaderName='default'
                name='photos'
                onLoad={this.uploadImage}
                onlyLoad
                placeholder='Добавить фото'
                style={{ minHeight: 108 }}
              />
            )
          ) : null}

          <HeaderPage
            onlyTitle
            title='Режим работы'
          />
          <div className='flex-row-container'>
            <FormGroup
              checked={
                this.state.restaurantData.workTime
                  ? Boolean(
                      workDays === '1,2,3,4,5' &&
                        this.state.restaurantData.workTime.weekDays !== ''
                    )
                  : false
              }
              id='mode-1'
              name='mode'
              onChange={this.handleModeWork}
              placeholder='ПН-ПТ-рабочие, СБ-ВС выходные'
              type='radio'
            />
            <FormGroup
              checked={
                this.state.restaurantData.workTime
                  ? Boolean(
                      workDays === '1,2,3,4,7' &&
                        this.state.restaurantData.workTime.weekDays !== ''
                    )
                  : false
              }
              id='mode-2'
              name='mode'
              onChange={this.handleModeWork}
              placeholder='ПН-ЧТ, ВС - рабочие, ПТ-СБ - выходные'
              type='radio'
            />
          </div>

          <HeaderPage
            button='Добавить'
            key='table-title'
            little
            noForm
            onClick={this.toggleCreateModal}
            style={{ marginTop: 30, paddingBottom: 20 }}
            title='Текущие менеджеры'
          />
          {this.props.managers && this.props.managers.length > 0 ? (
            <Table
              data={this.props.managers.map((manager, key) => (
                <tr key={key}>
                  <td>{manager.login}</td>
                  <td align='right'>
                    <SvgButton
                      image='edit'
                      onClick={() => this.toggleModal(manager)}
                    />
                  </td>
                </tr>
              ))}
              key='table'
              title={['Логин', '']}
            />
          ) : this.props.managers && this.props.managers.length === 0 ? (
            <HeaderPage
              little
              notFound
              title='Менеджеры ресторана отстутствуют'
            />
          ) : null}
        </Form>

        {this.state.formAction === 'update' && (
          <Button
            onClick={this.handleDelete}
            style={{ marginTop: 20, width: `${100}%` }}
            value='Удалить'
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurant: state.restaurants.restaurant,
    zone: state.restaurants.zone,
    currentID: state.restaurants.currentID,
    managers: state.restaurants.managers
  };
}

export default connect(mapStateToProps, {
  fetchManager,
  clearManager,
  createRestaurant,
  fetchRestaurant,
  clearRestourant,
  updateRestaurant,
  clearZone,
  fetchZone,
  deleteRestaurant,
  uploadImage
})(RestaurantForm);
