import React, { Component } from 'react';
import { Form, FormGroup, Input } from '../../elements/elements';
import { fetchDaData } from '../../../actions/dadata';

export default class AddressModal extends Component {
  state = {
    city: this.props.data.city,
    street: this.props.data.street,
    home: this.props.data.home,
    housing: this.props.data.housing,
    lat: this.props.data.lat,
    lon: this.props.data.lon,
    streetType: this.props.data.streetType,
    apartment: this.props.data.apartment,
    doorphone: this.props.data.doorphone,
    floor: this.props.data.floor,
    entrance: this.props.data.entrance,
    id: this.props.data._id,
    suggestions: [],
    daDataInput: ''
  };

  getPlaceHolder = name => {
    switch (name) {
      case 'city':
        return 'Город';
      case 'street':
        return 'Улица';
      case 'home':
        return 'Дом';
      case 'housing':
        return 'Корпус';
      case 'streetType':
        return 'Корпус';
      case 'apartment':
        return 'Квартира';
      case 'doorphone':
        return 'Домофон';
      case 'floor':
        return 'Этаж';
      case 'entrance':
        return 'Вход';
      case 'lat':
        return 'Lat';
      case 'lon':
        return 'Lon';
      default:
        return '';
    }
  };

  onChangeInput = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  getInputProps = name => ({
    value: this.state[name],
    name,
    placeholder: this.getPlaceHolder(name),
    onChange: this.onChangeInput
  });

  onSubmit = () => {
    const data = { ...this.state };
    delete data.suggestions;
    delete data.daDataInput;
    delete data.id;

    if (this.props.method === 'POST') this.props.onSubmit(data);
    if (this.props.method === 'PUT')
      this.props.onEditSubmit(data, this.state.id);
  };

  handleClickDaDataInput = async item => {
    const response = await fetchDaData({
      count: 1,
      query: item.value
    });

    this.setState({
      home: response[0].data.house,
      street: response[0].data.street,
      city: response[0].data.city,
      lat: response[0].data.geo_lat,
      lon: response[0].data.geo_lon,
      housing: response[0].data.block,
      daDataInput: item.value,
      suggestions: []
    });
  };

  onChangeInputDadata = async event => {
    const { name, value } = event.target;

    const list = await fetchDaData({
      count: 10,
      query: value,
      locations: [{ city: 'санкт-петербург' }]
    });

    this.setState({ suggestions: list, [name]: value });
  };

  renderDaDataDropDown = () => {
    const { suggestions } = this.state;

    return suggestions.map((item, key) => (
      <div
        className='dadata-value'
        key={key}
        onClick={() => this.handleClickDaDataInput(item)}
      >
        {item.value}
      </div>
    ));
  };

  render() {
    return (
      <div className='address-modal'>
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value={this.props.value}
        >
          <div>
            <Input
              name='daDataInput'
              onChange={this.onChangeInputDadata}
              placeholder='Введите адрес'
              style={{ marginBottom: '20px', position: 'relative' }}
              type='text'
              value={this.state.daDataInput}
            />
            {this.state.suggestions && this.state.suggestions.length ? (
              <div className='dadata-container'>
                {this.renderDaDataDropDown()}
              </div>
            ) : null}
          </div>
          <FormGroup {...this.getInputProps('city')} />
          <FormGroup {...this.getInputProps('street')} />
          <FormGroup {...this.getInputProps('streetType')} />
          <FormGroup {...this.getInputProps('home')} />
          <FormGroup {...this.getInputProps('housing')} />
          <FormGroup {...this.getInputProps('apartment')} />
          <FormGroup {...this.getInputProps('floor')} />
          <FormGroup {...this.getInputProps('doorphone')} />
          <FormGroup {...this.getInputProps('entrance')} />
          <FormGroup {...this.getInputProps('lat')} />
          <FormGroup {...this.getInputProps('lon')} />
        </Form>
      </div>
    );
  }
}
