import {CLEAR_ORDER, UPDATE_ORDER_TIME, FETCH_ORDERS, CLEAR_ORDERS, CHANGE_STATE, SEARCH_ORDER, FETCH_ORDER} from '../actions/ordersActions';

const INITIAL_STATE = {
  orders: [],
  search: false
};

export default function( state = INITIAL_STATE, action )
{
  
  switch( action.type ) {
    case FETCH_ORDERS:
    return {...state, orders: action.payload};

    case FETCH_ORDER:
    return {...state, order: action.payload};
    
    case SEARCH_ORDER:
    return {...state, search: action.payload};

    case CLEAR_ORDER:
    return {...state, search: action.payload};
    
    case CLEAR_ORDERS: 
    return {...state, orders: action.payload};
   
    case UPDATE_ORDER_TIME: 
    return state;

    case CHANGE_STATE: 
    return state;

    default:
      return state;
  }
}