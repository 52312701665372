import React from 'react';
import { connect } from 'react-redux';
import {
  changePasswordManager,
  deleteManager,
  editRightsManagers
} from '../../actions/restaurantActions';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';

class ManagerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      rights: [],
      isViewMonth: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      rights: newProps.manager ? newProps.manager.rights : []
    });
  }

  onCancel() {
    this.props.toggleModal();
    this.props.refresh();
  }

  handleChange(event) {
    const { value } = event.target;

    this.setState({ password: value });
  }

  onChangeCheckbox = event => {
    const { name } = event.target;

    const rights = [...this.state.rights];
    const includedItemId = rights.indexOf(name);

    if (includedItemId !== -1) rights.splice(includedItemId, 1);
    else rights.push(name);

    this.setState({ rights });
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      password: this.state.password
    };

    this.props.changePasswordManager(
      this.props.manager._id,
      data,
      this.props.refresh
    );
    alert('Пароль изменен!');
    this.onCancel();
  }

  editRightsManagers = () => {
    const { rights } = this.state;
    const id = this.props.manager._id;
    const data = {
      rights
    };

    this.props.editRightsManagers(id, data, this.onCancel);
  };

  handleDelete() {
    this.props.deleteManager(this.props.manager._id, this.props.refresh);
    alert('Менеджер удален!');
    this.onCancel();
  }

  render() {
    const login = this.props.manager.login;
    const { rights } = this.state;

    return (
      <div>
        <Modal
          onHide={this.onCancel}
          show={this.props.show}
        >
          <Modal.Body>
            {/* {this.state.campaingData.length < 1 ? <div className="spinner-wrapper"><Spinner/></div> :  */}
            <Form
              id='managerChangeForm'
              onSubmit={this.handleSubmit}
            >
              <h3 className='text-center'>Редактирование менеджера</h3>
              <FormGroup>
                <ControlLabel>Логин:</ControlLabel>
                <FormControl
                  defaultValue={login}
                  disabled
                  name='login'
                  required='required'
                  type='text'
                />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Новый пароль:</ControlLabel>
                <FormControl
                  name='password'
                  onChange={this.handleChange}
                  required='required'
                  type='text'
                  value={this.state.password}
                />
              </FormGroup>
              <h3 className='text-center'>Права доступа</h3>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  checked={rights.indexOf('viewMonth') !== -1}
                  name='viewMonth'
                  onChange={this.onChangeCheckbox}
                  placeholder='Просмотр статистики за месяц'
                  type='checkbox'
                />
                <div style={{ marginLeft: '5px', marginTop: '4px' }}>
                  Просмотр статистики за месяц
                </div>
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  checked={rights.indexOf('viewPeriod') !== -1}
                  name='viewPeriod'
                  onChange={this.onChangeCheckbox}
                  placeholder='Просмотр статистики за период'
                  type='checkbox'
                />
                <div style={{ marginLeft: '5px', marginTop: '4px' }}>
                  Просмотр статистики за период
                </div>
              </label>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='success'
              onClick={this.editRightsManagers}
            >
              Обновить права доступа
            </Button>
            <Button
              bsStyle='success'
              form='managerChangeForm'
              type='submit'
            >
              Заменить пароль
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.handleDelete}
            >
              Удалить менеджера
            </Button>
            {/* <Button bsStyle="danger" onClick={this.onCancel}>Отменить</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentManager: state.restaurants.manager
  };
}

export default connect(
  mapStateToProps,
  { changePasswordManager, deleteManager, editRightsManagers }
)(ManagerModal);
