import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import { updateDeliveryZones } from '../../../actions/restaurantActions';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) this.setState({ zones: false });
  }

  onUpdate = () => {
    const zones = new FormData();
    zones.append('zones', this.picker.getFile());

    this.props.updateDeliveryZones(zones, this.props.toggleModal);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle title='Загрузить зону' />
        <Form
          direction='column'
          onSubmit={this.onUpdate}
          value='Загрузить зону'
        >
          <FormGroup
            labelHidden
            onChange={this.uploadFile}
            placeholder='Загрузить файл'
            ref={node => (this.picker = node)}
            type='file'
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupon: state.modal.modalContent
  };
}

export default connect(mapStateToProps, { updateDeliveryZones })(
  ModalComponent
);
