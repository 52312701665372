import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeLetter, getLetters } from '../actions/lettersActions';
import { HeaderPage, Table } from '../components/elements/elements';
import Select from '../components/Select';

class LettersPage extends Component {
  componentDidMount() {
    this.props.getLetters();
  }

  getTableTitles = () => {
    return ['Название', 'Тип'];
  };

  onSelectType = letter => value => {
    this.props.changeLetter(letter._id, { ...letter, type: value });
  };

  getTypeSelectItems = () => {
    return [
      {
        value: 'adv',
        text: 'Реклама'
      },
      {
        value: 'info',
        text: 'Инфо'
      },
      {
        value: 'admin',
        text: 'Админ'
      }
    ];
  };

  renderLetterTypeSelect = letter => {
    return (
      <Select
        items={this.getTypeSelectItems()}
        onChange={this.onSelectType(letter)}
        value={letter.type}
      />
    );
  };

  renderTableData = () => {
    return this.props.letters.map(letter => (
      <tr key={letter._id}>
        <td>{letter.rusName || letter.name}</td>
        <td>{this.renderLetterTypeSelect(letter)}</td>
      </tr>
    ));
  };

  render() {
    return (
      <div className='letters'>
        <HeaderPage
          little
          title='Письма'
        />

        <Table
          data={this.renderTableData()}
          style={{ marginTop: 50 }}
          title={this.getTableTitles()}
        />
      </div>
    );
  }
}

const mapState = ({ letters }) => ({
  letters: letters.list
});

const mapDispatch = {
  getLetters,
  changeLetter
};

export default connect(
  mapState,
  mapDispatch
)(LettersPage);
