import Select from '../../Select';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import {
  updateRuleBonuses,
  createRuleBonuses
} from '../../../actions/couriersActions';
import {
  fetchRestaurant,
  clearRestourant
} from '../../../actions/restaurantActions';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: {
        from: '',
        to: '',
        amount: '',
        type: ''
      }
    };
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.show && newProps.show) {
      if (newProps.content && newProps.content.rule) {
        this.setState({
          rule: {
            type: newProps.content.rule.category,
            from: newProps.content.rule.from,
            to: newProps.content.rule.to,
            amount: newProps.content.rule.amount
          }
        });
      } else {
        this.setState({
          rule: {
            from: '',
            to: '',
            amount: '',
            type: ''
          }
        });
      }
    }
  }

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      rule: {
        ...prevState.rule,
        [name]: value
      }
    }));
  };

  refreshPage = () => {
    const { content } = this.props;
    this.props.clearRestourant();
    this.props.fetchRestaurant(content.restaurant);
    this.props.toggleModal();
  };

  onCreate = () => {
    const { rule } = this.state;
    const { content } = this.props;
    this.props.createRuleBonuses(content.restaurant, rule, this.refreshPage);
  };

  onUpdate = () => {
    const { rule } = this.state;
    const { content } = this.props;
    this.props.updateRuleBonuses(
      content.restaurant,
      content.rule.index,
      rule,
      this.refreshPage
    );
  };

  getRestourantName = id => {
    const { restaurants } = this.props;
    if (restaurants) {
      for (let i = 0; i < restaurants.length; i++) {
        if (id === restaurants[i]._id) return restaurants[i].name;
      }
    }
  };

  getSelectItems = () => {
    const array = [
      { text: 'Гарантированная', value: 'guaranteed' },
      { text: 'Негарантированная', value: 'nonGuaranteed' },
      { text: 'Золотая миля', value: 'goldenMile' }
    ];

    return array;
  };

  onChangeSelect = value => {
    this.setState(prevState => ({
      rule: {
        ...prevState.rule,
        type: value
      }
    }));
  };

  renderZone = zone => {
    switch (zone) {
      case 'guaranteed':
        return 'Гарантированная';
      case 'nonGuaranteed':
        return 'Негарантированная';
      case 'goldenMile':
        return 'Золотая миля';
      default:
        return zone;
    }
  };

  render() {
    const props = this.props.content;
    const { rule } = this.state;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <ModalTitle
          title={props.rule ? 'Изменить правило' : 'Добавить правило'}
        />
        <Form
          direction='column'
          onSubmit={props.rule ? this.onUpdate : this.onCreate}
          value={props.rule ? 'Сохранить изменения' : 'Добавить правило'}
        >
          <FormGroup
            disabled
            id='restourant'
            name='restourant'
            onChange={this.changeInput}
            placeholder='Ресторан'
            required
            value={this.getRestourantName(props.restaurant)}
          />
          <FormGroup
            onlyLabel
            placeholder='Зона'
          >
            <Select
              default='Выберите зону'
              items={this.getSelectItems()}
              onChange={this.onChangeSelect}
              value={this.renderZone(rule.type)}
            />
          </FormGroup>
          <FormGroup
            id='from'
            maxlength='3'
            name='from'
            onChange={this.changeInput}
            placeholder='От'
            required
            value={rule.from}
          />
          <FormGroup
            id='to'
            maxlength='3'
            name='to'
            onChange={this.changeInput}
            placeholder='До'
            required
            value={rule.to}
          />
          <FormGroup
            id='amount'
            maxlength='5'
            name='amount'
            onChange={this.changeInput}
            placeholder='Стоимость'
            required
            value={rule.amount}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.modal.modalContent,
    restaurants: state.restaurants.restaurants
  };
}

export default connect(
  mapStateToProps,
  { updateRuleBonuses, createRuleBonuses, fetchRestaurant, clearRestourant }
)(ModalComponent);
