import arrayMove from 'array-move';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '../components/Select';
import Loader from '../components/interface/loader';
import { HeaderPage, Table, SvgButton } from '../components/elements/elements';
import {
  fetchProducts,
  catalogUpdate,
  fetchCategories,
  fetchMenu,
  clearCatalog,
  fetchRecommended,
  saveRecommended
} from '../actions/productActions';

class ProductIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      categoryName: localStorage.getItem('CatalogCategoryName')
        ? JSON.parse(localStorage.getItem('CatalogCategoryName'))
        : false,
      categoryID: localStorage.getItem('CatalogCategoryID')
        ? JSON.parse(localStorage.getItem('CatalogCategoryID'))
        : false,

      recommended: []
    };
  }

  componentDidMount() {
    if (this.state.categoryID) {
      this.setState({ update: true });
      this.props.fetchMenu(this.state.categoryID, () => {
        this.setState({ update: false });
      });
    }
    this.props.fetchCategories();
    this.props.fetchRecommended();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.recommended.length &&
      this.props.recommended.length &&
      !prevProps.recommended.length
    ) {
      this.setState({ recommended: this.props.recommended });
    }
  }

  componentWillUnmount() {
    this.props.clearCatalog();
  }

  getTitles = () => {
    return ['Название', 'Описание', 'ID', 'Цена', 'Размер', ''];
  };

  findProductIndex = product =>
    this.state.recommended.findIndex(p => p._id === product._id);

  onChangeSelect = (value, name) => {
    this.setState({ update: true });

    this.props.fetchMenu(value, () => {
      this.setState({ categoryID: value, categoryName: name, update: false });
      localStorage.setItem('CatalogCategoryID', JSON.stringify(value));
      localStorage.setItem('CatalogCategoryName', JSON.stringify(name));
    });
  };

  onRecommended = (product, isRemove) => {
    return () => {
      const recommended = [...this.state.recommended];
      const matchId = this.findProductIndex(product);

      if (isRemove) {
        recommended.splice(matchId, 1);
      } else {
        if (matchId !== -1) {
          return alert('Продукт уже активен');
        }
        recommended.push(product);
      }

      this.setState({ recommended });
    };
  };

  onSort = (product, isTop) => {
    return () => {
      const recommended = [...this.state.recommended];
      const index = this.findProductIndex(product);

      this.setState({
        recommended: arrayMove(
          recommended,
          index,
          isTop ? index - 1 : index + 1
        )
      });
    };
  };

  onSave = () => {
    const { recommended } = this.state;

    if (recommended.length < 3 || recommended.length > 4) {
      return alert('Необходимо выбрать от 3-х до 4-х продуктов');
    }

    this.props.saveRecommended({
      dishes: recommended.map(product => product._id)
    });
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map(category =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  renderTableRow = (items, isRecommended) =>
    items.map(product => (
      <tr key={product._id}>
        <td>{product.name}</td>
        <td>{product.description}</td>
        <td>{product._id}</td>
        <td>{product.price}</td>
        <td>{product.additionalInfo ? product.additionalInfo.size : '-'}</td>
        <td>
          <div
            style={{ display: 'flex', float: 'right', alignItems: 'center' }}
          >
            {isRecommended && (
              <div className='recommended-sort'>
                <SvgButton
                  image='arrow'
                  onClick={this.onSort(product, true)}
                />
                <SvgButton
                  image='arrow'
                  onClick={this.onSort(product)}
                />
              </div>
            )}
            <SvgButton
              image={isRecommended ? 'delete' : 'add'}
              onClick={this.onRecommended(product, isRecommended)}
            />
          </div>
        </td>
      </tr>
    ));

  renderRecommended = () => {
    const { recommended } = this.state;

    return (
      <div className='recommended'>
        <HeaderPage
          little
          title='Активные'
        />
        <Table
          data={this.renderTableRow(recommended, true)}
          title={this.getTitles()}
        />
      </div>
    );
  };

  renderCategoriesSelect = () => {
    const { update, categoryName } = this.state;
    const { categories } = this.props;

    return (
      !update &&
      categories && (
        <Select
          default='Выберите категорию'
          items={this.getItems()}
          onChange={this.onChangeSelect}
          style={{ marginBottom: 50 }}
          value={categoryName}
        />
      )
    );
  };

  renderProductsTable = () => {
    const { products } = this.props;

    if (!products) return null;

    if (Array.isArray(products)) {
      if (!products.length) {
        return (
          <>
            <HeaderPage
              little
              style={{ opacity: 0.5, justifyContent: 'center' }}
              title={`В «${this.state.categoryName}» товаров нет...`}
            />
          </>
        );
      }
    }

    return (
      <Table
        data={this.renderTableRow(products)}
        title={this.getTitles()}
      />
    );
  };

  renderProductList = () => {
    return (
      <>
        <HeaderPage
          little
          title='Список продуктов'
        />
        {this.renderCategoriesSelect()}
        {this.renderProductsTable()}
      </>
    );
  };

  render() {
    const { update } = this.state;

    if (update) return <Loader />;

    return (
      <div>
        <HeaderPage
          button='Сохранить'
          onClick={this.onSave}
          title='Очень рекомендуем'
        />

        {this.renderRecommended()}

        {this.renderProductList()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.products.categories,
    products: state.products.products,
    recommended: state.products.recommended
  };
}
export default connect(mapStateToProps, {
  clearCatalog,
  fetchProducts,
  catalogUpdate,
  fetchCategories,
  fetchMenu,
  fetchRecommended,
  saveRecommended
})(ProductIndex);
