import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { setDeliveredBy } from '../actions/ordersActions';

import Loader from '../components/interface/loader';
import Modal from '../components/isolatedModal';
import {
  HeaderPage,
  Table,
  Button,
  Input
} from '../components/elements/elements';

import Select from '../components/Select';
import { fetchCourierOrders, editBonuses } from '../actions/couriersActions';
import { PaginationBlock } from '../components/elements/paginations';

import OrderGeodata from '../components/OrderGeodata';

const ordersModalTitle = [
  'Адрес',
  'Менеджер / Админ',
  'Бонусы курьеру',
  'Время отправки курьера',
  'Время доставки заказа',
  'Период доставки (мин)',
  'Номер заказа',
  'Номер заказа liko',
  'Статус',
  'Сумма',
  'Кем доставлено',
  'Сервис геокодирования',
  'Координаты',
  ''
];

const selectItems = [
  {
    text: 'Закрыты',
    value: ''
  },
  {
    text: 'В пути',
    value: 'onWay'
  },
  {
    text: 'Доставлены',
    value: 'delivered'
  },
  {
    text: 'Отменены',
    value: 'cancelled'
  }
];

const deliveredByItems = [
  {
    text: 'Все',
    value: ''
  },
  {
    text: 'Остальные',
    value: 'other'
  },
  {
    text: 'Iiko',
    value: 'iiko'
  },
  {
    text: 'Автоматически',
    value: 'auto'
  },
  {
    text: 'Курьером',
    value: 'courier'
  }
];

class CourierOrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: true,
      show: false,
      orderId: '',
      order: {},
      type: '',
      periodText: '',
      period: '',
      from: '',
      to: '',
      deliveredByText: '',
      deliveredBy: '',
      state: ''
    };
  }

  async componentDidMount() {
    await this.fetchRequests();
    this.setState({ update: false });
  }

  loadData = page => {
    this.fetchRequests({ page });
  };

  translateDate = date => {
    const newDate = moment(date).format('LLL');
    return newDate;
  };

  toogleModal = id => {
    this.setState({ show: true, orderId: id });
  };

  onHide = () => {
    this.setState({ show: false });
  };

  editBonuses = () => {
    const { orderId, bonus } = this.state;

    const data = {
      amount: bonus
    };

    const cb = () => {
      const { page } = this.props.pagination;
      this.setState({ show: false });
      this.fetchRequests({ page });
    };

    this.props.editBonuses(orderId, data, cb);
  };

  onChangeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (!value) event.target.style.background = 'white';
    if (value === '-' || value < 0) event.target.style.background = '#ffa3a3';
    if (value > 0) event.target.style.background = '#a9ffa3';

    this.setState({ [name]: value });
  };

  handleChangeSelect = name => {
    return value => {
      this.setState({ [name]: value });
    };
  };

  getItems = () => {
    const items = [
      { value: 'day', text: 'День' },
      { value: 'week', text: 'Неделя' },
      { value: 'month', text: 'Месяц' },
      { value: 'custom', text: 'Выбрать период' }
    ];

    return items;
  };

  fetchRequests = async (data = {}) => {
    const { period, from, to, state, deliveredBy } = this.state;

    const query = { ...data };

    if (state) query.state = state;
    if (deliveredBy) query.deliveredBy = deliveredBy;
    if (period !== 'custom') query.period = period;
    if (from)
      query.from = moment(from, 'YYYY.MM.DD')
        .add('hours', 3)
        .toISOString();
    if (to)
      query.to = moment(to, 'YYYY.MM.DD')
        .add('hours', 3)
        .toISOString();

    this.setState({ update: true });
    await this.props.fetchCourierOrders(this.props.match.params.id, query);
    this.setState({ update: false });
  };

  onChangePeriodSelect = (value, text) => {
    this.setState({ period: value, periodText: text, from: '', to: '' });
  };

  renderPeriodInputs = () => {
    const { period } = this.state;

    if (period === 'custom') {
      return (
        <div className='courier-orders-page-custom-period-container'>
          <input
            className='input-element'
            name='from'
            onChange={this.onChangeInput}
            placeholder='от'
            type='date'
            value={this.state.from}
          />
          <input
            className='input-element courier-orders-page-custom-period-container-date-picker-to'
            name='to'
            onChange={this.onChangeInput}
            placeholder='до'
            type='date'
            value={this.state.to}
          />
        </div>
      );
    }
  };

  onSelect = id => value => {
    this.props.setDeliveredBy(id, { deliveredBy: value });
  };

  getDeliveredBy = ({ deliveredBy, iikoId, deliveryFlagUpdatedBy }) => {
    return (
      <div>
        {deliveryFlagUpdatedBy && deliveryFlagUpdatedBy.login && (
          <span style={{ color: 'red' }}>
            Изменен: {deliveryFlagUpdatedBy.login}
          </span>
        )}
        <Select
          items={deliveredByItems.slice(1)}
          onChange={this.onSelect(iikoId)}
          value={deliveredBy || 'other'}
        />
      </div>
    );
  };

  getOrderUsers = ({ problemOrderRequest }) => {
    if (problemOrderRequest) {
      let result = '';

      if (problemOrderRequest.user && problemOrderRequest.user.login)
        result += `${problemOrderRequest.user.login} /`;
      else result += '- /';

      if (problemOrderRequest.admin && problemOrderRequest.admin.login)
        result += ` ${problemOrderRequest.admin.login}`;
      else result += ' -';

      return result;
    }

    return '- / -';
  };

  render() {
    const { update, show, periodText } = this.state;
    const { orders } = this.props;

    if (update) return <Loader />;
    return (
      <div>
        <Modal
          onHide={this.onHide}
          show={show}
        >
          <HeaderPage
            little
            title='Редактировать бонусы'
          />
          <Input
            name='bonus'
            onChange={this.onChangeInput}
            placeholder='Введите кол-во бонусов'
            value={this.state.bonus}
          />
          <Button
            little
            onClick={this.editBonuses}
            style={{ marginTop: '10px' }}
            value='Редактировать'
          />
        </Modal>

        <HeaderPage title='Заказы курьера'>
          <Select
            items={this.getItems()}
            onChange={this.onChangePeriodSelect}
            value={periodText}
          />
          <Select
            items={selectItems}
            onChange={this.handleChangeSelect('state')}
            value={this.state.state}
          />
          <Select
            items={deliveredByItems}
            onChange={this.handleChangeSelect('deliveredBy')}
            value={this.state.deliveredBy}
          />
        </HeaderPage>
        <div className='courier-orders-page-filters-bottom-section'>
          {this.renderPeriodInputs()}
          <Button
            className='courier-orders-page-filters-submit-button'
            onClick={() => this.fetchRequests()}
            value='Применить'
          />
        </div>
        <Table
          data={orders.map((order, key) => (
            <tr key={key}>
              <td>{order.address ? order.address : '-'}</td>
              <td>{this.getOrderUsers(order)}</td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    {order.bonus || order.bonus === 0 ? order.bonus : '-'}
                  </div>
                  <div>
                    {order.editor ? `Изменено: ${order.editor.login}` : ''}
                  </div>
                </div>
              </td>
              <td>
                {order.sendTime ? this.translateDate(order.sendTime) : '-'}
              </td>
              <td>
                {order.deliveryTime
                  ? this.translateDate(order.deliveryTime)
                  : '-'}
              </td>
              <td>{order.deliveryPeriod ? order.deliveryPeriod : '-'}</td>
              <td>{order.number ? order.number : '-'}</td>
              <td>{order.orderNumber ? order.orderNumber : '-'}</td>
              <td>{order.state ? order.state : '-'}</td>
              <td>{order.sum ? order.sum : '-'}</td>
              <td>{this.getDeliveredBy(order)}</td>
              <OrderGeodata order={order} />
              <td>
                <Button
                  little
                  onClick={() => this.toogleModal(order.iikoId)}
                  value='Редактировать бонусы'
                />
              </td>
            </tr>
          ))}
          style={{ marginTop: '50px' }}
          title={ordersModalTitle}
        />
        <PaginationBlock
          changePage={i => this.loadData(i)}
          count={this.props.pagination.totalPages}
          page={this.props.pagination.page}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.couriers.courierOrders,
    pagination: state.couriers.pagination
  };
}

export default connect(mapStateToProps, {
  fetchCourierOrders,
  editBonuses,
  setDeliveredBy
})(CourierOrdersPage);
