import React, { Component } from 'react';

import './style.scss';

export default class CustomAlert extends Component {
  render() {
    if (this.props.show) {
      return <div className='custom-alert'>{this.props.children}</div>;
    }
    return null;
  }
}
