import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN';
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';
export const UPDATE_CAMPAING = 'UPDATE_CAMPAING';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const FETCH_CAMPAIGNS_LIST = 'FETCH_CAMPAIGNS_LIST';
export const CHANGE_INDEX = 'CHANGE_INDEX';

export function clearCampaigns() {
  return {
    type: CLEAR_CAMPAIGNS,
    payload: []
  };
}

export const fetchCampaigns = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CAMPAIGNS,
        payload: json
      });
      break;

    case 401:
      dispatch(logOut());
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCampaignsList = () => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns/list`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CAMPAIGNS_LIST,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const fetchCampaing = id => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_CAMPAIGN,
        payload: json
      });
      break;
    case 401:
      dispatch(logOut());
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const changeIndex = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/campaigns/${id}/set_index`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify({ index: parseInt(data, 10) })
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: CHANGE_INDEX,
        payload: json
      });
      if (callback) await callback({ status, json });
      break;

    case 401:
      dispatch(logOut());
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteCampaing = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/campaigns/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: json
      });
      if (callback) await callback({ status, json });
      alert('Акция успешно удалена');
      break;
    case 401:
      dispatch(logOut());
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createCampaing = (data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/campaigns`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: CREATE_CAMPAIGN,
        payload: json
      });
      if (callback) await callback({ status, json });
      alert('Акция успешно создана');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateCampaing = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/campaigns/${id}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPDATE_CAMPAING,
        payload: json
      });
      if (callback) await callback({ status, json });
      alert('Акция успешно обновлена');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
