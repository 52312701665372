import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userInfo } from '../actions/loginActions';
import Loader from '../components/interface/loader';
import Sidebar from '../components/interface/sidebar';

import Routes from '../components/interface/routes';
import Modal from '../components/modal/modal';
import '../css/style.scss';
import { SvgButton } from '../components/elements/elements';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hide: false,
      openSideBar: false
    };
    this.openSideBar = this.openSideBar.bind(this);
    this.getRef = this.getRef.bind(this);
    this.checkToken();
    this.windowEvent = this.windowEvent.bind(this);
  }

  componentDidMount() {
    const window = document.getElementsByTagName('body')[0];
    window.addEventListener('click', this.windowEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowEvent);
  }

  checkToken = () => {
    if (this.props.userToken) {
      this.props.userInfo(this.props.userToken, null, () => {
        this.setState({ loading: false, hide: true });
      });
    }
  };

  loaderRemove = () => {
    if (this.state.loading) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
      setTimeout(() => {
        this.setState({ hide: true });
      }, 1500);
    }
  };

  loaderState = () => {
    if (!this.state.loading && this.state.hide) return 'fade hide';
    else if (!this.state.loading) return 'fade';
    return '';
  };

  windowEvent = event => {
    const target = event.target.closest('#sidebar');

    if (!this.sidebar) return;
    if (target === this.sidebar) return;

    if (this.state.openSideBar) {
      this.setState({
        openSideBar: false
      });
    }
  };

  openSideBar() {
    this.setState({ openSideBar: !this.state.openSideBar });
  }

  getRef(ref) {
    this.sidebar = ref;
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.userToken && newProps.userToken) this.loaderRemove();
  }

  renderPage = () => {
    const { userType } = this.props;

    const array = [<Routes key='routes' />];

    if (this.props.userToken) {
      array.push(
        <div
          className='mobile-menu'
          onClick={this.openSideBar}
          ref={ref => this.getRef(ref)}
        >
          <SvgButton image='view-list' />
        </div>
      );
      if (userType !== 'regionalManager') {
        array.push(<Modal key='modal' />);
      }
      array.push(<Sidebar
        key='navigations'
        open={this.state.openSideBar}
      />);
      array.push(
        <Loader
          className={this.loaderState()}
          key='loading'
        >
          <p>Подождите, идет авторизация...</p>
        </Loader>
      );
    }

    return array;
  };

  render() {
    const content = this.renderPage();
    return content;
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.login.token,
    userType: state.login.type
  };
}

export default hot(module)(
  withRouter(
    connect(
      mapStateToProps,
      { userInfo }
    )(App)
  )
);
