import React from 'react';
import './SelectItem.css';

class SelectItem extends React.Component {
  onClick = event => {
    if (this.props.onClick) this.props.onClick(event);
  };

  getClassName = () => {
    let className = 'select-element-item';
    if (this.props.default) className += ' default';
    if (this.props.disabled) className += ' disabled';
    if (this.props.selected) className += ' selected';
    if (this.props.multiple) className += ' multiple';

    return className;
  };

  render() {
    return (
      <div
        className={this.getClassName()}
        data-value={this.props.value ? this.props.value : ''}
        onClick={this.props.default ? null : this.onClick}
      >
        {this.props.text ? this.props.text : ''}
      </div>
    );
  }
}

export default SelectItem;
