import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  clearAllUsers,
  updateUsers,
  fetchAllUsers
} from '../../../actions/usersActions';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: '',
      reason: ''
    };
  }

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.setState({
        balance: '',
        reason: ''
      });
    }
  }

  refreshPage = () => {
    this.props.toggleModal();
    this.props.clearUsers();
    this.props.clearAllUsers();
    this.props.fetchAllUsers(this.props.data.user, 'all');
    this.props.fetchUsers(this.props.data.user, 'all');
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  onSubmit = () => {
    if (this.props.data.user) {
      const data = {
        _id: this.props.data.data._id,
        login: this.props.data.user,
        balance: this.state.balance,
        reason: this.state.reason
      };

      this.props.updateUsers(data, this.refreshPage);
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Изменить кол-во баллов'
        >
          <ModalTitle title='Изменить кол-во баллов' />
          <FormGroup
            id='balance'
            maxlength='4'
            name='balance'
            onChange={this.changeInput}
            placeholder='Баланс'
            required
            value={this.state.balance}
          />
          <FormGroup
            id='reason'
            maxlength='100'
            name='reason'
            onChange={this.changeInput}
            placeholder='Причина изменения'
            required
            value={this.state.reason}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  fetchUsers,
  fetchAllUsers,
  clearAllUsers,
  clearUsers,
  updateUsers
})(ModalComponent);
