import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const REFRESH_GROUPS = 'REFRESH_GROUPS';

export const clearGroups = callback => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_GROUPS,
    payload: false
  });
};

export const refreshGroups = () => async (dispatch, getState) => {
  dispatch({
    type: REFRESH_GROUPS,
    payload: 'Обновление'
  });
};

export const fetchGroups = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(
    `${API.url}/menu/categories/${id}?v=2&admin=true`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: API.token
      }
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();

      dispatch({
        type: FETCH_GROUPS,
        payload: json
      });

      if (callback) await callback(json);
      break;

    case 500:
      alert('Ошибка сервера');
      break;
    case 401:
      dispatch(logOut());
      break;
    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const updateGroup = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/custom_groups/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      alert('Продукт обновлен!');
      break;

    case 400:
      alert(
        'Сайт уже используется другим администратором, Вы не можете сейчас внести изменения'
      );
      break;
    case 401:
      dispatch(logOut());
      break;
    case 404:
      alert('Группа с данным ID не найдена');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const createGroup = (data, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/custom_groups`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;

    case 409:
      alert('Один или несколько продуктов уже используюьтся другой группой');
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteGroup = (id, callback) => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/custom_groups/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const deleteGroupProducts = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(
    `${API.url}/custom_groups/${id}/remove_products`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      },
      body: JSON.stringify(data)
    }
  );

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};

export const addGroupProducts = (id, data, callback) => async (
  dispatch,
  getState
) => {
  const response = await fetch(`${API.url}/custom_groups/${id}/add_products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    },
    body: JSON.stringify(data)
  });

  const status = await response.status;

  switch (status) {
    case 200:
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 409:
      alert('Один или несколько продуктов уже используюьтся другой группой');
      break;

    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
