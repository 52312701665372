import { logOut } from './loginActions';

export const API = {
  url: `https://${process.env.REACT_APP_BASE_URL}/api`
  // url: 'http://89.207.66.142:1776/api'
};

export const googleKey = 'AIzaSyBaCOVAHU2pzEz6pItQ6L8qjdlDZKfgTFs';
export const server = `https://${process.env.REACT_APP_BASE_URL}`;
// export const server = 'http://192.168.88.28:1776';

export const UPLOADIMAGE_CAMPAIGN = 'UPLOADIMAGE_CAMPAIGN';

export const uploadImage = (name, file, result) => async (
  dispatch,
  getState
) => {
  const body = new FormData();
  body.append(name, file);

  const response = await fetch(`${API.url}/images/`, {
    method: 'post',
    headers: {
      Authorization: getState().login.token
    },
    body
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: UPLOADIMAGE_CAMPAIGN,
        payload: json
      });
      return result === 'all' ? json.file : json.file.url;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
