import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/interface/loader';
import {
  fetchVacancies,
  clearVacancies,
  deleteVacancy
} from '../actions/vacanciesActions';
import { toggleVacanciesModal } from '../actions/modalActions';
import {
  HeaderPage,
  Table,
  SvgButton,
  Cross
} from '../components/elements/elements';

class VacanciesIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showCreateModal: false
    };
  }

  title = ['Название', ''];

  componentDidMount() {
    this.props.fetchVacancies();
  }

  componentWillUnmount() {
    this.props.clearVacancies();
  }

  refresh = () => {
    this.props.clearVacancies();
    this.props.fetchVacancies();
  };

  toggleModal = () => {
    this.setState({
      show: !this.state.show
    });
  };

  onDelete = vacancy => this.props.deleteVacancy(vacancy._id, this.refresh);

  render() {
    return (
      <div>
        <HeaderPage
          button='Добавить'
          onClick={this.props.toggleVacanciesModal}
          title='Вакансии'
        />
        {this.props.vacancies.length === 0 ? (
          <Loader />
        ) : (
          <Table
            data={
              this.props.vacancies &&
              this.props.vacancies.map((vacancy, key) => (
                <tr key={key}>
                  <td>{vacancy.name}</td>
                  <td>
                    <SvgButton
                      image='edit'
                      onClick={() => this.props.toggleVacanciesModal(vacancy)}
                    />
                    <Cross onClick={() => this.onDelete(vacancy)} />
                  </td>
                </tr>
              ))
            }
            title={this.title}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vacancies: state.vacancies.vacancies
  };
}

export default connect(mapStateToProps, {
  fetchVacancies,
  deleteVacancy,
  clearVacancies,
  toggleVacanciesModal
})(VacanciesIndex);
