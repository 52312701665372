import {
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  UPLOADIMAGE_CATEGORY
} from '../actions/categoryActions';

const INITIAL_STATE = {
  categories: [],
  category: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return { ...state, categories: action.payload };

    case FETCH_CATEGORY:
      return { ...state, category: action.payload };

    case UPLOADIMAGE_CATEGORY:
      return { ...state, category: action.payload };

    default:
      return state;
  }
}
