export function script(url) {
  if (Array.isArray(url)) {
    const prom = [];
    url.forEach(function(item) {
      prom.push(script(item));
    });
    return Promise.all(prom);
  }

  return new Promise(function(resolve, reject) {
    let r = false;
    const t = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.src = url;
    s.async = true;

    const onComplete = function() {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };

    s.onerror = reject;
    s.onabort = reject;
    s.onload = onComplete;
    s.onreadystatechange = onComplete;

    t.parentNode.insertBefore(s, t);
  });
}
