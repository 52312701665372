import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleRuleBonusesSystemModal } from '../actions/modalActions';
import { deleteRuleBonuses } from '../actions/couriersActions';
import {
  fetchRestaurants,
  fetchRestaurant,
  clearRestourant
} from '../actions/restaurantActions';
import {
  HeaderPage,
  Table,
  Cross,
  SvgButton,
  Button
} from '../components/elements/elements';

import Select from '../components/Select';
import Loader from '../components/interface/loader';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: true,
      restaurantRules: [],
      title: ['Зона', 'От', 'До', 'Стоимость', ''],
      restaurantValue: localStorage.getItem('BonusRestaurantValue')
        ? JSON.parse(localStorage.getItem('BonusRestaurantValue'))
        : false,
      restaurantName: localStorage.getItem('BonusRestaurantName')
        ? JSON.parse(localStorage.getItem('BonusRestaurantName'))
        : false
    };
  }

  async componentDidMount() {
    await this.props.fetchRestaurants();
    if (this.state.restaurantValue)
      await this.props.fetchRestaurant(this.state.restaurantValue);

    this.setState({ update: false });
  }

  refreshPage = () => {
    this.props.clearRestourant();
    this.props.fetchRestaurant(this.state.restaurantValue);
  };

  renderZone = zone => {
    switch (zone) {
      case 'guaranteed':
        return 'Гарантированная';
      case 'nonGuaranteed':
        return 'Негарантированная';
      case 'goldenMile':
        return 'Золотая миля';
      default:
        return zone;
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.restaurant) {
      let guaranteed = [];
      let nonGuaranteed = [];
      let goldenMile = [];
      if (newProps.restaurant.courierBonuses.guaranteed)
        guaranteed = newProps.restaurant.courierBonuses.guaranteed.map(a => {
          const item = a;
          item.category = 'guaranteed';
          return item;
        });
      if (newProps.restaurant.courierBonuses.nonGuaranteed)
        nonGuaranteed = newProps.restaurant.courierBonuses.nonGuaranteed.map(
          a => {
            const item = a;
            item.category = 'nonGuaranteed';
            return item;
          }
        );
      if (newProps.restaurant.courierBonuses.goldenMile)
        goldenMile = newProps.restaurant.courierBonuses.goldenMile.map(a => {
          const item = a;
          item.category = 'goldenMile';
          return item;
        });

      this.setState({
        restaurantRules: [...goldenMile, ...guaranteed, ...nonGuaranteed]
      });
    }
  }

  getSelectItems = () => {
    const { restaurants } = this.props;
    const array = [];

    if (restaurants && restaurants.length) {
      for (let i = 0; i < restaurants.length; i++) {
        array.push({
          text: restaurants[i].name,
          value: restaurants[i]._id
        });
      }
    }

    return array;
  };

  onDeleteRule = (index, type) => {
    const { restaurantValue } = this.state;

    const a = window.confirm('Вы точно хотите удалить это правило?');
    if (a) {
      const data = { type };
      this.props.deleteRuleBonuses(
        restaurantValue,
        index,
        data,
        this.refreshPage
      );
    }
  };

  onChangeSelect = (value, name) => {
    this.setState({ update: true });

    this.props.fetchRestaurant(value, () => {
      this.setState({
        restaurantValue: value,
        restaurantName: name,
        update: false
      });
      localStorage.setItem('BonusRestaurantValue', JSON.stringify(value));
      localStorage.setItem('BonusRestaurantName', JSON.stringify(name));
    });
  };

  render() {
    const {
      update,
      restaurantValue,
      restaurantName,
      restaurantRules,
      title
    } = this.state;

    if (update) return <Loader />;
    return (
      <div>
        <HeaderPage title='Бонусная система'>
          {restaurantValue && (
            <Button
              onClick={() =>
                this.props.toggleRuleBonusesSystemModal({
                  restaurant: restaurantValue
                })
              }
              value='Добавить'
            />
          )}
        </HeaderPage>
        <Select
          default='Выберите ресторан'
          items={this.getSelectItems()}
          onChange={this.onChangeSelect}
          style={{ marginBottom: 50 }}
          value={restaurantName}
        />

        {restaurantValue && restaurantRules && (
          <Table
            data={restaurantRules.map((a, index) => (
              <tr key={index}>
                <td>{this.renderZone(a.category)}</td>
                <td>{`${a.from} минут`}</td>
                <td>{`${a.to} минут`}</td>
                <td>{a.amount}</td>
                <td>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <SvgButton
                      image='edit'
                      onClick={() =>
                        this.props.toggleRuleBonusesSystemModal({
                          restaurant: restaurantValue,
                          rule: a
                        })
                      }
                      style={{ marginRight: 10 }}
                    />
                    <Cross
                      onClick={() => this.onDeleteRule(a.index, a.category)}
                    />
                  </div>
                </td>
              </tr>
            ))}
            title={title}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    restaurants: state.restaurants.restaurants,
    restaurant: state.restaurants.restaurant
  };
}

export default connect(mapStateToProps, {
  deleteRuleBonuses,
  fetchRestaurants,
  fetchRestaurant,
  clearRestourant,
  toggleRuleBonusesSystemModal
})(SettingsPage);
