import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/interface/loader';

import {
  Modal,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel
} from 'react-bootstrap';
import {
  answerRecall,
  clearRecall,
  publicRecall
} from '../../actions/recallsActions';

class RecallsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.recall.reply
    };
  }

  onCancel = () => {
    this.props.toggleModal();
    this.props.clearRecall();
  };

  answerRecall = () => {
    const data = {
      reply: this.state.answer
    };
    this.props.answerRecall(this.props.recall._id, data, () => {
      this.props.publicRecall(this.props.recall._id, () => {
        this.props.refresh();
        this.onCancel();
      });
    });
  };

  changeHandler = event => {
    if (event) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  render() {
    return (
      <div>
        <Modal
          onHide={this.onCancel}
          show={this.props.show}
        >
          <Modal.Body>
            <Form
              id='campaignForm'
              onSubmit={this.handleSubmit}
            >
              <h3 className='text-center'>Ответ на комментарий</h3>
              {!this.props.recall ? (
                <div className='spinner-wrapper'>
                  <Loader />
                </div>
              ) : (
                <div>
                  <FormGroup>
                    <ControlLabel>Тема отзыва</ControlLabel>
                    <FormControl
                      disabled
                      name='attribute'
                      onChange={this.changeHandler}
                      required
                      type='subject'
                      value={this.props.recall.subject}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Текст отзыва</ControlLabel>
                    <FormControl
                      className='textarea'
                      componentClass='textarea'
                      disabled
                      name='text'
                      onChange={this.changeHandler}
                      required
                      type='text'
                      value={this.props.recall.text}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Ответ на отзыв</ControlLabel>
                    <FormControl
                      className='textarea'
                      componentClass='textarea'
                      name='answer'
                      onChange={this.changeHandler}
                      placeholder='Ответ на комментарий'
                      required
                      type='text'
                      value={this.state.answer}
                    />
                  </FormGroup>
                </div>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='success'
              onClick={this.answerRecall}
            >
              Ответить и опубликовать
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.onCancel}
            >
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  answerRecall,
  clearRecall,
  publicRecall
})(RecallsModal);
