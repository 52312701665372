import { combineReducers } from 'redux';
import CategoriesReducer from './categoriesReducer';
import paymentsReducer from './paymentsReducer';
import ProductsReducer from './productsReducer';
import pushesReducer from './pushesReducer';
import VacanciesReducer from './vacanciesReducer';
import CampaignsReducer from './campaignsReducer';
import RestaurantsReducer from './restaurantsReducer';
import SettingsReducer from './settingsReducer';
import PromocodesReducer from './promocodesReducer';
import UsersReducer from './usersReducer';
import OrdersReducer from './ordersReducer';
import RecallsReducer from './recallsReducer';
import PushReducer from './pushReducer';
import LoginReducer from './loginReducer';
import ModalReducer from './modalReducer';
import StatisticsReducer from './statisticsReducer';
import GroupsReducer from './groupsReducer';
import CouriersReducer from './couriersReducer';
import CouponsReducer from './couponsReducer';
import DeliveryClubReducer from './deliveryClubReducer';
import LettersReducer from './lettersReducer';
import DeletedUsersReducer from './deletedUsersReducer';
import ServicesReducer from './servicesReducer';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  categories: CategoriesReducer,
  campaigns: CampaignsReducer,
  vacancies: VacanciesReducer,
  products: ProductsReducer,
  couriers: CouriersReducer,
  restaurants: RestaurantsReducer,
  settings: SettingsReducer,
  promocodes: PromocodesReducer,
  users: UsersReducer,
  deletedUser: DeletedUsersReducer,
  services: ServicesReducer,
  orders: OrdersReducer,
  recalls: RecallsReducer,
  form: formReducer,
  push: PushReducer,
  login: LoginReducer,
  modal: ModalReducer,
  coupons: CouponsReducer,
  statistics: StatisticsReducer,
  groups: GroupsReducer,
  deliveryClub: DeliveryClubReducer,
  letters: LettersReducer,
  pushes: pushesReducer,
  payments: paymentsReducer
});

export default rootReducer;
