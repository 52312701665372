import { API } from './index';
import { logOut } from './loginActions';

export const FETCH_COUPONS = 'FETCH_COUPONS';
export const CLEAR_COUPONS = 'CLEAR_COUPONS';

export const clearCoupons = callback => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_COUPONS,
    payload: []
  });
};

export const fetchCoupons = callback => async (dispatch, getState) => {
  const response = await fetch(`${API.url}/coupons?skip=0&level=15000`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().login.token
    }
  });

  const status = await response.status;

  switch (status) {
    case 200:
      const json = await response.json();
      dispatch({
        type: FETCH_COUPONS,
        payload: json
      });
      if (callback) await callback();
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
