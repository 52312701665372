import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteCoupon,
  fetchUsers,
  clearUsers
} from '../../../actions/usersActions';
import {
  Modal,
  ModalTitle,
  Cross,
  Table,
  HeaderPage
} from '../../elements/elements';
import { getCouponName } from '../../elements/functions';

import moment from 'moment';

moment.locale('ru');

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      arrayLength: true
    };
  }

  title = [
    'Тип купона',
    'Количество',
    'Код купона',
    'Время действия',
    'Сотрудник',
    ''
  ];

  refreshPage = () => {
    this.props.clearUsers();
    this.props.fetchUsers(this.props.coupons.user, 'all');
  };

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.renderData(newState.coupons.coupons);
      this.updateArrayLength(this.props.coupons.coupons);
    }
  }

  couponQuantity = coupon => {
    if (coupon.couponType.toLowerCase().includes('sticker'))
      return `${coupon.slices} шт.`;
    else if (coupon.couponType === 'lateDelivery') return '1 шт.';
    return '1 шт.';
  };

  couponCode = coupon => {
    return coupon.couponCode;
  };

  deleteCoupon = (id, key) => {
    const array = this.state.items;

    for (let i = 0; i < array.length; i++) {
      if (parseInt(array[i].key, 10) === parseInt(key, 10)) {
        array.splice(i, 1);
        break;
      }
    }

    if (array.length === 0) {
      this.setState({
        items: array,
        arrayLength: false
      });
    } else {
      this.setState({
        items: array,
        arrayLength: true
      });
    }

    const data = { id };
    this.props.deleteCoupon(data, this.refreshPage);
  };

  renderData = coupons => {
    const array = [];

    coupons.map((coupon, key) =>
      array.push(
        <tr key={key}>
          <td>{getCouponName(coupon && coupon.couponType)}</td>
          <td>{this.couponQuantity(coupon)}</td>
          <td>{this.couponCode(coupon)}</td>
          <td>
            {coupon.expireDate
              ? `до ${moment(coupon.expireDate)
                  .locale('ru')
                  .format('DD.MM.YYYY')}`
              : 'Бессрочный'}
          </td>
          <td>
            {coupon.history && coupon.history.giver
              ? coupon.history.giver.login
              : ''}
          </td>
          {this.isEditable && (
            <td>
              <Cross
                noModalCross
                onClick={() => this.deleteCoupon(coupon._id, key)}
              />
            </td>
          )}
        </tr>
      )
    );

    this.setState({
      items: array
    });
  };

  updateArrayLength = array => {
    if (array && array.length === 0) {
      if (this.state.arrayLength) {
        this.setState({
          arrayLength: false
        });
      }
    } else {
      if (!this.state.arrayLength) {
        this.setState({
          arrayLength: true
        });
      }
    }
  };

  get isCallCenter() {
    return this.props.userType === 'callCentre';
  }

  get isReadCallCenter() {
    return this.props.userType === 'readCallCentre';
  }

  get isEditable() {
    return !this.isReadCallCenter;
  }

  render() {
    const coupons = this.props.coupons.coupons;

    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        {coupons && this.state.arrayLength && coupons.length > 0 ? (
          [
            <ModalTitle
              key='modal-coupons'
              title='Купоны пользователя'
            />,
            <Table
              data={this.state.items}
              key='table-coupons'
              title={this.title}
            />
          ]
        ) : (
          <HeaderPage
            little
            notFound
            style={{ paddingBottom: 20 }}
            title='У пользователя нет купонов...'
          />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupons: state.modal.modalContent,
    user: state.users.user,
    userType: state.login.type
  };
}
export default connect(mapStateToProps, {
  getCouponName,
  deleteCoupon,
  fetchUsers,
  clearUsers
})(ModalComponent);
