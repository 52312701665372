import {
  FETCH_RESTAURANTS,
  CLEAR_RESTOURANTS,
  CLEAR_ZONE,
  FETCH_ZONE,
  CLEAR_RESTOURAN,
  UPDATE_RESTAURANT,
  FETCH_RESTAURANT,
  CHANGE_MANAGER,
  DELETE_MANAGER,
  ADD_MANAGER,
  FETCH_MANAGER,
  CLEAR_MANAGER
} from '../actions/restaurantActions';

const INITIAL_STATE = {
  restaurants: [],
  restaurant: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RESTAURANTS:
      return { ...state, restaurants: action.payload };

    case CLEAR_RESTOURANTS:
      return { ...state, restaurants: action.payload };

    case FETCH_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload,
        currentID: action.payload
      };

    case FETCH_ZONE:
      return { ...state, zone: action.payload };

    case UPDATE_RESTAURANT:
      return { ...state, restaurant: action.payload };

    case CLEAR_MANAGER:
      return { ...state, managers: action.payload };

    case CLEAR_RESTOURAN:
      return { ...state, restaurant: action.payload };

    case CLEAR_ZONE:
      return { ...state, zone: action.payload };

    case FETCH_MANAGER:
      return { ...state, managers: action.payload };

    case ADD_MANAGER:
      return { ...state, manager: action.payload };

    case CHANGE_MANAGER:
      return { ...state, manager: action.payload };

    case DELETE_MANAGER:
      return { ...state, manager: action.payload };

    default:
      return state;
  }
}
