import {
  FETCH_PRODUCTS,
  UPDATE_CATALOG,
  CLEAR_CATALOG,
  FETCH_TAGS,
  FETCH_PRODUCT,
  UPLOADIMAGE_PRODUCT,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_MENU,
  UPDATE_PRODUCT,
  CLEAR_CATEGORIES_MENU,
  FETCH_RECOMMENDED
} from '../actions/productActions';

const INITIAL_STATE = {
  products: false,
  product: false,
  recommended: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload.map(a => ({
          name: a.name,
          id: a._id,
          order: a.order
        }))
      };

    case FETCH_CATEGORIES_MENU:
      return { ...state, products: action.payload };

    case CLEAR_CATEGORIES_MENU:
      return { ...state, products: action.payload };

    case FETCH_RECOMMENDED:
      return { ...state, recommended: action.payload };

    case CLEAR_CATALOG:
      return { ...state, products: action.payload };

    case UPDATE_CATALOG:
      return { ...state, products: action.payload };

    case FETCH_PRODUCTS:
      return { ...state, products: action.payload };

    case UPDATE_PRODUCT:
      return state;

    case FETCH_PRODUCT:
      return { ...state, product: action.payload };

    case FETCH_TAGS:
      return {
        ...state,
        tags: action.payload.map(a => ({ tags: a.tags, id: a._id }))
      };

    case UPLOADIMAGE_PRODUCT:
      return { ...state, product: action.payload };

    default:
      return state;
  }
}
