import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendCustomEmail } from '../actions/pushActions';
import { HeaderPage, Form, FormGroup } from '../components/elements/elements';

class PushPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: '',
      text: ''
    };
  }

  onChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  actionNotify = () => {
    if (this.state.text) {
      const conf = window.confirm(
        'Вы уверены, что хотите отправить уведомление на email пользователей?'
      );
      if (conf) {
        this.props.sendCustomEmail(
          { text: this.state.text, subject: this.state.theme },
          () => {
            this.setState({
              theme: '',
              text: ''
            });
          }
        );
      }
    } else alert('Введите текст уведомления');
  };

  render() {
    return [
      <HeaderPage
        key='1'
        title='Email уведомления'
      />,
      <Form
        direction='column'
        key='2'
        onSubmit={this.actionNotify}
        style={{ marginBottom: 60 }}
        value='Отправить'
      >
        <FormGroup
          name='theme'
          onChange={this.onChange}
          placeholder='Тема'
          required
          value={this.state.theme}
        />
        <FormGroup
          direction='column'
          labelHidden
          name='text'
          onChange={this.onChange}
          placeholder='Уведомление'
          required
          style={{ width: `${100}%` }}
          styleInput={{ width: `${100}%` }}
          type='textarea'
          value={this.state.text}
        />
      </Form>
    ];
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.push.campaigns
  };
}

export default connect(mapStateToProps, { sendCustomEmail })(PushPage);
