import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FlexBlock, FormGroup, Input } from '../elements/elements';
import { getRemainingTime } from '../elements/functions';
import { DEFAULT_TIME } from '../../lib/consts';
import Select from '../Select';

import './RestaurantFormZones.scss';

const hoursLimit = {
  min: 1,
  max: 10
};

const selectItems = new Array(hoursLimit.max - hoursLimit.min + 1)
  .fill('')
  .map((el, i) => {
    const value = ++i;
    return {
      value,
      text: `${value} ч.`
    };
  });

const RestaurantFormZones = ({
  restaurantZones,
  restaurantData,
  onChangeZone,
  onChangeTimer
}) => {
  const [selectValues, setSelectValues] = useState({});
  const [remainingTimes, setRamainingTimes] = useState({});

  const updateRamainingTimes = useCallback(() => {
    const result = {};
    restaurantZones.forEach(({ timerName }) => {
      result[timerName] = getRemainingTime(restaurantData[timerName]);
    });
    setRamainingTimes(result);
  }, [restaurantZones, restaurantData]);

  // установка ост времени при выборе в дата пикере
  useEffect(() => {
    updateRamainingTimes();
  }, [updateRamainingTimes]);

  // таймер ост времени
  useEffect(() => {
    let timeout = null;
    const withTimeout = Object.keys(remainingTimes).some(
      key => remainingTimes[key] && remainingTimes[key] !== DEFAULT_TIME
    );

    if (withTimeout) timeout = setTimeout(updateRamainingTimes, 1000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [updateRamainingTimes, remainingTimes]);

  const handleChangeSelect = useCallback(
    timerName => value => {
      setSelectValues(prevState => ({
        ...prevState,
        [timerName]: prevState[timerName] !== value ? value : null
      }));
      onChangeTimer(timerName)(value);
    },
    [setSelectValues, onChangeTimer]
  );

  const zonesBlocks = useMemo(() => {
    return restaurantZones.map(({ timerName, value, label }) => {
      const remainingTime = remainingTimes[timerName] || DEFAULT_TIME;

      return (
        <FlexBlock
          className='zone-timers__block'
          isColumn
          key={value}
        >
          <div className='flex-row-container fullWidth'>
            <FormGroup
              checked={!restaurantData[value]}
              id={value}
              name={value}
              onChange={onChangeZone(value)}
              placeholder={label}
              type='checkbox'
            />
          </div>
          <div className='flex-row-container fullWidth'>
            <Select
              default='Выберите количество часов'
              items={selectItems}
              onChange={handleChangeSelect(timerName)}
              value={selectValues[timerName]}
              withClear
            />
          </div>
          <div className='flex-row-container fullWidth'>
            <Input
              disabled
              value={remainingTime}
            />
          </div>
        </FlexBlock>
      );
    });
  }, [
    restaurantZones,
    restaurantData,
    remainingTimes,
    onChangeZone,
    onChangeTimer
  ]);

  return <div className='zone-timers'>{zonesBlocks}</div>;
};

export default RestaurantFormZones;
