import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/interface/loader';
import InputMask from 'react-input-mask';

import { updateOrderTime } from '../../actions/ordersActions';
import {
  Modal,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  Col
} from 'react-bootstrap';

// eslint-disable-next-line
import DatePicker from 'react-datepicker';
// eslint-disable-next-line
import moment from 'moment';
// eslint-disable-next-line
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line
moment.locale('ru');

class OrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmTime: '',
      deliveryTime: ''
    };
  }

  onCancel = () => {
    this.props.toggleModal();
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const currentDate = moment().format('DD-MM-YYYY');
    const confirmTime = moment
      .utc(`${currentDate} ${this.state.confirmTime}`, 'DD-MM-YYYY HH-mm')
      .toISOString();
    const deliveryTime = moment
      .utc(`${currentDate} ${this.state.deliveryTime}`, 'DD-MM-YYYY HH-mm')
      .toISOString();

    if (confirmTime && deliveryTime) {
      const data = {
        confirmTime,
        deliveryTime
      };

      this.props.updateOrderTime(this.props.order._id, data, () => {
        this.setState({
          confirmTime: '',
          deliveryTime: ''
        });
        this.onCancel();
        this.props.refresh();
      });
    } else {
      if (!confirmTime) alert('Время принятия заказа введено некорректно!');
      if (!deliveryTime) alert('Время доставки заказа введено некорректно!');
    }
  };

  render() {
    return (
      <div>
        <Modal
          onHide={this.onCancel}
          show={this.props.show}
        >
          <Modal.Body>
            {!this.props.order ? (
              <div className='spinner-wrapper'>
                <Loader />
              </div>
            ) : (
              <Form
                id='orderTimeForm'
                onSubmit={this.handleSubmit}
              >
                <h3
                  className='text-center page-header'
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  Время заказа
                </h3>

                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    sm={12}
                  >
                    Время принятия заказа
                  </Col>
                  <Col sm={12}>
                    <InputMask
                      className='form-control-input'
                      mask='99:99'
                      name='confirmTime'
                      onChange={this.handleChange}
                      placeholder='Укажите время принятия заказа'
                      required
                      type='text'
                      value={this.state.confirmTime}
                    />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    sm={12}
                  >
                    Время доставки заказа
                  </Col>
                  <Col sm={12}>
                    <InputMask
                      className='form-control-input'
                      mask='99:99'
                      name='deliveryTime'
                      onChange={this.handleChange}
                      placeholder='Укажите время доставки заказа'
                      required
                      type='text'
                      value={this.state.deliveryTime}
                    />
                  </Col>
                </FormGroup>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle='success'
              form='orderTimeForm'
              type='submit'
            >
              Сохранить
            </Button>
            <Button
              bsStyle='danger'
              onClick={this.onCancel}
            >
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.orders.order
  };
}

export default connect(mapStateToProps, { updateOrderTime })(OrderModal);
